import { RootState } from 'modules/store';
import { createSlice } from '@reduxjs/toolkit';

import {
  ApplicationAccountType,
  ApplicationAccountTypeState,
} from './applicationAccountType.types';

const initialState: ApplicationAccountTypeState = {
  accountType: ApplicationAccountType.personal,
};

const applicationAccountTypeSlice = createSlice({
  name: 'applicationAccountType',
  initialState,
  reducers: {
    setAccountType: (state, { payload }) => {
      state.accountType = payload;
    },
  },
});

export default applicationAccountTypeSlice.reducer;
export const { setAccountType } = applicationAccountTypeSlice.actions;
const selectRoot = (state: RootState) => state.root.application.accountType;
export const selectApplicationAccountType = (state: RootState) =>
  selectRoot(state).accountType;

const prefix = 'APPLICATION_LIST';

export const actionTypes = {
  FETCH_SANDBOX_STARTED: `${prefix}_FETCH_SANDBOX_STARTED`,
  FETCH_SANDBOX_SUCCESS: `${prefix}_FETCH_SANDBOX_SUCCESS`,
  FETCH_SANDBOX_ERROR: `${prefix}_FETCH_SANDBOX_ERROR`,
  FETCH_PRODUCTION_STARTED: `${prefix}_FETCH_PRODUCTION_STARTED`,
  FETCH_PRODUCTION_SUCCESS: `${prefix}_FETCH_PRODUCTION_SUCCESS`,
  FETCH_PRODUCTION_ERROR: `${prefix}_FETCH_PRODUCTION_ERROR`,
  FETCH_SANDBOX_AND_PRODUCTION_STARTED: `${prefix}_FETCH_SANDBOX_AND_PRODUCTION_STARTED`,
  FETCH_SANDBOX_AND_PRODUCTION_SUCCESS: `${prefix}_FETCH_SANDBOX_AND_PRODUCTION_SUCCESS`,
  FETCH_SANDBOX_AND_PRODUCTION_ERROR: `${prefix}_FETCH_SANDBOX_AND_PRODUCTION_ERROR`,
};

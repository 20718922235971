import { apiFetch } from 'modules/shared/fetch';
import { createCorrelationId } from 'utils/createUniqueId';

import {
  GetExplanationsParams,
  UpdateExplanationsParams,
} from './consentExplanation.types';

const correlationId = createCorrelationId();

export default class ConsentExplanationService {
  static async getExplanations({
    appToken,
    appEnvironment,
  }: GetExplanationsParams) {
    const response = await apiFetch(
      `/applications/${appToken}/consent-explanation`,
      {
        // @ts-ignore
        appEnvironment,
        headers: {
          'lean-app-token': appToken,
          'lean-correlation-id': correlationId,
        },
      },
    );

    return response?.data;
  }

  static async updateExplanations({
    appToken,
    body,
    appEnvironment,
  }: UpdateExplanationsParams) {
    const response = await apiFetch(
      `/applications/${appToken}/consent-explanation`,
      {
        // @ts-ignore
        method: 'PUT',
        appEnvironment,
        headers: {
          'lean-app-token': appToken,
          'lean-correlation-id': correlationId,
        },
        body,
      },
    );

    return response?.data;
  }
}

import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from 'modules/store';
import { ErrorObject } from 'types/global';
import Errors from 'modules/shared/error/errors';

import { Payment, PaymentsPayload } from './payments.types';
import PaymentsService from './payments.service';

export const getPayments = createAsyncThunk<
  PaymentsPayload,
  {
    page: number;
    status: string;
    dateTo: string;
    pageSize: number;
    dateFrom: string;
    maxAmount: number;
    minAmount: number;
    isPayouts?: boolean;
    customerId: string;
  },
  {
    state: RootState;
    rejectValue: ErrorObject;
  }
>(
  'customers/get-payments',
  async (
    {
      page,
      status,
      dateTo,
      pageSize,
      dateFrom,
      maxAmount,
      minAmount,
      isPayouts,
      customerId,
    },
    { getState, rejectWithValue },
  ) => {
    const { environment: appEnvironment, appToken } = getState().root.global;

    const body = {
      page,
      order_by: 'DESC',
      page_size: pageSize,
      ...(status ? { status } : {}),
      ...(dateTo ? { to: dateTo } : {}),
      ...(isPayouts ? { payout: isPayouts } : {}),
      ...(dateFrom ? { from: dateFrom } : {}),
      ...(maxAmount ? { amount_max: maxAmount } : {}),
      ...(minAmount ? { amount_min: minAmount } : {}),
      ...(customerId ? { customer_id: customerId } : {}),
    };

    try {
      return await PaymentsService.getPayments({
        body,
        appToken,
        appEnvironment,
      });
    } catch (error: unknown) {
      const errorObj: ErrorObject = error as ErrorObject;

      if (errorObj.response.status === 404) {
        return rejectWithValue(errorObj);
      }

      Errors.handle(errorObj);
      return rejectWithValue(errorObj);
    }
  },
);

export const getPaymentById = createAsyncThunk<
  Payment,
  { paymentId: string },
  {
    state: RootState;
    rejectValue: ErrorObject;
  }
>(
  'customers/get-payment-by-id',
  async ({ paymentId }, { getState, rejectWithValue }) => {
    const { environment: appEnvironment, appToken } = getState().root.global;

    try {
      return await PaymentsService.getSinglePaymentById({
        appToken,
        paymentId,
        appEnvironment,
      });
    } catch (error: unknown) {
      const errorObj: ErrorObject = error as ErrorObject;

      if (errorObj.response.status === 404) {
        return rejectWithValue(errorObj);
      }

      Errors.handle(errorObj);
      return rejectWithValue(errorObj);
    }
  },
);

import {
  endOfMonth,
  startOfMonth,
  formatISO,
  subHours,
  subDays,
  subMonths,
  startOfWeek,
  endOfDay,
  formatDistanceToNow,
} from 'date-fns';

export const getDateAndTimeAsNumbers = (date = new Date()) => {
  const day = date.getDate();
  const month = date.getMonth() + 1; // 0-indexed
  const year = String(date.getFullYear()).slice(-2); // getting last two digits
  const time = date.toTimeString().split(' ')[0];

  return {
    date: `${day < 10 ? `0${day}` : day}/${
      month < 10 ? `0${month}` : month
    }/${year}`,
    time: `${time}`,
  };
};

export const getCurrentFullMonth = (date = new Date()) => ({
  from: formatISO(startOfMonth(date)),
  to: formatISO(endOfMonth(date)),
});

export const getOneMonthAgoFromToday = (date = new Date()) => ({
  from: formatISO(subMonths(date, 1)),
  to: formatISO(date),
});

export const getLastHourRange = () => {
  const now = new Date();
  return {
    from: formatISO(subHours(now, 1)),
    to: formatISO(now),
  };
};

export const getLastDayRange = () => {
  const now = new Date();
  return {
    from: formatISO(subDays(now, 1)),
    to: formatISO(now),
  };
};

export const getLastWeekRange = () => ({
  from: formatISO(subDays(new Date().setHours(0, 0, 0, 0), 7)),
  to: formatISO(new Date()),
});

export const getCurrentFullWeekRange = () => {
  const now = new Date();
  return {
    from: formatISO(startOfWeek(now)),
    to: formatISO(now),
  };
};

// return current time if today's date is equal the date
// else return end time of the day
export const getDateWithTime = (todayDate, date) => {
  if (todayDate === date) return formatISO(new Date());
  return formatISO(endOfDay(new Date(date)));
};

export const getTimeDistanceToNow = (date, options = {}) => {
  if (!date) return 'Unknown';

  let distance = formatDistanceToNow(new Date(date), options);

  // remove "about" from the string
  if (distance.includes('about')) {
    distance = distance.replace('about', '');
  }

  return `${distance.trim()} ago`;
};

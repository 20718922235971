import { isKSACountry } from 'config/countriesEnvironments';

const uaeUrls = {
  privacyURL: 'https://www.leantech.me/legal/privacy-notice',
  touURL: 'https://www.leantech.me/legal/terms-of-use',
  responsibleURL: 'https://www.leantech.me/legal/responsible-disclosure-policy',
  endUserAgreement: 'https://leantech.me/legal/end-user-agreement',
  contactUs: 'mailto:devsupport@leantech.me',
  leanCopyright: `© ${new Date().getFullYear()} Lean Technologies, Ltd. All rights reserved.`,
};

const ksaUrls = {
  privacyURL: 'https://www.leantech.me/sa/legal/privacy-notice',
  touURL: 'https://www.leantech.me/sa/legal/terms-of-use',
  responsibleURL:
    'https://www.leantech.me/sa/legal/responsible-disclosure-policy',
  endUserAgreement: 'https://www.leantech.me/sa/legal/end-user-agreement',
  contactUs: 'mailto:devsupport@leantech.me',
  leanCopyright: `© ${new Date().getFullYear()} Lean Technologies, Ltd. All rights reserved.`,
};

export const {
  privacyURL,
  touURL,
  responsibleURL,
  endUserAgreement,
  contactUs,
  leanCopyright,
} = isKSACountry ? ksaUrls : uaeUrls;

import PropTypes from 'prop-types';
import React from 'react';
import { $Input, $Label, $FieldContainer } from 'styles';
import { styledInputPropTypes } from 'utils/proptypes';

const Checkbox = ({
  label,
  name,
  value,
  onBlur,
  onChange,
  isErrored,
  isChecked,
  isDisabled,
  styledProps,
  'data-cy': dataCy,
}) => {
  const styles = { fontSize: 'md', ...styledProps };

  return (
    <$FieldContainer>
      <$Input
        id={value}
        name={name}
        value={value}
        type="checkbox"
        onBlur={onBlur}
        data-cy={dataCy}
        onChange={onChange}
        checked={isChecked}
        isErrored={isErrored}
        disabled={isDisabled}
        aria-labelledby={`${value}Label`}
        className={isDisabled ? 'disabled' : ''}
        aria-describedby={`${value}Description`}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...styles}
      />
      <$Label htmlFor={value} id={`${value}Label`} {...styles}>
        <span>
          {label}{' '}
          {
            // DEV_PORTAL_TODO: If there are more cases where we need a pre/suffix in a label we can add this. It's a bit tricky with checkbox, but could be done with a render prop?
            name === 'tncs' && (
              <>
                <a href="https://www.leantech.me/legal/terms-of-use">
                  terms of use
                </a>{' '}
                <span style={{ fontSize: '12px' }}>and</span>{' '}
                <a href="https://www.leantech.me/legal/privacy-notice">
                  privacy notice
                </a>
              </>
            )
          }
        </span>
      </$Label>
    </$FieldContainer>
  );
};

Checkbox.propTypes = {
  onBlur: PropTypes.func,
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  isChecked: PropTypes.bool,
  isErrored: PropTypes.bool,
  isDisabled: PropTypes.bool,
  helptext: PropTypes.string,
  'data-cy': PropTypes.string,
  name: PropTypes.string.isRequired,
  styledProps: styledInputPropTypes,
};

Checkbox.defaultProps = {
  label: '',
  value: '',
  helptext: '',
  'data-cy': '',
  isChecked: false,
  isErrored: false,
  isDisabled: false,
  onBlur: () => {},
  onChange: () => {},
  styledProps: {
    fontSize: 'md',
  },
};

export default Checkbox;

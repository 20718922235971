import { apiFetch } from 'modules/shared/fetch';
import { createCorrelationId } from 'utils/createUniqueId';

const correlationId = createCorrelationId();

export default class OrganisationService {
  /**
   * Gets an organisation data
   *
   * @returns {Object} The organisation data
   */
  static async getOrganisation() {
    const getOrg = apiFetch('/users/organization', {
      method: 'GET',
    });

    const { data: organisation } = await getOrg;
    return organisation;
  }

  /**
   * Activate an organisation
   *
   * @param {Object} body - The data from the activation form
   *
   * @returns {boolean} true if activation successful, corresponding error otherwise
   */
  static async activateOrganisation(body) {
    const activateOrg = apiFetch('/users/kyb/verify', {
      method: 'POST',
      body,
    });

    const { data: activeOrganisation } = await activateOrg;
    return activeOrganisation;
  }
}

import { setLanguageCode } from 'i18n';

const prefix = 'LAYOUT';

const actions = {
  MENU_TOGGLE: `${prefix}_MENU_TOGGLE`,
  MENU_HIDE: `${prefix}_MENU_HIDE`,
  MENU_SHOW: `${prefix}_MENU_SHOW`,

  HEADER_TOGGLE: `${prefix}_HEADER_TOGGLE`,
  HEADER_HIDE: `${prefix}_HEADER_HIDE`,
  HEADER_SHOW: `${prefix}_HEADER_SHOW`,

  USER_MENU_TOGGLE: `${prefix}_USER_MENU_TOGGLE`,
  USER_MENU_HIDE: `${prefix}_USER_MENU_HIDE`,
  USER_MENU_SHOW: `${prefix}_USER_MENU_SHOW`,

  LANGUAGE_TOGGLE: `${prefix}_LANGUAGE_TOGGLE`,
  LANGUAGE_HIDE: `${prefix}_LANGUAGE_HIDE`,
  LANGUAGE_SHOW: `${prefix}_LANGUAGE_SHOW`,

  doChangeLanguage: (language) => {
    setLanguageCode(language);

    /**
     * I18n is outside Redux store,
     * no we need this hack to load it properly
     */
    window.location.reload();
  },

  doToggleMenu: () => ({
      type: actions.MENU_TOGGLE,
    }),

  doToggleUserMenu: () => ({
      type: actions.USER_MENU_TOGGLE,
    }),

  doShowUserMenu: () => ({
      type: actions.USER_MENU_SHOW,
    }),

  doHideUserMenu: () => ({
      type: actions.USER_MENU_HIDE,
    }),

  doToggleHeader: () => ({
      type: actions.HEADER_TOGGLE,
    }),

  doToggleLanguage: () => ({
      type: actions.LANGUAGE_TOGGLE,
    }),

  doShowLanguage: () => ({
      type: actions.LANGUAGE_SHOW,
    }),

  doHideLanguage: () => ({
      type: actions.LANGUAGE_HIDE,
    }),

  doShowMenu: () => ({
      type: actions.MENU_SHOW,
    }),

  doHideMenu: () => ({
      type: actions.MENU_HIDE,
    }),
};

export default actions;

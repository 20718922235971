import { createSelector } from 'reselect';
import {
  DEPOSITS_PROCESSING_STATUSES,
  DEPOSITS_STATUSES__PROCESSING,
} from 'view/deposits/constants';

const selectDeposits = state => state.root.deposits;

const selectBanksList = createSelector([selectDeposits], ({ banks }) =>
  banks.map(b => ({
    identifier: b.bank_identifier,
    description: b.bank_description,
    sources: b.sources,
  })),
);

const selectUploadedDepositsSourceList = createSelector(
  [selectDeposits],
  ({ uploadedDepositsSourceList }) =>
    uploadedDepositsSourceList.map(depositSource => ({
      sources: depositSource.sources,
      accountIban: depositSource.iban,
      accountNumber: depositSource.account_number,
      bankIdentifier: depositSource.bank_identifier,
      accountDisplayName: depositSource.display_name,
      depositSourceId: depositSource.deposit_source_id,
      depositsUpdatedAt: depositSource.deposits_updated_at,
      credentialsUpdateRequired: depositSource.credentials_update_required,
    })),
);

const selectDepositsUploadStatusRequestId = createSelector(
  [selectDeposits],
  ({ depositsUploadStatusRequestId }) => depositsUploadStatusRequestId,
);

const selectDepositsReconnectId = createSelector(
  [selectDeposits],
  ({ depositsReconnectId }) => depositsReconnectId,
);

const selectIsDepositsSourceUploadStarted = createSelector(
  [selectDeposits],
  ({ isDepositsSourceUploadStarted }) => isDepositsSourceUploadStarted,
);

const selectIsAddingDepositBankDetails = createSelector(
  [selectDeposits],
  ({ isAddingDepositBankDetails }) => isAddingDepositBankDetails,
);

const selectAddDepositBankAccountStatus = createSelector(
  [selectDeposits],
  ({ addDepositBankAccountStatus }) => addDepositBankAccountStatus,
);

const selectIsSelectBankStep = createSelector(
  [selectDeposits],
  ({ isSelectBankStep }) => isSelectBankStep,
);

const selectCustomerId = createSelector(
  [selectDeposits],
  ({ customerId }) => customerId,
);

const selectDepositsSourceId = createSelector(
  [selectDeposits],
  ({ depositsSourceId }) => depositsSourceId,
);

const selectFetchAccountsStatus = createSelector(
  [selectDeposits],
  ({ fetchAccountsStatus }) => fetchAccountsStatus,
);

const selectIsFetchingAccounts = createSelector(
  [selectDeposits],
  ({ isFetchingAccounts }) => isFetchingAccounts,
);

const selectAccounts = createSelector(
  [selectDeposits],
  ({ accounts }) => accounts,
);

const selectDepositsUploadStatus = createSelector(
  [selectDeposits],
  ({ depositsUploadStatus }) =>
    DEPOSITS_PROCESSING_STATUSES.includes(depositsUploadStatus)
      ? DEPOSITS_STATUSES__PROCESSING
      : depositsUploadStatus,
);

const selectIsUpdateDepositsStep = createSelector(
  [selectDeposits],
  ({ isUpdateDepositsStep }) => isUpdateDepositsStep,
);

const selectIsDepositsDialogOpen = createSelector(
  [selectDeposits],
  ({ isDepositsDialogOpen }) => isDepositsDialogOpen,
);

const selectSelectedDepositsBankAccountDetails = createSelector(
  [selectDeposits],
  ({ selectedDepositsBankAccountDetails }) =>
    selectedDepositsBankAccountDetails,
);

export {
  selectAccounts,
  selectBanksList,
  selectCustomerId,
  selectDepositsSourceId,
  selectIsSelectBankStep,
  selectIsFetchingAccounts,
  selectDepositsReconnectId,
  selectFetchAccountsStatus,
  selectIsUpdateDepositsStep,
  selectIsDepositsDialogOpen,
  selectDepositsUploadStatus,
  selectUploadedDepositsSourceList,
  selectIsAddingDepositBankDetails,
  selectAddDepositBankAccountStatus,
  selectIsDepositsSourceUploadStarted,
  selectDepositsUploadStatusRequestId,
  selectSelectedDepositsBankAccountDetails,
};

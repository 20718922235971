import PropTypes from 'prop-types';
import React from 'react';
import { styledInputPropTypes } from 'utils/proptypes';
import { $DropdownButton, $Title } from './styles';
import Icon from '../../Icon';
import Tooltip from '../../Tooltip';

const DropdownButton = ({
  name,
  onChange,
  styledProps,
  value,
  isDisabled,
  iconRight,
  className,
  title,
  isActive,
  children,
  placement,
}) => (
  <div className={className}>
    <Tooltip content={children} disabled={isDisabled} placement={placement}>
      <$DropdownButton
        aria-describedby={`${name}Description`}
        aria-labelledby={`${name}Label`}
        id={name}
        name={name}
        onChange={onChange}
        value={value}
        disabled={isDisabled}
        active={isActive}
        data-cy={`dropdown_${name}`}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...styledProps}
      >
        <$Title>{title}</$Title>
        {iconRight && <Icon name={iconRight} />}
      </$DropdownButton>
    </Tooltip>
  </div>
);

DropdownButton.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  styledProps: styledInputPropTypes,
  value: PropTypes.string,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
  title: PropTypes.string,
  iconRight: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  onSubmit: PropTypes.func,
  onClear: PropTypes.func,
  isActive: PropTypes.bool,
  defaultValue: PropTypes.string,
  children: PropTypes.node,
  placement: PropTypes.string,
};

DropdownButton.defaultProps = {
  onClear: () => {},
  onSubmit: () => {},
  onChange: () => {},
  options: [],
  styledProps: {},
  value: '',
  isDisabled: false,
  className: '',
  title: '',
  iconRight: undefined,
  isActive: false,
  defaultValue: undefined,
  children: undefined,
  placement: 'bottom',
};

export default DropdownButton;

export const STATUS_FAILED = 'FAILED';
const STATUS_ACCEPTED_BY_BANK = 'ACCEPTED_BY_BANK';
const STATUS_REQUIRES_MANUAL_REVIEW = 'REQUIRES_MANUAL_REVIEW';
const STATUS_PENDING_WITH_BANK = 'PENDING_WITH_BANK';
const STATUS_AWAITING_AUTHORIZATION = 'AWAITING_AUTHORIZATION';

const FAILED = 'Failed';
const ACCEPTED = 'Accepted';
const PENDING = 'Pending';
const UNRESOLVED = 'Unresolved';
const AWAITING_AUTHORIZATION = 'Awaiting authorization';

interface LabelValue {
  label: string;
  value: string;
}

export const STATUS_FILTER_OPTIONS: LabelValue[] = [
  { label: FAILED, value: STATUS_FAILED },
  { label: ACCEPTED, value: STATUS_ACCEPTED_BY_BANK },
  { label: PENDING, value: STATUS_PENDING_WITH_BANK },
  { label: UNRESOLVED, value: STATUS_REQUIRES_MANUAL_REVIEW },
  { label: AWAITING_AUTHORIZATION, value: STATUS_AWAITING_AUTHORIZATION },
];

export const PAYOUTS_SINGLE = 'payouts-single';
export const PAYOUTS_BULK = 'payouts-bulk';

import LinkStatus from './LinkStatus';
import LinkAccount from './LinkAccount';
import GettingStarted from './GetStarted';
import BankSelection from './BankSelection';
import RequestBank from './RequestBank';
import RequestBankSuccess from './RequestBankSuccess';

export const hyperlinkUserSteps = {
  GettingStarted: {
    name: 'getting-started',
    component: GettingStarted,
  },
  BankSelection: {
    name: 'bank-selection',
    component: BankSelection,
  },
  RequestBank: {
    name: 'request-bank',
    component: RequestBank,
  },
  RequestBankSuccess: {
    name: 'request-bank-success',
    component: RequestBankSuccess,
  },
  LinkAccount: {
    name: 'link-account',
    component: LinkAccount,
  },
  LinkSuccessful: {
    name: 'link-successful',
    component: LinkStatus,
  },
  LinkFailed: {
    name: 'link-failed',
    component: LinkStatus,
  },
};

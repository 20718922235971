import React from 'react';
import PropTypes from 'prop-types';

import { $Sidenav } from '../../styles';

const Sidenav = ({ children }) => (
  <$Sidenav data-cy="sidenav_component">{children}</$Sidenav>
);

Sidenav.propTypes = { children: PropTypes.node.isRequired };

export default Sidenav;

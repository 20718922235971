import React from 'react';
import { i18n, getLanguageCode } from 'i18n';
// @ts-ignore
import { Helmet } from 'react-helmet';

import AppProfile from 'components/AppProfile';
import { useTypedSelector } from 'types/global';
import { selectHyperlink } from 'modules/hyperlink';
import LanguageSelector from 'components/LanguageSelector';

import {
  $HyperlinkLayoutCard,
  $HyperlinkLayoutContainer,
  $HyperlinkLayoutHeader,
} from './hyperlink.styled';
import { hyperlinkUserSteps } from './constants';
import { getAppName } from './utils';

function HyperlinkContainer({
  children,
  showAppNameAndLogo = true,
}: {
  children: React.ReactNode;
  showAppNameAndLogo?: boolean;
}) {
  const query = new URLSearchParams(window.location.search);
  const step = query.get('step') || hyperlinkUserSteps.GettingStarted.name;
  const isGettingStartedStep = step === hyperlinkUserSteps.GettingStarted.name;

  const { data } = useTypedSelector(selectHyperlink);
  const languageCode = getLanguageCode();
  const appName = getAppName(data, languageCode);

  return (
    <$HyperlinkLayoutContainer>
      <Helmet>
        <title>{i18n('hyperlink.title', appName)}</title>
      </Helmet>
      <$HyperlinkLayoutCard>
        <$HyperlinkLayoutHeader>
          {showAppNameAndLogo && (
            <>
              <AppProfile appName={appName} appLogoUrl={data.app_logo_url} />
              {isGettingStartedStep && <LanguageSelector />}
            </>
          )}
        </$HyperlinkLayoutHeader>

        {children}
      </$HyperlinkLayoutCard>
    </$HyperlinkLayoutContainer>
  );
}

export default HyperlinkContainer;

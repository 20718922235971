import { MockUsersFilter } from 'modules/application/constants';
import actions from './applicationViewActions';

const initialData = {
  loading: false,
  mockUsers: [],
  filter: MockUsersFilter.all,
};

const applicationViewReducers = (state = initialData, { type, payload }) => {
  // Mock Users
  if (type === actions.GET_MOCK_USERS_STARTED) {
    return {
      ...state,
      mockUsers: [],
      loading: true,
    };
  }

  if (type === actions.GET_MOCK_USERS_SUCCESS) {
    return {
      ...state,
      mockUsers: payload,
      loading: false,
    };
  }

  if (type === actions.GET_MOCK_USERS_ERROR) {
    return {
      ...state,
      mockUsers: [],
      loading: false,
    };
  }

  if (
    type === actions.POST_MOCK_USER_ADD_FUNDS_STARTED ||
    type === actions.POST_MOCK_USER_ADD_TRANSACTIONS_STARTED ||
    type === actions.POST_MOCK_USER_EXPIRE_SESSION_STARTED ||
    type === actions.DELETE_MOCK_USER_STARTED
  ) {
    return {
      ...state,
      loading: true,
    };
  }

  if (
    type === actions.POST_MOCK_USER_ADD_FUNDS_SUCCESS ||
    type === actions.POST_MOCK_USER_ADD_TRANSACTIONS_SUCCESS ||
    type === actions.POST_MOCK_USER_EXPIRE_SESSION_SUCCESS ||
    type === actions.DELETE_MOCK_USER_SUCCESS
  ) {
    return {
      ...state,
      loading: false,
    };
  }

  if (
    type === actions.POST_MOCK_USER_ADD_FUNDS_ERROR ||
    type === actions.POST_MOCK_USER_ADD_TRANSACTIONS_ERROR ||
    type === actions.POST_MOCK_USER_EXPIRE_SESSION_ERROR ||
    type === actions.DELETE_MOCK_USER_ERROR
  ) {
    return {
      ...state,
      loading: false,
    };
  }

  if (type === actions.SET_MOCK_USERS_FILTER) {
    return {
      ...state,
      filter: payload,
    };
  }

  return state;
};

export default applicationViewReducers;

import PropTypes from 'prop-types';

export const UNRECEIVED_PAYMENTS_TAB = 'unreceived';
export const RECONCILED_PAYMENTS_TAB = 'reconciled';
export const UNRECONCILED_PAYMENTS_TAB = 'unreconciled';

export const UNIQUE_MATCH = 'UNIQUE_MATCH';
export const PARTIAL_MATCH = 'PARTIAL_MATCH';
export const NO_MATCH = 'NO_MATCH';

export const MATCH_IDENTIFIER = {
  NO_MATCH: 'no_match',
  UNIQUE_MATCH: 'unique',
  PARTIAL_MATCH: 'partial',
};

export const ACCEPTED_BY_BANK = 'ACCEPTED_BY_BANK';
export const PENDING_WITH_BANK = 'PENDING_WITH_BANK';
export const OUTSTANDING = 'OUTSTANDING';
export const UNRECEIVED = 'UNRECEIVED';
export const RECONCILED = 'RECONCILED';

export const ReconciliationPaymentProp = PropTypes.shape({
  id: PropTypes.string,
  amount: PropTypes.number,
  currency: PropTypes.string,
  description: PropTypes.string,
  initiation_timestamp: PropTypes.string,
  bank_transaction_reference: PropTypes.string,
  deposits: PropTypes.shape({
    all: PropTypes.array,
    partial: PropTypes.array,
    unique: PropTypes.array,
  }),
  reconciliation: PropTypes.shape({
    amount: PropTypes.number,
  }),
  sender_details: PropTypes.shape({
    name: PropTypes.string,
    iban: PropTypes.string,
    bank_name: PropTypes.string,
  }),
});

export const TotalPaymentsProp = PropTypes.shape({
  reconciled: PropTypes.number,
  unreceived: PropTypes.number,
  unreconciled: PropTypes.number,
  matches: PropTypes.shape({
    no_match: PropTypes.number,
    unique: PropTypes.number,
    partial: PropTypes.number,
  }),
});

export const PaymentsCountProp = PropTypes.shape({
  reconciled: PropTypes.number,
  unreceived: PropTypes.number,
  unreconciled: PropTypes.number,
  matches: PropTypes.shape({
    no_match: PropTypes.number,
    unique: PropTypes.number,
    partial: PropTypes.number,
  }),
});

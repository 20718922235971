import styled, { css } from 'styled-components/macro';
import { linkStyles } from 'styles/components/Link';

// mixin for now, will be a component soon!
export const baseButtonStyles = css`
  align-items: center;
  border-radius: 4px;
  display: flex;
  line-height: 1;
  min-height: 32px;
  position: relative;
`;

const startStyles = css`
  align-self: flex-start;
`;

const centerStyles = css`
  align-self: center;
  margin: 0 auto;
`;

const endStyles = css`
  align-self: flex-end;
`;

const inlineStyles = css`
  display: inline-flex;
`;

const fullWidthStyles = css`
  width: 100%;
`;

const primaryStyles = css`
  background-color: ${({ theme }) => theme.colors.trust._60};
  color: ${({ theme }) => theme.colors.white};

  &:hover {
    background-color: ${({ theme }) => theme.colors.trust._70};
    color: ${({ theme }) => theme.colors.white};
  }
`;

const priamaryDisabledStyles = css`
  outline: none;
  background-color: ${({ theme }) => theme.colors.neutral._40};
  cursor: auto;
`;

const primaryDangerStyles = css`
  background-color: ${({ theme }) => theme.colors.coral._50};
  color: ${({ theme }) => theme.colors.white};

  &:hover {
    background-color: ${({ theme }) => theme.colors.coral._60};
    color: ${({ theme }) => theme.colors.white};
  }
`;

const secondaryStyles = css`
  background-color: ${({ theme }) => theme.colors.neutral._20};
  color: ${({ theme }) => theme.colors.trust._60};

  &:hover {
    color: ${({ theme }) => theme.colors.trust._70};
  }
`;

const secondaryDisabledStyles = css`
  outline: none;
  color: ${({ theme }) => theme.colors.neutral._50};
  border-color: ${({ theme }) => theme.colors.neutral._50};
  cursor: auto;

  &:active {
    color: ${({ theme }) => theme.colors.neutral._50};
  }

  &:hover {
    color: ${({ theme }) => theme.colors.neutral._50};
  }
`;

const secondaryDangerStyles = css`
  background-color: ${({ theme }) => theme.colors.neutral._20};
  color: ${({ theme }) => theme.colors.coral._50};

  &:hover {
    color: ${({ theme }) => theme.colors.coral._60};
  }
`;

const tertiaryStyles = css`
  color: ${({ theme }) => theme.colors.trust._60};
  border: solid 1px ${({ theme }) => theme.colors.trust._60};

  &:hover {
    color: ${({ theme }) => theme.colors.trust._70};
    border-color: ${({ theme }) => theme.colors.trust._70};
  }
`;

const tertiaryDisabledStyles = css`
  outline: none;
  color: ${({ theme }) => theme.colors.neutral._50};
  border-color: ${({ theme }) => theme.colors.neutral._50};
  cursor: auto;

  &:active {
    color: ${({ theme }) => theme.colors.neutral._50};
  }

  &:hover {
    color: ${({ theme }) => theme.colors.neutral._50};
  }
`;

const tertiaryDangerStyles = css`
  color: ${({ theme }) => theme.colors.coral._50};
  border: solid 1px ${({ theme }) => theme.colors.coral._50};

  &:hover {
    color: ${({ theme }) => theme.colors.coral._60};
    border-color: ${({ theme }) => theme.colors.coral._60};
  }
`;

const textStyles = css`
  ${linkStyles}
`;

const textDisabledStyles = css`
  color: ${({ theme }) => theme.colors.neutral._50};
`;

const textDangerStyles = css`
  color: ${({ theme }) => theme.colors.coral._50};
  transition: none;

  &:hover {
    color: ${({ theme }) => theme.colors.coral._60};
  }
`;

const groupStyles = css`
  border-radius: 6px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.neutral._20};
  }

  &:focus-visible {
    background-color: ${({ theme }) => theme.colors.neutral._20};
    box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.trust._40};
    outline: none;
  }
`;

const groupDisabledStyles = css`
  color: ${({ theme }) => theme.colors.neutral._60};
`;

const groupActiveStyles = css`
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 0 4px rgba(149, 157, 157, 0.24);
  outline: none;

  &:disabled {
    background-color: ${({ theme }) => theme.colors.neutral._20};
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.white};
  }
`;

const loadingButtonStyles = css`
  position: absolute;
  background: rgba(255, 255, 255, 0.5);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  left: 0;
  top: 0;
  height: 100%;
`;

export const $ChildrenWrapper = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
`;

export const $SpinnerBackground = styled.div`
  ${loadingButtonStyles}
`;

const isLoadingStyles = css`
  pointer-events: none;

  ${$ChildrenWrapper} {
    visibility: hidden;
  }
`;

const getVariantStyles = variant => {
  switch (variant) {
    case 'text':
      return textStyles;
    case 'secondary':
      return secondaryStyles;
    case 'tertiary':
      return tertiaryStyles;
    case 'group':
      return groupStyles;
    case 'primary':
    default:
      return primaryStyles;
  }
};

const getVariantDisabledStyles = variant => {
  switch (variant) {
    case 'text':
      return textDisabledStyles;
    case 'secondary':
      return secondaryDisabledStyles;
    case 'tertiary':
      return tertiaryDisabledStyles;
    case 'group':
      return groupDisabledStyles;
    case 'primary':
    default:
      return priamaryDisabledStyles;
  }
};

const getVariantActiveStyles = variant => {
  switch (variant) {
    case 'group':
      return groupActiveStyles;
    default:
      return null;
  }
};

const getDangerVariantStyles = variant => {
  switch (variant) {
    case 'text':
      return textDangerStyles;
    case 'secondary':
      return secondaryDangerStyles;
    case 'tertiary':
      return tertiaryDangerStyles;
    case 'primary':
    default:
      return primaryDangerStyles;
  }
};

const smallSizeStyles = css`
  font-size: ${({ theme }) => theme.font.size.body.sm};
  height: 32px;
  padding: 0 16px;
`;

const mediumSizeStyles = css`
  font-size: ${({ theme }) => theme.font.size.body.md};
  height: 40px;
  padding: 0 16px;
`;

const largeSizeStyles = css`
  font-size: ${({ theme }) => theme.font.size.body.xl};
  height: 48px;
  padding: 0 24px;
`;

const getSizeStyles = size => {
  switch (size) {
    case 'large':
      return largeSizeStyles;
    case 'medium':
      return mediumSizeStyles;
    case 'small':
    default:
      return smallSizeStyles;
  }
};

const $Button = styled.button`
  ${baseButtonStyles}
  ${({ variant }) => getVariantStyles(variant)};
  ${props => props.align === 'center' && centerStyles}
  ${props => props.align === 'end' && endStyles}
  ${props => props.align === 'start' && startStyles}
  ${props => props.display === 'inline' && inlineStyles}
  ${props => props.$fullwidth && fullWidthStyles}
  ${props => props.$isLoading && isLoadingStyles}
  ${({ size }) => getSizeStyles(size)};

  justify-content: ${({ justifyContent }) => justifyContent};

  transition: background-color 150ms ease, color 150ms ease;

  ${({ danger, variant }) => danger && getDangerVariantStyles(variant)};
  ${({ active, variant }) => active && getVariantActiveStyles(variant)};

  & + & {
    margin-left: 16px;
  }

  .ion-icon {
    font-size: 16px;
  }

  .ion-icon-start,
  .styled-icon-start {
    margin-inline-end: 8px;
  }

  .ion-icon-end {
    margin-inline-start: 8px;
  }

  &[disabled],
  &:disabled {
    pointer-events: none;
    ${({ variant }) => getVariantDisabledStyles(variant)};
  }
`;

export default $Button;

import { actions } from './teamActions';

const initialState = {
  loadingMembers: true,
  addingMember: false,
  updatingMember: false,
  removingMember: false,
  members: [],
};

export default function reducer(state = initialState, { type, payload }) {
  if (type === actions.GET_MEMBERS_LIST_STARTED) {
    return {
      ...state,
      loadingMembers: true,
    };
  }

  if (type === actions.GET_MEMBERS_LIST_SUCCESS) {
    return {
      ...state,
      loadingMembers: false,
      members: payload,
    };
  }

  if (type === actions.GET_MEMBERS_LIST_ERROR) {
    return {
      ...state,
      loadingMembers: false,
    };
  }

  return state;
}

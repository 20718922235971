import React from 'react';
import PropTypes from 'prop-types';
import { $DetailsCell, $CellTitle, $CellData } from './styles';

const DetailsCell = ({
  direction = 'column',
  dataFont = 'body',
  title,
  data,
  'data-cy': dataCy,
}) => {
  if (!data) return null;
  return (
    <$DetailsCell
      styledProps={{
        direction,
      }}
      data-cy={dataCy}
    >
      <$CellTitle
        styledProps={{
          direction,
        }}
      >
        {title}
      </$CellTitle>
      <$CellData
        styledProps={{
          font: dataFont,
        }}
      >
        {data}
      </$CellData>
    </$DetailsCell>
  );
};

DetailsCell.propTypes = {
  direction: PropTypes.oneOf(['column', 'row']),
  dataFont: PropTypes.oneOf(['body', 'mono']),
  title: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  'data-cy': PropTypes.string,
};

DetailsCell.defaultProps = {
  direction: 'column',
  dataFont: 'body',
  title: '',
  data: '',
  'data-cy': '',
};

export default DetailsCell;

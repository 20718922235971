const backendUrl = `https://app.sa.leantech.me`;
const sandboxUrl = `https://app.sandbox.sa.leantech.me`;
const url = 'https://dev.sa.leantech.me';
const hostname = 'dev.sa.leantech.me';

const apiSandboxUrl = `https://sandbox.sa.leantech.me`;
const hyperlinkBackendUrl = `https://hyperlink-api.sa.leantech.me`;
const hyperlinkSandboxBackendUrl = `https://hyperlink-api.sandbox.sa.leantech.me`;
const oauthUrl = `https://auth.sa.leantech.me`;
const oauthSandboxUrl = `https://auth.sandbox.sa.leantech.me`;
const linkSDKCDN = `https://cdn.leantech.me/sa/link/sdk/web/latest/Lean.min.js`;
const linkSDKv2CDN =
  'https://cdn.leantech.me/link/sdk/web/v2/prod/sa/latest/Lean.min.js';

const linkApiUrl = 'https://link.sa.leantech.me';
const linkSandboxApiUrl = 'https://link.sandbox.sa.leantech.me';

const sa01 = {
  backendUrl,
  apiSandboxUrl,
  linkSDKCDN,
  sandboxUrl,
  url,
  hostname,
  hyperlinkBackendUrl,
  hyperlinkSandboxBackendUrl,
  oauthUrl,
  oauthSandboxUrl,
  linkApiUrl,
  linkSandboxApiUrl,
  linkSDKv2CDN,
};
export default sa01;

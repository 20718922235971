// Selectors
import { createSelector } from 'reselect';

const selectTeam = state => state.root.team;

const selectMembers = createSelector([selectTeam], ({ members }) => members);

const selectLoadingMembers = createSelector(
  [selectTeam],
  ({ loadingMembers }) => loadingMembers,
);

export { selectMembers, selectLoadingMembers };

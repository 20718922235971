import styled, { keyframes } from 'styled-components/macro';

const rotate360 = keyframes`
  0% {
    transform: rotate(0); }
  100% {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  width: 16px;
  height: 16px;
  border: 1px solid
    ${({ variant = 'primary', theme }) =>
      variant === 'primary'
        ? theme.colors.blender._40
        : theme.colors.trust._60};
  border-top-color: white;
  border-left-color: white;
  border-radius: 50%;
  position: relative;
  animation: ${rotate360} 1s linear infinite;

  &::before,
  &::after {
    content: '';
    width: 1px;
    height: 1px;
    background: white;
    border-radius: 50%;
    position: absolute;
  }

  &::before {
    top: -1px;
    left: 9px;
  }

  &::after {
    top: 9px;
    left: -1px;
  }
`;

export default Spinner;

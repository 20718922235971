import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  $PaginationContainer,
  $CustomComponentsRow,
  $LeftCustomComponent,
  $RightCustomComponent,
} from './styles';
import { Pagination } from '../Pagination';
import TableMarkup from './TableMarkup';

function extractSuperHeaders(obj) {
  return Object.keys(obj).map(key => {
    const numberOfChildren = Object.keys(obj[key]).length;
    return { title: key, numberOfChildren };
  });
}

function flattenObject(obj) {
  return Object.keys(obj).reduce(
    (acc, curVal) => ({ ...acc, ...obj[curVal] }),
    {},
  );
}

const getSuperHeaderValues = (
  isSuperHeader,
  values,
  customSuperHeaderValues,
) => {
  if (!isSuperHeader) {
    return [];
  }

  if (customSuperHeaderValues.length) {
    return customSuperHeaderValues.map((value, index) => ({
      title: value,
      id: value || index,
    }));
  }

  return extractSuperHeaders(values);
};

/**
 *
 * @param data - array of objects containing table data
 * @param pageSize - max number of rows in a page
 * @param totalItems - total number of items in table, needed to calculate number of pages in pagination
 * @param onRowClick - if provided, called when any cell is clicked
 * @param customCell - custom element for rendering row cells
 * @param currentPage - initial page in paginated tables; defaults to 1
 * @param onPageClick - pagination navigation
 * @param isSuperHeader
 * @param confirmNextPage - optional bool to confirm if navigation to a new page should happen
 * @param sortableColumns - array of columns that can be used to sort the table
 * @param customHeaderCell - custom element for rendering header cells
 * @param checkboxSelection - determines is the table shows checkboxes for data selection
 * @param onAllRowsSelected - called when the checkbox in tale header is clicked
 * @param bottomLeftElement
 * @param bottomRightElement
 * @param hasStickyPagination - determines is the table pagination is shown at the bottom of the page
 * @param defaultSelectedRows - array of selected table rows
 * @param headerCellStyleProps
 * @param isMultiRowSelectable - determines if a table should support multi row selection
 * @param isSingleRowSelectable - determines if a table should support single row selection
 * @param customSuperHeaderValues  - provide the values for super header as array of strings
 * @param selectedRowIdentifierPath - data object path for unique identifier of each row; required for selectable tables
 * @returns {JSX.Element}
 * @constructor
 */

const Table = ({
  data,
  pageSize = 10,
  totalItems,
  onRowClick,
  customCell,
  currentPage,
  onPageClick,
  isSuperHeader,
  confirmNextPage,
  sortableColumns,
  customHeaderCell,
  checkboxSelection,
  onAllRowsSelected,
  bottomLeftElement,
  bottomRightElement,
  hasStickyPagination,
  defaultSelectedRows,
  isMultiRowSelectable,
  headerCellStyleProps,
  isSingleRowSelectable,
  customSuperHeaderValues,
  selectedRowIdentifierPath,
}) => {
  const [isPaginatedTable, setIsPaginatedTable] = useState(false);

  useEffect(() => {
    if (totalItems !== undefined && !!onPageClick && totalItems > pageSize) {
      setIsPaginatedTable(true);
    } else {
      setIsPaginatedTable(false);
    }
  }, [totalItems, pageSize, onPageClick]);

  const superHeaderValues = getSuperHeaderValues(
    isSuperHeader,
    data[0],
    customSuperHeaderValues,
  );

  if (!data?.length) return null;

  // flatten data if it has double header.
  const arrayOfData = isSuperHeader
    ? data.map(item => flattenObject(item))
    : data;

  // extracting hidden values from the table data
  const dataWithoutHiddenValues = arrayOfData.map(item => {
    const { hidden, ...rest } = item;
    return rest;
  });

  // get table headers
  const titles = Object.keys(dataWithoutHiddenValues[0]);

  return (
    <>
      <TableMarkup
        titles={titles}
        data={arrayOfData}
        pageSize={pageSize}
        onRowClick={onRowClick}
        customCell={customCell}
        isSuperHeader={isSuperHeader}
        sortableColumns={sortableColumns}
        isPaginatedTable={isPaginatedTable}
        customHeaderCell={customHeaderCell}
        superHeaderValues={superHeaderValues}
        checkboxSelection={checkboxSelection}
        onAllRowsSelected={onAllRowsSelected}
        defaultSelectedRows={defaultSelectedRows}
        headerCellStyleProps={headerCellStyleProps}
        isMultiRowSelectable={isMultiRowSelectable}
        isSingleRowSelectable={isSingleRowSelectable}
        selectedRowIdentifierPath={selectedRowIdentifierPath}
      />
      {(bottomLeftElement || bottomRightElement) && (
        <$CustomComponentsRow>
          <$LeftCustomComponent>{bottomLeftElement}</$LeftCustomComponent>
          <$RightCustomComponent>{bottomRightElement}</$RightCustomComponent>
        </$CustomComponentsRow>
      )}
      {isPaginatedTable && (
        <$PaginationContainer stickyPagination={hasStickyPagination}>
          <Pagination
            pageSize={pageSize}
            totalItems={totalItems}
            initialPage={currentPage}
            confirmNextPage={confirmNextPage}
            onChangePage={pageData => onPageClick(pageData)}
          />
        </$PaginationContainer>
      )}
    </>
  );
};

Table.propTypes = {
  pageSize: PropTypes.number,
  onRowClick: PropTypes.func,
  onPageClick: PropTypes.func,
  totalItems: PropTypes.number,
  isSuperHeader: PropTypes.bool,
  currentPage: PropTypes.number,
  confirmNextPage: PropTypes.bool,
  data: PropTypes.array.isRequired,
  sortableColumns: PropTypes.array,
  checkboxSelection: PropTypes.bool,
  customCell: PropTypes.elementType,
  onAllRowsSelected: PropTypes.func,
  headerCellStyleProps: PropTypes.any,
  hasStickyPagination: PropTypes.bool,
  defaultSelectedRows: PropTypes.array,
  isMultiRowSelectable: PropTypes.bool,
  isSingleRowSelectable: PropTypes.bool,
  customHeaderCell: PropTypes.elementType,
  bottomLeftElement: PropTypes.elementType,
  customSuperHeaderValues: PropTypes.array,
  bottomRightElement: PropTypes.elementType,
  selectedRowIdentifierPath: PropTypes.string,
};

Table.defaultProps = {
  currentPage: 1,
  sortableColumns: [],
  pageSize: undefined,
  isSuperHeader: false,
  totalItems: undefined,
  customCell: undefined,
  onRowClick: undefined,
  onPageClick: undefined,
  defaultSelectedRows: [],
  checkboxSelection: false,
  headerCellStyleProps: [],
  confirmNextPage: false,
  hasStickyPagination: false,
  isMultiRowSelectable: false,
  customSuperHeaderValues: [],
  customHeaderCell: undefined,
  bottomLeftElement: undefined,
  isSingleRowSelectable: false,
  onAllRowsSelected: undefined,
  selectedRowIdentifierPath: '',
  bottomRightElement: undefined,
};

export default Table;

export interface AvsConfigurationState {
  error: string;
  fetching: boolean;
  isSubmitting: boolean;
  configurations: AvsConfiguration[];
  updatedConfigurations: AvsUpdateConfigPayload[];
}

export interface AvsConfigurationError {}

export enum AvsConfigStatus {
  enabled = 'ENABLED',
  disabled = 'DISABLED',
}

export type MatchAvailability = {
  match: AvsConfigStatus;
  no_match: AvsConfigStatus;
};

export enum AvsAccountType {
  personal = 'personal',
  freelancer = 'freelancer',
  business = 'business',
}

export type AvailabilityAccountType = {
  personal: MatchAvailability;
  freelancer: MatchAvailability;
  business: MatchAvailability;
};

export type AvsConfiguration = {
  country_code: string;
  bank_code: string;
  bank_name: {
    en: string;
    ar: string;
  };
  availability: {
    global: AvsConfigStatus;
    account_type: AvailabilityAccountType;
  };
  application_enablement: AvsConfigStatus;

  // data table props to meet table row constrain and track row selectable state
  id: string;
  isSelectable: boolean;
};

export type AvsUpdateConfigPayload = {
  country_code: string;
  bank_code: string;
  enablement: AvsConfigStatus;
};

export enum AvsIcons {
  disrupted = 'AlertCircle',
  unavailable = 'CloseCircle',
  available = 'CheckmarkCircle',
}

export enum AvsStatusTooltip {
  disrupted = 'Disrupted',
  unavailable = 'Unavailable',
  available = 'Available',
}

export type AvsIconData = {
  tooltipText: string;
  iconName: AvsIcons;
  iconColor: string;
};

export enum AvsServiceStatus {
  disabledByLean = 'All verification responses disabled by Lean',
  disabledByAdmin = 'All verification responses disabled by Admin',
  enabled = 'All verification responses enabled',
  disrupted = 'Some services disrupted',
  unavailable = 'All services disrupted',

  // custom statuses for pending states
  disabledByAdminSyncPending = 'Disabled by Admin - Sync pending',
  enabledByAdminSyncPending = 'Enabled by Admin - Sync pending',
}

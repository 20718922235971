import React from 'react';
import styled from 'styled-components';
import ReactToggle from 'react-toggle';
import 'react-toggle/style.css';

const $Toggle = styled(ReactToggle)`
  &.react-toggle .react-toggle-track {
    background-color: ${({ theme }) => theme.colors.neutral._80} !important;
  }
  &.react-toggle .react-toggle-thumb {
    border-color: ${({ theme }) => theme.colors.neutral._80} !important;
  }

  &.react-toggle--checked .react-toggle-track {
    background-color: ${({ theme }) => theme.colors.trust._60} !important;
  }
  &.react-toggle--checked .react-toggle-thumb {
    border-color: ${({ theme }) => theme.colors.trust._60} !important;
    left: 17px;
  }
  .react-toggle-track {
    width: 32px;
    height: 16px;
  }
  .react-toggle-thumb {
    width: 14px;
    height: 14px;
  }
`;

function Toggle(props) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <$Toggle {...props} />;
}

Toggle.propTypes = {};

export default Toggle;

import React from 'react';
import { FormGroup, TextField, Select, CheckboxGroup } from 'components';

// DEV_PORTAL_TODO: These should be component and invert the control of customising them. eg elem
export const getFormTextField = (form, field, appendElem) => {
  const { values, errors, touched, handleChange } = form;
  const { label, name, helptext, ...rest } = field;

  const formField = (
    <FormGroup styledProps={{ variant: 'stacked' }}>
      <TextField
        data-cy={`auth_input_${name}`}
        helptext={(errors[name] && touched[name] && errors[name]) || helptext}
        isErrored={errors[name] && touched[name]}
        type={name.includes('password') ? 'password' : 'text'} // e.g. Could be 'password' or 'confirm_password'
        label={label}
        name={name}
        onChange={handleChange}
        value={values[name]}
        {...rest}
      />
      {appendElem}
    </FormGroup>
  );

  return formField;
};

export const getFormSelectField = (form, field) => {
  const { values, errors, touched, handleChange } = form;
  const { label, name, defaultValue, options, helptext } = field;

  const formField = (
    <FormGroup styledProps={{ variant: 'stacked' }}>
      <Select
        data-cy={`auth_input_${name}`}
        defaultValue={defaultValue}
        helptext={(errors[name] && touched[name] && errors[name]) || helptext}
        isErrored={errors[name] && touched[name]}
        label={label}
        name={name}
        onChange={handleChange}
        options={options}
        value={values[name]}
        styledProps={{ fontSize: 'md', optionFontSize: 'lg' }}
      />
    </FormGroup>
  );

  return formField;
};

export const getFormCheckboxGroup = (form, field) => {
  const { values, errors, touched, handleBlur, handleChange } = form;
  const {
    label,
    name,
    options,
    helptext,
    entireGroupValidation,
    chkBoxStyledProps,
  } = field;

  function getHelpText() {
    if (entireGroupValidation) {
      return (errors[name] && errors[name]) || helptext;
    }
    return (errors[name] && touched[name] && errors[name]) || helptext;
  }

  function isErrored() {
    if (entireGroupValidation) {
      return !!errors[name];
    }
    return errors[name] && !!touched[name]; // TODO: This is a bit of a bug we'll have to fix later, touched doesn't work so well with arrays.
  }

  const formField = (
    <FormGroup styledProps={{ variant: 'stacked' }}>
      <CheckboxGroup
        data-cy={`auth_input_${name}`}
        groupLabel={label}
        groupName={name}
        helptext={getHelpText()}
        isErrored={isErrored()}
        onBlur={handleBlur}
        onChange={handleChange}
        options={options}
        valuesArray={values[name]}
        entireGroupValidation={entireGroupValidation}
        chkBoxStyledProps={chkBoxStyledProps || {}}
      />
    </FormGroup>
  );

  return formField;
};

import React from 'react';
import PropTypes from 'prop-types';
import Loading from '../Loading';
import Icon from '../Icon';
import {
  $HeaderRow,
  $Title,
  $ContentContainer,
  $BackButton,
  $TitleAndBackButtonContainer,
} from './styles';
import DetailsCell from './DetailsCell';

const LogDetails = ({
  children,
  title,
  currentLogTitle,
  currentLogData,
  isLoading,
  isError,
  errorState,
  onGoBackClicked,
  statusComponent,
}) => {
  const ErrorState = errorState;
  return (
    <>
      {/** header row */}
      <$HeaderRow>
        <$TitleAndBackButtonContainer>
          {onGoBackClicked && (
            <$BackButton onClick={onGoBackClicked}>
              <Icon name="ArrowBack" size={20} />
            </$BackButton>
          )}
          <$Title data-cy="log_details_title">{title}</$Title>
          {statusComponent}
        </$TitleAndBackButtonContainer>
        <DetailsCell
          title={currentLogTitle}
          data={currentLogData}
          dataFont="mono"
          direction="row"
          data-cy="current_log_cell"
        />
      </$HeaderRow>
      {isLoading || isError ? (
        <$ContentContainer>
          {isLoading ? <Loading /> : <ErrorState />}
        </$ContentContainer>
      ) : (
        children
      )}
    </>
  );
};

LogDetails.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  currentLogTitle: PropTypes.string,
  currentLogData: PropTypes.string,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  errorState: PropTypes.elementType,
  onGoBackClicked: PropTypes.func,
  statusComponent: PropTypes.elementType,
};

LogDetails.defaultProps = {
  children: undefined,
  title: '',
  currentLogTitle: '',
  currentLogData: '',
  isLoading: false,
  isError: false,
  errorState: undefined,
  onGoBackClicked: undefined,
  statusComponent: undefined,
};

export default LogDetails;

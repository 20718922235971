import React from 'react';
import _get from 'lodash/get';
import PropTypes from 'prop-types';
import { $TableRow } from './styles';
import TableRowCells from './TableRowCells';

function TableRows({
  data,
  titles,
  customCell,
  isRowSelected,
  handleRowClick,
  checkboxSelection,
  selectedRowIdentifierPath,
}) {
  return (
    <tbody>
      {data.map((row, index) => {
        const onClick = e => {
          e.preventDefault();
          handleRowClick(row);
        };
        const currentRowId = _get(
          row,
          selectedRowIdentifierPath,
          String(index),
        );
        const rowSelected = isRowSelected(currentRowId);

        return (
          <React.Fragment key={currentRowId}>
            <$TableRow
              onClick={onClick}
              key={currentRowId}
              isClickable={!!handleRowClick}
              data-cy="table_row_component"
              className={rowSelected ? 'is-selected' : ''}
            >
              <TableRowCells
                data={row}
                titles={titles}
                rowId={currentRowId}
                customCell={customCell}
                isRowSelected={rowSelected}
                checkboxSelection={checkboxSelection}
              />
            </$TableRow>
          </React.Fragment>
        );
      })}
    </tbody>
  );
}

TableRows.propTypes = {
  data: PropTypes.array.isRequired,
  titles: PropTypes.array.isRequired,
  customCell: PropTypes.elementType,
  handleRowClick: PropTypes.func,
  checkboxSelection: PropTypes.bool,
  isRowSelected: PropTypes.func.isRequired,
  selectedRowIdentifierPath: PropTypes.string,
};

TableRows.defaultProps = {
  customCell: undefined,
  handleRowClick: () => {},
  checkboxSelection: false,
  selectedRowIdentifierPath: '',
};

export default TableRows;

import { ApmBase, init, TransactionOptions } from '@elastic/apm-rum';

import { isUAECountry } from 'config/countriesEnvironments';
import config from 'config';
import Package from '../../../package.json';

let apm: ApmBase;

export const elasticApm = {
  initialize: () => {
    if (!isUAECountry) {
      return undefined;
    }

    apm = init({
      serviceName: 'dev-portal',
      serviceVersion: Package.version,
      serverUrl: config.apmUrl,
      distributedTracingOrigins: [
        config.apiSandboxUrl,
        config.backendUrl,
        config.sandboxUrl,
      ],
      active: isUAECountry,
    });

    return apm;
  },
  startTransaction: (
    name?: string | null,
    type?: string | null,
    options?: TransactionOptions,
  ) => {
    return apm?.startTransaction(name, type, options);
  },
};

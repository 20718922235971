import { apiFetch } from 'modules/shared/fetch';
import { createCorrelationId } from 'utils/createUniqueId';
import { isNullOrUndef } from 'chart.js/helpers';

interface RequestParams {
  appToken: string;
  appUserId: string;
  appEnvironment: string;
  body?: object;
}

interface GetCustomersRequestParams {
  page: number;
  pageSize: number;
  appUserId: string;
  appToken: string;
  appEnvironment: string;
  body?: object;
}

interface GetCustomerDetailsRequestParams {
  appUserId: string;
  appToken: string;
  appEnvironment: string;
  withReports: boolean;
}

interface GetReportDetailsByIdRequestParams {
  appUserId: string;
  appToken: string;
  reportId: string;
  appEnvironment: string;
}

interface GetReportTransactionsRequestParams {
  page: number;
  type?: string;
  pageSize: number;
  appToken: string;
  appUserId: string;
  reportId: string;
  appEnvironment: string;
}

interface GetReportRequestParams {
  appToken: string;
  appUserId: string;
  reportId: string;
  appEnvironment: string;
  overviewType?: string;
}

interface RefreshCustomerEntityRequestParams {
  appToken: string;
  appUserId: string;
  entityId: string;
  appEnvironment: string;
}

const correlationId = createCorrelationId();

export default class ReportsService {
  static async getCustomers({
    page,
    appToken,
    pageSize,
    appUserId,
    appEnvironment,
  }: GetCustomersRequestParams) {
    const response = await apiFetch(
      `/reports/apps/${appToken}/customers?page=${page}&size=${pageSize}&app_user_id=${appUserId}`,
      {
        // @ts-ignore
        appEnvironment,
        headers: {
          'lean-app-token': appToken,
          'lean-correlation-id': correlationId,
        },
      },
    );

    return response?.data;
  }

  static async getEvents({
    appToken,
    appUserId,
    reportId,
    appEnvironment,
  }: GetReportRequestParams) {
    const response = await apiFetch(
      `/reports/apps/${appToken}/customers/${appUserId}/reports/${reportId}/events`,
      {
        // @ts-ignore
        appEnvironment,
        headers: {
          'lean-app-token': appToken,
          'lean-correlation-id': correlationId,
        },
      },
    );

    return response?.data;
  }

  static async getCustomerDetails({
    appToken,
    appUserId,
    appEnvironment,
    withReports,
  }: GetCustomerDetailsRequestParams) {
    const response = await apiFetch(
      `/reports/apps/${appToken}/customers/${appUserId}?withReports=${withReports}`,
      {
        // @ts-ignore
        appEnvironment,
        headers: {
          'lean-app-token': appToken,
          'lean-correlation-id': correlationId,
        },
      },
    );

    return response?.data;
  }

  static async refreshCustomerEntity({
    appToken,
    appUserId,
    entityId,
    appEnvironment,
  }: RefreshCustomerEntityRequestParams) {
    const response = await apiFetch(
      `/reports/apps/${appToken}/customers/${appUserId}/entities/${entityId}/refresh`,
      {
        // @ts-ignore
        method: 'POST',
        appEnvironment,
        headers: {
          'lean-app-token': appToken,
          'lean-correlation-id': correlationId,
        },
      },
    );

    return response?.data;
  }

  static async createCustomer({
    appToken,
    appUserId,
    appEnvironment,
  }: RequestParams) {
    const response = await apiFetch(`/reports/apps/${appToken}/customers`, {
      // @ts-ignore
      method: 'POST',
      appEnvironment,
      headers: {
        'lean-app-token': appToken,
        'lean-correlation-id': correlationId,
      },
      body: {
        app_user_id: appUserId,
      },
    });

    return response?.data;
  }

  static async generateCustomerReport({
    appToken,
    appUserId,
    appEnvironment,
  }: RequestParams) {
    const response = await apiFetch(
      `/reports/apps/${appToken}/customers/${appUserId}/reports`,
      {
        // @ts-ignore
        method: 'POST',
        appEnvironment,
        headers: {
          'lean-app-token': appToken,
          'lean-correlation-id': correlationId,
        },
      },
    );

    return response?.data;
  }

  static async downloadAccountsCSV({
    appToken,
    reportId,
    appUserId,
    appEnvironment,
  }: {
    reportId: string;
    appToken: string;
    appUserId: string;
    appEnvironment: string;
  }) {
    const response = await apiFetch(
      `/reports/apps/${appToken}/customers/${appUserId}/reports/${reportId}/accounts`,
      {
        // @ts-ignore
        appEnvironment,
        headers: {
          'lean-app-token': appToken,
          'lean-correlation-id': correlationId,
        },
      },
    );

    return response?.data;
  }

  static async downloadTransactionsCSV({
    appToken,
    reportId,
    appUserId,
    appEnvironment,
  }: {
    reportId: string;
    appToken: string;
    appUserId: string;
    appEnvironment: string;
  }) {
    const response = await apiFetch(
      `/reports/apps/${appToken}/customers/${appUserId}/reports/${reportId}/transactions`,
      {
        // @ts-ignore
        appEnvironment,
        headers: {
          'lean-app-token': appToken,
          'lean-correlation-id': correlationId,
        },
      },
    );

    return response?.data;
  }

  static async downloadCombinedPDF({
    appToken,
    reportId,
    appUserId,
    appEnvironment,
  }: {
    reportId: string;
    appToken: string;
    appUserId: string;
    appEnvironment: string;
  }) {
    const response = await apiFetch(
      `/reports/apps/${appToken}/customers/${appUserId}/reports/${reportId}/combined`,
      {
        // @ts-ignore
        appEnvironment,
        headers: {
          'lean-app-token': appToken,
          'lean-correlation-id': correlationId,
        },
      },
    );

    return response?.data;
  }

  static async getReportDetailsById({
    appToken,
    appUserId,
    reportId,
    appEnvironment,
  }: GetReportDetailsByIdRequestParams) {
    const response = await apiFetch(
      `/reports/apps/${appToken}/customers/${appUserId}/reports/${reportId}`,
      {
        // @ts-ignore
        appEnvironment,
        headers: {
          'lean-app-token': appToken,
          'lean-correlation-id': correlationId,
        },
      },
    );

    return response?.data;
  }

  static async getReportTransactions({
    page,
    appToken,
    pageSize,
    type,
    appUserId,
    reportId,
    appEnvironment,
  }: GetReportTransactionsRequestParams) {
    let url = `/reports/apps/${appToken}/customers/${appUserId}/reports/${reportId}/transactions-page?page=${page}&size=${pageSize}`;
    if (!isNullOrUndef(type)) {
      url += `&type=${type}`;
    }

    const response = await apiFetch(url, {
      // @ts-ignore
      appEnvironment,
      headers: {
        'lean-app-token': appToken,
        'lean-correlation-id': correlationId,
      },
    });

    return response?.data;
  }

  static async getSalaryTransactions({
    appToken,
    appUserId,
    reportId,
    appEnvironment,
  }: GetReportRequestParams) {
    const response = await apiFetch(
      `/reports/apps/${appToken}/customers/${appUserId}/reports/${reportId}/transactions-salary`,
      {
        // @ts-ignore
        appEnvironment,
        headers: {
          'lean-app-token': appToken,
          'lean-correlation-id': correlationId,
        },
      },
    );

    return response?.data;
  }

  static async getReportStats({
    appToken,
    appUserId,
    reportId,
    appEnvironment,
  }: GetReportRequestParams) {
    const response = await apiFetch(
      `/reports/apps/${appToken}/customers/${appUserId}/reports/${reportId}/summary?months=12`,
      {
        // @ts-ignore
        appEnvironment,
        headers: {
          'lean-app-token': appToken,
          'lean-correlation-id': correlationId,
        },
      },
    );

    return response?.data;
  }

  static async getReportCashflow({
    appToken,
    appUserId,
    reportId,
    appEnvironment,
  }: GetReportRequestParams) {
    const response = await apiFetch(
      `/reports/apps/${appToken}/customers/${appUserId}/reports/${reportId}/cashflow-overview?months=12`,
      {
        // @ts-ignore
        appEnvironment,
        headers: {
          'lean-app-token': appToken,
          'lean-correlation-id': correlationId,
        },
      },
    );

    return response?.data;
  }

  static async getReportFinancesAndLoans({
    appToken,
    appUserId,
    reportId,
    appEnvironment,
  }: GetReportRequestParams) {
    const response = await apiFetch(
      `/reports/apps/${appToken}/customers/${appUserId}/reports/${reportId}/finances-and-loans?months=12`,
      {
        // @ts-ignore
        appEnvironment,
        headers: {
          'lean-app-token': appToken,
          'lean-correlation-id': correlationId,
        },
      },
    );

    return response?.data;
  }

  static async getReportCreditCards({
    appToken,
    appUserId,
    reportId,
    appEnvironment,
  }: GetReportRequestParams) {
    const response = await apiFetch(
      `/reports/apps/${appToken}/customers/${appUserId}/reports/${reportId}/credit-cards?months=12`,
      {
        // @ts-ignore
        appEnvironment,
        headers: {
          'lean-app-token': appToken,
          'lean-correlation-id': correlationId,
        },
      },
    );

    return response?.data;
  }

  static async getReportExpensesBreakdown({
    appToken,
    appUserId,
    reportId,
    appEnvironment,
  }: GetReportRequestParams) {
    const response = await apiFetch(
      `/reports/apps/${appToken}/customers/${appUserId}/reports/${reportId}/expenses-breakdown`,
      {
        // @ts-ignore
        appEnvironment,
        headers: {
          'lean-app-token': appToken,
          'lean-correlation-id': correlationId,
        },
      },
    );

    return response?.data;
  }
}

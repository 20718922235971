import { createSelector } from 'reselect';

const selectForm = state => state.root.application.form;

const selectRecord = createSelector([selectForm], form => form.record);

const selectSandboxAppCreationLoading = createSelector(
  [selectForm],
  form => form.sandboxAppCreationLoading,
);

const selectSandboxAppCreationErrored = createSelector(
  [selectForm],
  form => form.sandboxAppCreationErrored,
);

const selectUpdateApplicationLoading = createSelector(
  [selectForm],
  form => form.saveLoading,
);

const applicationFormSelectors = {
  selectRecord,
  selectSandboxAppCreationLoading,
  selectSandboxAppCreationErrored,
  selectUpdateApplicationLoading,
};

export default applicationFormSelectors;

import styled, { css, keyframes } from 'styled-components/macro';

export const fadeUp = keyframes`
  0% {opacity: 0; transform: translateY(8px);}     
  100% {opacity: 1; transform: translateY(0px);}
`;

const footerSectionStyle = css`
  overflow: hidden;
`;

const $Section = styled.section`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.neutral.primary};
  font-size: ${({ theme }) => theme.font.size.body.sm};
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;

  ${props => props.hasFooter && footerSectionStyle}

  animation: ${fadeUp} 0.5s 0.2s backwards cubic-bezier(0.2, 0.8, 0.2, 1);

  .header {
    display: flex;
    justify-content: space-between;
    padding: 16px 24px 20px 16px;
  }

  .main {
    padding: 0 24px 16px 16px;
  }

  .footer {
    padding: 24px 16px;
    background-color: #f8f8fb;
    border-top: 1px solid #ececf4;
    color: #8a8a9d;

    .no-margin-bottom {
      margin-bottom: 0;
    }
  }

  .title {
    font-family: ${({ theme }) => theme.font.family.heading};
    font-size: ${({ theme }) => theme.font.size.heading.xs};
    font-weight: 600;
  }
`;

export default $Section;

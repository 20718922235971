import React from 'react';
import PropTypes from 'prop-types';
import useScript from 'utils/useScript';
import config from '../config';

const LinkSDK = ({ isV2 }) => {
  const { linkSDKCDN, linkSDKv2CDN } = config;
  const cdnUrl = isV2 ? linkSDKv2CDN : linkSDKCDN;

  useScript(cdnUrl);

  return <div id="lean-link" />;
};

LinkSDK.propTypes = {
  isV2: PropTypes.bool,
};

LinkSDK.defaultProps = {
  isV2: false,
};

export default LinkSDK;

import styled from 'styled-components/macro';

const $Main = styled.main`
  padding: 16px ${({ theme }) => theme.layout.main.padding};
  max-width: ${({ theme }) => theme.layout.main.maxWidth};

  &.full-desktop-width {
    max-width: ${({ theme }) => theme.layout.main.maxWidthFullScreen};
  }

  &.initial {
    max-width: initial;
    padding: initial;
  }
`;

export default $Main;

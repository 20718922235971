import { apiFetch } from 'modules/shared/fetch';
import { AvsUpdateConfigPayload } from './avs.types';

export default class AvsService {
  static async getAvsConfigurations({
    appToken,
    appEnvironment,
  }: {
    appToken: string;
    appEnvironment: string;
  }) {
    const response = await apiFetch('/verifications/v2/iban/banks', {
      // @ts-ignore
      appEnvironment,
      headers: {
        'lean-app-token': appToken,
      },
    });

    return response?.data;
  }

  static async updateAvsConfigurations({
    data,
    appToken,
    appEnvironment,
  }: {
    appToken: string;
    appEnvironment: string;
    data: AvsUpdateConfigPayload[];
  }) {
    const response = await apiFetch('/verifications/v2/iban/banks', {
      // @ts-ignore
      method: 'PUT',
      body: {
        availabilities: data,
      },
      appEnvironment,
      headers: {
        'lean-app-token': appToken,
      },
    });

    return response?.data;
  }
}

import { RootState } from 'modules/store';
import Message from 'view/shared/message';
import { ErrorObject } from 'types/global';
import Errors from 'modules/shared/error/errors';
import { createAsyncThunk } from '@reduxjs/toolkit';

import CustomersService from './customers.service';
import {
  CustomerData,
  CustomerEntitiesPayload,
  CustomerEntity,
  CustomerPayment,
  CustomerPaymentsPayload,
  CustomersError,
  CustomersPayload,
} from './customers.types';

export const getAllCustomers = createAsyncThunk<
  CustomersPayload,
  { search: string; page: number; pageSize: number },
  {
    state: RootState;
    rejectValue: CustomersError | ErrorObject;
  }
>(
  'customers/get-all-customers',
  async ({ search, page, pageSize }, { getState, rejectWithValue }) => {
    const { environment: appEnvironment, appToken } = getState().root.global;

    try {
      return await CustomersService.getAllCustomersByAppToken({
        page,
        search,
        pageSize,
        appToken,
        appEnvironment,
      });
    } catch (error: unknown) {
      const errorObj: ErrorObject = error as ErrorObject;

      if (errorObj.response.status === 404) {
        return rejectWithValue(errorObj);
      }

      Errors.handle(errorObj);
      return rejectWithValue(errorObj);
    }
  },
);

export const getCustomerById = createAsyncThunk<
  CustomerData,
  { customerId: string },
  {
    state: RootState;
    rejectValue: CustomersError | ErrorObject;
  }
>(
  'customers/get-customer-by-id',
  async ({ customerId }, { getState, rejectWithValue }) => {
    const { environment: appEnvironment, appToken } = getState().root.global;

    try {
      return await CustomersService.getSingleCustomersByAppToken({
        appToken,
        customerId,
        appEnvironment,
      });
    } catch (error: unknown) {
      const errorObj: ErrorObject = error as ErrorObject;

      if (errorObj.response.status === 404) {
        return rejectWithValue(errorObj);
      }

      Errors.handle(errorObj);
      return rejectWithValue(errorObj);
    }
  },
);

export const getCustomerEntities = createAsyncThunk<
  CustomerEntitiesPayload,
  { page: number; pageSize: number; customerId: string },
  {
    state: RootState;
    rejectValue: CustomersError | ErrorObject;
  }
>(
  'customers/get-customer-entities',
  async ({ page, pageSize, customerId }, { getState, rejectWithValue }) => {
    const { environment: appEnvironment, appToken } = getState().root.global;

    try {
      return await CustomersService.getCustomerEntities({
        page,
        pageSize,
        appToken,
        customerId,
        appEnvironment,
      });
    } catch (error: unknown) {
      const errorObj: ErrorObject = error as ErrorObject;

      if (errorObj.response.status === 404) {
        return rejectWithValue(errorObj);
      }

      Errors.handle(errorObj);
      return rejectWithValue(errorObj);
    }
  },
);

export const getCustomerEntityById = createAsyncThunk<
  CustomerEntity,
  { entityId: string; customerId: string },
  {
    state: RootState;
    rejectValue: CustomersError | ErrorObject;
  }
>(
  'customers/get-customer-entity-details',
  async ({ entityId, customerId }, { getState, rejectWithValue }) => {
    const { environment: appEnvironment, appToken } = getState().root.global;

    try {
      return await CustomersService.getCustomerEntityById({
        entityId,
        appToken,
        customerId,
        appEnvironment,
      });
    } catch (error: unknown) {
      const errorObj: ErrorObject = error as ErrorObject;

      if (errorObj.response.status === 404) {
        return rejectWithValue(errorObj);
      }

      Errors.handle(errorObj);
      return rejectWithValue(errorObj);
    }
  },
);

export const deleteCustomerEntityById = createAsyncThunk<
  undefined,
  { entityId: string; customerId: string },
  {
    state: RootState;
    rejectValue: ErrorObject;
  }
>(
  'customers/delete-customer-entity',
  async ({ entityId, customerId }, { getState, rejectWithValue }) => {
    const { environment: appEnvironment, appToken } = getState().root.global;

    try {
      return await CustomersService.deleteSingleCustomerEntityById({
        entityId,
        appToken,
        customerId,
        appEnvironment,
      });
    } catch (error: unknown) {
      const errorObj: ErrorObject = error as ErrorObject;

      Errors.handle(errorObj);
      return rejectWithValue(errorObj);
    }
  },
);

export const getCustomerPayments = createAsyncThunk<
  CustomerPaymentsPayload,
  {
    page: number;
    status: string;
    dateTo: string;
    pageSize: number;
    dateFrom: string;
    maxAmount: number;
    minAmount: number;
    customerId: string;
    paymentIntentId: string;
  },
  {
    state: RootState;
    rejectValue: CustomersError | ErrorObject;
  }
>(
  'customers/get-customers-payments',
  async (
    {
      page,
      status,
      dateTo,
      pageSize,
      dateFrom,
      maxAmount,
      minAmount,
      customerId,
      paymentIntentId,
    },
    { getState, rejectWithValue },
  ) => {
    const { environment: appEnvironment, appToken } = getState().root.global;

    const body = {
      page,
      order_by: 'DESC',
      page_size: pageSize,
      customer_id: customerId,
      ...(status ? { status } : {}),
      ...(dateTo ? { to: dateTo } : {}),
      ...(dateFrom ? { from: dateFrom } : {}),
      ...(maxAmount ? { amount_max: maxAmount } : {}),
      ...(minAmount ? { amount_min: minAmount } : {}),
      ...(paymentIntentId ? { payment_intent_id: paymentIntentId } : {}),
    };

    try {
      return await CustomersService.getCustomerPayments({
        body,
        appToken,
        appEnvironment,
      });
    } catch (error: unknown) {
      const errorObj: ErrorObject = error as ErrorObject;

      if (errorObj.response.status === 404) {
        return rejectWithValue(errorObj);
      }

      if (Errors.errorStatusOrStatusCode(errorObj) === 'INVALID_PARAMETERS') {
        Message.error('Please provide a valid UUID intent');
        return rejectWithValue(errorObj);
      }

      Errors.handle(errorObj);
      return rejectWithValue(errorObj);
    }
  },
);

export const getCustomerPaymentById = createAsyncThunk<
  CustomerPayment,
  { paymentId: string },
  {
    state: RootState;
    rejectValue: CustomersError | ErrorObject;
  }
>(
  'customers/get-customer-payment-by-id',
  async ({ paymentId }, { getState, rejectWithValue }) => {
    const { environment: appEnvironment, appToken } = getState().root.global;

    try {
      return await CustomersService.getSingleCustomerPaymentById({
        appToken,
        paymentId,
        appEnvironment,
      });
    } catch (error: unknown) {
      const errorObj: ErrorObject = error as ErrorObject;

      if (errorObj.response.status === 404) {
        return rejectWithValue(errorObj);
      }

      Errors.handle(errorObj);
      return rejectWithValue(errorObj);
    }
  },
);

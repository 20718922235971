import { RootState } from 'modules/store';
import { createSlice } from '@reduxjs/toolkit';
import { AvsConfiguration, AvsConfigurationState } from './avs.types';
import { getAvsConfigurations, updateAvsConfigurations } from './avs.actions';

const initialState: AvsConfigurationState = {
  configurations: [],
  updatedConfigurations: [],
  error: '',
  fetching: false,
  isSubmitting: false,
};

const avsSlice = createSlice({
  name: 'avs',
  initialState,
  reducers: {
    setUpdateConfigurations: (state, { payload }) => {
      state.updatedConfigurations = payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(getAvsConfigurations.pending, state => {
      state.fetching = true;
    });
    builder.addCase(getAvsConfigurations.fulfilled, (state, { payload }) => {
      state.fetching = false;
      state.configurations = payload.map((configuration: AvsConfiguration) => ({
        ...configuration,
        id: configuration.bank_code,
        isSelectable: false,
      }));
    });
    builder.addCase(getAvsConfigurations.rejected, state => {
      state.fetching = false;
      state.error = 'Failed to load Account Verification configurations';
    });

    builder.addCase(updateAvsConfigurations.pending, state => {
      state.isSubmitting = true;
    });
    builder.addCase(updateAvsConfigurations.fulfilled, state => {
      state.isSubmitting = false;
      state.updatedConfigurations = [];
    });
    builder.addCase(updateAvsConfigurations.rejected, state => {
      state.isSubmitting = false;
    });
  },
});

export default avsSlice.reducer;
export const selectAvsState = (state: RootState) => state.root.avs;
export const { setUpdateConfigurations } = avsSlice.actions;

const SCOPES = {
  DASHBOARD_VIEW: {
    READ: 'dashboardview.read',
    WRITE: 'dashboardview.write',
  },
  DATA_CALLS: {
    READ: 'datacalls.read',
    WRITE: 'datacalls.write',
  },
  PAYMENTS_LOGS: {
    READ: 'paymentslogs.read',
    WRITE: 'paymentslogs.write',
  },
  INTEGRATION_APPLICATION: {
    READ: 'integrationapplication.read',
    WRITE: 'integrationapplication.write',
  },
  CERTIFICATES: {
    READ: 'certificates.read',
    WRITE: 'certificates.write',
  },
  SECRET: {
    READ: 'secret.read',
    WRITE: 'secret.write',
  },
  SANDBOX_TEST_USERS: {
    READ: 'sandboxtestusers.read',
    WRITE: 'sandboxtestusers.write',
  },
  BILLING: {
    READ: 'billing.read',
    WRITE: 'billing.write',
  },
  SETTINGS: {
    READ: 'settings.read',
    WRITE: 'settings.write',
  },
  BANK_CONNECTIONS: {
    READ: 'bankconnections.read',
    WRITE: 'bankconnections.write',
  },
  USER_SETTINGS: {
    READ: 'usersettings.read',
    WRITE: 'usersettings.write',
  },
  USER_MANAGEMENT: {
    READ: 'usermanagement.read',
    WRITE: 'usermanagement.write',
  },
  WEBHOOKS: {
    READ: 'webhooks.read',
    WRITE: 'webhooks.write',
  },
  PAYMENT_RECONCILIATION: {
    READ: 'paymentreconciliation.read',
    WRITE: 'paymentreconciliation.write',
  },
  PAYOUTS: {
    READ: 'payouts.read',
    WRITE: 'payouts.write',
  },
  CUSTOMERS: {
    READ: 'customersmanagement.read',
    WRITE: 'customersmanagement.write',
  },
  DATA_REPORT: {
    MGMT: 'datareport.management',
  },
};

export const getReadScopeForPage = page => {
  switch (page) {
    case 'dashboard':
      return SCOPES.DASHBOARD_VIEW.READ;
    case 'data-calls':
      return SCOPES.DATA_CALLS.READ;
    case 'payments':
      return SCOPES.PAYMENTS_LOGS.READ;
    case 'integration':
      return SCOPES.INTEGRATION_APPLICATION.READ;
    case 'billing':
      return SCOPES.BILLING.READ;
    case 'settings':
      return SCOPES.SETTINGS.READ;
    case 'banks':
      return SCOPES.BANK_CONNECTIONS.READ;
    case 'team':
      return SCOPES.USER_MANAGEMENT.READ;
    case 'payouts':
      return SCOPES.PAYOUTS.WRITE;
    case 'reconciliation':
      return SCOPES.PAYMENT_RECONCILIATION.READ;
    case 'webhooks':
      return SCOPES.WEBHOOKS.READ;
    case 'customers':
      return SCOPES.CUSTOMERS.READ;
    default:
      return null;
  }
};

export default SCOPES;

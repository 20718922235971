import PropTypes from 'prop-types';
import React from 'react';

import { $Section } from '../styles';

const Section = ({
  children,
  headerElement = null,
  title = '',
  footer,
  'data-cy': dataCy,
}) => (
  <$Section data-cy={dataCy} hasFooter={Boolean(footer)}>
    <header className="header">
      {title && <h2 className="title">{title}</h2>}
      {headerElement}
    </header>
    <div className="main">{children}</div>
    {footer && <div className="footer">{footer}</div>}
  </$Section>
);

Section.propTypes = {
  children: PropTypes.node.isRequired,
  headerElement: PropTypes.element,
  title: PropTypes.string,
  'data-cy': PropTypes.string,
  footer: PropTypes.node,
};

Section.defaultProps = {
  headerElement: undefined,
  title: '',
  'data-cy': '',
  footer: null,
};

export default Section;

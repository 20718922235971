import production from './production';
import sa01 from './sa01';
import ae02 from './ae02';
import sa02 from './sa02';
import ae03 from './ae03';
import localhost from './localhost';

export const COUNTRIES_ENVIRONMENTS = [
  {
    KSA: {
      hostname: sa01.hostname,
      url: sa01.url,
      value: 'ksa',
    },
    UAE: {
      hostname: production.hostname,
      url: production.url,
      value: 'uae',
    },
  },
  {
    KSA: {
      hostname: null,
      url: null,
      value: 'ksa',
    },
    UAE: {
      hostname: ae03.hostname,
      url: ae03.url,
      value: 'uae',
    },
  },
  {
    KSA: {
      hostname: sa02.hostname,
      url: sa02.url,
      value: 'ksa',
    },
    UAE: {
      hostname: ae02.hostname,
      url: ae02.url,
      value: 'uae',
    },
  },
  {
    KSA: {
      hostname: null,
      url: null,
      value: 'ksa',
    },
    UAE: {
      hostname: localhost.hostname,
      url: localhost.url,
      value: 'uae',
    },
  },
];

export const COUNTRIES_LIST = {
  KSA: {
    label: 'KSA',
    value: 'ksa',
    emoji_icon: '🇸🇦',
  },
  UAE: {
    label: 'UAE',
    value: 'uae',
    emoji_icon: '🇦🇪',
  },
};

export const COUNTRY_CODE__SA = 'SAU';
export const COUNTRY_CODE__AE = 'ARE';
export const COUNTRIES_CONFIG = {
  countryCode: process.env.REACT_APP_COUNTRY_CODE || COUNTRY_CODE__AE,
  openBanking: process.env.REACT_APP_COUNTRY_CODE === COUNTRY_CODE__SA,
};
export const isKSACountry = COUNTRIES_CONFIG.countryCode === COUNTRY_CODE__SA;
export const isUAECountry = COUNTRIES_CONFIG.countryCode === COUNTRY_CODE__AE;

import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { copyToClipboard } from 'modules/shared/strings/copyToClipboard';
import Icon from 'components/Icon';

export const $Button = styled.button<{ copied: boolean }>`
  margin: 0;
  padding: 4px;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.layout.spacing.xxs};
  color: ${({ copied, theme }) =>
    copied ? theme.colors.neon._70 : theme.colors.neutral._80};
  font-size: ${({ theme }) => theme.font.size.body.xs};
  line-height: 16.2px;
  border-radius: 4px;

  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.colors.neutral._20};
  }
`;

export const $Text = styled.span``;

interface Props {
  textToCopy: string;
}

enum State {
  default,
  copied,
}

const CopyToClipboardButton = ({ textToCopy, ...rest }: Props) => {
  const [state, setState] = useState(State.default);
  const timeoutIdRef = useRef<number | undefined>(undefined);
  const onClick = () => {
    window.clearTimeout(timeoutIdRef.current);

    copyToClipboard(textToCopy, { showMessage: false }).then(() => {
      setState(State.copied);

      timeoutIdRef.current = window.setTimeout(() => {
        setState(State.default);
      }, 5000);
    });
  };

  const icon = state === State.default ? 'Clipboard' : 'Checkmark';
  const text = state === State.default ? 'Copy' : 'Copied';

  return (
    <$Button
      {...rest}
      type="button"
      onClick={onClick}
      copied={state === State.copied}
    >
      <Icon name={icon} size={16} />
      <$Text>{text}</$Text>
    </$Button>
  );
};

export default CopyToClipboardButton;

import { camelCase } from 'lodash';
/* eslint-disable consistent-return */
import ApplicationService from 'modules/application/applicationService';
import Errors from 'modules/shared/error/errors';

import { actionTypes } from './actionTypes';

const getProductionAndPreLiveApplications = applications => {
  const result = { production: [], preLive: [] };

  if (!Array.isArray(applications)) {
    return result;
  }

  result.preLive = applications.filter(app => app.plan.name === 'PRE_LIVE');
  result.production = applications.filter(
    app => app.plan.name === 'PRODUCTION',
  );

  return result;
};

const actions = {
  doGetProductionApplications: () => async dispatch => {
    try {
      dispatch({
        type: actionTypes.FETCH_PRODUCTION_STARTED,
      });

      const applications = await ApplicationService.getProductionApplications();
      const { production, preLive } =
        getProductionAndPreLiveApplications(applications);

      dispatch({
        type: actionTypes.FETCH_PRODUCTION_SUCCESS,
        payload: { production, preLive },
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: actionTypes.FETCH_PRODUCTION_ERROR,
      });
      return error;
    }
  },

  doGetSandboxApplications: () => async dispatch => {
    try {
      dispatch({
        type: actionTypes.FETCH_SANDBOX_STARTED,
      });

      const sandbox = await ApplicationService.getSandboxApplications();

      dispatch({
        type: actionTypes.FETCH_SANDBOX_SUCCESS,
        payload: sandbox,
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: actionTypes.FETCH_SANDBOX_ERROR,
      });
      return error;
    }
  },

  doGetSandboxAndProductionApplications: () => async dispatch => {
    try {
      dispatch({
        type: actionTypes.FETCH_SANDBOX_AND_PRODUCTION_STARTED,
      });

      const [sandbox, productionApplications] = await Promise.all([
        ApplicationService.getSandboxApplications(),
        ApplicationService.getProductionApplications(),
      ]);
      const { production, preLive } = getProductionAndPreLiveApplications(
        productionApplications,
      );

      dispatch({
        type: actionTypes.FETCH_SANDBOX_AND_PRODUCTION_SUCCESS,
        payload: { sandbox, production, preLive },
      });

      return { sandbox, production, preLive };
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: actionTypes.FETCH_SANDBOX_AND_PRODUCTION_ERROR,
      });
      return error;
    }
  },

  // This could be a selector?
  getApplicationEnvironment: appId => (dispatch, getState) => {
    const { list } = getState().root.application;

    const app = [...list.production, ...list.sandbox, ...list.preLive].find(
      app => app.id === appId,
    );

    return app && camelCase(app.plan.name);
  },
};

export default actions;

import styled from 'styled-components/macro';

const $AppProfile = styled.div`
  align-items: center;
  display: flex;
  gap: 0 8px;
  padding: 16px 8px;

  .label {
    overflow: hidden;
    font-size: ${({ theme }) => theme.font.size.body.md};
    text-overflow: ellipsis;
    margin: 0;
    padding: 0;
  }

  // mobile
  @media (max-width: 767px) {
    .label {
      display: none;
    }
  }
`;

export default $AppProfile;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import actions from 'modules/auth/authActions';
import authSelectors from 'modules/auth/authSelectors';
import { setRolesLabels } from 'view/teamPage/helpers';
import { isEmptyValue } from 'view/shared/validation/Validation';
import featureFlags, { FeatureFlags } from 'modules/featureFlags';
import { MfaStatus } from 'modules/auth/constants';
import { getHistory } from 'modules/history';
import { Dialog, DialogContent } from '../Dialog';
import TextField from '../form/TextField';
import Button from '../Button';
import {
  $Badge,
  $DialogFooter,
  $Divider,
  $Field,
  $Role,
  $WarningIcon,
} from './AccountSettingsDialogStyles';

function AccountSettingsDialog({
  isOpen,
  dispatch,
  currentUser,
  toggleDialog,
}) {
  const history = getHistory();
  const [isSaving, setIsSaving] = useState(false);
  const [name, setName] = useState({
    firstName: '',
    lastName: '',
  });

  const userHasEnrolledMfa =
    currentUser.mfa_settings.status !== MfaStatus.notEnrolled;

  useEffect(() => {
    setName({
      firstName: currentUser.first_name,
      lastName: currentUser.last_name,
    });
  }, [currentUser]);

  const getErrorText = fieldName => `Please enter your ${fieldName}`;

  const handleChange = event => {
    setName(prevState => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const handleChangePassword = async () => {
    toggleDialog();
    history.push('/auth/change-password');
  };

  const handleSubmit = async () => {
    setIsSaving(true);
    const data = {
      first_name: name.firstName?.trim(),
      last_name: name.lastName?.trim(),
    };
    await dispatch(actions.doUpdateAccount(data));
    setIsSaving(false);
    toggleDialog();
  };

  const handleSetupMfa = () => {
    dispatch(actions.doClearErrorMessage());
    history.push('/mfa/setup');
    toggleDialog();
  };

  const handleUpdateMfa = () => {
    dispatch(actions.doClearErrorMessage());
    history.push('/mfa/update');
  };

  const getMfaSection = () => {
    if (!featureFlags.isOn(FeatureFlags.rolloutMfa)) {
      return null;
    }

    return (
      <$Field className="mb-92">
        <span className="label">MFA</span>
        {userHasEnrolledMfa ? (
          <>
            <Button styledProps={{ variant: 'text' }} onClick={handleUpdateMfa}>
              Update Authenticator App
            </Button>

            <Badge
              type="success"
              title="Configured"
              imageScr="/icons/checkmark-checked.svg"
              imgAlt="success icon"
            />
          </>
        ) : (
          <>
            <Button styledProps={{ variant: 'text' }} onClick={handleSetupMfa}>
              Setup Authenticator App
            </Button>

            <Badge
              type="warning"
              title="Not enabled"
              imageScr="/images/warning/warning.svg"
              imgAlt="warning icon"
            />
          </>
        )}
      </$Field>
    );
  };

  return (
    <Dialog open={isOpen} onOpenChange={toggleDialog}>
      <DialogContent
        width={640}
        isCloseButton
        title="Account settings"
        data-cy="account_settings_dialog"
      >
        <div>
          <$Field className="mb-30">
            <TextField
              type="text"
              name="firstName"
              label="First name"
              value={name.firstName}
              onChange={handleChange}
              isErrored={isEmptyValue(name.firstName)}
              helptext={
                isEmptyValue(name.firstName) ? getErrorText('first name') : ''
              }
              data-cy="account_settings_input_firstName"
            />
          </$Field>

          <$Field className="mb-30">
            <TextField
              type="text"
              name="lastName"
              label="Last name"
              value={name.lastName}
              onChange={handleChange}
              isErrored={isEmptyValue(name.lastName)}
              helptext={
                isEmptyValue(name.lastName) ? getErrorText('last name') : ''
              }
              data-cy="account_settings_input_lastName"
            />
          </$Field>

          <$Field className="mb-30">
            <span className="label">Role</span>
            <$Role data-cy="account_settings_label_roles">
              {setRolesLabels(currentUser.roles).join(', ')}
            </$Role>
          </$Field>

          <$Divider />

          <$Field className="mb-30">
            <TextField
              isDisabled
              type="text"
              name="email"
              label="Email address"
              value={currentUser.email}
              data-cy="account_settings_input_email"
            />
          </$Field>

          <$Field className="mb-30">
            <span className="label">Password</span>
            <Button
              styledProps={{ variant: 'text' }}
              onClick={handleChangePassword}
            >
              Change Password
            </Button>
          </$Field>

          {getMfaSection()}
        </div>

        <$DialogFooter>
          <Button
            type="submit"
            isDisabled={
              isEmptyValue(name.firstName) || isEmptyValue(name.lastName)
            }
            isLoading={isSaving}
            onClick={handleSubmit}
            data-cy="account_settings_submit"
          >
            Save settings
          </Button>
        </$DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

AccountSettingsDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  toggleDialog: PropTypes.func.isRequired,
  currentUser: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string,
    roles: PropTypes.arrayOf(PropTypes.string),
    mfa_settings: PropTypes.shape({
      status: PropTypes.string,
    }),
  }),
};

AccountSettingsDialog.defaultProps = {
  currentUser: null,
};

const Badge = ({ type, title, imageScr, imgAlt }) => (
  <$Badge className={type === 'warning' ? 'warning' : 'success'}>
    <span>{title}</span>
    <$WarningIcon src={imageScr} alt={imgAlt} />
  </$Badge>
);

Badge.propTypes = {
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  imageScr: PropTypes.string.isRequired,
  imgAlt: PropTypes.string.isRequired,
};

function select(state) {
  return {
    currentUser: authSelectors.selectCurrentUser(state),
  };
}

export default connect(select)(AccountSettingsDialog);

import SCOPES from 'security/scopes';
import { FeatureFlags } from 'modules/featureFlags';
import { isKSACountry } from 'config/countriesEnvironments';
import { ENVIRONMENT_SANDBOX } from 'utils/constants';
import { i18n } from '../i18n';

/**
 * Private Routes props
 *
 * path — route path
 * label - menu label
 * exact — is path exact
 * loader — lazy loaded component
 * permissionRequired — user permission type for route
 * menu - determines if route label should be shown in sidebar menu
 * isDetailsPage - indicates if a page is details view under a parent page,
 * showOnlyOnEnv - determines which env a menu item should show (sandbox | production | preLive)
 */
export const privateRoutes = [
  {
    path: '/',
    loader: () => import('view/application/list/ApplicationAutoCreatePage'),
    exact: true,
    icon: 'AppsSharp',
    label: i18n('entities.application.menu'),
    menu: false,
  },
  {
    path: '/mfa/setup',
    loader: () => import('view/mfa/MfaSetupPage'),
    exact: true,
    menu: false,
  },
  {
    path: '/mfa/update',
    loader: () => import('view/mfa/MfaUpdatePage'),
    exact: true,
    menu: false,
  },
  {
    path: '/applications',
    loader: () => import('view/application/list/ApplicationAutoCreatePage'),
    exact: true,
    icon: 'AppsSharp',
    label: i18n('entities.application.menu'),
    menu: false,
  },
  {
    path: '/id/:id',
    loader: () => import('view/dashboardPage/DashboardPage'),
    menu: true,
    exact: true,
    icon: 'Flash',
    label: 'Getting started',
    showOnlyOnEnv: ENVIRONMENT_SANDBOX,
  },
  {
    path: '/id/:id/payments',
    loader: () => import('view/paymentsPage/PaymentsPage'),
    menu: true,
    permissionRequired: SCOPES.PAYMENTS_LOGS.READ,
    exact: true,
    icon: 'Cash',
    label: 'Payments',
    featureFlag: FeatureFlags.paymentsPage,
  },
  {
    path: '/id/:id/payments/:payment_id',
    loader: () => import('view/paymentsPage/PaymentDetailsPage'),
    menu: false,
    permissionRequired: SCOPES.PAYMENTS_LOGS.READ,
    exact: true,
    icon: 'Cash',
    label: 'Payment details',
    isDetailsPage: true,
    featureFlag: FeatureFlags.paymentsPage,
  },
  {
    path: '/id/:id/data-calls',
    loader: () => import('view/dataCallsPage/DataCallsPage'),
    menu: true,
    permissionRequired: SCOPES.DATA_CALLS.READ,
    exact: true,
    icon: 'Terminal',
    label: 'Data calls',
  },
  {
    path: '/id/:id/integration',
    loader: () => import('view/integrationPage/IntegrationPage'),
    menu: true,
    isFullScreen: true,
    permissionRequired: SCOPES.INTEGRATION_APPLICATION.READ,
    exact: true,
    icon: 'Construct',
    label: 'Integration',
  },
  {
    path: '/id/:id/billing',
    loader: () => import('view/billingPage/BillingPage'),
    menu: true,
    permissionRequired: SCOPES.BILLING.READ,
    exact: true,
    icon: 'Receipt',
    label: 'Billing',
  },
  {
    path: '/id/:id/settings',
    loader: () => import('view/settingsPage/SettingsPage'),
    menu: true,
    permissionRequired: SCOPES.SETTINGS.READ,
    exact: true,
    icon: 'Settings',
    label: 'Settings',
  },
  {
    path: '/id/:id/banks',
    loader: () => import('view/bankConnectionsPage/BankConnectionsPage'),
    menu: true,
    permissionRequired: SCOPES.BANK_CONNECTIONS.READ,
    exact: true,
    icon: 'BuildingBank',
    label: 'Bank connections',
  },
  {
    path: '/id/:id/data-calls/:results_id',
    loader: () => import('view/dataCallsPage/DataRequestDetailsPage'),
    menu: false,
    permissionRequired: SCOPES.DATA_CALLS.READ,
    exact: true,
    icon: 'Terminal',
    label: 'Data call details',
    isDetailsPage: true,
  },
  {
    path: '/id/:id/team',
    loader: () => import('view/teamPage/TeamPage'),
    menu: true,
    permissionRequired: SCOPES.USER_MANAGEMENT.READ,
    exact: true,
    icon: 'People',
    label: 'Team',
  },
  {
    path: '/id/:id/payouts',
    loader: () => import('view/payoutsPage/PayoutsPage'),
    permissionRequired: SCOPES.PAYOUTS.WRITE,
    menu: true,
    exact: true,
    isFullScreen: true,
    icon: 'Payouts',
    label: 'Payouts',
  },
  ...(isKSACountry
    ? []
    : [
        {
          path: '/id/:id/reconciliation',
          loader: () => import('view/reconciliationPage/ReconciliationPage'),
          menu: true,
          permissionRequired: SCOPES.PAYMENT_RECONCILIATION.READ,
          exact: true,
          isFullScreen: true,
          icon: 'CheckmarkDoneCircle',
          label: 'Reconciliation',
        },
        {
          path: '/id/:id/reconciliation/:category/:payment_id',
          loader: () =>
            import('view/reconciliationPage/ReconciliationDetailsPage'),
          permissionRequired: SCOPES.PAYMENT_RECONCILIATION.READ,
          menu: false,
          exact: true,
          isFullScreen: true,
          label: 'Reconciliation details',
        },
      ]),
  {
    path: '/id/:id/webhooks',
    loader: () => import('view/webhooksPage/WebhooksPage'),
    menu: true,
    permissionRequired: SCOPES.WEBHOOKS.READ,
    exact: true,
    icon: 'Webhooks',
    label: 'Webhooks',
  },
  {
    path: '/id/:id/webhooks/:webhook_destination_id',
    loader: () => import('view/webhooksPage/WebhookLogsPage'),
    menu: false,
    permissionRequired: SCOPES.WEBHOOKS.READ,
    exact: true,
    icon: 'Webhooks',
    label: 'Webhook details',
    isDetailsPage: true,
  },
  {
    menu: true,
    exact: true,
    icon: 'PersonAdd',
    isFullScreen: true,
    isDetailsPage: false,
    label: 'Customers',
    path: '/id/:id/customers',
    permissionRequired: SCOPES.CUSTOMERS.READ,
    loader: () => import('view/customersPage/CustomersPage'),
  },
  {
    menu: false,
    exact: true,
    icon: 'PersonAdd',
    isFullScreen: false,
    isDetailsPage: true,
    label: 'Customer Details',
    path: '/id/:id/customers/:customer_id',
    permissionRequired: SCOPES.CUSTOMERS.READ,
    loader: () => import('view/customersPage/CustomerDetailsPage'),
  },
  {
    menu: false,
    exact: true,
    icon: 'PersonAdd',
    isFullScreen: false,
    isDetailsPage: true,
    label: 'Entities connected',
    permissionRequired: SCOPES.CUSTOMERS.READ,
    path: '/id/:id/customers/:customer_id/entities',
    loader: () => import('view/customersPage/CustomerEntitiesPage'),
  },
  {
    menu: false,
    exact: true,
    icon: 'PersonAdd',
    isFullScreen: false,
    isDetailsPage: true,
    label: 'Entity details',
    permissionRequired: SCOPES.CUSTOMERS.READ,
    path: '/id/:id/customers/:customer_id/entities/:entity_id',
    loader: () => import('view/customersPage/CustomerEntityDetailsPage'),
  },
  {
    menu: false,
    exact: true,
    icon: 'PersonAdd',
    isFullScreen: false,
    isDetailsPage: true,
    label: 'Payments',
    permissionRequired: SCOPES.CUSTOMERS.READ,
    path: '/id/:id/customers/:customer_id/payments',
    loader: () => import('view/customersPage/CustomerPaymentsPage'),
  },
  {
    menu: false,
    exact: true,
    icon: 'PersonAdd',
    isFullScreen: false,
    isDetailsPage: true,
    label: 'Payment details',
    permissionRequired: SCOPES.CUSTOMERS.READ,
    path: '/id/:id/customers/:customer_id/payments/:payment_id',
    loader: () => import('view/customersPage/CustomerPaymentDetailsPage'),
  },
  ...(isKSACountry
    ? [
        {
          menu: true,
          exact: true,
          icon: 'LinkOutline',
          isDetailsPage: false,
          label: 'Reports',
          path: '/id/:id/reports',
          featureFlag: FeatureFlags.reportsPage,
          permissionRequired: SCOPES.DATA_REPORT.MGMT,
          loader: () => import('view/reportsPage/ReportsPage'),
        },
        {
          menu: false,
          exact: true,
          icon: 'LinkOutline',
          isFullScreen: false,
          isDetailsPage: true,
          label: 'Customer details',
          path: '/id/:id/reports/:app_user_id',
          featureFlag: FeatureFlags.reportsPage,
          permissionRequired: SCOPES.DATA_REPORT.MGMT,
          loader: () => import('view/reportsPage/ReportsDetailsPage'),
        },
        {
          menu: false,
          exact: true,
          icon: 'LinkOutline',
          isFullScreen: false,
          isDetailsPage: true,
          hasNoStylesForMainSection: true,
          label: 'Financial report',
          path: '/id/:id/reports/:app_user_id/:report_id',
          featureFlag: FeatureFlags.rolloutFinancialReportPage,
          permissionRequired: SCOPES.DATA_REPORT.MGMT,
          loader: () =>
            import('view/reportsPage/financialReport/FinancialReportPage'),
        },
      ]
    : []),

  // Note: Breadcrumbs generator depends on the order of the routes.
  // Please make sure to place the parent route/page before its details page

  // Note: Do not remove the line below as it is required for automated code generation to inject route configs
  /* CODEGEN_INJECT_ROUTE */
];

export const publicRoutes = [
  ...(isKSACountry
    ? [
        {
          path: '/hyperlink/:customer_id',
          loader: () => import('view/hyperlinkPages/Register'),
        },
      ]
    : []),
  {
    path: '/auth/signin',
    loader: () => import('view/auth/SigninPage'),
  },
  {
    path: '/auth/signup',
    loader: () => import('view/auth/SignupPage'),
  },
  {
    path: '/auth/forgot-password',
    loader: () => import('view/auth/ForgotPasswordPage'),
  },
  // Page to ask user to check their email for email verification.
  {
    path: '/verify-email',
    loader: () => import('view/auth/VerifyEmailPage'),
  },
  {
    path: '/auth/join',
    loader: () => import('view/auth/JoinTeamPage'),
  },
  {
    path: '/verify-mfa',
    loader: () => import('view/auth/VerifyMFAPage'),
  },
];

export const emptyPermissionsRoutes = [
  {
    path: '/auth/empty-permissions',
    loader: () => import('view/auth/EmptyPermissionsPage'),
  },
];

export const emailUnverifiedRoutes = [];

export const disabledRoutes = [];

export const simpleRoutes = [
  {
    path: '/auth/reset-password',
    loader: () => import('view/auth/PasswordResetPage'),
  },
  {
    path: '/auth/change-password',
    loader: () => import('view/auth/PasswordChangePage'),
  },
  // /email/verify-token?token= -- link sent to email for email verification is via this page
  {
    path: '/email/verify-token',
    loader: () => import('view/auth/VerifyTokenPage'),
  },
  // TODO: fix to allow non logged in users to access it
  {
    path: '/auth/resend-email',
    loader: () => import('view/auth/ResendEmailPage'),
  },
  {
    path: '/403',
    loader: () => import('view/shared/errors/Error403Page'),
  },
  {
    path: '/locked-account',
    loader: () =>
      import('view/shared/errors/ErrorMultipleIncorrectLoginAttempts'),
  },
  {
    path: '/429',
    loader: () => import('view/shared/errors/Error429Page'),
  },
  {
    path: '/500',
    loader: () => import('view/shared/errors/Error500Page'),
  },
  {
    path: '/404',
    loader: () => import('view/shared/errors/Error404Page'),
  },
  {
    path: '**',
    loader: () => import('view/shared/errors/Error404Page'),
  },
];

export const routeHelpers = {
  isDetailsView: path => {
    return Boolean(
      privateRoutes.find(route => route.path === path)?.isDetailsView,
    );
  },
  isFullScreen: path => {
    return privateRoutes.find(route => route.path === path)?.isFullScreen;
  },
  hasNoStylesForMainSection: path => {
    return privateRoutes.find(route => route.path === path)
      ?.hasNoStylesForMainSection;
  },
  updateAppIdInPath: ({ pathname, applicationId }) => {
    if (!applicationId) {
      return '/';
    }

    let nextPathname = `/id/${applicationId}`;
    if (pathname.includes('/id/')) {
      const pathnameArray = String(pathname || '').split('/');
      // replace {applicationId} from pathname with provided {applicationId}
      pathnameArray[2] = applicationId;
      nextPathname = pathnameArray.join('/');
    }

    return nextPathname;
  },
};

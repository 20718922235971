// Selectors
import { createSelector } from 'reselect';
import {
  RECONCILED_PAYMENTS_TAB,
  UNRECEIVED_PAYMENTS_TAB,
  UNRECONCILED_PAYMENTS_TAB,
} from 'view/reconciliationPage/constants';

const selectReconciliations = state => state.root.reconciliations;

const selectDeposits = createSelector(
  [selectReconciliations],
  ({ deposits }) => deposits,
);

const selectTotals = createSelector(
  [selectReconciliations],
  ({ totals }) => totals,
);

const selectPaymentsCount = createSelector(
  [selectReconciliations],
  ({ paymentsCount }) => paymentsCount,
);

const selectTableData = createSelector(
  [selectReconciliations],
  ({ unreconciledPayments, unreceivedPayments, reconciledPayments }) => ({
    [UNRECONCILED_PAYMENTS_TAB]: unreconciledPayments,
    [UNRECEIVED_PAYMENTS_TAB]: unreceivedPayments,
    [RECONCILED_PAYMENTS_TAB]: reconciledPayments,
  }),
);

const selectTableLoadingState = createSelector(
  [selectReconciliations],
  ({
    loadingUnreconciledPayments,
    loadingUnreceivedPayments,
    loadingReconciledPayments,
  }) => ({
    [UNRECONCILED_PAYMENTS_TAB]: loadingUnreconciledPayments,
    [UNRECEIVED_PAYMENTS_TAB]: loadingUnreceivedPayments,
    [RECONCILED_PAYMENTS_TAB]: loadingReconciledPayments,
  }),
);

const selectBanks = createSelector(
  [selectReconciliations],
  ({ banks }) => banks,
);

const selectBanksObj = createSelector([selectReconciliations], ({ banks }) =>
  banks.reduce((banks, bank) => {
    banks[bank.identifier] = bank;
    return banks;
  }, {}),
);

const selectLoadingBanks = createSelector(
  [selectReconciliations],
  ({ loadingBanks }) => loadingBanks,
);

const selectUpdatingPaymentStatus = createSelector(
  [selectReconciliations],
  ({ updatingPaymentStatus }) => updatingPaymentStatus,
);

const selectPaymentStatusUpdateError = createSelector(
  [selectReconciliations],
  ({ paymentStatusUpdateHasError }) => paymentStatusUpdateHasError,
);

const selectReconciliationConfiguration = createSelector(
  [selectReconciliations],
  ({ reconciliationConfiguration }) => reconciliationConfiguration,
);

export {
  selectBanks,
  selectTotals,
  selectBanksObj,
  selectDeposits,
  selectTableData,
  selectLoadingBanks,
  selectPaymentsCount,
  selectTableLoadingState,
  selectUpdatingPaymentStatus,
  selectPaymentStatusUpdateError,
  selectReconciliationConfiguration,
};

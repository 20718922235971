import React from 'react';
import ReactDOM from 'react-dom';
import SettingsService from 'modules/settings/settingsService';

import App from './App';

SettingsService.applyTheme('default');
// Wrap the rendering in a function:
const render = () => {
  ReactDOM.render(<App />, document.getElementById('root'));
};

render();

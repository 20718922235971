import { createSelector } from 'reselect';

const selectWebhooks = state => state.root.webhooks;

const selectWebhookLogs = createSelector([selectWebhooks], ({ webhookLogs }) =>
  webhookLogs.map(item => {
    const {
      id,
      webhook_message_id: messageId,
      event_type: eventType,
      timestamp,
      status,
    } = item;
    return {
      Webhook: { eventType, status },
      'Sent timestamp': { timestamp, messageId, status, id },
      hidden: { id, log: item },
    };
  }),
);

const selectWebhookUrl = createSelector(
  [selectWebhooks],
  ({ webhookUrl }) => webhookUrl,
);

const selectWebhooksTotalElements = createSelector(
  [selectWebhooks],
  ({ webhooksTotalElements }) => webhooksTotalElements,
);

const selectWebhookDestinations = createSelector(
  [selectWebhooks],
  ({ webhookDestinations }) => webhookDestinations,
);
const selectWebhookDestinationsTableData = createSelector(
  [selectWebhooks],
  ({ webhookDestinationsTableData }) => webhookDestinationsTableData,
);

const selectLoadingWebhookDestinations = createSelector(
  [selectWebhooks],
  ({ loadingWebhookDestinations }) => loadingWebhookDestinations,
);

const selectWebhookDefaultUrl = createSelector(
  [selectWebhooks],
  ({ defaultWebhookDestination }) =>
    defaultWebhookDestination && defaultWebhookDestination.url,
);

const selectWebhookEventTypes = createSelector(
  [selectWebhooks],
  ({ webhookEventTypes }) => webhookEventTypes,
);

export {
  selectWebhookLogs,
  selectWebhookUrl,
  selectWebhooksTotalElements,
  selectWebhookDestinations,
  selectWebhookDestinationsTableData,
  selectLoadingWebhookDestinations,
  selectWebhookDefaultUrl,
  selectWebhookEventTypes,
};

/* eslint-disable no-param-reassign */
import { createSelector, createSlice } from '@reduxjs/toolkit';
import { apiFetch } from 'modules/shared/fetch';
import { getDateAndTimeAsNumbers } from 'utils';
import Message from 'view/shared/message';
import featureFlags, { FeatureFlags } from './featureFlags';

const initialState = {
  list: [],
  isGetLoading: false,
  errored: false,
  isUpdateLoading: false,
};

export const banksAvailabilitySlice = createSlice({
  name: 'banksAvailability',
  initialState,
  reducers: {
    getBanksAvailabilityStarted: (state, action) => {
      state.isGetLoading = true;
      state.list = [];
    },
    getBanksAvailabilitySuccess: (state, action) => {
      state.list = action.payload;
      state.isGetLoading = false;
    },
    getBanksAvailabilityErrored: (state, action) => {
      state.isGetLoading = false;
      state.errored = true;
    },
    updateBanksAvailabilityStarted: (state, action) => {
      state.isUpdateLoading = true;
    },
    updateBanksAvailabilitySuccess: (state, action) => {
      state.isUpdateLoading = false;
    },
    updateBanksAvailabilityErrored: (state, action) => {
      state.isUpdateLoading = false;
      state.errored = true;
    },
  },
});

// APIs
/**
 * GET /applications/banks/status/:appToken
 * @param {string} appToken
 * @param {string} appEnvironment
 * @param {boolean} includeAccountTypes
 */
const getBanksAvailability = async (
  appToken,
  { appEnvironment, includeAccountTypes },
) => {
  const query = includeAccountTypes
    ? `?account_types=PERSONAL&account_types=BUSINESS`
    : '';

  const response = await apiFetch(
    `/applications/banks/status/${appToken}${query}`,
    {
      appEnvironment,
      headers: {
        'lean-app-token': appToken,
      },
    },
  );
  const { data } = response;
  return data;
};

/**
 * POST /applications/banks/status/:appToken
 * @param {string} appToken
 * @param {object} body
 * @param {string} appEnvironment
 */
const updateBanksAvailability = async (appToken, body, { appEnvironment }) => {
  const response = await apiFetch(`/applications/banks/status/${appToken}`, {
    body,
    appEnvironment,
  });
  const { data } = response;
  return data;
};

// Action creators
const {
  getBanksAvailabilityStarted,
  getBanksAvailabilitySuccess,
  getBanksAvailabilityErrored,
  updateBanksAvailabilityStarted,
  updateBanksAvailabilitySuccess,
  updateBanksAvailabilityErrored,
} = banksAvailabilitySlice.actions;

// Actions
export const doGetBanksAvailability =
  appToken => async (dispatch, getState) => {
    await dispatch(getBanksAvailabilityStarted());

    const isRolloutAccountTypes = featureFlags.isOn(
      FeatureFlags.rolloutAccountTypes,
    );

    try {
      const { environment } = getState().root.global;
      const response = await getBanksAvailability(appToken, {
        appEnvironment: environment,
        includeAccountTypes: isRolloutAccountTypes,
      });

      dispatch(getBanksAvailabilitySuccess(response));
    } catch (error) {
      dispatch(getBanksAvailabilityErrored());
    }
  };

export const doUpdateBanksAvailability =
  (appToken, data) => async (dispatch, getState) => {
    await dispatch(updateBanksAvailabilityStarted());

    const { banksList, reason } = data;

    // transform data to body
    const body = Object.keys(banksList).map(bankId => ({
      bank_identifier: bankId,
      enabled: banksList[bankId],
      reason,
    }));

    try {
      const { environment } = getState().root.global;
      const response = await updateBanksAvailability(appToken, body, {
        appEnvironment: environment,
      });

      dispatch(updateBanksAvailabilitySuccess(response));

      dispatch(doGetBanksAvailability(appToken));

      Message.success('Your changes were synced successfully');
    } catch (error) {
      dispatch(updateBanksAvailabilityErrored());
      Message.error('We couldn’t sync your changes. Try again later.');
    }
  };

// Helpers
const getStatusText = ({ active, enabled, enabledByLean, enabledByTPP }) => {
  /*
  if bank is enabled by Lean and TPP
    -> return "Connection enabled"
  if bank is disabled by TPP
    -> return "Connection disabled since HH:MM:SS DD/MM/YY"
  if bank is disabled by Lean
    -> return "Connection disabled by Lean since HH:MM:SS DD/MM/YY" */
  if (enabledByLean && enabledByTPP) {
    return {
      statusText: 'Connection enabled',
      statusTextInverted: 'Connection disabled - Sync pending',
    };
  }
  if (!enabledByLean) {
    const { date, time } = getDateAndTimeAsNumbers(new Date(active.updated_at));
    return {
      statusText: `Connection disabled by Lean since ${time} ${date}`,
      statusTextInverted: 'Connection enabled - Sync pending',
    };
  }
  if (!enabledByTPP) {
    const { date, time } = getDateAndTimeAsNumbers(
      new Date(enabled.updated_at),
    );
    return {
      statusText: `Connection disabled since ${time} ${date}`,
      statusTextInverted: 'Connection enabled - Sync pending',
    };
  }
  return {};
};

const getActiveOrDisabled = bool => (bool ? 'active' : 'disabled');

// Selectors
export const selectBanksAvailabilityList = state => {
  const { list } = state.root.banksAvailability;

  if (list.length === 0) {
    return [];
  }

  const isRolloutAccountTypes = featureFlags.isOn(
    FeatureFlags.rolloutAccountTypes,
  );

  return list
    .map(item => {
      const { name, active, enabled, identifier } = item;

      const enabledByLean = active.payments && active.data;
      const disabledByLean = !enabledByLean;
      const enabledByTPP = enabled.payments && enabled.data;
      const disabledByTPP = !enabledByTPP;
      const { statusText, statusTextInverted } = getStatusText({
        active,
        enabled,
        enabledByLean,
        enabledByTPP,
      });

      return {
        enabled: { enabledValue: enabledByTPP, disabledByLean, identifier },
        'Bank Name': {
          name,
          statusText,
          statusTextInverted,
          disabledByLean,
          disabledByTPP,
          identifier,
        },
        // Reflects status of the bank controlled by Lean (active.data and active.payments)
        data: {
          dataStatus: getActiveOrDisabled(active.data),
          disabledByTPP,
          identifier,
        },
        // only include payments for the UAE (this data is used to generate table headers)
        ...(isRolloutAccountTypes
          ? {}
          : {
              payments: {
                paymentsStatus: getActiveOrDisabled(active.payments),
                disabledByTPP,
                identifier,
              },
            }),
        hidden: { identifier },
      };
    })
    .sort((a, b) => a['Bank Name'].name.localeCompare(b['Bank Name'].name));
};

export const selectBanksEnablementByTPP = createSelector(
  selectBanksAvailabilityList,
  list => {
    const banksList = {};
    list.forEach(bank => {
      const { enabled } = bank;
      const { identifier, enabledValue } = enabled;
      banksList[identifier] = enabledValue;
    });

    return banksList;
  },
);

export const selectGetLoading = state =>
  state.root.banksAvailability.isGetLoading;
export const selectUpdateLoading = state =>
  state.root.banksAvailability.isUpdateLoading;

export default banksAvailabilitySlice.reducer;

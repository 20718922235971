import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Tippy from '@tippyjs/react';
import { roundArrow } from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import 'tippy.js/dist/svg-arrow.css';

const $StyledTippy = styled(({ borderRadius, styledProps, ...rest }) => (
  <Tippy {...rest} />
))`
  border-radius: ${({ borderRadius }) => borderRadius} !important;

  .tippy-content {
    padding: 0 !important;
  }
`;

const Tooltip = ({
  theme,
  trigger,
  content,
  children,
  placement,
  styledProps,
  ...rest
}) => {
  const Content = <>{content}</>;

  return (
    <$StyledTippy
      content={Content}
      theme={theme}
      placement={placement}
      arrow={roundArrow}
      interactive
      zIndex={2}
      {...{
        ...(rest?.visible !== undefined
          ? { visible: rest?.visible }
          : { trigger }),
        ...(rest?.onClickOutside !== undefined
          ? { onClickOutside: rest?.onClickOutside }
          : {}),
      }}
      {...styledProps}
      {...rest}
    >
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
      <span tabIndex={0}>{children}</span>
    </$StyledTippy>
  );
};

Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
  content: PropTypes.node.isRequired,
  styledProps: PropTypes.shape({}),
  placement: PropTypes.string,
  trigger: PropTypes.string,
  theme: PropTypes.string,
};

Tooltip.defaultProps = {
  placement: 'bottom',
  trigger: 'click',
  theme: 'light',
  styledProps: {
    borderRadius: '8px',
  },
};

export default Tooltip;

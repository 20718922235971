import React from 'react';
import PropTypes from 'prop-types';
import CsvDownloader from 'react-csv-downloader';
import Table from '../Table';
import Button from '../Button';
import Loading from '../Loading';
import { $EmptyStateContainer, $LoadingStateContainer } from './styles';

const Logs = ({
  items,
  csvData,
  pageSize,
  isLoading,
  totalItems,
  customCell,
  onRowClick,
  currentPage,
  onPageClick,
  onDownloadCSV,
  isLoadingCSVData,
  csvFileNamePrefix,
  bottomLeftElement,
  emptyStateContent: EmptyStateContent,
  headerCellStyleProps,
  customHeaderCell,
  selectedRowIdentifierPath,
  isSingleRowSelectable,
  isMultiRowSelectable,
  defaultSelectedRows,
}) => {
  // flag to display table or emptyState
  const isEmpty = !items.length;

  const downloadCSVOnClick = () => onDownloadCSV();

  const DownloadCSVFileButton = () => {
    const fileNamePostFix = `logs_${new Date().toISOString()}.csv`;
    const fileName = csvFileNamePrefix
      ? `${csvFileNamePrefix}_${fileNamePostFix}`
      : fileNamePostFix;

    return (
      <>
        <CsvDownloader
          filename={fileName}
          datas={downloadCSVOnClick}
          wrapColumnChar='"'
        >
          <Button
            isLoading={isLoadingCSVData}
            iconEnd="document-text"
            styledProps={{ variant: 'secondary' }}
          >
            Download CSV
          </Button>
        </CsvDownloader>
      </>
    );
  };

  const isShowDownloadCSVFile = onDownloadCSV;

  if (isLoading) {
    return (
      <$LoadingStateContainer>
        <Loading />
      </$LoadingStateContainer>
    );
  }

  if (isEmpty) {
    return (
      <$EmptyStateContainer>
        <EmptyStateContent />
      </$EmptyStateContainer>
    );
  }

  return (
    <Table
      data={items}
      customCell={customCell}
      onPageClick={({ pageNumber }) => onPageClick(pageNumber)}
      totalItems={totalItems}
      currentPage={currentPage}
      bottomLeftElement={bottomLeftElement}
      bottomRightElement={
        isShowDownloadCSVFile ? <DownloadCSVFileButton /> : null
      }
      onRowClick={onRowClick}
      pageSize={pageSize}
      headerCellStyleProps={headerCellStyleProps}
      customHeaderCell={customHeaderCell}
      selectedRowIdentifierPath={selectedRowIdentifierPath}
      isSingleRowSelectable={isSingleRowSelectable}
      isMultiRowSelectable={isMultiRowSelectable}
      defaultSelectedRows={defaultSelectedRows}
    />
  );
};

Logs.propTypes = {
  items: PropTypes.array,
  csvFileNamePrefix: PropTypes.string,
  totalItems: PropTypes.number,
  onPageClick: PropTypes.func,
  customCell: PropTypes.elementType,
  customHeaderCell: PropTypes.elementType,
  emptyStateContent: PropTypes.elementType,
  currentPage: PropTypes.number,
  pageSize: PropTypes.number,
  bottomLeftElement: PropTypes.elementType,
  // array of data to be exported as CSV
  csvData: PropTypes.array,
  // to be called to retrieve CSV data and store them in redux
  onDownloadCSV: PropTypes.func,
  // true while retrieving CSV data
  isLoadingCSVData: PropTypes.bool,
  onRowClick: PropTypes.func,
  isLoading: PropTypes.bool,
  headerCellStyleProps: PropTypes.shape({
    textAlign: PropTypes.string,
  }),
  selectedRowIdentifierPath: PropTypes.string,
  isSingleRowSelectable: PropTypes.bool,
  isMultiRowSelectable: PropTypes.bool,
  defaultSelectedRows: PropTypes.arrayOf(PropTypes.string),
};

Logs.defaultProps = {
  items: [],
  csvFileNamePrefix: '',
  totalItems: 0,
  currentPage: 0,
  onPageClick: undefined,
  customCell: undefined,
  customHeaderCell: undefined,
  emptyStateContent: undefined,
  bottomLeftElement: undefined,
  csvData: [],
  onDownloadCSV: undefined,
  isLoadingCSVData: PropTypes.bool,
  onRowClick: undefined,
  pageSize: undefined,
  isLoading: false,
  headerCellStyleProps: {},
  selectedRowIdentifierPath: undefined,
  isSingleRowSelectable: false,
  isMultiRowSelectable: false,
  defaultSelectedRows: [],
};

export default Logs;

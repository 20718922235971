import customers from 'modules/customers/customers.slice';

export {
  selectCustomer,
  selectCustomers,
  selectCustomerEntity,
  selectCustomerPayment,
  selectCustomerEntities,
  selectCustomerPayments,
} from 'modules/customers/customers.slice';

export {
  getAllCustomers,
  getCustomerById,
  getCustomerEntities,
  getCustomerPayments,
  getCustomerEntityById,
  getCustomerPaymentById,
  deleteCustomerEntityById,
} from 'modules/customers/customers.actions';

export default customers;

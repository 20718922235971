import React from 'react';
import { Button } from 'components';
import { $Name, $List, $Spot } from './styles';

interface ListItem {
  id: string;
  iconName?: string;
  title: string;
}

interface BanksListProps {
  list: ListItem[];
  onClickItem: (id: ListItem['id']) => any;
  dataCyPrefix?: string;
}

const List = ({
  list = [],
  onClickItem,
  dataCyPrefix = '',
}: BanksListProps) => (
  <$List>
    {list.map(item => (
      <div key={item.id}>
        <Button
          data-cy={`${dataCyPrefix}${item.id}`}
          onClick={() => onClickItem(item.id)}
          styledProps={{ variant: 'text' }}
          iconEnd="chevron-forward"
        >
          <$Spot>{item.iconName}</$Spot>
          <$Name>{item.title}</$Name>
        </Button>
      </div>
    ))}
  </$List>
);

export default List;

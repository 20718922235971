import { RootState } from 'modules/store';
import { ErrorObject } from 'types/global';
import Errors from 'modules/shared/error/errors';
import { createAsyncThunk } from '@reduxjs/toolkit';

import ConsentExplanationService from './consentExplanation.service';
import {
  ConsentExplanationData,
  GetExplanationsError,
  GetExplanationsPayload,
  UpdateExplanationsPayload,
} from './consentExplanation.types';

export const getConsentExplanations = createAsyncThunk<
  GetExplanationsPayload,
  undefined,
  {
    state: RootState;
    rejectValue: GetExplanationsError | ErrorObject;
  }
>(
  'consent-explanation/get-explanations',
  async (_, { getState, rejectWithValue }) => {
    const { environment: appEnvironment, appToken } = getState().root.global;

    try {
      return await ConsentExplanationService.getExplanations({
        appToken,
        appEnvironment,
      });
    } catch (error: unknown) {
      const errorObj: ErrorObject = error as ErrorObject;

      if (errorObj.response.status === 404) {
        return rejectWithValue(errorObj);
      }

      Errors.handle(errorObj);
      return rejectWithValue(errorObj);
    }
  },
);

export const updateConsentExplanations = createAsyncThunk<
  UpdateExplanationsPayload,
  ConsentExplanationData,
  { state: RootState }
>(
  'consent-explanation/update-explanations',
  async (body, { getState, rejectWithValue }) => {
    const { environment: appEnvironment, appToken } = getState().root.global;

    try {
      await ConsentExplanationService.updateExplanations({
        appToken,
        appEnvironment,
        body,
      });

      return body;
    } catch (error: unknown) {
      Errors.handle(error);
      return rejectWithValue(error);
    }
  },
);

import React from 'react';
import { Button } from 'components';
import { $ButtonGroup } from './styles';

interface Props {
  selectedValue: string;
  onChange: (value: string) => any;
  options: Array<{
    label: string;
    value: string;
  }>;
}

const ButtonGroup = ({ onChange, options, selectedValue }: Props) => (
  <$ButtonGroup>
    {options.map(({ label, value }) => (
      <Button
        key={value}
        styledProps={{ variant: 'group', active: value === selectedValue }}
        onClick={() => onChange(value)}
      >
        {label}
      </Button>
    ))}
  </$ButtonGroup>
);

export default ButtonGroup;

import { privateRoutes } from 'view/routes';
import {
  UNRECONCILED_PAYMENTS_TAB,
  RECONCILED_PAYMENTS_TAB,
  UNRECEIVED_PAYMENTS_TAB,
} from 'view/reconciliationPage/constants';
import { i18n } from '../../i18n';

// TODO: can we use a new field parent to point to the parent route?
// TODO: that way we can sort the routes here without depending on their order

const getBreadCrumbs = match => {
  let crumbs = privateRoutes
    // Get all routes that contain the current one.
    .filter(({ path }) => match.path.includes(path))
    // Swap out any dynamic routes with their param values.
    .map(({ path, ...rest }) => ({
      path: Object.keys(match.params).length
        ? Object.keys(match.params).reduce(
            (path, param) => path.replace(`:${param}`, match.params[param]),
            path,
          )
        : path,
      ...rest,
    }));

  crumbs = crumbs.map(({ label, path, ...rest }) => {
    let labelValue = label;

    if (path.includes(`/reconciliation/${UNRECONCILED_PAYMENTS_TAB}`)) {
      labelValue = 'Outstanding payments';
    }

    if (path.includes(`/reconciliation/${UNRECEIVED_PAYMENTS_TAB}`)) {
      labelValue = 'Unreceived payments';
    }

    if (path.includes(`/reconciliation/${RECONCILED_PAYMENTS_TAB}`)) {
      labelValue = 'Reconciled payments';
    }

    return { label: labelValue, path, ...rest };
  });

  // Exclude "Getting Started" if it's not the current page
  // Exclude "Applications" - Home route
  crumbs = crumbs.filter(
    ({ label, path }) =>
      (crumbs.length > 2 ? label !== 'Getting started' : true) &&
      label !== i18n('entities.application.menu'),
  );

  return crumbs;
};

export default getBreadCrumbs;

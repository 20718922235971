import { createSlice } from '@reduxjs/toolkit';

import { RootState } from 'modules/store';

import { PayoutsState, QueuedPayment } from './payout.types';
import {
  optInForPayouts,
  getPayoutDetails,
  getQueuedPayments,
  getUsersPayoutDetails,
} from './payouts.actions';

const initialState: PayoutsState = {
  endUserId: '',
  tppUserId: '',
  customerId: '',
  connected: false,
  isOptingIn: false,
  queuedPayments: [],
  paymentSourceId: '',
  leanAuthorizer: false,
  isOptInRequired: false,
  totalQueuedPaymentsCount: 0,
  isFetchingPayoutDetails: false,
  isFetchingQueuedPayments: false,
  isFetchingUsersPayoutDetails: false,
  usersPayoutDetails: null,
};

const payoutsSlice = createSlice({
  name: 'payouts',
  initialState,
  reducers: {},
  extraReducers: builder => {
    // GET-PAYOUT-DETAILS
    builder.addCase(getPayoutDetails.pending, state => {
      state.isFetchingPayoutDetails = true;
    });
    builder.addCase(getPayoutDetails.fulfilled, (state, { payload }) => ({
      ...state,
      isOptInRequired: false,
      isFetchingPayoutDetails: false,
      connected: payload.connected,
      endUserId: payload.end_user_id,
      tppUserId: payload.tpp_user_id,
      customerId: payload.payouts_customer_id,
      leanAuthorizer: payload.lean_authorizer,
      paymentSourceId: payload.payment_source_id,
    }));
    builder.addCase(getPayoutDetails.rejected, (state, { payload }) => {
      state.isFetchingPayoutDetails = false;

      if (payload && 'isOptInRequired' in payload) {
        state.isOptInRequired = payload.isOptInRequired;
      }
    });

    // GET-USERS-PAYOUT-DETAILS
    builder.addCase(getUsersPayoutDetails.pending, state => {
      state.isFetchingUsersPayoutDetails = true;
    });
    builder.addCase(getUsersPayoutDetails.fulfilled, (state, { payload }) => {
      state.isFetchingUsersPayoutDetails = false;
      state.usersPayoutDetails = payload;
    });
    builder.addCase(getUsersPayoutDetails.rejected, (state, { payload }) => {
      state.isFetchingUsersPayoutDetails = false;
    });

    // OPT-IN-FOR-PAYOUT
    builder.addCase(optInForPayouts.pending, state => {
      state.isOptingIn = true;
    });
    builder.addCase(optInForPayouts.fulfilled, (state, { payload }) => ({
      ...state,
      isOptingIn: false,
      isOptInRequired: false,
      connected: payload.connected,
      endUserId: payload.end_user_id,
      tppUserId: payload.tpp_user_id,
      customerId: payload.payouts_customer_id,
      leanAuthorizer: payload.lean_authorizer,
      paymentSourceId: payload.payment_source_id,
    }));
    builder.addCase(optInForPayouts.rejected, state => {
      state.isOptingIn = false;
    });

    // FETCH-QUEUED-PAYMENTS
    builder.addCase(getQueuedPayments.pending, state => {
      state.isFetchingQueuedPayments = true;
      state.totalQueuedPaymentsCount = 0;
      state.queuedPayments = [];
    });
    builder.addCase(getQueuedPayments.fulfilled, (state, { payload }) => {
      const queuedPayments = payload.content || [];
      const totalQueuedPaymentsCount = payload.total_elements || 0;

      return {
        ...state,
        totalQueuedPaymentsCount,
        isFetchingQueuedPayments: false,
        queuedPayments: queuedPayments.map((qp: QueuedPayment) => ({
          ...qp,
          id: qp.payment_intent_id,
          payment_intent_id: qp.payment_intent_id,
          amount: qp.amount,
          isSelectable: !qp.auto_authorized,
        })),
      };
    });
    builder.addCase(getQueuedPayments.rejected, state => {
      state.isFetchingQueuedPayments = false;
    });
  },
});

export default payoutsSlice.reducer;
export const selectPayoutDetails = (state: RootState) => state.root.payouts;

export const DEPOSITS_STATUSES__PROCESSING = 'PROCESSING'; // internal status not part of the api status
export const DEPOSITS_STATUSES__UPLOADED_RAW = 'UPLOADED_RAW';
export const DEPOSITS_STATUSES__PARSED = 'PARSED';
export const DEPOSITS_STATUSES__PERSISTED = 'PERSISTED';
export const DEPOSITS_STATUSES__MATCHED = 'MATCHED';
export const DEPOSITS_STATUSES__FAILED = 'FAILED';
export const DEPOSITS_STATUSES__AUTO_RECONCILED = 'AUTO_RECONCILED';

export const STATUS__FAILED = 'FAILED';
export const STATUS__SUCCESS = 'SUCCESS';
export const STATUS__CANCELLED = 'CANCELLED';

export const DEPOSITS_PROCESSING_STATUSES = [
  DEPOSITS_STATUSES__PROCESSING,
  DEPOSITS_STATUSES__UPLOADED_RAW,
  DEPOSITS_STATUSES__PARSED,
];

export const DEPOSITS_LOADED_STATUSES = [
  DEPOSITS_STATUSES__MATCHED,
  DEPOSITS_STATUSES__PERSISTED,
];

export const DEPOSIT_SOURCES = {
  CSV: 'CSV',
  BANK_CONNECTION: 'BANK_CONNECTION',
};

export const SESSION_BANK_IDENTIFIER =
  '_lean_reconciliation_deposit_selected_bank';

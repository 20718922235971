import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { $Helptext, $Input, $InputContainer, $Label, $TextArea } from 'styles';
import {
  $IconRight,
  $IconLeft,
  $RightIconWrapper,
  $SearchKeyboardShortcut,
  $InsideLabel,
} from 'styles/components/form/Input';
import { styledInputPropTypes } from 'utils/proptypes';
import Icon from '../Icon';
import useKeyPress from '../../hooks/useKeyPress';

const TextField = ({
  onBlur,
  inputRef,
  iconEnd,
  helptext,
  onKeyDown,
  isErrored,
  label,
  insideLabel,
  isMultiline,
  shortcutKey,
  name,
  type,
  value,
  onChange,
  placeholder,
  styledProps,
  isDisabled,
  onClear,
  onKeyPress,
  'data-cy': dataCy,
  min,
  max,
  textAlign,
}) => {
  const ref = useRef(null);
  const textInputRef = inputRef || ref;
  const searchShortcutPressed = useKeyPress(shortcutKey);
  const [inputType, setInputType] = useState(type);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  useEffect(() => {
    if (type === 'search' && searchShortcutPressed && textInputRef.current) {
      textInputRef.current.focus();
    }
  }, [textInputRef, searchShortcutPressed, type]);

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
    setInputType(inputType === 'password' ? 'text' : 'password');
  };

  const isShowClearButton = value && !!value.length;

  const Input = isMultiline ? $TextArea : $Input;

  const getRightIconContent = () => {
    if (type === 'password') {
      return (
        <Icon
          onClick={togglePasswordVisibility}
          name={isPasswordVisible ? 'Eye' : 'EyeOff'}
          size={16}
        />
      );
    }

    if (type === 'search' && shortcutKey && !isShowClearButton) {
      return <$SearchKeyboardShortcut>{shortcutKey}</$SearchKeyboardShortcut>;
    }

    if (type === 'search' && isShowClearButton) {
      return <Icon onClick={onClear} name="Close" size={20} />;
    }

    if (iconEnd) {
      return (
        <$RightIconWrapper>
          <Icon name={iconEnd} size={18} />
        </$RightIconWrapper>
      );
    }

    return null;
  };

  const getLeftIconContent = () => {
    if (type === 'search') {
      return <Icon name="Search" size={16} />;
    }

    return null;
  };

  const isShowInsideLabel = !!insideLabel;
  const isShowRightIcon = !!getRightIconContent();
  const isShowLeftIcon = !!getLeftIconContent();

  return (
    <>
      {label && (
        <$Label htmlFor={name} id={`${name}Label`} {...styledProps}>
          {label}
        </$Label>
      )}
      <$InputContainer>
        {isShowInsideLabel && (
          <$InsideLabel htmlFor={name} id={`${name}InsideLabel`}>
            {insideLabel}
          </$InsideLabel>
        )}
        {isShowLeftIcon && (
          <$IconLeft disabled={isDisabled}>{getLeftIconContent()} </$IconLeft>
        )}
        <Input
          id={name}
          name={name}
          value={value}
          onBlur={onBlur}
          type={inputType}
          data-cy={dataCy}
          ref={textInputRef}
          onChange={onChange}
          disabled={isDisabled}
          isErrored={isErrored}
          onKeyDown={onKeyDown}
          onKeyPress={onKeyPress}
          placeholder={placeholder}
          textAlign={textAlign}
          dir="auto"
          isShowInsideLabel={isShowInsideLabel}
          isShowLeftIcon={isShowLeftIcon}
          aria-labelledby={`${name}Label`}
          isShowRightIcon={isShowRightIcon}
          aria-describedby={`${name}Description`}
          {...(min ? { min: String(min) } : {})}
          {...(max ? { max: String(max) } : {})}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...styledProps}
        />
        {isShowRightIcon && (
          <$IconRight disabled={isDisabled}>
            {getRightIconContent()}{' '}
          </$IconRight>
        )}
        <$Helptext
          id={`${name}Description`}
          isErrored={isErrored}
          data-cy={`${name}Description`}
        >
          {helptext}
        </$Helptext>
      </$InputContainer>
    </>
  );
};

TextField.propTypes = {
  iconEnd: PropTypes.string,
  helptext: PropTypes.string,
  shortcutKey: PropTypes.string,
  isErrored: PropTypes.bool,
  label: PropTypes.string,
  insideLabel: PropTypes.string,
  isMultiline: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  onClear: PropTypes.func,
  onKeyPress: PropTypes.func,
  placeholder: PropTypes.string,
  styledProps: styledInputPropTypes,
  type: PropTypes.string,
  value: PropTypes.string,
  isDisabled: PropTypes.bool,
  'data-cy': PropTypes.string,
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  textAlign: PropTypes.string,
};

TextField.defaultProps = {
  inputRef: null,
  iconEnd: '',
  helptext: '',
  shortcutKey: '',
  isErrored: false,
  label: '',
  insideLabel: '',
  isMultiline: false,
  onBlur: () => {},
  onKeyDown: () => {},
  onChange: () => {},
  onClear: () => {},
  onKeyPress: () => {},
  placeholder: '',
  styledProps: {
    fontSize: 'md',
    borderRadius: '2px',
  },
  type: 'text',
  value: '',
  isDisabled: false,
  'data-cy': '',
  min: '',
  max: '',
  textAlign: 'left',
};

export default TextField;

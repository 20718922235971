export enum AccountType {
  retail = 'RETAIL',
  sme = 'SME',
}

export enum SignInResponseStatus {
  mfaRequired = 'MFA_REQUIRED',
  passwordUpdateRequired = 'PASSWORD_UPDATE_REQUIRED',
  accountLocked = 'ACCOUNT_LOCKED',
}

export enum MfaStatus {
  notEnrolled = 'NOT_SET',
}

import { i18n } from 'i18n';
import Message from 'view/shared/message';
import { RootState } from 'modules/store';
import { createSlice } from '@reduxjs/toolkit';
import { getBanks, getTppData, postBankRequest } from './hyperlink.actions';
import { BankCategories, HyperlinkState } from './hyperlink.types';

const initialState: HyperlinkState = {
  data: {
    app_token: '',
    app_name: '',
    arabic_name: '',
    legal_name: '',
    arabic_legal_name: '',
    app_logo_url: '',
    customer_id: '',
    sandbox: true,
    account_type: '',
    customer_account_type: '',
  },
  activeBanks: [],
  disabledBanks: [],
  error: '',
  fetchingTppData: false,
  fetchingBanks: false,
  isSubmitting: false,
};

const hyperlinkSlice = createSlice({
  name: 'hyperlink',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getTppData.pending, state => {
      state.fetchingTppData = true;
    });
    builder.addCase(getTppData.fulfilled, (state, { payload }) => {
      state.fetchingTppData = false;
      state.data = payload;
    });
    builder.addCase(getTppData.rejected, state => {
      state.fetchingTppData = false;
      state.error = i18n('hyperlink.generic_error.subtitle');
    });
    builder.addCase(getBanks.pending, state => {
      state.fetchingBanks = true;
    });
    builder.addCase(getBanks.fulfilled, (state, { payload }) => {
      state.fetchingBanks = false;

      // Separate active banks from disabled ones
      const { activeBanks, disabledBanks } = payload.reduce(
        (acc, currentBank) => {
          if (currentBank.is_active) {
            return { ...acc, activeBanks: [...acc.activeBanks, currentBank] };
          }

          return { ...acc, disabledBanks: [...acc.disabledBanks, currentBank] };
        },
        { activeBanks: [], disabledBanks: [] } as BankCategories,
      );

      state.activeBanks = activeBanks;
      state.disabledBanks = disabledBanks;
    });
    builder.addCase(getBanks.rejected, state => {
      state.fetchingBanks = false;
      state.error = i18n('hyperlink.generic_error.subtitle');
    });
    builder.addCase(postBankRequest.pending, state => {
      state.isSubmitting = true;
    });
    builder.addCase(postBankRequest.fulfilled, state => {
      state.isSubmitting = false;
    });
    builder.addCase(postBankRequest.rejected, (state, { payload }) => {
      state.isSubmitting = false;

      if (payload && 'message' in payload) {
        Message.error(payload.message);
      }
    });
  },
});

export default hyperlinkSlice.reducer;
export const selectHyperlink = (state: RootState) => state.root.hyperlink;

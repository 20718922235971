import Roles from 'security/roles';
import { apiFetch } from 'modules/shared/fetch';
import { createCorrelationId } from 'utils/createUniqueId';

const correlationId = createCorrelationId();
const { userRoles: roles } = Roles;

export default class TeamService {
  static getUserRoles(userRoles) {
    return userRoles?.map(role => roles[role]) || [];
  }

  static getInviteIdForMember(member, invites) {
   return invites?.forEach(invite => {
      if (invite.email === member.email) {
        return invite.id;
      }
      return undefined;
    });
  }

  static formatMembers(members, isActivated, invites) {
    return [...members].map(member => ({
      user: {
        id: member.id?.toString(),
        inviteId: this.getInviteIdForMember(member, invites) || member.id,
        principalId: member.principal,
        name: `${member.first_name || ''} ${member.last_name || ''}`,
        email: member.email,
        isActivated,
        expirationDate: member?.expiration_date ?? null,
      },
      roles: this.getUserRoles(member?.roles),
      'last login': !isActivated ? 'Pending' : member.last_login || '-',
      '': isActivated ? ' ' : 'actions-column' 
    }));
  }

  static filterInvitedMembers(invitedMembers) {
    return invitedMembers.filter(i => i.active);
  }

  static sortMembersByName(members) {
    return [...members].sort((a, b) => {
      const valueA = a.user.name.toUpperCase().trim();
      const valueB = b.user.name.toUpperCase().trim();

      // equal items sort equally
      if (valueA === valueB) return 0;

      // nulls and empty values sorted at the end
      if (!valueA) return 1;
      if (!valueB) return -1;

      // sort ascending
      return valueA < valueB ? -1 : 1;
    });
  }

  static async getTeamMembers() {
    const getCurrentMembers = apiFetch('/users/v1/', {
      headers: {
        'lean-correlation-id': correlationId,
      },
    });
    const getInvitedMembers = apiFetch('/users/v1/invites', {
      headers: {
        'lean-correlation-id': correlationId,
      },
    });

    const { data: members } = await getCurrentMembers;
    const { data: invited } = await getInvitedMembers;

    const filteredInvited = this.filterInvitedMembers(invited);

    return this.sortMembersByName([
      ...this.formatMembers(members, true, invited),
      ...this.formatMembers(filteredInvited, false, invited),
    ]);
  }

  static async inviteMembers({ values }) {
    const inviteMember = apiFetch(`/users/v1/invites`, {
      method: 'POST',
      body: values,
      headers: {
        'lean-correlation-id': correlationId,
      },
    });

    await inviteMember;
  }

  static async cancelMemeberInvite({ inviteId }) {
    const cancelMemberInvite = apiFetch(
      `/users/v1/invites/${inviteId}/cancel`, 
      {
        method: 'PUT',
        headers: {
          'lean-correlation-id': correlationId,
      },
    }
  );

    await cancelMemberInvite;
  }

  static async updateMemberRoles({ principalId, body }) {
    const updateRoles = apiFetch(`/users/v1/${principalId}/roles`, {
      method: 'PUT',
      body,
      headers: {
        'lean-correlation-id': correlationId,
      },
    });

    await updateRoles;
  }

  static async deleteMember({ principalId }) {
    const deleteMember = apiFetch(`/users/v1/${principalId}`, {
      method: 'DELETE',
      headers: {
        'lean-correlation-id': correlationId,
      },
    });

    await deleteMember;
  }
}

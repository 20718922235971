import React, { useState } from 'react';

import { useTypedSelector } from 'types/global';
import { selectHyperlink } from 'modules/hyperlink';
import { getLanguageCode, i18n, i18nHtml } from 'i18n';
import { Accordion, AccordionItem, Button, Dialog, Icon } from 'components';

import {
  $HyperlinkAccordionTitle,
  $HyperlinkAccordionWrapper,
  $HyperlinkConnectFooter,
  $HyperlinkDialogContent,
  $HyperlinkDialogFooter,
  $HyperlinkPageParagraph,
  $HyperlinkPageSubtitle,
} from './hyperlink.styled';
import { hyperlinkUserSteps } from './constants';
import { getAppName } from './utils';

function PermissionsSection({
  accessTo,
  accessFrom,
}: {
  accessTo: Date;
  accessFrom: Date;
}) {
  const { data } = useTypedSelector(selectHyperlink);
  const languageCode = getLanguageCode();
  const appName = getAppName(data, languageCode);
  const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);

  const query = new URLSearchParams(window.location.search);
  const bankIdentifier = query.get('bankIdentifier') || '';

  const failRedirectUrl = new URL(window.location.href);
  failRedirectUrl.searchParams.set('step', hyperlinkUserSteps.LinkFailed.name);

  const successRedirectUrl = new URL(window.location.href);
  successRedirectUrl.searchParams.set(
    'step',
    hyperlinkUserSteps.LinkSuccessful.name,
  );

  const handleConnect = () => {
    // @ts-ignore
    Lean.connect({
      sandbox: data.sandbox,
      app_token: data.app_token,
      callback: undefined,
      language: getLanguageCode(),
      customer_id: data.customer_id,
      bank_identifier: bankIdentifier,
      fail_redirect_url: failRedirectUrl.href,
      success_redirect_url: successRedirectUrl.href,
      access_from: accessFrom.toISOString(),
      access_to: accessTo.toISOString(),
      account_type: data.customer_account_type || data.account_type,
      permissions: [
        'balance',
        'transactions',
        'accounts',
        'identity',
        'identities',
      ],
      context: ['HYPERLINK'],
    });
  };

  const TitleComponent = ({ icon, title }: { icon: string; title: string }) => (
    <$HyperlinkAccordionTitle>
      <Icon name={icon} size={16} />
      <span>{title}</span>
    </$HyperlinkAccordionTitle>
  );

  return (
    <>
      <div className="permissions">
        <$HyperlinkPageSubtitle>
          {i18n('hyperlink.link_account.permission.title')}
        </$HyperlinkPageSubtitle>

        <$HyperlinkAccordionWrapper>
          <Accordion defaultIndex={-1}>
            <AccordionItem
              id="account-details"
              titleComponent={() => (
                <TitleComponent
                  icon="Albums"
                  title={i18n(
                    'hyperlink.link_account.permission.account_details.title',
                  )}
                />
              )}
            >
              {i18nHtml(
                'hyperlink.link_account.permission.account_details.content',
              )}
            </AccordionItem>
            <AccordionItem
              id="transactions"
              titleComponent={() => (
                <TitleComponent
                  icon="Card"
                  title={i18n(
                    'hyperlink.link_account.permission.account_transactions.title',
                  )}
                />
              )}
            >
              {i18n(
                'hyperlink.link_account.permission.account_transactions.content',
              )}
            </AccordionItem>
            <AccordionItem
              id="identity"
              titleComponent={() => (
                <TitleComponent
                  icon="Person"
                  title={i18n(
                    'hyperlink.link_account.permission.identity.title',
                  )}
                />
              )}
            >
              {i18nHtml('hyperlink.link_account.permission.identity.content')}
            </AccordionItem>
          </Accordion>
        </$HyperlinkAccordionWrapper>

        <$HyperlinkConnectFooter>
          <$HyperlinkPageParagraph className="small subtitle">
            {i18n('hyperlink.link_account.permission.tc.content', appName)}{' '}
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <span
              className="t-and-c"
              onClick={() => setShowTermsAndConditions(true)}
            >
              {i18n('hyperlink.link_account.permission.tc.link_text', appName)}
            </span>
          </$HyperlinkPageParagraph>

          <div>
            <Button
              className="link-account"
              data-cy="hyperlink_link_account_button"
              onClick={handleConnect}
            >
              {i18n('hyperlink.link_account.permission.link_account')}
            </Button>
          </div>
        </$HyperlinkConnectFooter>
      </div>

      <Dialog
        open={showTermsAndConditions}
        onOpenChange={setShowTermsAndConditions}
      >
        <$HyperlinkDialogContent
          width={340}
          isCloseButton={false}
          title={i18n('hyperlink.terms_and_conditions.title')}
          data-cy="hyperlink_terms_and_conditions_dialog"
        >
          {i18nHtml('hyperlink.terms_and_conditions.content', appName)}

          <$HyperlinkDialogFooter>
            <Button
              className="confirm"
              onClick={() => setShowTermsAndConditions(false)}
              styledProps={{
                align: 'center',
                justifyContent: 'center',
              }}
              isLoading={false}
            >
              {i18n('hyperlink.terms_and_conditions.continue')}
            </Button>
          </$HyperlinkDialogFooter>
        </$HyperlinkDialogContent>
      </Dialog>
    </>
  );
}

export default PermissionsSection;

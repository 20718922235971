import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

// if name is one word then the first letter of the word. (App -> A)
// if name is >1 words then the first letters of first two words. (Best App in the world -> BA)
const getInitialsFromName = name => {
  const nameValue = name.trim();

  if (!nameValue) return '';

  const [firstName, lastName] = nameValue.split(' ').filter(value => !!value);

  if (firstName && lastName) {
    return `${firstName[0]}${lastName[0]}`.toUpperCase();
  }

  return firstName[0].toUpperCase();
};

const $LogoReplacement = styled.div`
  background-color: ${({ theme }) => theme.colors.neutral._80};
  color: ${({ theme }) => theme.colors.blender._10};
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.font.size.body.sm};
  border-radius: 4px;
  height: 32px;
  width: 32px;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
`;

const $Logo = styled.img`
  height: 32px;
  width: 32px;
  object-fit: contain;
  border-radius: 4px;
`;

const Logo = ({ name, url }) => {
  if (url) {
    return <$Logo src={url} />;
  }

  return <$LogoReplacement>{getInitialsFromName(name)}</$LogoReplacement>;
};

Logo.propTypes = {
  name: PropTypes.string,
  url: PropTypes.string || null,
};

Logo.defaultProps = {
  name: '',
  url: null,
};

export default Logo;

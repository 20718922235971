/* eslint-disable no-param-reassign */
import QueryString from 'query-string';
import { createSlice } from '@reduxjs/toolkit';

import { routeHelpers } from 'view/routes';
import { getHistory } from 'modules/history';
import Errors from 'modules/shared/error/errors';
import { setEnvironmentInitialValue } from 'modules/global';
import applicationListActions from 'modules/application/list/applicationListActions';
import applicationFormActions from 'modules/application/form/applicationFormActions';
import applicationListSelectors from 'modules/application/list/applicationListSelectors';
import { actions as organisationActions } from 'modules/organisation/organisationActions';
import featureFlags, {
  FeatureFlags,
  FeatureFlagsAttributes,
} from 'modules/featureFlags';
import { COUNTRIES_CONFIG, isKSACountry } from 'config/countriesEnvironments';
import JWTDecode from 'jwt-decode';
import authSelectors from './auth/authSelectors';
import { MfaStatus } from './auth/constants';
import { AuthToken } from './auth/authToken';

const initialState = {
  loading: false,
  errored: false,
  initialised: false,
};

export const bootstrapSlice = createSlice({
  name: 'bootstrap',
  initialState,
  reducers: {
    bootstrapStarted: state => {
      state.loading = true;
    },
    bootstrapSuccess: state => {
      state.loading = false;
      state.initialised = true;
    },
    bootstrapErrored: (state, action) => {
      state.loading = false;
      state.errored = true;
    },
    boostrapResetState: () => {
      return initialState;
    },
  },
});

// Action creators
const {
  bootstrapStarted,
  bootstrapSuccess,
  bootstrapErrored,
  boostrapResetState,
} = bootstrapSlice.actions;

// Actions
export const bootstrapApp =
  isToggleEnvironment => async (dispatch, getState) => {
    await dispatch(bootstrapStarted());

    // 1. fetch getProdApplication, getSandboxApplication and organisation info
    // 2. store applications in store
    // 3. based on user choice of env set active application (either prod, sandbox or pre-live)

    try {
      const token = await AuthToken.get();
      const decodedJWT = JWTDecode(token);
      const { org } = decodedJWT;

      await dispatch(
        applicationListActions.doGetSandboxAndProductionApplications(),
      );
      await dispatch(setEnvironmentInitialValue(isToggleEnvironment));

      featureFlags.setAttributes({
        [FeatureFlagsAttributes.organizationId]: org,
      });

      if (isKSACountry && featureFlags.isOn(FeatureFlags.rolloutOwnerRole)) {
        await dispatch(organisationActions.doGetOrganisation());
      }

      const application = applicationListSelectors.selectApplication(
        getState(),
      );

      const applications = applicationListSelectors.selectApplicationList(
        getState(),
      );

      const { sandbox, production, preLive } = applications;

      if (
        sandbox &&
        sandbox.length === 0 &&
        preLive.length === 0 &&
        production.length === 0
      ) {
        // no sandbox application -- first load
        // create a sandbox application

        await dispatch(applicationFormActions.doCreateSandboxApplication());
        await dispatch(applicationListActions.doGetSandboxApplications());
      }

      const { router } = getState();
      const { pathname } = router.location;
      const queryParams = QueryString.parse(window.location.search);
      const previousPath = String(queryParams.previousPath || '');

      const currentUser = authSelectors.selectCurrentUser(getState());
      const userHasEnrolledMfa =
        currentUser.mfa_settings.status !== MfaStatus.notEnrolled;

      const userSkippedSetup = localStorage.getItem(
        '_lean_user_skipped_mfa_setup',
      );

      if (!userHasEnrolledMfa && userSkippedSetup !== 'true') {
        if (featureFlags.isOn(FeatureFlags.rolloutMfa)) {
          getHistory().replace('/mfa/setup');

          await handleBootstrapCompleted(dispatch, isToggleEnvironment);

          return;
        }
      }

      // If user has set up MFA, handle general redirections
      if (previousPath && previousPath !== '/' && application) {
        const pathArray = previousPath.split('/');

        // Step 1: Check if application id matches any of the user's applications and continue to previous path
        if (
          [sandbox[0]?.id, preLive[0]?.id, production[0]?.id].includes(
            pathArray[2],
          )
        ) {
          getHistory().push(previousPath);
        } else {
          // Step 2: If application id does not match any of the user's applications
          // update application id in previous path and take the user to the page they were viewing
          const nextPathname = routeHelpers.updateAppIdInPath({
            pathname: previousPath,
            applicationId: application.id,
          });
          getHistory().push(nextPathname);
        }

        await handleBootstrapCompleted(dispatch, isToggleEnvironment);
        return;
      }

      // Step 3: If there's no previous path, redirect to /id/:appId
      if (pathname === '/' && application) {
        // redirect to /id/:appId
        getHistory().push(`/id/${application.id}`);

        await handleBootstrapCompleted(dispatch, isToggleEnvironment);
        return;
      }

      // Step 4: If user has toggled environment, update application id in pathname
      if (isToggleEnvironment && pathname.includes('/id/')) {
        // user has toggled environment and current page has `/id/` in the pathname (as opposed to being an error page)
        const nextPathname = routeHelpers.updateAppIdInPath({
          pathname,
          applicationId: application.id,
        });
        getHistory().push(nextPathname);

        await handleBootstrapCompleted(dispatch, isToggleEnvironment);
        return;
      }

      await handleBootstrapCompleted(dispatch, isToggleEnvironment);
    } catch (error) {
      dispatch(bootstrapErrored());
      Errors.handle(error);
    }
  };

const handleBootstrapCompleted = async (dispatch, isToggleEnvironment) => {
  // update application environments state in case there was any redirection
  await dispatch(setEnvironmentInitialValue(isToggleEnvironment));

  featureFlags.setAttributes({
    [FeatureFlagsAttributes.country]: COUNTRIES_CONFIG.countryCode,
  });

  await dispatch(bootstrapSuccess());
};

export const reloadApp = () => async dispatch => {
  await dispatch(bootstrapApp(true));
};

export const resetBootstrapState = () => dispatch => {
  dispatch(boostrapResetState());
};

// Selectors
export const selectInitialised = state => state.root.bootstrap.initialised;
export const selectLoading = state => state.root.bootstrap.loading;

export default bootstrapSlice.reducer;

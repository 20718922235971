import styled from 'styled-components/macro';

export const $BreadCrumbs = styled.div`
  display: flex;
  margin-left: 24px;
  align-items: center;
  color: ${({ theme }) => theme.colors.trust._60};
  font-size: ${({ theme }) => theme.font.size.body.sm};

  svg {
    color: ${({ theme }) => theme.colors.neutral._70};
  }

  *:hover {
    color: inherit;
    text-decoration: none;
  }

  button,
  .current-page {
    padding: 0 8px;
  }

  .current-page {
    margin: 0;
    color: ${({ theme }) => theme.colors.black};
  }
`;

export const $Container = styled.div`
  display: flex;
  align-items: center;
`;

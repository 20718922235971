import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from './Button';
import FullScreen from './FullScreen';

const $CenteredBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
`;

const $ButtonsContainer = styled.div`
  display: flex;
  margin-top: 21px;
`;

const $Text = styled.span`
  font-size: ${({ theme }) => theme.font.size.body.md};
  max-width: 408px;
  text-align: center;
`;

const WarningIcon = styled.img`
  margin-bottom: 18px;
`;

const FullScreenError = ({ text, email, appName, isCloseButton }) => {
  const MainButton = () =>
    isCloseButton ? (
      <Button
        onClick={() => {
          window.location.href = '/';
        }}
      >
        Close
      </Button>
    ) : (
      <Button
        onClick={() => {
          window.location.reload();
        }}
      >
        Try Again
      </Button>
    );
  const contactUsLink = `mailto:devsupport@leantech.me?subject=Error Creating Application&body=Hello! This error has been constantly appearing and the application ${appName} registered to the email address ${email} cannot be created.`;
  return (
    <FullScreen isFooterVisible>
      <$CenteredBox>
        <WarningIcon src="/images/warning/warning.svg" alt="warning icon" />
        <$Text data-cy="error_message">{text}</$Text>
        <$ButtonsContainer>
          <MainButton />
          <Button
            onClick={() => {
              window.location.href = contactUsLink;
            }}
            styledProps={{ variant: 'secondary' }}
          >
            Contact Us
          </Button>
        </$ButtonsContainer>
      </$CenteredBox>
    </FullScreen>
  );
};

FullScreenError.propTypes = {
  text: PropTypes.string.isRequired,
  appName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  isCloseButton: PropTypes.bool,
};

FullScreenError.defaultProps = {
  isCloseButton: false,
};

export default FullScreenError;

import React from 'react';
import PropTypes from 'prop-types';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import styled from 'styled-components';
import { theme } from 'styles/abstracts/theme';

const $Header = styled.div`
  background-color: ${({ theme }) => theme.colors.trust._100};
  padding: 16px;
  color: ${({ theme }) => theme.colors.white};
  text-transform: uppercase;
  margin-bottom: -1px;
  border-radius: 4px 4px 0 0;
`;

const $Code = styled.div`
  display: flex;
  flex-direction: column;
  font-size: ${({ theme }) => theme.font.size.body.xs};
  min-width: 349px;
  max-width: 490px;

  & + & {
    margin-top: 16px;
  }
`;
const Code = ({ language, children }) => (
  <$Code>
    <$Header>
      <span>{language}</span>
    </$Header>
    <SyntaxHighlighter
      language={language}
      style={{
        ...dracula,
        'code[class*="language-"]': {
          ...dracula['code[class*="language-"]'],
          fontFamily: 'inherit',
        },
        'pre[class*="language-"]': {
          ...dracula['pre[class*="language-"]'],
          fontFamily: 'inherit',
        },
      }}
      customStyle={{
        fontFamily: theme.font.family.mono,
        margin: 0,
        borderRadius: '0 0 4px 4px',
        backgroundColor: '#000029',
        minHeight: '150px',
      }}
    >
      {children}
    </SyntaxHighlighter>
  </$Code>
);

Code.propTypes = {
  language: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
};

export default Code;

import styled, { css } from 'styled-components/macro';
import { baseButtonStyles } from 'styles/components/Button';

const activeStyles = css`
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.trust._60};
  fill: ${({ theme }) => theme.colors.trust._60};
`;

const $MenuLink = styled.div`
  color: ${({ theme }) => theme.colors.neutral._90};
  fill: ${({ theme }) => theme.colors.neutral._90};
  font-size: ${({ theme }) => theme.font.size.body.sm};
  margin-bottom: 8px;
  padding: 0 9px;
  width: 100%;
  background-color: transparent;
  transition: background-color 150ms ease;

  ${baseButtonStyles}
  ${props => props.$isActive && activeStyles}

  &:hover {
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.neutral._100};
    fill: ${({ theme }) => theme.colors.neutral._100};

    /* Hack until we sort the base colors out */
    ${props => props.$isActive && activeStyles}
  }

  .icon {
    margin-right: 9px;
    display: flex;
    justify-content: center;
  }

  .label {
    line-height: 1;
  }
`;

export default $MenuLink;

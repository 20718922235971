import PropTypes from 'prop-types';
import React from 'react';
import { $Link } from 'styles';
import { Link } from 'react-router-dom';
import { segementAnalyticsPropTypes } from 'utils/proptypes';

const LeanLink = ({
  analytics,
  children,
  path,
  className,
  'data-cy': dataCy,
}) => {
  // Just trying this out - if it causes issues let's delete and either
  // set explict isExternal
  // use separate props for path and url
  const checkDomain = url => {
    let newURL = url;
    if (url.indexOf('//') === 0) {
      newURL = window.location.protocol + url;
    }
    return newURL
      .toLowerCase()
      .replace(/([a-z])?:\/\//, '$1')
      .split('/')[0];
  };

  const isExternal = url =>
    (url.indexOf(':') > -1 || url.indexOf('//') > -1) &&
    checkDomain(window.location.href) !== checkDomain(url);

  const handleAnalytics = () => {
    const { event, properties } = analytics;

    if (event) {
      window.analytics.track(event, properties);
    }
  };

  const linkProps = isExternal(path)
    ? { href: path, target: '_blank', onClick: handleAnalytics }
    : { as: Link, to: path };

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <$Link data-cy={dataCy} className={className} {...linkProps}>
      {children}
    </$Link>
  );
};

LeanLink.propTypes = {
  analytics: segementAnalyticsPropTypes,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  path: PropTypes.string.isRequired,
  'data-cy': PropTypes.string,
};

LeanLink.defaultProps = {
  analytics: {
    event: '',
  },
  className: '',
  'data-cy': '',
};

export default LeanLink;

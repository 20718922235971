import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Dialog, DialogContent } from 'components/index';
import { styledButtonPropsPropTypes } from 'utils/proptypes';

const $Footer = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: ${({ theme }) => theme.font.size.body.sm};
  padding-top: 24px;

  button {
    width: 158.5px;
  }
`;

function ConfirmationDialog({
  title,
  dataCy,
  isOpen,
  content,
  closeDialog,
  handleConfirm,
  confirmationText,
  confirmLoading,
  confirmStyledProps,
  confirmDataCy,
}) {
  return (
    <Dialog open={isOpen} onOpenChange={closeDialog}>
      <DialogContent
        width={375}
        isCloseButton
        z-index={9999}
        title={title}
        data-cy={dataCy}
      >
        <span>{content}</span>

        <$Footer>
          <Button
            onClick={closeDialog}
            styledProps={{
              variant: 'secondary',
              align: 'center',
              justifyContent: 'center',
            }}
          >
            Cancel
          </Button>

          <Button
            className="confirm"
            onClick={handleConfirm}
            styledProps={{
              align: 'center',
              justifyContent: 'center',
              ...confirmStyledProps,
            }}
            isLoading={confirmLoading}
            data-cy={confirmDataCy}
          >
            {confirmationText || 'Continue'}
          </Button>
        </$Footer>
      </DialogContent>
    </Dialog>
  );
}

ConfirmationDialog.propTypes = {
  dataCy: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  confirmationText: PropTypes.string,
  title: PropTypes.string.isRequired,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
  closeDialog: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  confirmLoading: PropTypes.bool,
  confirmStyledProps: styledButtonPropsPropTypes,
  confirmDataCy: PropTypes.string,
};

ConfirmationDialog.defaultProps = {
  dataCy: '',
  confirmationText: '',
  confirmLoading: false,
  confirmStyledProps: {},
  confirmDataCy: '',
};

export default ConfirmationDialog;

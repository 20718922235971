const backendUrl = `https://app.leantech.me`;
const sandboxUrl = `https://app.sandbox.leantech.me`;
const url = 'https://dev.leantech.me';
const hostname = 'dev.leantech.me';
const apmUrl = 'https://apm.ae01.leantech.me';
const oauthUrl = `https://auth.leantech.me`;
const oauthSandboxUrl = `https://auth.sandbox.leantech.me`;
const apiSandboxUrl = `https://sandbox.leantech.me`;

const linkSDKCDN = `https://cdn.leantech.me/link/sdk/web/latest/Lean.min.js`;

const production = {
  backendUrl,
  apiSandboxUrl,
  linkSDKCDN,
  sandboxUrl,
  url,
  hostname,
  apmUrl,
  oauthUrl,
  oauthSandboxUrl,
};
export default production;

import React from 'react';
import PropTypes from 'prop-types';
import { $Title, $Description, $HeaderRow } from './styles';

function PageHeaderRow({ title, description }) {
  return (
    <$HeaderRow>
      <$Title>{title}</$Title>
      {description && <$Description>{description}</$Description>}
    </$HeaderRow>
  );
}

PageHeaderRow.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
};

PageHeaderRow.defaultProps = {
  description: '',
};

export default PageHeaderRow;

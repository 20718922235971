import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import toast, { Toaster } from 'react-hot-toast';
import Icon from './Icon';

const successStyles = css`
  background-color: ${({ theme }) => theme.colors.neon._30};
  color: ${({ theme }) => theme.colors.neon._70};
`;

const warningStyles = css`
  background-color: ${({ theme }) => theme.colors.sun._10};
  color: ${({ theme }) => theme.colors.sun._40};
`;

const neutralStyles = css`
  background-color: ${({ theme }) => theme.colors.neutral._20};
  color: ${({ theme }) => theme.colors.black};
`;

const errorStyles = css`
  background-color: ${({ theme }) => theme.colors.coral._20};
  color: ${({ theme }) => theme.colors.coral._60};
`;

const informativeStyles = css`
  background-color: ${({ theme }) => theme.colors.trust._30};
  color: ${({ theme }) => theme.colors.trust._60};
`;

const getStylesFromType = type => {
  switch (type) {
    case 'success':
      return successStyles;
    case 'error':
      return errorStyles;
    case 'warning':
      return warningStyles;
    case 'neutral':
      return neutralStyles;
    case 'informative':
      return informativeStyles;
    default:
      return '';
  }
};

const $Toast = styled.div`
  ${({ type }) => getStylesFromType(type)}

  border-radius: 4px;
  padding: 16px;
  display: flex;
  align-items: center;
`;

const $IconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 16px;
`;

const $TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const $Title = styled.span`
  font-size: ${({ theme }) => theme.font.size.body.sm};
  color: ${({ theme }) => theme.colors.black};
  margin-right: 16px;
`;

const $Description = styled.span`
  font-size: ${({ theme }) => theme.font.size.body.sm};
  color: ${({ theme }) => theme.colors.blender._80};
`;

const $Action = styled.div`
  display: flex;
  align-items: center;
`;

const $CloseIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.blender._80};
`;

const getIconNameFromType = type => {
  switch (type) {
    case 'success':
      return 'CheckmarkCircle';
    case 'error':
      return 'Sad';
    case 'warning':
      return 'Warning';
    case 'neutral':
      return 'HandLeft';
    case 'informative':
      return 'Flash';
    default:
      return '';
  }
};

const ToasterComponent = () => <Toaster />;

const Toast = ({ title, description, type, onClose }) => (
  <$Toast variant="light" type={type} data-cy="toast">
    <$IconContainer>
      <Icon name={getIconNameFromType(type)} size={24} />
    </$IconContainer>
    <$TextContainer>
      {title && <$Title data-cy="toast_title">{title}</$Title>}
      {description && <$Description>{description}</$Description>}
    </$TextContainer>
    <$Action>
      <$CloseIcon name="Close" size={16} onClick={onClose} />
    </$Action>
  </$Toast>
);

export const showToast = props =>
  toast.custom(t => <Toast {...props} onClose={() => toast.remove(t.id)} />, {
    duration: 30000,
    position: 'bottom-right',
  });

Toast.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  type: PropTypes.oneOf([
    'success',
    'error',
    'warning',
    'neutral',
    'informative',
  ]),
  onClose: PropTypes.func,
};

Toast.defaultProps = {
  title: '',
  description: '',
  type: 'success',
  onClose: () => {},
};

export default ToasterComponent;

import { apiFetch } from 'modules/shared/fetch';
import { createCorrelationId } from 'utils/createUniqueId';

const correlationId = createCorrelationId();

export default class ReconciliationService {
  static async getPaymentObjects({ appToken, payload, appEnvironment }) {
    const response = await apiFetch('/v1/reconciliation/payments', {
      body: payload,
      appEnvironment,
      headers: {
        'lean-app-token': appToken,
        'lean-correlation-id': correlationId,
      },
    });

    const initial = {
      reconciled: 0,
      unreceived: 0,
      unreconciled: {
        total: 0,
        matches: {
          unique: 0,
          partial: 0,
          no_match: 0,
        },
      },
    };

    const {
      deposits,
      totals: {
        amount = { ...initial },
        number_of_items: numberOfItems = { ...initial },
      },
      payments,
    } = response.data;

    return {
      deposits,
      payments,
      totals: {
        reconciled: amount.reconciled,
        unreceived: amount.unreceived,
        unreconciled: amount.unreconciled.total,
        matches: amount.unreconciled.matches,
      },
      paymentsCount: {
        reconciled: numberOfItems.reconciled,
        unreceived: numberOfItems.unreceived,
        unreconciled: numberOfItems.unreconciled.total,
        matches: numberOfItems.unreconciled.matches,
      },
    };
  }

  static updatePaymentStatus({ payload, appToken, appEnvironment }) {
    return apiFetch('/v1/reconciliation/payments', {
      body: payload,
      method: 'PUT',
      appEnvironment,
      headers: {
        'lean-app-token': appToken,
        'lean-correlation-id': correlationId,
      },
    });
  }

  static getBanksList = async ({ appToken, appEnvironment }) => {
    const response = await apiFetch(`/applications/banks/status/${appToken}`, {
      appEnvironment,
    });
    return response.data;
  };

  static getReconciliationConfiguration = async ({
    appToken,
    appEnvironment,
  }) => {
    const response = await apiFetch('/v1/reconciliation/configuration', {
      appEnvironment,
      headers: {
        'lean-app-token': appToken,
      },
    });
    return response.data;
  };

  static updateReconciliationConfiguration = async ({
    payload,
    appToken,
    appEnvironment,
  }) => {
    const response = await apiFetch('/v1/reconciliation/configuration', {
      body: payload,
      method: 'PUT',
      appEnvironment,
      headers: {
        'lean-app-token': appToken,
      },
    });

    return response.data;
  };
}

import { createSelector } from 'reselect';

const selectRaw = state => state.root.auth;

const selectFromPath = state =>
  state.router &&
  state.router.location &&
  state.router.location.state &&
  state.router.location.state.from &&
  state.router.location.state.from.pathname;

const selectAuthenticationUser = createSelector(
  [selectRaw],
  auth => auth.authenticationUser,
);

const selectCurrentUser = createSelector([selectRaw], auth => auth.currentUser);

// selects scopes, and adds environments to agnostic scopes
// ex. 'usermanagement.write` -> `usermanagement.${environment}.write`
const selectScopes = createSelector([selectRaw], auth =>
  auth.scopes.reduce((acc, scope) => {
    const arr = scope.split('.');
    if (arr.length === 2) {
      return acc.concat([
        `${arr[0]}.sandbox.${arr[1]}`,
        `${arr[0]}.production.${arr[1]}`,
      ]);
    }
    return acc.concat(scope);
  }, []),
);

const selectCurrentUserEmail = createSelector(
  [selectCurrentUser],
  currentUser => (currentUser ? currentUser.email : null),
);

const selectCurrentUserId = createSelector([selectCurrentUser], currentUser =>
  currentUser ? currentUser.id : '',
);

const selectCurrentUserFullName = createSelector(
  [selectCurrentUser],
  currentUser => (currentUser ? currentUser.fullName : ''),
);

const selectSignedIn = createSelector(
  [selectCurrentUser],
  currentUser => !!currentUser && !!currentUser.id,
);

const selectRoles = createSelector([selectCurrentUser], currentUser =>
  currentUser ? currentUser.roles || [] : [],
);

const selectEmptyPermissions = createSelector(
  [selectRoles],
  roles => !roles || !roles.length,
);

const selectLoading = createSelector([selectRaw], auth => !!auth.loading);

const selectLoadingInit = createSelector(
  [selectRaw],
  auth => !!auth.loadingInit,
);

const selectLoadingEmailConfirmation = createSelector(
  [selectRaw],
  auth => !!auth.loadingEmailConfirmation,
);

const selectLoadingPasswordResetEmail = createSelector(
  [selectRaw],
  auth => !!auth.loadingPasswordResetEmail,
);

const selectLoadingPasswordReset = createSelector(
  [selectRaw],
  auth => !!auth.loadingPasswordReset,
);

const selectLoadingPasswordChange = createSelector(
  [selectRaw],
  auth => !!auth.loadingPasswordChange,
);

const selectLoadingVerifyEmail = createSelector(
  [selectRaw],
  auth => !!auth.loadingVerifyEmail,
);

const selectLoadingUpdateProfile = createSelector(
  [selectRaw],
  auth => !!auth.loadingUpdateProfile,
);

const selectErrorMessage = createSelector(
  [selectRaw],
  auth => auth.errorMessage,
);

const selectCurrentUserNameOrEmailPrefix = createSelector(
  [selectCurrentUser, selectCurrentUserFullName],
  (currentUser, fullName) => {
    if (!currentUser) {
      return '';
    }

    if (fullName && fullName.length < 25) {
      return fullName;
    }

    if (currentUser.first_name) {
      return currentUser.first_name;
    }

    return currentUser.email.split('@')[0];
  },
);

const selectCurrentUserAvatar = createSelector(
  [selectCurrentUser],
  currentUser => {
    if (
      !currentUser ||
      !currentUser.avatars ||
      !currentUser.avatars.length ||
      !currentUser.avatars[0].publicUrl
    ) {
      return null;
    }

    return currentUser.avatars[0].publicUrl;
  },
);

const selectInvitationTokenData = createSelector(
  [selectRaw],
  auth => auth.invitationTokenData,
);

const selectDocsToken = createSelector([selectRaw], auth => auth.docsToken);

const selectAccountType = createSelector(
  [selectCurrentUser],
  currentUser => currentUser.account_type,
);

const selectVerifyingUpdateMfa = createSelector(
  [selectRaw],
  auth => !!auth.verifyingUpdateMfa,
);

const selectFetchingMfaDetails = createSelector(
  [selectRaw],
  auth => !!auth.fetchingMfaDetails,
);

const selectMfaDetails = createSelector([selectRaw], auth => auth.mfaDetails);

const selectFetchingPasswordResetDetails = createSelector(
  [selectRaw],
  auth => !!auth.fetchingPasswordResetDetails,
);

const selectPasswordResetDetails = createSelector(
  [selectRaw],
  auth => auth.passwordResetDetails,
);

const selectors = {
  selectLoadingPasswordResetEmail,
  selectLoadingEmailConfirmation,
  selectLoadingInit,
  selectLoadingUpdateProfile,
  selectLoading,
  selectEmptyPermissions,
  selectRoles,
  selectSignedIn,
  selectCurrentUserFullName,
  selectCurrentUserEmail,
  selectCurrentUserId,
  selectCurrentUser,
  selectAuthenticationUser,
  selectErrorMessage,
  selectRaw,
  selectCurrentUserNameOrEmailPrefix,
  selectCurrentUserAvatar,
  selectLoadingPasswordReset,
  selectLoadingPasswordChange,
  selectLoadingVerifyEmail,
  selectFromPath,
  selectScopes,
  selectInvitationTokenData,
  selectDocsToken,
  selectAccountType,
  selectVerifyingUpdateMfa,
  selectFetchingMfaDetails,
  selectMfaDetails,
  selectFetchingPasswordResetDetails,
  selectPasswordResetDetails,
};

export default selectors;

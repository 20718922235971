import React from 'react';
import Lottie from 'lottie-react';
import QueryString from 'query-string';
import { Button } from 'components';
import { getLanguageCode, i18n } from 'i18n';
import { useTypedSelector } from 'types/global';
import { selectHyperlink } from 'modules/hyperlink';
import { getHistory } from 'modules/history';
import HyperlinkContainer from './HyperlinkContainer';
import successAnimation from './animations/animation-success-bank.json';
import { getAppName } from './utils';
import {
  $HyperlinkLayoutBody,
  $HyperlinkPageParagraph,
  $HyperlinkRedirectImage,
} from './hyperlink.styled';
import { hyperlinkUserSteps } from './constants';

function RequestBankSuccess() {
  const { data } = useTypedSelector(selectHyperlink);
  const languageCode = getLanguageCode();
  const appName = getAppName(data, languageCode);

  const history = getHistory();
  const queryParams = QueryString.parse(window.location.search);

  const gotoBankSelection = () => {
    const newParams = {
      ...queryParams,
      bankName: undefined,
      bankIdentifier: undefined,
      step: hyperlinkUserSteps.BankSelection.name,
    };
    history.push({ search: `${QueryString.stringify(newParams)}` });
  };
  return (
    <HyperlinkContainer>
      <$HyperlinkLayoutBody className="text-center">
        <$HyperlinkRedirectImage>
          <Lottie animationData={successAnimation} loop />
        </$HyperlinkRedirectImage>

        <$HyperlinkPageParagraph className="text-center">
          {i18n('hyperlink.request_bank.success', appName)}
        </$HyperlinkPageParagraph>

        <div>
          <Button
            onClick={gotoBankSelection}
            styledProps={{ variant: 'secondary' }}
            className="connect-another-bank-button"
            data-cy="hyperlink_return_to_bank_selection_button"
          >
            {i18n('hyperlink.request_bank.connect_other_bank_btn')}
          </Button>
        </div>
      </$HyperlinkLayoutBody>
    </HyperlinkContainer>
  );
}

export default RequestBankSuccess;

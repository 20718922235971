import styled from 'styled-components/macro';

export const $Field = styled.div`
  display: flex;
  padding: 0 48px 0 8px;

  label,
  .label {
    flex-basis: 25%;
    font-size: ${({ theme }) => theme.font.size.body.sm};
  }

  input {
    font-size: ${({ theme }) => theme.font.size.body.md};
  }

  input[name='email'] {
    color: ${({ theme }) => theme.colors.neutral._40};
  }

  // set a height for help text so the view doesn't expand when an error happens
  input ~ div {
    height: 19px;
  }

  button {
    padding: 0;
  }

  &.mb-30 {
    margin-bottom: 30px;
  }

  &.mb-92 {
    margin-bottom: 92px;
  }
`;

export const $Divider = styled.hr`
  margin: 16px 0;
  border: 0.5px solid ${({ theme }) => theme.colors.neutral._30};
`;

export const $Role = styled.span`
  text-transform: capitalize;
  color: ${({ theme }) => theme.colors.neutral._50};
  font-size: ${({ theme }) => theme.font.size.body.md};
`;

export const $DialogFooter = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const $WarningIcon = styled.img`
  height: 14px;
  width: 14px;
  margin-left: 5px;
`;

export const $Badge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 0 8px;
  margin-left: 32px;
  font-size: 12px;

  &.warning {
    background-color: ${({ theme }) => theme.colors.sun._10};
    color: ${({ theme }) => theme.colors.sun._60};
  }

  &.success {
    background-color: ${({ theme }) => theme.colors.neon._20};
    color: ${({ theme }) => theme.colors.neon._80};
  }
`;

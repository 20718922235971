import actions from 'modules/layout/layoutActions';
import { getLanguageCode } from 'i18n';

const initialData = {
  menuVisible: false,
  userMenuVisible: false,
  languageVisible: false,
  language: getLanguageCode(),
  loading: false,
};

const layoutReducers = (state = initialData, { type, payload }) => {
  // User Menu
  if (type === actions.USER_MENU_TOGGLE) {
    return {
      ...state,
      userMenuVisible: !state.userMenuVisible,
    };
  }

  if (type === actions.USER_MENU_SHOW) {
    return {
      ...state,
      userMenuVisible: true,
    };
  }

  if (type === actions.USER_MENU_HIDE) {
    return {
      ...state,
      userMenuVisible: false,
    };
  }

  // Language

  if (type === actions.LANGUAGE_TOGGLE) {
    return {
      ...state,
      languageVisible: !state.languageVisible,
    };
  }

  if (type === actions.LANGUAGE_SHOW) {
    return {
      ...state,
      languageVisible: true,
    };
  }

  if (type === actions.LANGUAGE_HIDE) {
    return {
      ...state,
      languageVisible: false,
    };
  }

  // Menu
  if (type === actions.MENU_TOGGLE) {
    return {
      ...state,
      menuVisible: !state.menuVisible,
    };
  }

  if (type === actions.MENU_SHOW) {
    return {
      ...state,
      menuVisible: true,
    };
  }

  if (type === actions.MENU_HIDE) {
    return {
      ...state,
      menuVisible: false,
    };
  }

  return state;
};

export default layoutReducers;

import React from 'react';

import ConnectSection from './ConnectSection';
import PermissionsSection from './PermissionsSection';
import HyperlinkContainer from './HyperlinkContainer';
import { $HyperlinkConnectBody } from './hyperlink.styled';

const HYPER_LINK_CONSENT_DURATION_IN_HOURS = 23;
function LinkAccount() {
  // Access from last year minus two days
  const accessFrom = new Date();
  accessFrom.setDate(accessFrom.getDate() - 2);
  accessFrom.setHours(0, 0, 0);
  accessFrom.setFullYear(accessFrom.getFullYear() - 1);

  // Access expires the next day
  const accessTo = new Date();
  accessTo.setHours(accessTo.getHours() + HYPER_LINK_CONSENT_DURATION_IN_HOURS);

  return (
    <HyperlinkContainer>
      <$HyperlinkConnectBody>
        <ConnectSection accessFrom={accessFrom} accessTo={accessTo} />
        <PermissionsSection accessTo={accessTo} accessFrom={accessFrom} />
      </$HyperlinkConnectBody>
    </HyperlinkContainer>
  );
}

export default LinkAccount;

import { RootState } from 'modules/store';
import { createSlice } from '@reduxjs/toolkit';

import { ConsentExplanationState } from './consentExplanation.types';
import {
  getConsentExplanations,
  updateConsentExplanations,
} from './consentExplanation.actions';

const initialState: ConsentExplanationState = {
  purpose_statement: {
    arabic: '',
    english: '',
  },
  benefit_statement: {
    arabic: '',
    english: '',
  },
  show_consent_screen: true,
};

const consentExplanationSlice = createSlice({
  name: 'consent-explanation',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getConsentExplanations.fulfilled, (state, { payload }) => {
      state.purpose_statement = payload.purpose_statement || {
        arabic: '',
        english: '',
      };
      state.benefit_statement = payload.benefit_statement || {
        arabic: '',
        english: '',
      };
      state.show_consent_screen = payload.show_consent_screen ?? true;
    });

    builder.addCase(
      updateConsentExplanations.fulfilled,
      (state, { payload }) => {
        state = payload;
      },
    );
  },
});

export default consentExplanationSlice.reducer;
export const selectConsentExplanation = (state: RootState) =>
  state.root.consentExplanation;
export const selectConsentExplanationPurposeStatement = (state: RootState) =>
  selectConsentExplanation(state).purpose_statement;
export const selectConsentExplanationBenefitStatement = (state: RootState) =>
  selectConsentExplanation(state).benefit_statement;
export const selectConsentExplanationShowConsentScreenFlag = (
  state: RootState,
) => selectConsentExplanation(state).show_consent_screen;

import { createAsyncThunk } from '@reduxjs/toolkit';

import Message from 'view/shared/message';
import { RootState } from 'modules/store';
import { ErrorObject } from 'types/global';
import Errors from 'modules/shared/error/errors';
import { downloadPdfFromArrayBuffer } from 'utils/downloadPdfFromArrayBuffer';

import ReportsService from './reports.service';
import {
  CustomerData,
  CustomerDetailsData,
  ReportExpenseCategory,
  GenerateReportError,
  ReportDetailsData,
  ReportsError,
  ReportsPayload,
  ReportStatsPayload,
  ReportsTransactionsPayload,
  SalaryTransactionsPayload,
  ReportEvent,
  RefreshCustomerResponse,
  ReportCashflowResponse,
  ReportLoansResponse,
  ReportCreditCardsResponse,
} from './reports.types';

export const getCustomersAction = createAsyncThunk<
  ReportsPayload,
  { page: number; pageSize: number; search: string },
  {
    state: RootState;
    rejectValue: ReportsError | ErrorObject;
  }
>(
  'reports/get-customers',
  async ({ page, pageSize, search }, { getState, rejectWithValue }) => {
    const { environment: appEnvironment, appToken } = getState().root.global;

    try {
      return await ReportsService.getCustomers({
        appToken,
        pageSize,
        appEnvironment,
        appUserId: search,
        page: search === '' ? page : 0,
      });
    } catch (error: unknown) {
      const errorObj: ErrorObject = error as ErrorObject;

      Errors.handle(errorObj);
      return rejectWithValue(errorObj);
    }
  },
);

export const getCustomerDetailsAction = createAsyncThunk<
  CustomerDetailsData,
  { appUserId: string; withReports?: boolean; isReFetching?: boolean },
  {
    state: RootState;
    rejectValue: ReportsError | ErrorObject;
  }
>(
  'reports/get-customer-details',
  async ({ appUserId, withReports = true }, { getState, rejectWithValue }) => {
    const { environment: appEnvironment, appToken } = getState().root.global;
    try {
      return await ReportsService.getCustomerDetails({
        appToken,
        appUserId,
        appEnvironment,
        withReports,
      });
    } catch (error: unknown) {
      const errorObj: ErrorObject = error as ErrorObject;

      Errors.handle(errorObj);
      return rejectWithValue(errorObj);
    }
  },
);

export const createCustomerAction = createAsyncThunk<
  CustomerData,
  { appUserId: string },
  { state: RootState }
>(
  'reports/create-customer',
  async ({ appUserId }, { getState, rejectWithValue }) => {
    const { environment: appEnvironment, appToken } = getState().root.global;

    try {
      return await ReportsService.createCustomer({
        appToken,
        appUserId,
        appEnvironment,
      });
    } catch (error: unknown) {
      Errors.handle(error);
      return rejectWithValue(error);
    }
  },
);

export const generateCustomerReportAction = createAsyncThunk<
  CustomerData,
  { appUserId: string },
  { state: RootState; rejectValue: GenerateReportError | ErrorObject }
>(
  'reports/generate-customer-report',
  async ({ appUserId }, { getState, rejectWithValue }) => {
    const { environment: appEnvironment, appToken } = getState().root.global;

    try {
      return await ReportsService.generateCustomerReport({
        appToken,
        appUserId,
        appEnvironment,
      });
    } catch (error: unknown) {
      const errorObj: ErrorObject = error as ErrorObject;

      if (errorObj.response.status === 404) {
        if (errorObj.data.message.includes('has wrong state')) {
          Message.error(
            'Failed to generate report',
            'The customer data has not yet been fetched.',
          );
        }

        if (errorObj.data.message.includes('not found')) {
          Message.error(
            'Failed to generate report',
            'The selected customer does not exist.',
          );
        }

        return rejectWithValue(errorObj.data);
      }

      Errors.handle(error);
      return rejectWithValue(errorObj.data);
    }
  },
);

interface DownloadParams {
  rowId?: string;
  reportId: string;
  appToken: string;
  appUserId: string;
  appEnvironment: string;
}

export const downloadCSVsAction = async ({
  reportId,
  appToken,
  appUserId,
  appEnvironment,
}: DownloadParams) => {
  try {
    Message.informative('Downloading CSVs...');
    const getAccountsCSV = ReportsService.downloadAccountsCSV({
      reportId,
      appToken,
      appUserId,
      appEnvironment,
    });

    const getTransactionsCSV = ReportsService.downloadTransactionsCSV({
      reportId,
      appToken,
      appUserId,
      appEnvironment,
    });

    const response = await Promise.allSettled([
      getAccountsCSV,
      getTransactionsCSV,
    ]);
    const [accountsCSV, transactionsCSV] = response;

    if (
      accountsCSV.status === 'rejected' ||
      transactionsCSV.status === 'rejected'
    ) {
      Message.error('Error downloading reports. Please try again.');
      return [];
    }

    return [accountsCSV.value, transactionsCSV.value];
  } catch (error) {
    Message.error('Error downloading reports. Please try again.');
    return Promise.reject(error);
  }
};

export const downloadPDFAction = async ({
  rowId,
  reportId,
  appToken,
  appUserId,
  appEnvironment,
}: DownloadParams) => {
  Message.informative('Downloading PDF report...');

  try {
    const response = await ReportsService.downloadCombinedPDF({
      reportId,
      appToken,
      appUserId,
      appEnvironment,
    });

    const filename = `report_${appUserId}_${rowId}.pdf`;

    downloadPdfFromArrayBuffer(response, filename);
  } catch (error) {
    Message.error('Error downloading report. Please try again.');
  }
};

export const getReportDetailsById = createAsyncThunk<
  ReportDetailsData,
  { appUserId: string; reportId: string },
  {
    state: RootState;
    rejectValue: ReportsError | ErrorObject;
  }
>(
  'reports/get-report-details-by-id',
  async ({ appUserId, reportId }, { getState, rejectWithValue }) => {
    const { environment: appEnvironment, appToken } = getState().root.global;
    try {
      return await ReportsService.getReportDetailsById({
        appToken,
        appUserId,
        appEnvironment,
        reportId,
      });
    } catch (error: unknown) {
      const errorObj: ErrorObject = error as ErrorObject;

      Errors.handle(errorObj);
      return rejectWithValue(errorObj);
    }
  },
);

export const getReportTransactions = createAsyncThunk<
  ReportsTransactionsPayload,
  {
    page: number;
    pageSize: number;
    type?: string;
    appUserId: string;
    reportId: string;
  },
  {
    state: RootState;
    rejectValue: ReportsError | ErrorObject;
  }
>(
  'reports/get-report-transactions',
  async (
    { page, pageSize, type, appUserId, reportId },
    { getState, rejectWithValue },
  ) => {
    const { environment: appEnvironment, appToken } = getState().root.global;
    try {
      return await ReportsService.getReportTransactions({
        appToken,
        pageSize,
        appEnvironment,
        page,
        type,
        appUserId,
        reportId,
      });
    } catch (error: unknown) {
      const errorObj: ErrorObject = error as ErrorObject;

      Errors.handle(errorObj);
      return rejectWithValue(errorObj);
    }
  },
);

export const getSalaryTransactions = createAsyncThunk<
  SalaryTransactionsPayload,
  { appUserId: string; reportId: string },
  {
    state: RootState;
    rejectValue: ReportsError | ErrorObject;
  }
>(
  'reports/get-salary-transactions',
  async ({ appUserId, reportId }, { getState, rejectWithValue }) => {
    const { environment: appEnvironment, appToken } = getState().root.global;
    try {
      return await ReportsService.getSalaryTransactions({
        appToken,
        appEnvironment,
        appUserId,
        reportId,
      });
    } catch (error: unknown) {
      const errorObj: ErrorObject = error as ErrorObject;

      Errors.handle(errorObj);
      return rejectWithValue(errorObj);
    }
  },
);

export const getReportStats = createAsyncThunk<
  ReportStatsPayload,
  { appUserId: string; reportId: string },
  {
    state: RootState;
    rejectValue: ReportsError | ErrorObject;
  }
>(
  'reports/get-report-stats',
  async ({ appUserId, reportId }, { getState, rejectWithValue }) => {
    const { environment: appEnvironment, appToken } = getState().root.global;
    try {
      return await ReportsService.getReportStats({
        appToken,
        appEnvironment,
        appUserId,
        reportId,
      });
    } catch (error: unknown) {
      const errorObj: ErrorObject = error as ErrorObject;

      Errors.handle(errorObj);
      return rejectWithValue(errorObj);
    }
  },
);

export const getReportCashflow = createAsyncThunk<
  ReportCashflowResponse,
  { appUserId: string; reportId: string },
  {
    state: RootState;
    rejectValue: ReportsError | ErrorObject;
  }
>(
  'reports/get-report-cashflow',
  async ({ appUserId, reportId }, { getState, rejectWithValue }) => {
    const { environment: appEnvironment, appToken } = getState().root.global;
    try {
      return await ReportsService.getReportCashflow({
        appToken,
        appEnvironment,
        appUserId,
        reportId,
      });
    } catch (error: unknown) {
      const errorObj: ErrorObject = error as ErrorObject;

      Errors.handle(errorObj);
      return rejectWithValue(errorObj);
    }
  },
);

export const getReportFinancesAndLoans = createAsyncThunk<
  ReportLoansResponse,
  { appUserId: string; reportId: string },
  {
    state: RootState;
    rejectValue: ReportsError | ErrorObject;
  }
>(
  'reports/get-report-finances-and-loans',
  async ({ appUserId, reportId }, { getState, rejectWithValue }) => {
    const { environment: appEnvironment, appToken } = getState().root.global;
    try {
      return await ReportsService.getReportFinancesAndLoans({
        appToken,
        appEnvironment,
        appUserId,
        reportId,
      });
    } catch (error: unknown) {
      const errorObj: ErrorObject = error as ErrorObject;

      Errors.handle(errorObj);
      return rejectWithValue(errorObj);
    }
  },
);

export const getReportCreditCards = createAsyncThunk<
  ReportCreditCardsResponse,
  { appUserId: string; reportId: string },
  {
    state: RootState;
    rejectValue: ReportsError | ErrorObject;
  }
>(
  'reports/get-report-credit-cards',
  async ({ appUserId, reportId }, { getState, rejectWithValue }) => {
    const { environment: appEnvironment, appToken } = getState().root.global;
    try {
      return await ReportsService.getReportCreditCards({
        appToken,
        appEnvironment,
        appUserId,
        reportId,
      });
    } catch (error: unknown) {
      const errorObj: ErrorObject = error as ErrorObject;

      Errors.handle(errorObj);
      return rejectWithValue(errorObj);
    }
  },
);

export const getReportExpensesBreakdown = createAsyncThunk<
  ReportExpenseCategory[],
  { appUserId: string; reportId: string },
  {
    state: RootState;
    rejectValue: ReportsError | ErrorObject;
  }
>(
  'reports/get-report-expenses-breakdown',
  async ({ appUserId, reportId }, { getState, rejectWithValue }) => {
    const { environment: appEnvironment, appToken } = getState().root.global;
    try {
      return await ReportsService.getReportExpensesBreakdown({
        appToken,
        appEnvironment,
        appUserId,
        reportId,
      });
    } catch (error: unknown) {
      const errorObj: ErrorObject = error as ErrorObject;

      Errors.handle(errorObj);
      return rejectWithValue(errorObj);
    }
  },
);

export const getReportEvents = createAsyncThunk<
  ReportEvent[],
  { appUserId: string; reportId: string },
  {
    state: RootState;
    rejectValue: ReportsError | ErrorObject;
  }
>(
  'reports/get-report-events',
  async ({ appUserId, reportId }, { getState, rejectWithValue }) => {
    const { environment: appEnvironment, appToken } = getState().root.global;
    try {
      return await ReportsService.getEvents({
        appToken,
        appEnvironment,
        appUserId,
        reportId,
      });
    } catch (error: unknown) {
      const errorObj: ErrorObject = error as ErrorObject;

      Errors.handle(errorObj);
      return rejectWithValue(errorObj);
    }
  },
);

export const refreshCustomerEntity = createAsyncThunk<
  RefreshCustomerResponse,
  { appUserId: string; entityId: string },
  {
    state: RootState;
    rejectValue: ReportsError | ErrorObject;
  }
>(
  'reports/refresh-customer-entity',
  async ({ appUserId, entityId }, { getState, rejectWithValue }) => {
    const { environment: appEnvironment, appToken } = getState().root.global;
    try {
      return await ReportsService.refreshCustomerEntity({
        appToken,
        appEnvironment,
        appUserId,
        entityId,
      });
    } catch (error: unknown) {
      const errorObj: ErrorObject = error as ErrorObject;

      Errors.handle(errorObj);
      return rejectWithValue(errorObj);
    }
  },
);

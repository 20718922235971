import { createSelector } from 'reselect';
import { generateFlowChallengesFromBehaviours } from 'utils';
import authSelectors from 'modules/auth/authSelectors';
import { selectEnvironment } from 'modules/global';
import PermissionChecker from 'modules/auth/permissionChecker';
import { MockUsersFilter, MockUsersRoles } from 'modules/application/constants';
import SCOPES from 'security/scopes';

const selectRaw = state => state.root.application.view;

const selectLoading = createSelector([selectRaw], view => view.loading);

const selectMockUsersFilter = createSelector([selectRaw], raw => raw.filter);

const selectMockUsers = createSelector([selectRaw], raw => raw.mockUsers);

const selectFilteredMockUsers = createSelector(
  [selectMockUsers, selectMockUsersFilter],
  (users, filter) => {
    switch (filter) {
      case MockUsersFilter.sme:
        return users.filter(user => user.user_role !== MockUsersRoles.retail);
      case MockUsersFilter.retail:
        return users.filter(user => user.user_role === MockUsersRoles.retail);
      default:
        return users;
    }
  },
);

const selectMockUsersTableData = createSelector(
  [
    selectFilteredMockUsers,
    authSelectors.selectCurrentUser,
    authSelectors.selectScopes,
    selectEnvironment,
  ],
  (users, currentUser, scopes, environment) => {
    const permissionChecker = new PermissionChecker(
      currentUser,
      scopes,
      environment,
    );

    const isWritePermission = permissionChecker.check(
      SCOPES.SANDBOX_TEST_USERS.WRITE,
    );

    return generateFlowChallengesFromBehaviours(users, isWritePermission);
  },
);

const selectFirstMockUser = createSelector(
  [selectMockUsers],
  users => users[0],
);

const applicationViewSelectors = {
  selectLoading,
  selectMockUsersTableData,
  selectFirstMockUser,
  selectMockUsersFilter,
  selectMockUsers,
};
export default applicationViewSelectors;

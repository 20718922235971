import Errors from 'modules/shared/error/errors';
import FileUploader from 'modules/shared/fileUpload/fileUploader';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import React, { useRef, useState } from 'react';
import { useTypedSelector } from 'types/global';
import { selectAppToken, selectEnvironment } from 'modules/global';
import Button from './Button';
import Icon from './Icon';
import { $Helptext } from '../styles';

const $UpdateImage = styled.div`
  display: flex;
  align-items: center;
`;

const $IconPlaceholder = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 2px;
  border: 1px solid ${({ theme }) => theme.colors.neutral._30};
  background-color: white;

  display: flex;
  justify-content: center;
  align-items: center;

  background-clip: content-box;
  background-image: url(${({ url }) => url});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
`;

const $PlusIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.neutral._30};
`;

const UpdateImage = ({
  value = '',
  schema,
  onChange,
  path,
  isUploadDisabled,
}) => {
  const appToken = useTypedSelector(selectAppToken);
  const environment = useTypedSelector(selectEnvironment);

  const fileInputElem = useRef(null);
  const [newPublicUrl, setNewPublicUrl] = useState(value);
  const [hasMaxFileSizeError, setHasMaxFileSizeError] = useState(false);
  const newSchema = {
    ...schema,
    image: true,
  };

  const handleClick = () => fileInputElem.current.click();

  const handleSuccess = file => {
    const { publicUrl } = file;

    setNewPublicUrl(publicUrl);
    onChange(publicUrl);
  };

  const handleError = error => Errors.showMessage(error);

  return (
    <div>
      <$UpdateImage>
        <input
          accept="image/png, image/jpeg"
          hidden
          id="UpdateImage"
          onChange={event => {
            const fileRequest = event.target.files[0];
            const fileSizeInMB = fileRequest.size / 1024 / 1024;

            // Max file size is 5MB
            if (fileSizeInMB > 5) {
              setHasMaxFileSizeError(true);
            } else {
              setHasMaxFileSizeError(false);

              FileUploader.uploadFromRequest(
                path,
                fileRequest,
                newSchema,
                appToken,
                environment,
                file => {
                  handleSuccess(file);
                },
                error => {
                  handleError(error);
                },
              );
            }
          }}
          ref={fileInputElem}
          type="file"
        />

        <$IconPlaceholder
          className="logo"
          url={newPublicUrl}
          data-cy="app_logo"
        >
          {!newPublicUrl && <$PlusIcon name="AddCircle" size={32} />}
        </$IconPlaceholder>

        <Button
          onClick={handleClick}
          styledProps={{ variant: 'text' }}
          isDisabled={isUploadDisabled}
        >
          Upload
        </Button>
      </$UpdateImage>

      {hasMaxFileSizeError && (
        <$Helptext
          isErrored
          id="max-file-size-error-message"
          data-cy="max-file-size-error-message"
        >
          File size must be less than 5MB
        </$Helptext>
      )}
    </div>
  );
};

UpdateImage.propTypes = {
  onChange: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
  schema: PropTypes.shape({
    size: PropTypes.number,
  }).isRequired,
  value: PropTypes.string,
  isUploadDisabled: PropTypes.bool,
};

UpdateImage.defaultProps = {
  value: '',
  isUploadDisabled: false,
};

export default UpdateImage;

import { apiFetch } from 'modules/shared/fetch';
import { createCorrelationId } from 'utils/createUniqueId';

import { RequestParams, GetPaymentParams } from './payments.types';

const correlationId = createCorrelationId();

export default class PaymentsService {
  static async getPayments({ body, appToken, appEnvironment }: RequestParams) {
    const response = await apiFetch(`/logs/applications/${appToken}/payments`, {
      // @ts-ignore
      appEnvironment,
      method: 'POST',
      headers: {
        'lean-app-token': appToken,
        'lean-correlation-id': correlationId,
      },
      body,
    });

    return response?.data;
  }

  static async getSinglePaymentById({
    appToken,
    paymentId,
    appEnvironment,
  }: GetPaymentParams) {
    const response = await apiFetch(
      `/logs/applications/${appToken}/payments/${paymentId}`,
      {
        // @ts-ignore
        appEnvironment,
        headers: {
          'lean-app-token': appToken,
          'lean-correlation-id': correlationId,
        },
      },
    );

    return response?.data;
  }
}

import { apiFetch } from 'modules/shared/fetch';
import { createCorrelationId } from 'utils/createUniqueId';

import {
  RequestParams,
  GetCustomerParams,
  GetAllCustomersParams,
  GetCustomerEntityParams,
  GetCustomerPaymentParams,
  GetCustomerEntitiesParams,
  DeleteCustomerEntityParams,
} from './customers.types';

const correlationId = createCorrelationId();

export default class CustomersService {
  static async getAllCustomersByAppToken({
    page,
    search,
    pageSize,
    appToken,
    appEnvironment,
  }: GetAllCustomersParams) {
    const response = await apiFetch(
      `/logs/applications/${appToken}/customers?page_number=${page}&page_size=${pageSize}&search=${search}`,
      {
        // @ts-ignore
        appEnvironment,
        headers: {
          'lean-app-token': appToken,
          'lean-correlation-id': correlationId,
        },
      },
    );

    return response?.data;
  }

  static async getSingleCustomersByAppToken({
    appToken,
    customerId,
    appEnvironment,
  }: GetCustomerParams) {
    const response = await apiFetch(
      `/logs/applications/${appToken}/customers/${customerId}`,
      {
        // @ts-ignore
        appEnvironment,
        headers: {
          'lean-app-token': appToken,
          'lean-correlation-id': correlationId,
        },
      },
    );

    return response?.data;
  }

  static async getCustomerEntities({
    page,
    pageSize,
    appToken,
    customerId,
    appEnvironment,
  }: GetCustomerEntitiesParams) {
    const response = await apiFetch(
      `/logs/applications/${appToken}/customers/${customerId}/entities?page_number=${page}&page_size=${pageSize}`,
      {
        // @ts-ignore
        appEnvironment,
        headers: {
          'lean-app-token': appToken,
          'lean-correlation-id': correlationId,
        },
      },
    );

    return response?.data;
  }

  static async getCustomerEntityById({
    appToken,
    entityId,
    customerId,
    appEnvironment,
  }: GetCustomerEntityParams) {
    // @TODO: investigate why this returns a 404
    const response = await apiFetch(
      `/logs/applications/${appToken}/customers/${customerId}/entities/${entityId}`,
      {
        // @ts-ignore
        appEnvironment,
        headers: {
          'lean-app-token': appToken,
          'lean-correlation-id': correlationId,
        },
      },
    );

    return response?.data;
  }

  static async getCustomerPayments({
    body,
    appToken,
    appEnvironment,
  }: RequestParams) {
    const response = await apiFetch(`/logs/applications/${appToken}/payments`, {
      // @ts-ignore
      appEnvironment,
      method: 'POST',
      headers: {
        'lean-app-token': appToken,
        'lean-correlation-id': correlationId,
      },
      body,
    });

    return response?.data;
  }

  static async getSingleCustomerPaymentById({
    appToken,
    paymentId,
    appEnvironment,
  }: GetCustomerPaymentParams) {
    const response = await apiFetch(
      `/logs/applications/${appToken}/payments/${paymentId}`,
      {
        // @ts-ignore
        appEnvironment,
        headers: {
          'lean-app-token': appToken,
          'lean-correlation-id': correlationId,
        },
      },
    );

    return response?.data;
  }

  static async deleteSingleCustomerEntityById({
    appToken,
    customerId,
    entityId,
    appEnvironment,
  }: DeleteCustomerEntityParams) {
    const response = await apiFetch(
      `/customers/${customerId}/entities/${entityId}`,
      {
        // @ts-ignore
        appEnvironment,
        method: 'DELETE',
        headers: {
          'lean-app-token': appToken,
          'lean-correlation-id': correlationId,
        },
      },
    );

    return response?.data;
  }
}

import { createBrowserHistory } from 'history';

const basename = process.env.REACT_APP_BASENAME;

export const history = basename
  ? createBrowserHistory({ basename: `${basename}` })
  : createBrowserHistory();

export function getHistory() {
  return history;
}

const prefix = 'leantech.me/route:';
export function pushToHistory(path) {
  // save search params of current location in local storage
  const { search, pathname } = history.location;
  if (search) {
    localStorage.setItem(`${prefix}${pathname}`, search);
  }

  // check if new path has search params in local storage
  const pathSearchParams = localStorage.getItem(`${prefix}${pathname}`);
  if (pathSearchParams) {
    // append search params in path
    history.push(path + pathSearchParams);
  } else {
    history.push(path);
  }
}

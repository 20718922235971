import { createSelector } from 'reselect';
import { apiFetch } from 'modules/shared/fetch';
import Errors from 'modules/shared/error/errors';
import { getDateAndTimeAsNumbers } from 'utils';

const prefix = 'dev-portal/billing/entities';
// Actions
const actionTypes = {
  GET_BILLING_ENTITIES_STARTED: `${prefix}/GET_BILLING_ENTITIES_STARTED`,
  GET_BILLING_ENTITIES_SUCCESS: `${prefix}/GET_BILLING_ENTITIES_SUCCESS`,
  GET_BILLING_ENTITIES_ERROR: `${prefix}/GET_BILLING_ENTITIES_ERROR`,

  GET_DOWNLOADABLE_CSV_DATA_STARTED: `${prefix}/GET_DOWNLOADABLE_CSV_DATA_STARTED`,
  GET_DOWNLOADABLE_CSV_DATA_SUCCESS: `${prefix}/GET_DOWNLOADABLE_CSV_DATA_SUCCESS`,
  GET_DOWNLOADABLE_CSV_DATA_ERROR: `${prefix}/GET_DOWNLOADABLE_CSV_DATA_ERROR`,
};

// Reducer
const initialState = {
  loading: false,
  items: [],
  entries_total: 0,
  csvData: [],
  loadingCSVData: false,
};

export default function reducer(state = initialState, { type, payload }) {
  if (type === actionTypes.GET_BILLING_ENTITIES_STARTED) {
    return {
      ...state,
      loading: true,
    };
  }

  if (type === actionTypes.GET_BILLING_ENTITIES_SUCCESS) {
    return {
      ...state,
      loading: false,
      ...payload,
    };
  }

  if (type === actionTypes.GET_BILLING_ENTITIES_ERROR) {
    return {
      ...initialState,
    };
  }

  if (type === actionTypes.GET_DOWNLOADABLE_CSV_DATA_STARTED) {
    return {
      ...state,
      loadingCSVData: true,
    };
  }

  if (type === actionTypes.GET_DOWNLOADABLE_CSV_DATA_SUCCESS) {
    return {
      ...state,
      loadingCSVData: false,
      csvData: payload,
    };
  }

  if (type === actionTypes.GET_DOWNLOADABLE_CSV_DATA_ERROR) {
    return {
      ...initialState,
    };
  }

  return state;
}

// Action Creators
const doGetBillingActiveEntities =
  (appToken, body) => async (dispatch, getState) => {
    try {
      dispatch({
        type: actionTypes.GET_BILLING_ENTITIES_STARTED,
      });

      const { environment } = getState().root.global;
      const response = await getBillingActiveEntities(appToken, body, {
        appEnvironment: environment,
      });

      dispatch({
        type: actionTypes.GET_BILLING_ENTITIES_SUCCESS,
        payload: response,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: actionTypes.GET_BILLING_ENTITIES_ERROR,
      });
    }
  };

// eslint-disable-next-line consistent-return
const doGetCSVData = (appToken, body) => async (dispatch, getState) => {
  try {
    dispatch({
      type: actionTypes.GET_DOWNLOADABLE_CSV_DATA_STARTED,
    });

    const { environment } = getState().root.global;
    const response = await getBillingActiveEntities(appToken, body, {
      appEnvironment: environment,
    });

    dispatch({
      type: actionTypes.GET_DOWNLOADABLE_CSV_DATA_SUCCESS,
      payload: response.items,
    });

    return response.items;
  } catch (error) {
    Errors.handle(error);

    dispatch({
      type: actionTypes.GET_DOWNLOADABLE_CSV_DATA_ERROR,
    });
  }
};

export const actions = {
  doGetBillingActiveEntities,
  doGetCSVData,
};

// APIs
/**
 * POST /billing/applications/:appToken/active-entities
 * @param {string} appToken
 * @param {object} body
 * @param {string} appEnvironment
 */
const getBillingActiveEntities = async (appToken, body, { appEnvironment }) => {
  const response = await apiFetch(
    `/billing/applications/${appToken}/active-entities`,
    { body, appEnvironment },
  );
  const { data } = response;
  return data;
};

// Selectors
const selectEntities = state => state.root.billing.entities;

const selectItems = createSelector([selectEntities], entities => {
  const { loading, ...rest } = entities;
  return rest;
});

const selectTableViewData = createSelector([selectItems], entities =>
  entities.items.map(entity => {
    const {
      last_request_timestamp: lastRequestTimestamp,
      entity_id: entityId,
    } = entity;
    const timestampAsDate = new Date(lastRequestTimestamp);
    const { date, time } = getDateAndTimeAsNumbers(timestampAsDate);
    const timestamp = `${time}  ${date}`;
    return {
      'Entity ID': { entityId },
      'Last Used': { last_used: timestamp },
    };
  }),
);

const selectCSVData = createSelector(
  [selectEntities],
  entities => entities.csvData,
);

const selectLoadingCSVData = createSelector(
  [selectEntities],
  entities => entities.loadingCSVData,
);

const selectLoading = createSelector(
  [selectEntities],
  entities => entities.loading,
);

export const selectors = {
  selectItems,
  selectTableViewData,
  selectLoading,
  selectCSVData,
  selectLoadingCSVData,
};

import React from 'react';
import PropTypes, { object, string } from 'prop-types';
import styled from 'styled-components/macro';
import {
  AccordionItem as AccordionItemReach,
  AccordionButton,
  AccordionPanel,
} from '@reach/accordion';
import '@reach/accordion/styles.css';
import { ChevronDown } from '@styled-icons/ionicons-solid';

export const $AccodrionChevron = styled(ChevronDown)``;

const $AccordionItem = styled(AccordionItemReach)`
  background-color: ${({ theme }) => theme.colors.neutral._20};
  border-radius: 8px;
  margin: 16px 0;
  padding: 16px;

  /* When the accordion item is open */
  &[data-state='open'] {
    ${$AccodrionChevron} {
      transform: rotate(180deg);
    }
  }
`;

const $AccordionButton = styled(AccordionButton)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0;

  font-family: ${({ theme }) => theme.font.family.heading};
  font-size: ${({ theme }) => theme.font.size.heading.sm};
`;

const $AccordionPanel = styled(AccordionPanel)`
  &[data-state='open'] {
    padding-bottom: 16px;
  }
`;

const $AccordionContent = styled.div`
  padding: 24px;
`;

const AccordionItem = ({
  id,
  title,
  children,
  onToggle,
  analytics,
  titleComponent: TitleComponent,
}) => {
  if (!title && !TitleComponent) {
    throw new Error(
      'You must provide a title or a titleComponent to the AccordionItem',
    );
  }

  const handleOnClick = e => {
    const { properties } = analytics;
    const action = e.target.dataset.state === 'open' ? 'closed' : 'opened';

    const event = `toggled_accordion`;
    // Smoosh on the action
    window.analytics.track(event, {
      ...properties,
      action,
    });

    if (onToggle) {
      onToggle();
    }
  };

  return (
    <$AccordionItem>
      <$AccordionButton
        onClick={e => handleOnClick(e)}
        data-cy={`accordion_${id}`}
      >
        {TitleComponent ? <TitleComponent /> : title}
        <$AccodrionChevron size={16} />
      </$AccordionButton>
      <$AccordionPanel>
        <$AccordionContent>{children}</$AccordionContent>
      </$AccordionPanel>
    </$AccordionItem>
  );
};

AccordionItem.propTypes = {
  analytics: PropTypes.shape({
    event: string,
    properties: object,
  }),
  children: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  onToggle: PropTypes.func,
  titleComponent: PropTypes.elementType,
};
AccordionItem.defaultProps = {
  title: undefined,
  titleComponent: undefined,
  analytics: {
    event: 'User opened accordion',
  },
  onToggle: undefined,
};

export default AccordionItem;

import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  /* html,
  body {
  } */

  body {
    color: ${({ theme }) => theme.colors.black};;
    font-family: ${({ theme }) => theme.font.family.body};
    font-size: ${({ theme }) => theme.font.size.body.md};
    font-weight: normal;
  }

  a {
    color: inherit;
  }

  p {
    margin-bottom: 1.4em;
  }
  
  .hyperlink-pages {
    background-color: #F8F8FB;
  }
`;

import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { matchPropTypes } from 'utils/proptypes';
import { routeHelpers } from 'view/routes';
import { $Main } from 'styles';

const Main = ({ children, match }) => {
  const [isFullWidth, setFullWidth] = useState(false);
  const [isInitial, setInitial] = useState(false);

  useEffect(() => {
    setFullWidth(routeHelpers.isFullScreen(match?.path));
    setInitial(routeHelpers.hasNoStylesForMainSection(match?.path));
  }, [match?.path]);

  return (
    <$Main
      data-cy="main_section"
      className={classNames({
        'full-desktop-width': isFullWidth,
        initial: isInitial,
      })}
    >
      {children}
    </$Main>
  );
};

Main.propTypes = {
  children: PropTypes.node.isRequired,
  match: matchPropTypes.isRequired,
};

export default withRouter(Main);

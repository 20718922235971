import styled, { css } from 'styled-components/macro';
import {
  defaultStyles,
  errorStyles,
  focusStyles,
  hoverStyles,
} from 'styles/components/form/Input';

const defaultLabelStyle = css`
  color: ${({ theme }) => theme.colors.neutral._40};
  background-color: ${({ theme }) => theme.colors.white};
`;

const $Select = styled.select`
  appearance: none;
  font-size: ${({ theme, optionFontSize }) => optionFontSize ? theme.font.size.label[optionFontSize] : theme.font.size.body.md};
  height: 32px;
  margin-bottom: 5px;
  padding: 0 8px;
  width: 100%;

  ${defaultStyles}

  &:hover {
    ${hoverStyles}
  }

  &:focus {
    ${focusStyles}
  }

  ${props => props.isErrored && errorStyles}

  ${props => props.isDefaultLabel && defaultLabelStyle}
`;

export const $SelectArrow = styled.div`
  pointer-events: none;
  position: absolute;
  right: 8px;
  top: 6px;
  font-size: 20px;
  line-height: normal;
`;

export const $SelectContainer = styled.div`
  position: relative;
`;

export default $Select;

export enum FeatureFlags {
  payoutsPage = 'payouts-page', // Active. On for specific app_tokens
  paymentsPage = 'payments-page', // Stale. Serving Off for KSA
  reportsPage = 'reports-page', // Active. On for specific app_tokens
  rolloutFinancialReportPage = 'rollout-financial-report-page', // Stale. Serving On
  corporateHlReportDashboard = 'corporate-hl-report-dashboard',
  rolloutMfa = 'rollout-mfa', // Stale. Serving On
  rolloutAccountTypes = 'rollout-account-types', // Stale. Serving On for KSA
  rolloutHyperlinkBankSelection = 'rollout-hyperlink-bank-selection', // Stale. Serving On
  rolloutSkipOtpLoginSetup = 'rollout-skip-otp-login-setup',
  rolloutHyperlinkLinksdkv2 = 'rollout-hyperlink-linksdkv2',
  rolloutOwnerRole = 'rollout-add-owner-role',
}

export enum FeatureFlagsAttributes {
  country = 'country',
  appToken = 'app_token',
  customerId = 'customer_id',
  accessLevel = 'accessLevel',
  organizationId = 'organization_id',
}

import styled from 'styled-components';

export const $MfaWarningNotice = styled.a`
  width: 100%;
  height: 40px;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: ${({ theme }) => theme.font.size.label.lg};
  background: ${({ theme }) => theme.colors.sun._40};

  .caption {
    display: flex;
    align-items: center;
    font-weight: bold;
  }
`;

export const $VerificationWarningNotice = styled.div`
  width: 100%;
  height: 40px;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  font-size: ${({ theme }) => theme.font.size.label.lg};
  background: ${({ theme }) => theme.colors.sun._40};
`;

export const $WarningIcon = styled.img`
  height: 14px;
  width: 14px;
  margin-right: 5px;
`;

// Dialog components

export const $Name = styled.p`
  font-size: 16px;
  margin: 0;
  color: ${({ theme }) => theme.colors.black};
`;

export const $Email = styled.span`
  font-size: 14px;
  text-transform: none;
  color: ${({ theme }) => theme.colors.neutral._60};
`;

export const $UserInfoRow = styled.div`
  padding-top: 22px;
  padding-bottom: 30px;
`;

export const $DialogBody = styled.div`
  height: 100%;
  max-height: 55vh;
  overflow-y: scroll;
`;

export const $Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: ${({ theme }) => theme.font.size.body.sm};
  padding-top: 10px;

  .error-message {
    color: ${({ theme }) => theme.colors.coral._50};
  }
`;

export const $Header = styled.p`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.black};
  padding: 0 80px;
  margin-bottom: 25px;
`;

export const $OverflowContainer = styled.div`
  height: 100%;
  padding: 0 80px;
  overflow-y: scroll;
`;

import PropTypes from 'prop-types';
import React from 'react';
import { $FormGroup } from 'styles';
import { styledFormGroupPropsPropTypes } from 'utils/proptypes';

// Usage
// FormGroup expects two elems
// $Label + whatevs
// <FormGroup>
//   <$Label>Label</$Label>
//   <div>
//     <button>Button</button>
//     <button>Button</button>
//   </div>
// </FormGroup>

// Or, a single React component which equates to the above
// <FormGroup>
//   <TextField label="label" name="name" />
// </FormGroup>

// eslint-disable-next-line react/jsx-props-no-spreading
const FormGroup = ({ children, styledProps }) => <$FormGroup {...styledProps}>{children}</$FormGroup>;

FormGroup.propTypes = {
  styledProps: styledFormGroupPropsPropTypes,
  children: PropTypes.node.isRequired,
};

FormGroup.defaultProps = {
  styledProps: {
    align: 'absolute',
    variant: 'horizontal',
  },
};

export default FormGroup;

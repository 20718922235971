import React from 'react';
import QueryString from 'query-string';
import { getLanguageCode, getLanguageDirection, i18n } from 'i18n';
import { Button } from 'components';
import { getHistory } from 'modules/history';
import { useTypedSelector } from 'types/global';
import { selectHyperlink } from 'modules/hyperlink';
import { LANGUAGE__DIR_LTR } from 'modules/shared/language';
import featureFlags, { FeatureFlags } from 'modules/featureFlags';

import {
  $HyperlinkBankImage,
  $HyperlinkLayoutBody,
  $HyperlinkPageParagraph,
  $HyperlinkPageTitle,
} from './hyperlink.styled';
import { hyperlinkUserSteps } from './constants';
import HyperlinkContainer from './HyperlinkContainer';
import { getAccountType, getAppName } from './utils';

function GetStarted() {
  const history = getHistory();
  const { data } = useTypedSelector(selectHyperlink);
  const languageCode = getLanguageCode();
  const appName = getAppName(data, languageCode);
  const queryParams = QueryString.parse(window.location.search);
  const accountType = getAccountType(data);

  const handleNextStep = () => {
    let nextStep = hyperlinkUserSteps.LinkAccount.name;

    if (featureFlags.isOn(FeatureFlags.rolloutHyperlinkBankSelection)) {
      nextStep = hyperlinkUserSteps.BankSelection.name;
    }

    const newParams = {
      ...queryParams,
      step: nextStep,
    };
    history.push({ search: `${QueryString.stringify(newParams)}` });
  };

  return (
    <HyperlinkContainer>
      <$HyperlinkLayoutBody>
        <$HyperlinkBankImage>
          <img src="/images/hyperlink/bank.svg" alt="bank logo" />
        </$HyperlinkBankImage>

        <$HyperlinkPageTitle data-cy="hyperlink_start_a_bank_check">
          {i18n('hyperlink.getting_started.title')}
        </$HyperlinkPageTitle>
        <$HyperlinkPageParagraph>
          {i18n(
            `hyperlink.getting_started.description_${accountType}`.toLowerCase(),
            appName,
          )}
        </$HyperlinkPageParagraph>

        <div>
          <Button
            data-cy="hyperlink_get_started_button"
            className="get-started-button"
            iconEnd={
              getLanguageDirection() === LANGUAGE__DIR_LTR
                ? 'arrow-forward-outline'
                : 'arrow-back-outline'
            }
            onClick={handleNextStep}
          >
            {i18n('hyperlink.getting_started.get_started')}
          </Button>
        </div>
      </$HyperlinkLayoutBody>
    </HyperlinkContainer>
  );
}

export default GetStarted;

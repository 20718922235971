/* eslint-disable camelcase */
import { RootState } from 'modules/store';
import { TypedUseSelectorHook, useSelector } from 'react-redux';

// @TODO: update FixMeLater type to unknown and fix all usages
export type FixMeLater = any;

export interface PlainObject {
  [key: string]: unknown;
}

export interface FilterObjectType {
  [key: string]: {
    label: string;
    value: string;
  };
}

export interface ErrorObject {
  data: {
    error: string;
    message: string;
    status?: string;
    status_code?: string;
  };
  response: {
    status: number;
    statusText: string;
  };
}

export interface Application {
  id: string;
  name: string;
  legal_name: string;
  description: string;
  link_redirect_url: string;
  common_name: string;
  bank_type: string;
  webhook_shared_secret: string;
  active: boolean;
  logo_url: string | null;
  sandbox_user_limit: number;
  organization_id: number;
}

export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

export interface CurrentUser {
  id: number;
  email: string;
  roles: string[];
  username: string;
  principal: string;
  last_name: string;
  disabled: boolean;
  first_name: string;
  phone_number: string;
  email_verified: boolean;
  organization_name: string;
  organization_address: string;
  mfa_settings: {
    status: string;
  };
}

const backendUrl = `https://app.ae03.leantech.me`;
const sandboxUrl = `https://app.sandbox.ae03.leantech.me`;
const url = `https://dev.ae03.leantech.me`;
const hostname = `dev.ae03.leantech.me`;
const apmUrl = 'https://apm.ae03.leantech.me';

const apiSandboxUrl = `https://sandbox.ae03.leantech.me`;
const oauthUrl = `https://auth.ae03.leantech.me`;
const oauthSandboxUrl = `https://auth.ae03.leantech.me`;

const linkSDKCDN = `https://cdn.leantech.me/link/sdk/web/dev/ae/latest/Lean.min.js`;

const ae03 = {
  backendUrl,
  apiSandboxUrl,
  linkSDKCDN,
  sandboxUrl,
  url,
  hostname,
  apmUrl,
  oauthUrl,
  oauthSandboxUrl
};

export default ae03;

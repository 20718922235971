import styled from 'styled-components';
import { $ChildrenWrapper } from 'styles/components/Button';

export const $List = styled.div`
  height: 100%;
  overflow-y: auto;

  button {
    color: ${({ theme }) => theme.colors.black};
    font-size: ${({ theme }) => theme.font.size.body.md};
    width: 100%;
    height: auto;
    padding: 0;
    border-radius: 0;

    &:hover,
    &:active {
      color: ${({ theme }) => theme.colors.black};
      text-decoration: none;
    }

    ${$ChildrenWrapper} {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: ${({ theme }) => theme.layout.spacing.sm} 0;
      border-bottom: 1px solid ${({ theme }) => theme.colors.neutral._30};
    }

    .ion-icon {
      text-align: right;
    }
  }
`;

export const $Name = styled.div`
  flex: 1;
  text-align: left;
`;

export const $Spot = styled.div`
  border-radius: 50%;
  height: 32px;
  width: 32px;
  background: ${({ theme }) => theme.colors.neutral._80};
  margin-right: ${({ theme }) => theme.layout.spacing.xxs};
  color: ${({ theme }) => theme.colors.blender._10};
  border: 1px solid ${({ theme }) => theme.colors.black30};
  font-size: ${({ theme }) => theme.font.size.label.xs};
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 0 0 auto;
`;

import React from 'react';
import Lottie from 'lottie-react';
import QueryString from 'query-string';
import { getLanguageCode, i18n } from 'i18n';
import { Button } from 'components';
import { getHistory } from 'modules/history';
import { useTypedSelector } from 'types/global';
import { selectHyperlink } from 'modules/hyperlink';
import featureFlags, { FeatureFlags } from 'modules/featureFlags';

import {
  $HyperlinkLayoutBody,
  $HyperlinkPageParagraph,
  $HyperlinkPageTitle,
  $HyperlinkRedirectImage,
} from './hyperlink.styled';
import { hyperlinkUserSteps } from './constants';

import HyperlinkContainer from './HyperlinkContainer';
import failedAnimation from './animations/animation-failed-bank.json';
import successAnimation from './animations/animation-success-bank.json';
import { getAppName } from './utils';

function LinkStatus({ isSuccessful = false }: { isSuccessful?: boolean }) {
  const history = getHistory();
  const { data } = useTypedSelector(selectHyperlink);
  const languageCode = getLanguageCode();
  const appName = getAppName(data, languageCode);
  const queryParams = QueryString.parse(window.location.search);

  const pageData = isSuccessful
    ? {
        title: i18n('hyperlink.link_successful.title'),
        subtitle: i18n('hyperlink.link_successful.subtitle'),
        animation: successAnimation,
      }
    : {
        title: i18n('hyperlink.link_failed.title'),
        subtitle: i18n('hyperlink.link_failed.subtitle', appName),
        animation: failedAnimation,
      };

  const gotoInitialScreen = () => {
    const newParams = {
      ...queryParams,
      step: hyperlinkUserSteps.GettingStarted.name,
    };
    history.push({ search: `${QueryString.stringify(newParams)}` });
  };

  const gotoLinkScreen = () => {
    let nextStep = hyperlinkUserSteps.LinkAccount.name;

    if (featureFlags.isOn(FeatureFlags.rolloutHyperlinkBankSelection)) {
      nextStep = hyperlinkUserSteps.BankSelection.name;
    }

    const newParams = {
      ...queryParams,
      step: nextStep,
    };
    history.push({ search: `${QueryString.stringify(newParams)}` });
  };

  return (
    <HyperlinkContainer>
      <$HyperlinkLayoutBody className="text-center">
        <$HyperlinkRedirectImage>
          <Lottie animationData={pageData.animation} loop />
        </$HyperlinkRedirectImage>

        <$HyperlinkPageTitle
          className={`text-center ${isSuccessful && 'status-page-header'}`}
        >
          {pageData.title}
        </$HyperlinkPageTitle>
        <$HyperlinkPageParagraph className="text-center">
          {pageData.subtitle}
        </$HyperlinkPageParagraph>

        <div>
          {isSuccessful ? (
            <Button
              className="connect-again-btn"
              data-cy="hyperlink_connect_again_btn"
              onClick={gotoLinkScreen}
            >
              {i18n('hyperlink.link_successful.connect_another_account')}
            </Button>
          ) : (
            <Button iconEnd="reload" onClick={gotoInitialScreen}>
              {i18n('hyperlink.link_failed.try_again')}
            </Button>
          )}
        </div>
      </$HyperlinkLayoutBody>
    </HyperlinkContainer>
  );
}

export default LinkStatus;

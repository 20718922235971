// Note: Do not remove the line below as it is required for automated code generation to inject reducers
/* CODEGEN_INJECT_REDUCER_IMPORT */
import reports from 'modules/reports';
import payments from 'modules/payments';
import hyperlink from 'modules/hyperlink';
import customers from 'modules/customers';
import avs from 'modules/avs';
import { organisation } from 'modules/organisation';

import global from 'modules/global';
import { team } from 'modules/team';
import payouts from 'modules/payouts';
import { history } from 'modules/history';
import bootstrap from 'modules/bootstrap';
import { webhooks } from 'modules/webhooks';
import { deposits } from 'modules/deposits';
import auth from 'modules/auth/authReducers';
import entities from 'modules/billing/entities';
import layout from 'modules/layout/layoutReducers';
import { combineReducers } from '@reduxjs/toolkit';
import gettingStarted from 'modules/gettingStarted';
import dataRequests from 'modules/logs/dataRequests';
import billingPayments from 'modules/billing/payments';
import { connectRouter } from 'connected-react-router';
import { reconciliations } from 'modules/reconciliation';
import banksAvailability from 'modules/banksAvailability';
import certificate from 'modules/certificate/certificateReducer';
import application from 'modules/application/applicationReducers';
import consentExplanation from 'modules/consentExplanation';

const createRootReducer = () =>
  combineReducers({
    router: connectRouter(history),
    root: appReducer,
  });

const appReducer = combineReducers({
  auth,
  application,
  banksAvailability,
  billing: combineReducers({
    entities,
    payments: billingPayments,
  }),
  bootstrap,
  certificate,
  deposits,
  gettingStarted,
  global,
  layout,
  logs: combineReducers({
    dataRequests,
  }),
  payouts,
  reconciliations,
  team,
  webhooks,
  customers,
  payments,
  reports,
  hyperlink,
  consentExplanation,
  avs,
  organisation,

  // Note: Do not remove the line below as it is required for automated code generation to inject reducers
  /* CODEGEN_INJECT_REDUCER_TO_APP_REDUCER */
});

export default createRootReducer;

import styled from 'styled-components';
import { $Button } from 'styles';

export const $ButtonGroup = styled.div`
  padding: 2px;
  display: flex;
  gap: 4px;
  background-color: ${({ theme }) => theme.colors.neutral._30};
  border-radius: 8px;
  width: 100%;
  max-width: 300px;

  ${$Button} {
    padding-left: 7px;
    padding-right: 7px;
    justify-content: center;
    width: 100%;
    height: 28px;
    min-height: 28px;
  }

  ${$Button} + ${$Button} {
    margin-left: 0;
    margin-right: 0;
  }
`;

import { v4 as uuid } from 'uuid';
import { RootState } from 'modules/store';
import { createSlice } from '@reduxjs/toolkit';

import Message from 'view/shared/message';

import {
  CustomerBanksData,
  CustomerData,
  CustomerReportsData,
  CustomersState,
  ReportExpenseCategory,
  TransactionsCategoryMap,
  TransactionsCategoryType,
} from './reports.types';
import {
  createCustomerAction,
  generateCustomerReportAction,
  getCustomerDetailsAction,
  getCustomersAction,
  getReportCashflow,
  getReportCreditCards,
  getReportDetailsById,
  getReportEvents,
  getReportExpensesBreakdown,
  getReportFinancesAndLoans,
  getReportStats,
  getReportTransactions,
  getSalaryTransactions,
  refreshCustomerEntity,
} from './reports.actions';

const initialState: CustomersState = {
  data: [],
  total: 0,
  error: '',
  loading: true,
  fetchingCustomer: true,
  reFetchingCustomer: true,
  creatingCustomer: false,
  isGeneratingReport: false,
  customer: {
    id: '',
    app_user_id: '',
    state: '',
    created_at: '',
    user_portal_link: '',
    banks: [],
    reports: [],
    account_holder_name: null,
    account_holder_abbreviated_name: null,
    customer_account_type: null,
  },
  isLoadingReport: false,
  report: null,
  isLoadingTransactions: false,
  transactions: null,
  corporateTransactions: {
    credit_card: null,
    inward_transactions: null,
    outward_transactions: null,
    loans_repayments: null,
    loans_received: null,
  },
  isLoadingCorporateTransactions: {
    credit_card: false,
    inward_transactions: false,
    outward_transactions: false,
    loans_repayments: false,
    loans_received: false,
  },
  isLoadingSalaryTransactions: false,
  salaryTransactions: null,
  isLoadingCashflow: false,
  cashflow: null,
  isLoadingLoans: false,
  loans: null,
  isLoadingCreditCards: false,
  creditCards: null,
  isLoadingStats: false,
  stats: null,
  isLoadingExpensesBreakdown: false,
  expensesBreakdown: null,
  isLoadingEvents: false,
  events: null,
  isRefreshingCustomerEntity: false,
  customerEntityRefreshStatus: null,
};

const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getCustomersAction.pending, state => {
      state.loading = true;
    });
    builder.addCase(getCustomersAction.fulfilled, (state, { payload }) => {
      const customers = payload.customers.content || [];
      const total = payload.customers.total_elements || 0;

      return {
        ...state,
        total,
        loading: false,
        data: customers.map((customer: CustomerData) => ({
          ...customer,
          id: customer.app_user_id,
        })),
      };
    });
    builder.addCase(getCustomersAction.rejected, state => {
      state.loading = false;
      state.error = 'Failed to load customers';
    });

    builder.addCase(
      getCustomerDetailsAction.pending,
      (
        state,
        {
          meta: {
            arg: { isReFetching },
          },
        },
      ) => {
        if (isReFetching) {
          state.reFetchingCustomer = true;
        } else {
          state.fetchingCustomer = true;
        }
      },
    );
    builder.addCase(
      getCustomerDetailsAction.fulfilled,
      (state, { payload }) => {
        return {
          ...state,
          fetchingCustomer: false,
          reFetchingCustomer: false,
          customer: {
            ...payload,
            id: payload.app_user_id,
            banks: payload.banks.map((bank: CustomerBanksData) => ({
              ...bank,
              id: uuid(),
            })),
            reports: Array.isArray(payload.reports)
              ? payload.reports.map(
                  (report: CustomerReportsData, rowId: number) => ({
                    ...report,
                    id: (rowId + 1).toString().padStart(7, '0'),
                  }),
                )
              : [],
          },
        };
      },
    );
    builder.addCase(getCustomerDetailsAction.rejected, state => {
      state.fetchingCustomer = false;
      state.reFetchingCustomer = false;
      state.error = 'Failed to load customer details';
    });

    builder.addCase(createCustomerAction.pending, state => {
      state.creatingCustomer = true;
    });
    builder.addCase(createCustomerAction.fulfilled, (state, { payload }) => {
      state.creatingCustomer = false;

      navigator.clipboard
        .writeText(payload.user_portal_link)
        .then(() =>
          Message.success(
            'Your customer was created',
            'The HyperLink is on your clipboard',
          ),
        );
    });
    builder.addCase(createCustomerAction.rejected, state => {
      state.creatingCustomer = false;
      state.error = 'Failed to create customer';
    });

    builder.addCase(generateCustomerReportAction.pending, state => {
      state.isGeneratingReport = true;
    });
    builder.addCase(generateCustomerReportAction.fulfilled, state => {
      state.isGeneratingReport = false;

      Message.success('Report generated.');
    });
    builder.addCase(generateCustomerReportAction.rejected, state => {
      state.isGeneratingReport = false;
      state.error = 'Failed to generate report';
    });

    builder.addCase(getReportDetailsById.pending, state => {
      state.isLoadingReport = true;
    });
    builder.addCase(getReportDetailsById.fulfilled, (state, { payload }) => {
      state.isLoadingReport = false;
      state.report = payload;
    });
    builder.addCase(getReportDetailsById.rejected, state => {
      state.isLoadingReport = false;
    });

    builder.addCase(getReportTransactions.pending, (state, { meta }) => {
      const type = meta.arg?.type as TransactionsCategoryType | undefined;

      if (type && type in TransactionsCategoryMap) {
        const key = TransactionsCategoryMap[type];
        state.isLoadingCorporateTransactions[key] = true;
      } else {
        state.isLoadingTransactions = true;
      }
    });
    builder.addCase(
      getReportTransactions.fulfilled,
      (state, { payload, meta }) => {
        const { content } = payload.transactions;
        const type = meta.arg?.type as TransactionsCategoryType | undefined;

        if (type && type in TransactionsCategoryMap) {
          const key = TransactionsCategoryMap[type];
          // @ts-ignore
          state.corporateTransactions[key] = {
            ...payload.transactions,
            content,
          };
          // @ts-ignore
          state.isLoadingCorporateTransactions[key] = false;
        } else {
          state.transactions = {
            ...payload.transactions,
            content,
          };
          state.isLoadingTransactions = false;
        }
      },
    );
    builder.addCase(getReportTransactions.rejected, (state, { meta }) => {
      const type = meta.arg?.type as TransactionsCategoryType | undefined;

      if (type && type in TransactionsCategoryMap) {
        const key = TransactionsCategoryMap[type];
        state.isLoadingCorporateTransactions[key] = false;
      } else {
        state.isLoadingTransactions = false;
      }
    });

    // Salary transactions
    builder.addCase(getSalaryTransactions.pending, state => {
      state.isLoadingSalaryTransactions = true;
    });
    builder.addCase(getSalaryTransactions.fulfilled, (state, { payload }) => {
      state.isLoadingSalaryTransactions = false;
      state.salaryTransactions = payload.transactions;
    });
    builder.addCase(getSalaryTransactions.rejected, state => {
      state.isLoadingSalaryTransactions = false;
    });
    builder.addCase(getReportCashflow.pending, state => {
      state.isLoadingCashflow = true;
    });
    builder.addCase(getReportCashflow.fulfilled, (state, { payload }) => {
      state.isLoadingCashflow = false;
      state.cashflow = payload;
    });

    builder.addCase(getReportFinancesAndLoans.pending, state => {
      state.isLoadingLoans = true;
    });
    builder.addCase(
      getReportFinancesAndLoans.fulfilled,
      (state, { payload }) => {
        state.isLoadingLoans = false;
        state.loans = payload;
      },
    );
    builder.addCase(getReportFinancesAndLoans.rejected, state => {
      state.isLoadingLoans = false;
    });
    builder.addCase(getReportCreditCards.pending, state => {
      state.isLoadingCreditCards = true;
    });
    builder.addCase(getReportCreditCards.fulfilled, (state, { payload }) => {
      state.isLoadingCreditCards = false;
      state.creditCards = payload;
    });
    builder.addCase(getReportCreditCards.rejected, state => {
      state.isLoadingCreditCards = false;
    });
    builder.addCase(getReportCashflow.rejected, state => {
      state.isLoadingCashflow = false;
    });
    builder.addCase(getReportStats.pending, state => {
      state.isLoadingStats = true;
    });
    builder.addCase(getReportStats.fulfilled, (state, { payload }) => {
      state.isLoadingStats = false;
      state.stats = payload;
    });
    builder.addCase(getReportStats.rejected, state => {
      state.isLoadingStats = false;
    });

    builder.addCase(getReportExpensesBreakdown.pending, state => {
      state.isLoadingExpensesBreakdown = true;
    });
    builder.addCase(
      getReportExpensesBreakdown.fulfilled,
      (state, { payload }) => {
        const defaultColorForUndefined = '#ECECF4';
        const categoryColours = [
          '#FFC20A',
          '#0C7BDC',
          '#994F00',
          '#006CD1',
          '#E1BE6A',
          '#40B0A6',
          '#E66100',
          '#5D3A9B',
          '#1AFF1A',
          '#4B0092',
          '#FEFE62',
          '#D35FB7',
          '#005AB5',
          '#DC3220',
          '#1A85FF',
          '#D41159',
        ];

        let indexForOtherCategories = 0;

        state.isLoadingExpensesBreakdown = false;
        state.expensesBreakdown = payload.map(
          ({ category, ...rest }: ReportExpenseCategory) => {
            const isCategoryUndefined = category === 'UNDEFINED';

            return {
              ...rest,
              id: uuid(),
              category: isCategoryUndefined ? 'Other' : category,
              colour: isCategoryUndefined
                ? defaultColorForUndefined
                : // eslint-disable-next-line no-plusplus
                  categoryColours[indexForOtherCategories++],
            };
          },
        );
      },
    );
    builder.addCase(getReportExpensesBreakdown.rejected, state => {
      state.isLoadingExpensesBreakdown = false;
    });
    builder.addCase(getReportEvents.pending, state => {
      state.isLoadingEvents = true;
    });
    builder.addCase(getReportEvents.fulfilled, (state, { payload }) => {
      state.isLoadingEvents = false;
      state.events = payload;
    });
    builder.addCase(getReportEvents.rejected, state => {
      state.isLoadingEvents = false;
    });
    builder.addCase(refreshCustomerEntity.pending, state => {
      state.isRefreshingCustomerEntity = true;
    });
    builder.addCase(refreshCustomerEntity.fulfilled, (state, { payload }) => {
      state.isRefreshingCustomerEntity = false;
      state.customerEntityRefreshStatus = payload;
    });
    builder.addCase(refreshCustomerEntity.rejected, state => {
      state.isRefreshingCustomerEntity = false;
    });
  },
});

export default reportsSlice.reducer;
export const selectReports = (state: RootState) => state.root.reports;

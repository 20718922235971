import Errors from '../shared/error/errors';
import TeamService from './teamService';
import Message from '../../view/shared/message';
import { i18n } from '../../i18n';

const prefix = 'dev-portal/team';

export const actions = {
  GET_MEMBERS_LIST_STARTED: `${prefix}/GET_MEMBERS_LIST_STARTED`,
  GET_MEMBERS_LIST_SUCCESS: `${prefix}/GET_MEMBERS_LIST_SUCCESS`,
  GET_MEMBERS_LIST_ERROR: `${prefix}/GET_MEMBERS_LIST_ERROR`,

  ADD_MEMBERS_STARTED: `${prefix}/ADD_MEMBERS_STARTED`,
  ADD_MEMBERS_SUCCESS: `${prefix}/ADD_MEMBERS_SUCCESS`,
  ADD_MEMBERS_ERROR: `${prefix}/ADD_MEMBERS_ERROR`,

  UPDATE_MEMBER_STARTED: `${prefix}/UPDATE_MEMBER_STARTED`,
  UPDATE_MEMBER_SUCCESS: `${prefix}/UPDATE_MEMBER_SUCCESS`,
  UPDATE_MEMBER_ERROR: `${prefix}/UPDATE_MEMBER_ERROR`,

  REMOVE_MEMBER_STARTED: `${prefix}/REMOVE_MEMBER_STARTED`,
  REMOVE_MEMBER_SUCCESS: `${prefix}/REMOVE_MEMBER_SUCCESS`,
  REMOVE_MEMBER_ERROR: `${prefix}/REMOVE_MEMBER_ERROR`,

  CANCEL_MEMBER_INVITE_STARTED: `${prefix}/CANCEL_MEMBER_INVITE_STARTED`,
  CANCEL_MEMBER_INVITE_SUCCESS: `${prefix}/CANCEL_MEMBER_INVITE_SUCCESS`,
  CANCEL_MEMBER_INVITE_ERROR: `${prefix}/CANCEL_MEMBER_INVITE_ERROR`,


  doGetTeamMembers: () => async dispatch => {
    try {
      dispatch({
        type: actions.GET_MEMBERS_LIST_STARTED,
      });

      const members = await TeamService.getTeamMembers();

      dispatch({
        type: actions.GET_MEMBERS_LIST_SUCCESS,
        payload: members,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: actions.GET_MEMBERS_LIST_ERROR,
      });
    }
  },

  doInviteMembers: values => async dispatch => {
    try {
      dispatch({
        type: actions.ADD_MEMBERS_STARTED,
      });
      await TeamService.inviteMembers({
        values,
      });
      dispatch({
        type: actions.ADD_MEMBERS_SUCCESS,
      });
      Message.success(i18n('team.members.invite_members.success'));
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: actions.ADD_MEMBERS_ERROR,
      });
    }
  },

  doCancelInvite: (inviteId) => async dispatch => {
    try {
      dispatch({
        type: actions.CANCEL_MEMBER_INVITE_STARTED
      });
      if (!inviteId || !isNaN(inviteId)) {
        dispatch({
          type: actions.CANCEL_MEMBER_INVITE_ERROR
        })
      }
      await TeamService.cancelMemeberInvite({
        inviteId
      });
      dispatch({
        type: actions.CANCEL_MEMBER_INVITE_SUCCESS,
      });
      Message.success(i18n('team.members.cancel_invite.success'));
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: actions.CANCEL_MEMBER_INVITE_ERROR,
      });
    }
  },

  doUpdateMemberRoles: (principalId, roles) => async dispatch => {
    try {
      dispatch({
        type: actions.UPDATE_MEMBER_STARTED,
      });
      await TeamService.updateMemberRoles({
        principalId,
        body: roles,
      });
      dispatch({
        type: actions.UPDATE_MEMBER_SUCCESS,
      });
      Message.success(i18n('team.members.update_member.success'));
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: actions.UPDATE_MEMBER_ERROR,
      });
    }
  },

  doRemoveMember: principalId => async dispatch => {
    try {
      dispatch({
        type: actions.REMOVE_MEMBER_STARTED,
      });
      await TeamService.deleteMember({
        principalId,
      });
      dispatch({
        type: actions.REMOVE_MEMBER_SUCCESS,
      });
      Message.success(i18n('team.members.remove_member.success'));
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: actions.REMOVE_MEMBER_ERROR,
      });
    }
  },
};

import styled, { css } from 'styled-components/macro';

export const linkStyles = css`
  color: ${({ theme }) => theme.colors.trust._60};
  transition: none;

  &:hover {
    color: ${({ theme }) => theme.colors.trust._70};
    text-decoration: underline;
  }

  &:focus {
    box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.focus};
    border-radius: 4px;
    outline: none;
  }
`;

const $Link = styled.a`
  ${linkStyles}
`;

export default $Link;

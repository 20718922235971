import config from 'config';
import { apiFetch } from 'modules/shared/fetch';
import { BankData } from './hyperlink.types';

export default class HyperlinkService {
  static async getTppData({
    sandbox,
    customerId,
    appEnvironment,
  }: {
    sandbox: boolean;
    customerId: string;
    appEnvironment: string;
  }) {
    const baseURL = sandbox
      ? config.hyperlinkSandboxBackendUrl
      : config.hyperlinkBackendUrl;

    const response = await apiFetch(`/customers/${customerId}/tpp-data`, {
      // @ts-ignore
      baseURL,
      appEnvironment,
      noCustomHeader: true,
    });

    return response?.data;
  }

  static async getBanks({
    sandbox,
    appToken,
    customerId,
    accountType,
  }: {
    sandbox: boolean;
    appToken: string;
    customerId: string;
    accountType: string | null;
  }): Promise<BankData[]> {
    const baseURL = sandbox ? config.linkSandboxApiUrl : config.linkApiUrl;
    const response = await window.fetch(
      `${baseURL}/banks/v1?account_types=${accountType}`,
      {
        headers: {
          'Content-Type': 'application/json',
          'lean-app-token': appToken,
          'lean-link-sandbox': String(sandbox),
          'lean-customer-id': customerId,
        },
      },
    );
    return response.json();
  }

  static async postBankRequest({
    data,
    sandbox,
    customerId,
    appEnvironment,
  }: {
    data: object;
    sandbox: boolean;
    customerId: string;
    appEnvironment: string;
  }) {
    const baseURL = sandbox
      ? config.hyperlinkSandboxBackendUrl
      : config.hyperlinkBackendUrl;

    const response = await apiFetch(`/customers/${customerId}/events`, {
      // @ts-ignore
      baseURL,
      method: 'POST',
      body: data,
      appEnvironment,
      noCustomHeader: true,
    });

    return response?.data;
  }
}

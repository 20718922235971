import { i18n } from 'i18n';
import _values from 'lodash/values';

class Roles {
  static get values() {
    return {
      owner: 'owner',
      editor: 'editor',
      viewer: 'viewer',
      auditLogViewer: 'auditLogViewer',
      iamSecurityReviewer: 'iamSecurityReviewer',
      entityEditor: 'entityEditor',
      entityViewer: 'entityViewer',
      applicationEditor: 'applicationEditor',
      applicationViewer: 'applicationViewer',
      certificateEditor: 'certificateEditor',
      certificateViewer: 'certificateViewer',
    };
  }

  static get userRoles() {
    return {
      OWNER: 'OWNER',
      ADMIN: 'ADMIN',
      COMPLIANCE: 'COMPLIANCE',
      LEAD_DEVELOPER: 'LEAD_DEVELOPER',
      DEVELOPER: 'DEVELOPER',
      SUPPORT: 'SUPPORT',
      FINANCE: 'FINANCE',
      PAYOUTS: 'PAYOUTS',
      OPERATIONS: 'OPERATIONS',
    };
  }

  static labelOf(roleId) {
    if (!this.values[roleId]) {
      return roleId;
    }

    return i18n(`roles.${roleId}.label`);
  }

  static descriptionOf(roleId) {
    if (!this.values[roleId]) {
      return roleId;
    }

    return i18n(`roles.${roleId}.description`);
  }

  static get selectOptions() {
    return _values(this.values).map(value => ({
      id: value,
      value,
      title: this.descriptionOf(value),
      label: this.labelOf(value),
    }));
  }
}

export default Roles;

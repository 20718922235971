const backendUrl = `http://localhost:3000`;
const url = `http://localhost:3000`;
const sandboxUrl = `http://localhost:3000/sandbox`;
const hostname = `localhost`;
const oauthUrl = `https://localhost:8080`;
const oauthSandboxUrl = `https://localhost:8080`;
const apiSandboxUrl = `http://localhost:3000/sandbox`;

const localhost = { backendUrl, sandboxUrl, apiSandboxUrl, url, hostname, oauthUrl, oauthSandboxUrl };
export default localhost;

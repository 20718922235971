import 'assets/css/main.css';
import 'assets/css/fonts.css';
import 'assets/css/modal.css';
import 'assets/css/helpers.css';
import 'assets/css/animations.css';
import { theme } from 'styles/abstracts/theme';
import { GlobalStyles } from 'styles/base/GlobalStyles';

import { GrowthBookProvider } from '@growthbook/growthbook-react';
import { Provider } from 'react-redux';
import React from 'react';
import { Helmet } from 'react-helmet';
import { getHistory } from 'modules/history';
import { initializeStore } from 'modules/store';
import { ThemeProvider } from 'styled-components';
import featureFlags from 'modules/featureFlags';
import { ErrorBoundary } from 'react-error-boundary';
import { ConnectedRouter } from 'connected-react-router';
import { FullScreenError, LinkSDK, Toaster } from 'components';
import RoutesComponent from 'view/shared/routes/RoutesComponent';
import { elasticApm } from 'modules/elasticApm/elasticApm';

const store = initializeStore();
elasticApm.initialize();

const getEmailAndAppName = () => {
  const { root } = store.getState();
  const { currentUser } = root.auth;

  return {
    email: currentUser.email,
    appName: currentUser.organization_name,
  };
};

const getErrorStateContext = () => {
  const { root, router } = store.getState();
  const { currentUser } = root.auth;
  const { environment } = root.global;
  const { pathname } = router.location;

  return {
    email: currentUser.email,
    appName: currentUser.organization_name,
    environment,
    pathname,
  };
};

const injectLinkSDK = () => {
  // Include LinkSDK globally, except for HL.
  // Temp, can be removed when Hyperlink is a separate codebase
  const { router } = store.getState();
  const path = router.location.pathname;

  if (path) {
    const isPathHyperlink = path.split('/')[1] === 'hyperlink';

    return isPathHyperlink ? null : <LinkSDK />;
  }

  return null;
};

const App = () => {
  return (
    <GrowthBookProvider growthbook={featureFlags.growthbook}>
      <Helmet>
        <title>Lean | Application Dashboard</title>
      </Helmet>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <GlobalStyles />
          <Toaster />
          {injectLinkSDK()}
          <ErrorBoundary
            FallbackComponent={() => (
              <FullScreenError
                email={getEmailAndAppName().email}
                appName={getEmailAndAppName().appName}
                text="Something went wrong. We're working on fixing this issue, please try again later."
                isCloseButton
              />
            )}
            onError={(error, info) => {
              // change to proper error tracking software (sentery, bugsnag, etc)
              window.analytics.track('BoundaryError', {
                error,
                info,
                ...getErrorStateContext(),
              });
            }}
          >
            <ConnectedRouter history={getHistory()}>
              <RoutesComponent />
            </ConnectedRouter>
          </ErrorBoundary>
        </ThemeProvider>
      </Provider>
    </GrowthBookProvider>
  );
};

export default App;

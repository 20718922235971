import React from 'react';
import Lottie from 'lottie-react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import leanLogo from './lean-logo-animated.json';

const $Wrapper = styled.div`
  margin: 0 auto;

  height: ${({ height }) => height}px;
  width: ${({ width }) => width}px;
`;

const LeanLogoAnimated = ({ size, className }) => (
  <$Wrapper height={size + 2} width={size} className={className}>
    <Lottie animationData={leanLogo} loop />
  </$Wrapper>
);

LeanLogoAnimated.propTypes = {
  size: PropTypes.number,
  className: PropTypes.string,
};

LeanLogoAnimated.defaultProps = {
  size: 32,
  className: '',
};
export default LeanLogoAnimated;

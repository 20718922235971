// @ts-nocheck
import styled, { css } from 'styled-components/macro';
import { math } from 'polished';

// Table
export const $Table = styled.table`
  border: none;
  border-spacing: 0;
  border-collapse: inherit;

  tbody tr:first-child td {
    border-top: none;
  }

  flex: 1;
`;

export const $TableHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const $TableHeadCell = styled.th`
  color: ${({ theme }) => theme.colors.neutral._60};
  font-size: ${({ theme }) => theme.font.size.label.lg};
  background-color: ${({ theme }) => theme.colors.white};
  font-weight: normal;
  padding: 0 8px;
  height: 40px;
  text-transform: capitalize;

  border-bottom: 1px solid ${({ theme }) => theme.colors.black20};

  ${({ headerCellStyleProps }) => headerCellStyleProps};

  :first-child {
    padding-left: 16px;
  }

  :last-child {
    padding-right: 16px;
  }
`;

export const $TableHeadGroupCell = styled.th`
  color: ${({ theme }) => theme.colors.neutral._60};
  font-size: ${({ theme }) => theme.font.size.label.md};
  font-weight: normal;
  padding: 0 8px;
  height: 28px;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  text-align: start;
`;

export const $TableHeaderIcon = styled.div`
  display: flex;
  padding: 2px;
  width: 16px;
  height: 16px;
  border-radius: 50px;
  cursor: pointer;
  margin-right: 4px;

  :hover {
    color: ${({ theme }) => theme.colors.black};
    background-color: ${({ theme }) => theme.colors.neutral._20};
  }

  &.initiated {
    color: ${({ theme }) => theme.colors.black};
  }
`;

export const $TableCheckbox = styled.div`
  // selects $FieldContainer component wrapper
  div:first-child {
    padding: 0;
  }

  input[type='checkbox'] ~ label {
    // override default input label padding
    padding-left: 16px !important;
  }
`;

export const $TableCell = styled.td`
  font-size: ${({ theme }) => theme.font.size.body.sm};
  height: 48px;
  padding: 0 8px;
  white-space: nowrap;

  :first-child {
    padding-left: 16px;
  }

  :last-child {
    padding-right: 16px;
  }
`;

export const clickableRowStyles = css`
  cursor: pointer;
`;

export const onHoverRowStyles = css`
  background-color: ${({ theme }) => theme.colors.neutral._10};
`;

const selectedRowStyles = css`
  background: linear-gradient(
      0deg,
      rgba(0, 128, 255, 0.08),
      rgba(0, 128, 255, 0.08)
    ),
    ${({ theme }) => theme.colors.white};

  // remove border top from next selected element
  + .is-selected td {
    border-top: none;
  }
`;

export const $TableRow = styled.tr`
  td {
    border-top: 1px solid ${({ theme }) => theme.colors.neutral.primary};
  }

  ${({ isClickable }) => isClickable && clickableRowStyles};

  &.is-selected {
    ${selectedRowStyles}
  }

  &:hover {
    ${onHoverRowStyles}
  }
`;

export const $TableRowPanel = styled.div`
  overflow: hidden;
  height: ${({ isOpen }) => (isOpen ? 'auto' : 0)};

  transition: height 0.2s ease-out;
`;

const stickyPaginationStyles = css`
  position: sticky;
  bottom: 0;
`;

export const $PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
  padding: 16px 0 32px;
  background-color: ${({ theme }) => theme.colors.white};

  ${({ stickyPagination }) => stickyPagination && stickyPaginationStyles};
`;

export const $TableContainer = styled.div`
  /* to preserve the location of pagination component for better UX */
  /* min-height: ${({ pageSize, isSuperHeader, isPaginatedTable }) =>
    // if table has pagination don't set min-height,
    // if not, calculate based on number of items in page (pageSize)
    isPaginatedTable
      ? math(`(40px * (${pageSize} + 1)) + ${isSuperHeader ? '28px' : '0'}`)
      : 0}; */
  display: flex;
`;

export const $CustomComponentsRow = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 24px 0;
`;
export const $LeftCustomComponent = styled.div`
  display: flex;
`;
export const $RightCustomComponent = styled.div`
  display: flex;
`;

import list from 'modules/application/list/applicationListReducers';
import form from 'modules/application/form/applicationFormReducers';
import view from 'modules/application/view/applicationViewReducers';
import accountType from 'modules/application/accountType/applicationAccountType.slice';
import { combineReducers } from '@reduxjs/toolkit';

export default combineReducers({
  list,
  form,
  view,
  accountType,
});

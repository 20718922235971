import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '../form/Checkbox';
import { $TableCell, $TableCheckbox } from './styles';

const TableRowCells = ({
  data,
  rowId,
  titles,
  isRowSelected,
  checkboxSelection,
  customCell: CustomCell,
}) => (
  <>
    {checkboxSelection ? (
      <$TableCell>
        <$TableCheckbox>
          <Checkbox
            isChecked={isRowSelected}
            name={`table-select-row-${rowId}`}
            value={`data-table-checkbox-select-${rowId}`}
            data-cy={`data-table-checkbox-select-${rowId}`}
          />
        </$TableCheckbox>
      </$TableCell>
    ) : null}

    {titles.map(title => {
      const cellContent = data[title];
      const customCellOrRegularCell = CustomCell ? (
        <CustomCell data={cellContent} rowData={data} title={title} />
      ) : (
        cellContent
      );
      return (
        <$TableCell key={title + cellContent}>
          {customCellOrRegularCell}
        </$TableCell>
      );
    })}
  </>
);

TableRowCells.propTypes = {
  rowId: PropTypes.string,
  isRowSelected: PropTypes.bool,
  checkboxSelection: PropTypes.bool,
  customCell: PropTypes.elementType,
  titles: PropTypes.array.isRequired,
  data: PropTypes.shape({}).isRequired,
};

TableRowCells.defaultProps = {
  rowId: '',
  isRowSelected: false,
  customCell: undefined,
  checkboxSelection: false,
};

export default TableRowCells;

import { TppData } from 'modules/hyperlink/hyperlink.types';

export function isArabicLanguageEnabled(languageCode: string | null) {
  return typeof languageCode === 'string' && languageCode.startsWith('ar');
}

export function getAppName(data: TppData, languageCode: string | null) {
  if (
    isArabicLanguageEnabled(languageCode) &&
    data.arabic_name &&
    data.arabic_name.length > 0
  ) {
    return data.arabic_name;
  }
  return data.app_name;
}

export function getLegalName(data: TppData, languageCode: string | null) {
  if (
    isArabicLanguageEnabled(languageCode) &&
    data.arabic_legal_name &&
    data.arabic_legal_name.length > 0
  ) {
    return data.arabic_legal_name;
  }
  if (data.legal_name && data.legal_name.length > 0) {
    return data.legal_name;
  }
  return getAppName(data, languageCode);
}

export function getLocalisedBankName<
  T extends {
    name: string;
    arabic_name: string | null;
  },
>(data: T, languageCode: string | null) {
  if (
    isArabicLanguageEnabled(languageCode) &&
    data.arabic_name &&
    data.arabic_name.length > 0
  ) {
    return data.arabic_name;
  }
  return data.name;
}

export function getAccountType({
  account_type,
  customer_account_type,
}: TppData) {
  return customer_account_type || account_type || 'PERSONAL';
}

import styled from 'styled-components/macro';
import { linkStyles } from 'styles/components/Link';

const $FieldContainer = styled.div`
  flex: 0 50%;
  padding: 0px 8px;

  a {
    ${linkStyles}
    font-size: ${({ theme }) => theme.font.size.body.xs};
  }
`;

export default $FieldContainer;

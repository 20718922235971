import Errors from 'modules/shared/error/errors';
import Message from 'view/shared/message';

export function copyToClipboard(text, { showMessage = true } = {}) {
  return navigator.clipboard
    .writeText(text)
    .then(() => {
      if (showMessage) {
        Message.success('Copied to clipboard');
      }
    })
    .catch(error => {
      // The promise is rejected if the caller does not have permission to write to the clipboard.
      // https://developer.mozilla.org/en-US/docs/Web/API/Clipboard/writeText
      Errors.showMessage({ message: 'Unable to copy' });

      return Promise.reject(error);
    });
}

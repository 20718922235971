import React from 'react';
import { format } from 'date-fns';

import { getLanguageCode, i18n } from 'i18n';
import { useTypedSelector } from 'types/global';
import { selectHyperlink } from 'modules/hyperlink';

import {
  $HyperlinkAccessInfo,
  $HyperlinkPageParagraph,
  $HyperlinkPageTitle,
} from './hyperlink.styled';
import { getAccountType, getAppName, getLegalName } from './utils';

function ConnectSection({
  accessTo,
  accessFrom,
}: {
  accessTo: Date;
  accessFrom: Date;
}) {
  const { data } = useTypedSelector(selectHyperlink);
  const languageCode = getLanguageCode();
  const appName = getAppName(data, languageCode);
  const legalName = getLegalName(data, languageCode);
  const accountType = getAccountType(data);

  return (
    <div className="connect">
      <$HyperlinkPageTitle data-cy="hyperlink_connect_bank_title">
        {i18n('hyperlink.link_account.connect.title')}
      </$HyperlinkPageTitle>
      <$HyperlinkPageParagraph>
        {i18n(
          `hyperlink.link_account.connect.paragraph_1_${accountType}`.toLowerCase(),
          appName,
        )}
      </$HyperlinkPageParagraph>

      <$HyperlinkPageParagraph>
        {i18n('hyperlink.link_account.connect.paragraph_2', appName)}
      </$HyperlinkPageParagraph>

      <$HyperlinkPageParagraph className="small no-margin">
        {i18n('hyperlink.link_account.connect.consent_details.title')}
      </$HyperlinkPageParagraph>
      <$HyperlinkPageParagraph className="small subtitle">
        {i18n(
          'hyperlink.link_account.connect.consent_details.paragraph',
          legalName,
        )}
      </$HyperlinkPageParagraph>

      <$HyperlinkAccessInfo>
        <div>
          <$HyperlinkPageParagraph className="small no-margin">
            {i18n('hyperlink.link_account.connect.consent_details.access_from')}
          </$HyperlinkPageParagraph>
          <$HyperlinkPageParagraph
            data-cy="hyperlink_access_from"
            className="small subtitle no-margin"
          >
            {format(accessFrom, 'dd/MM/yyyy')}
          </$HyperlinkPageParagraph>
        </div>

        <div>
          <$HyperlinkPageParagraph className="small no-margin">
            {i18n('hyperlink.link_account.connect.consent_details.access_to')}
          </$HyperlinkPageParagraph>
          <$HyperlinkPageParagraph
            data-cy="hyperlink_access_to"
            className="small subtitle no-margin"
          >
            {format(accessTo, 'dd/MM/yyyy')}
          </$HyperlinkPageParagraph>
        </div>

        <div>
          <$HyperlinkPageParagraph className="small no-margin">
            {i18n('hyperlink.link_account.connect.consent_details.expires')}
          </$HyperlinkPageParagraph>
          <$HyperlinkPageParagraph
            data-cy="hyperlink_consent_expires"
            className="small subtitle no-margin"
          >
            {format(accessTo, 'dd/MM/yyyy')}
          </$HyperlinkPageParagraph>
        </div>
      </$HyperlinkAccessInfo>
    </div>
  );
}

export default ConnectSection;

import React, { useState, useEffect } from 'react';
import featureFlags, { FeatureFlags } from 'modules/featureFlags';
import { MfaStatus } from 'modules/auth/constants';
import { selectOrganisation } from 'modules/organisation/organisationSelectors';
import { useTypedSelector } from 'types/global';
import { matchPropTypes } from 'utils/proptypes';
import { isKSACountry } from 'config/countriesEnvironments';
import VerifyOwnerDialog from 'components/NoticeBanner/VerifyOwnerDialog';
import PropTypes from 'prop-types';

import {
  $MfaWarningNotice,
  $VerificationWarningNotice,
  $WarningIcon,
} from './styles';

const NoticeBanner = ({ match, currentUser }) => {
  const { organisation } = useTypedSelector(selectOrganisation);

  const [isTeamPage, setIsTeamPage] = useState(false);
  const [shouldVerify, setShouldVerify] = useState(false);
  const [startVerificationFlow, setStartVerificationFlow] = useState(false);

  const userHasEnrolledMfa =
    currentUser.mfa_settings.status !== MfaStatus.notEnrolled;

  useEffect(() => {
    if (match.path.split('/')[3] === 'team') {
      setIsTeamPage(true);
      setShouldVerify(organisation && !organisation.verified);
    }
  }, [match, organisation?.verified]);

  const toggleVerifyDialog = () => {
    setStartVerificationFlow(prev => !prev);
  };

  //! TODO: for now, only show one banner, so order is important, lets decide on the importance or a new design to show other banners
  const renderTheBanner = () => {
    const shouldRolloutOwnerRole = featureFlags.isOn(
      FeatureFlags.rolloutOwnerRole,
    );
    const shouldRolloutMfa = featureFlags.isOn(FeatureFlags.rolloutMfa);

    const showOwnerVerificationBanner =
      shouldRolloutOwnerRole && isKSACountry && isTeamPage && shouldVerify;

    if (showOwnerVerificationBanner) {
      return (
        <$VerificationWarningNotice onClick={toggleVerifyDialog}>
          <$WarningIcon src="/icons/warning.svg" alt="warning icon" />
          <strong>
            <span>Identity verification required &nbsp;</span>
          </strong>
          <span>
            Please verify your identity to comply with regulatory requirements.
            →
          </span>
        </$VerificationWarningNotice>
      );
    }

    const showMFABanner = shouldRolloutMfa && !userHasEnrolledMfa;

    if (showMFABanner) {
      return (
        <$MfaWarningNotice href="/mfa/setup">
          <div className="caption">
            <$WarningIcon src="/icons/warning.svg" alt="warning icon" />
            <span>MFA is not enabled &nbsp;</span>
          </div>
          <span>Setup an Authenticator app now to protect your account →</span>
        </$MfaWarningNotice>
      );
    }

    return null;
  };

  return (
    <>
      {renderTheBanner()}
      <VerifyOwnerDialog
        isOpen={startVerificationFlow}
        currentUser={currentUser}
        toggleVerifyDialog={toggleVerifyDialog}
      />
    </>
  );
};

NoticeBanner.propTypes = {
  match: matchPropTypes,
  currentUser: PropTypes.shape({
    mfa_settings: PropTypes.shape({
      status: PropTypes.string,
    }),
  }),
};

NoticeBanner.defaultProps = {
  match: {},
  currentUser: {},
};

export default NoticeBanner;

import styled from 'styled-components/macro';

const $Sidenav = styled.aside`
  background-color: ${({ theme }) => theme.colors.neutral._20};
  box-shadow: inset -4px 0px 4px rgba(181, 181, 181, 0.08);
  display: flex;
  padding: 4px 22px 20px 18px;
  width: ${({ theme }) => theme.layout.sidenav.width};
  height: 100vh;
  position: fixed;
  z-index: 1;

  @media screen and (max-height: 680px) {
    overflow: scroll;
  }
`;

export default $Sidenav;

import styled from 'styled-components/macro';
import { divider } from '../../styles/abstracts/mixins';

// Menu
export const $Divider = styled.div`
  ${divider}
`;

export const $MenuLinksContainer = styled.div`
  padding: 16px 0;
`;

// EnvironmentSection
export const $EnvironmentSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 4px 0;
  margin: 16px 0;
`;

export const $Title = styled.span`
  font-size: ${({ theme }) => theme.font.size.label.lg};
  color: ${({ theme }) => theme.colors.neutral._90};
  line-height: 1rem;
`;

export const $DropdownTitle = styled.span`
  font-size: ${({ theme }) => theme.font.size.label.lg};
  color: ${({ theme }) => theme.colors.black};
  font-weight: 600;
  margin-bottom: 8px;
`;

export const $DropdownText = styled.span`
  font-size: ${({ theme }) => theme.font.size.label.lg};
  color: ${({ theme }) => theme.colors.neutral._80};

  a {
    color: ${({ theme }) => theme.colors.active};
  }
`;

export const $DropdownContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
`;

// types/index.js
import {
  array,
  arrayOf,
  bool,
  element,
  node,
  number,
  object,
  oneOf,
  shape,
  string,
  func,
} from 'prop-types';

export const segementAnalyticsPropTypes = shape({
  event: string.isRequired,
  properties: object,
});
export const styledButtonPropsPropTypes = shape({
  align: oneOf(['start', 'center', 'end']),
  disabled: bool,
  display: oneOf(['flex', 'inline']),
  $fullwidth: bool,
  size: oneOf(['large', 'medium', 'small', 'x-small']),
  variant: oneOf([
    'primary',
    'secondary',
    'tertiary',
    'outline',
    'text',
    'group',
  ]),
  danger: bool,
  active: bool,
  justifyContent: oneOf(['start', 'center', 'end']),
});

export const styledFormGroupPropsPropTypes = shape({
  variant: oneOf(['compact', 'horizontal', 'stacked']),
  align: oneOf(['absolute', 'center']),
});

export const styledFormPropsPropTypes = shape({
  variant: oneOf(['inline', 'stacked']),
});

export const styledInputPropTypes = shape({});

export const applicationPropTypes = shape({
  active: bool,
  common_name: string,
  consent_requested: shape({
    identity: bool,
    balance: bool,
    transactions: bool,
    accounts: bool,
  }),
  description: string,
  id: string,
  link_redirect_url: string,
  logo_url: string,
  name: string,
});

export const childrenPropTypes = node.isRequired;

export const currentUserPropTypes = shape({
  disabled: bool,
  email: string,
  email_verified: bool,
  first_name: string,
  id: number,
  principal: string,
  last_name: string,
  organization_address: string,
  organization_name: string,
  phone_number: string,
  roles: array,
  username: string,
});

export const matchPropTypes = shape({
  isExact: bool,
  params: object,
  path: string,
  url: string,
});

export const historyPropTypes = shape({
  push: func.isRequired,
}).isRequired;

export const locationPropTypes = shape({
  pathname: string.isRequired,
});

export const permissionRequiredPropTypes = shape({
  allowedRoles: arrayOf(string),
  id: string,
});

export const reactElementPropTypes = element;

export const recordPropTypes = shape({
  id: string,
  name: string,
  description: string,
  link_redirect_url: string,
  common_name: string,
  active: bool,
  logo_url: string,
  token: shape({
    active: bool,
    id: string,
  }),
  traits: arrayOf(string),
  consents: shape({
    identity: bool,
    balance: bool,
    transactions: bool,
    accounts: bool,
  }),
});

import { apiFetch } from 'modules/shared/fetch';

export default class CertificateService {
  /**
   * POST /cert/pem/$filename
   * @param {Object} data
   * @param {String} filename
   * @param {string} appToken
   * @param {string} appEnvironment
   */
  static async signCertificate(data, filename, appToken, { appEnvironment }) {
    const body = data;
    const response = await apiFetch(`/cert/${filename}`, {
      body,
      headers: { 'lean-app-token': appToken },
      appEnvironment,
    });

    return response.data;
  }

  /**
   * POST /cert/revoke
   * @param {String} appToken
   * @param {string} appEnvironment
   */
  static async revoke(appToken, { appEnvironment }) {
    const response = await apiFetch('/cert/revoke', {
      method: 'POST',
      headers: { 'lean-app-token': appToken },
      appEnvironment,
    });

    return response.data;
  }

  /**
   * POST {authURL}/applications/${appToken}/secrets
   * @param {String} appToken
   * @param {string} appEnvironment
   */
  static async getOrCreateClientSecret(appToken, { appEnvironment }) {
    const response = await apiFetch(`/applications/${appToken}/secrets`, {
      method: 'post',
      authURL: true,
      appEnvironment,
    });

    return response.data;
  }

  /**
   * GET /cert/lean-public-chain
   * @param {string} appEnvironment
   */
  static async getPublicCertChain({ appEnvironment }) {
    return apiFetch('/cert/lean-public-chain', {
      appEnvironment,
    });
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';

const primaryStyles = css`
  background-color: ${({ theme }) => theme.colors.trust._20};
  color: ${({ theme }) => theme.colors.trust._80};
`;

const successStyles = css`
  background-color: ${({ theme }) => theme.colors.neon._20};
  color: ${({ theme }) => theme.colors.neon._80};
`;

const dangerStyles = css`
  background-color: ${({ theme }) => theme.colors.coral._20};
  color: ${({ theme }) => theme.colors.coral._60};
`;

const secondaryStyles = css`
  background-color: ${({ theme }) => theme.colors.neutral._30};
  color: ${({ theme }) => theme.colors.neutral._80};
`;

const warningStyles = css`
  background-color: ${({ theme }) => theme.colors.sun._10};
  color: ${({ theme }) => theme.colors.sun._60};
`;

const $Label = styled.div`
  height: 24px;
  font-size: ${({ theme }) => theme.font.size.label.md};

  ${({ type }) => {
    if (type === 'success') {
      return successStyles;
    }
    if (type === 'primary') {
      return primaryStyles;
    }
    if (type === 'secondary') {
      return secondaryStyles;
    }
    if (type === 'warning') {
      return warningStyles;
    }
    // (type === 'danger')
    return dangerStyles;
  }}
  border-radius: 4px;
  padding: 0 8px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const Label = ({ type, children, className }) => (
  <$Label type={type} className={className}>
    {children}
  </$Label>
);

Label.propTypes = {
  type: PropTypes.oneOf([
    'success',
    'primary',
    'danger',
    'secondary',
    'warning',
  ]),
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Label.defaultProps = {
  type: 'success',
  className: '',
};

export default Label;

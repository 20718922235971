import React from 'react';

import { ButtonGroup } from 'components';
import { getLanguageCode, getLanguages, setLanguageCode } from 'i18n';

const LanguageSelector = () => {
  const onLanguageChange = (language: string) => {
    setLanguageCode(language);
    window.location.reload();
  };

  const languages = getLanguages().map(language => ({
    label: language.label,
    value: language.id,
  }));

  return (
    <div>
      <ButtonGroup
        onChange={onLanguageChange}
        selectedValue={getLanguageCode()}
        options={languages}
      />
    </div>
  );
};

export default LanguageSelector;

// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import Icon from './Icon';

const overlayShow = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

const contentShow = keyframes`
  0% { 
    opacity: 0; 
    transform: translate(-50%, -48%) scale(.96); 
  }
  100% { 
    opacity: 1; 
    transform: translate(-50%, -50%) scale(1);
  }
`;

const $Overlay = styled(DialogPrimitive.Overlay)`
  background-color: rgba(0, 0, 0, 0.2);
  position: fixed;
  inset: 0;
  @media (prefers-reduced-motion: no-preference) {
    animation: ${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1);
  }
  z-index: 100;
`;

const $Content = styled(DialogPrimitive.Content)`
  background-color: white;
  border-radius: 16px;
  box-shadow: hsl(206 22% 7% / 35%) 0 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: ${({ width = '426' }) => `${width}px`};
  max-height: 85vh;
  padding: 24px;
  @media (prefers-reduced-motion: no-preference) {
    animation: ${contentShow} 150ms cubic-bezier(0.16, 1, 0.3, 1);
  }
  &:focus {
    outline: none;
  }
  z-index: 101;
`;

const $Title = styled(DialogPrimitive.Title)`
  margin-bottom: 0;
  color: ${({ theme }) => theme.colors.black};
  font-size: ${({ theme }) => theme.font.size.body.md};
`;

const $CloseButton = styled(DialogPrimitive.Close)`
  background-color: ${({ theme }) => theme.colors.neutral._30};
  width: 28px;
  height: 28px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const $Header = styled.div`
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Content = ({ title, isCloseButton, children, ...props }) => (
  <DialogPrimitive.Portal>
    <$Overlay />
    <$Content {...props}>
      {(title || isCloseButton) && (
        <$Header>
          {title && <$Title>{title}</$Title>}
          {isCloseButton && (
            <$CloseButton>
              <Icon name="Close" size={20} data-cy="dialog_btn_close" />
            </$CloseButton>
          )}
        </$Header>
      )}
      {children}
    </$Content>
  </DialogPrimitive.Portal>
);

Content.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  isCloseButton: PropTypes.bool,
};

Content.defaultProps = {
  title: '',
  isCloseButton: false,
};

// TODO: This is causing an error:
//  Warning: Function components cannot be given refs. Attempts to access this ref will fail. Did you mean to use React.forwardRef()?
//  Check the render method of `SlotClone`.
// Maybe because it hasn't been extended according to the docs
// https://www.radix-ui.com/docs/primitives/overview/styling#extending-a-primitive
export const Dialog = DialogPrimitive.Root;
export const DialogContent = Content;
export const DialogTrigger = DialogPrimitive.Trigger;
export const DialogClose = DialogPrimitive.Close;

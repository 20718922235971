import authInitializer from 'modules/auth/authInitializer';
import { routerMiddleware } from 'connected-react-router';
import { configureStore } from '@reduxjs/toolkit';
import createRootReducer from 'modules/reducers';
import { history } from 'modules/history';

const store = configureStore({
  reducer: createRootReducer(),
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(routerMiddleware(history)),
});

export const initializeStore = () => {
  if (process.env.NODE_ENV !== 'production') {
    if (module.hot) {
      module.hot.accept('modules/reducers', () => {
        store.replaceReducer(createRootReducer());
      });
    }
  }

  // initialize auth state
  authInitializer(store);

  return store;
};

// Define the `RootState` as the return type:
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

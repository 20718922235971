import { RootState } from 'modules/store';
import { createSlice } from '@reduxjs/toolkit';

import {
  CustomerEntity,
  CustomerPayment,
  CustomersData,
  CustomersState,
} from './customers.types';
import {
  getAllCustomers,
  getCustomerById,
  getCustomerPayments,
  getCustomerEntities,
  getCustomerEntityById,
  getCustomerPaymentById,
} from './customers.actions';

const initialState: CustomersState = {
  customers: {
    total: 0,
    isFetching: false,
    data: [],
  },
  customer: {
    isFetching: false,
    data: null,
  },
  entities: {
    total: 0,
    isFetching: false,
    data: [],
    error: null,
  },
  entity: {
    isFetching: false,
    data: null,
  },
  payment: {
    isFetching: false,
    data: null,
  },
  payments: {
    total: 0,
    isFetching: false,
    data: [],
  },
};

const customersSlice = createSlice({
  name: 'customers',
  initialState,
  reducers: {},
  extraReducers: builder => {
    // Get all customers
    builder.addCase(getAllCustomers.pending, state => {
      state.customers.isFetching = true;
    });
    builder.addCase(getAllCustomers.fulfilled, (state, { payload }) => {
      state.customers.total = payload.total_elements;
      state.customers.data = payload.content.map((customer: CustomersData) => ({
        ...customer,
        id: customer.customer_id, // assign unique ids to meet table row constrain
      }));

      state.customers.isFetching = false;
    });
    builder.addCase(getAllCustomers.rejected, state => {
      state.customers.isFetching = false;
    });

    // Get single customer
    builder.addCase(getCustomerById.pending, state => {
      state.customer.isFetching = true;
    });
    builder.addCase(getCustomerById.fulfilled, (state, { payload }) => {
      state.customer.data = payload;
      state.customer.isFetching = false;
    });
    builder.addCase(getCustomerById.rejected, state => {
      state.customer.isFetching = false;
    });

    // Get customer entities
    builder.addCase(getCustomerEntities.pending, state => {
      state.entities.isFetching = true;
      state.entities.error = null;
    });
    builder.addCase(getCustomerEntities.fulfilled, (state, { payload }) => {
      state.entities.total = payload.total_elements;
      state.entities.data = payload.content.map((entity: CustomerEntity) => ({
        ...entity,
        id: entity.entity_id,
        iban: '',
        status: 'Unknown',
      }));
      state.entities.isFetching = false;
    });
    builder.addCase(getCustomerEntities.rejected, (state, { error }) => {
      state.entities.isFetching = false;
      state.entities.error = error;
    });

    // Get single customer entity
    builder.addCase(getCustomerEntityById.pending, state => {
      state.entity.isFetching = true;
    });
    builder.addCase(getCustomerEntityById.fulfilled, (state, { payload }) => {
      state.entity.data = payload;
      state.entity.isFetching = false;
    });
    builder.addCase(getCustomerEntityById.rejected, state => {
      state.entity.isFetching = false;
    });

    // Get customer payments
    builder.addCase(getCustomerPayments.pending, state => {
      state.payments.isFetching = true;
    });
    builder.addCase(getCustomerPayments.fulfilled, (state, { payload }) => {
      state.payments.total = payload.entries_total;
      state.payments.data = payload.items.map((payment: CustomerPayment) => ({
        ...payment,
        id: payment.payment_intent_id,
      }));
      state.payments.isFetching = false;
    });
    builder.addCase(getCustomerPayments.rejected, state => {
      state.payments.isFetching = false;
    });

    // Get single customer payment
    builder.addCase(getCustomerPaymentById.pending, state => {
      state.payment.isFetching = true;
    });
    builder.addCase(getCustomerPaymentById.fulfilled, (state, { payload }) => {
      state.payment.data = payload;
      state.payment.isFetching = false;
    });
    builder.addCase(getCustomerPaymentById.rejected, state => {
      state.payment.isFetching = false;
    });
  },
});

export default customersSlice.reducer;
export const selectCustomers = (state: RootState) =>
  state.root.customers.customers;
export const selectCustomer = (state: RootState) =>
  state.root.customers.customer;
export const selectCustomerEntities = (state: RootState) =>
  state.root.customers.entities;
export const selectCustomerEntity = (state: RootState) =>
  state.root.customers.entity;
export const selectCustomerPayments = (state: RootState) =>
  state.root.customers.payments;
export const selectCustomerPayment = (state: RootState) =>
  state.root.customers.payment;

import React from 'react';
import Lottie from 'lottie-react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ringSpinner from './ring-spinner.json';

const $Wrapper = styled.div`
  margin: 0 auto;

  height: ${({ height }) => height}px;
  width: ${({ width }) => width}px;
`;

const Loading = ({ size }) => (
  <$Wrapper height={size} width={size}>
    <Lottie animationData={ringSpinner} loop />
  </$Wrapper>
);

Loading.propTypes = {
  size: PropTypes.number,
};

Loading.defaultProps = {
  size: 32,
};
export default Loading;

import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import Icon from './Icon';
import { $MenuLink } from '../styles';

const MenuLink = ({ children, icon, isActive, path }) => (
    <$MenuLink
      data-cy="menu_link_component"
      $isActive={isActive}
      as={Link}
      to={path}
    >
      <span className="icon">
        <Icon name={icon} />
      </span>
      <span className="label">{children}</span>
    </$MenuLink>
  );

MenuLink.propTypes = {
  children: PropTypes.node.isRequired,
  icon: PropTypes.string,
  isActive: PropTypes.bool.isRequired,
  path: PropTypes.string.isRequired,
};

MenuLink.defaultProps = {
  icon: '',
};

export default MenuLink;

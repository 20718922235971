import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components/macro';
import { connect } from 'react-redux';

import { matchPropTypes } from 'utils/proptypes';
import authSelectors from 'modules/auth/authSelectors';
import { NoticeBanner } from 'components/NoticeBanner';
import BreadCrumbs from '../BreadCrumbs/BreadCrumbs';
import { $Topnav } from '../../styles';
import UserMenu from '../UserMenu';

import { $NavigationAndBreadcrumbs } from '../../styles/layout/Topnav';

const $NavLink = styled.a`
  margin-right: 20px;
  color: ${({ theme }) => theme.colors.trust._60};
  font-size: ${({ theme }) => theme.font.size.body.sm};
`;

const Topnav = ({ match, docsToken, currentUser }) => {
  const docsUrl = docsToken
    ? `https://docs.leantech.me/?auth_token=${docsToken}`
    : 'https://docs.leantech.me/';

  return (
    <$Topnav data-cy="topnav_component">
      <$NavigationAndBreadcrumbs>
        <BreadCrumbs match={match} />

        <section>
          <$NavLink
            data-cy="docs_navlink_component"
            href={docsUrl}
            target="_blank"
          >
            Docs
          </$NavLink>
          <$NavLink href="https://status.leantech.me" target="_blank">
            Status
          </$NavLink>
          <UserMenu />
        </section>
      </$NavigationAndBreadcrumbs>

      <NoticeBanner match={match} currentUser={currentUser} />
    </$Topnav>
  );
};

Topnav.propTypes = {
  match: matchPropTypes,
  docsToken: PropTypes.string,
  currentUser: PropTypes.shape({
    mfa_settings: PropTypes.shape({
      status: PropTypes.string,
    }),
  }),
};

Topnav.defaultProps = {
  match: {},
  currentUser: {},
  docsToken: null,
};

const mapStateToProps = state => ({
  docsToken: authSelectors.selectDocsToken(state),
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Topnav);

export const formatAmount = (amount, currency) => {
  let formatter = null;

  if (currency) {
    formatter = new Intl.NumberFormat('en-GB', {
      style: 'currency',
      minimumFractionDigits: 2,
      currency,
      currencyDisplay: 'code',
    });
  } else {
    formatter = new Intl.NumberFormat('en-GB', {
      minimumFractionDigits: 2,
    });
  }

  return formatter.format(amount);
};

// https://stackoverflow.com/a/9462382
/**
 *
 * @param {number} number
 * @param {number} digits - number of decimal digits to display
 * @returns
 */
export const formatNumber = (number, digits = 1) => {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'K' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'B' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find(item => number >= item.value);
  return item
    ? (number / item.value).toFixed(digits).replace(rx, '$1') + item.symbol
    : '0';
};

/**
 * Adds comma separation in numbers
 * @param num
 * @returns {string}
 */
export const formatCount = num =>
  num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

/* eslint-disable camelcase */
import { i18n } from 'i18n';
import JWTDecode from 'jwt-decode';

import Message from 'view/shared/message';
import { getHistory } from 'modules/history';
import service from 'modules/auth/authService';
import Errors from 'modules/shared/error/errors';
import { AuthToken } from 'modules/auth/authToken';
import authSelectors from 'modules/auth/authSelectors';
import { resetBootstrapState } from 'modules/bootstrap';
import { COUNTRIES_CONFIG } from 'config/countriesEnvironments';
import { SignInResponseStatus } from 'modules/auth/constants';
import { STATUS_MFA_REQUIRED } from 'utils/constants';
import featureFlags, {
  FeatureFlags,
  FeatureFlagsAttributes,
} from 'modules/featureFlags';

const { analytics } = window;
const prefix = 'AUTH';

const actions = {
  ERROR_MESSAGE_CLEARED: `${prefix}_ERROR_MESSAGE_CLEARED`,

  RESET_STATE: 'RESET_STATE',
  AUTH_INIT_SUCCESS: `${prefix}_INIT_SUCCESS`,
  AUTH_INIT_ERROR: `${prefix}_INIT_ERROR`,

  AUTH_START: `${prefix}_START`,
  AUTH_SUCCESS: `${prefix}_SUCCESS`,
  AUTH_DISABLED: `${prefix}_DISABLED`,
  AUTH_ERROR: `${prefix}_ERROR`,

  UPDATE_PROFILE_START: `${prefix}_UPDATE_PROFILE_START`,
  UPDATE_PROFILE_SUCCESS: `${prefix}_UPDATE_PROFILE_SUCCESS`,
  UPDATE_PROFILE_ERROR: `${prefix}_UPDATE_PROFILE_ERROR`,

  UPDATE_ACCOUNT_START: `${prefix}_UPDATE_ACCOUNT_START`,
  UPDATE_ACCOUNT_SUCCESS: `${prefix}_UPDATE_ACCOUNT_SUCCESS`,
  UPDATE_ACCOUNT_ERROR: `${prefix}_UPDATE_ACCOUNT_ERROR`,

  CURRENT_USER_REFRESH_START: `${prefix}_CURRENT_USER_REFRESH_START`,
  CURRENT_USER_REFRESH_SUCCESS: `${prefix}_CURRENT_USER_REFRESH_SUCCESS`,
  CURRENT_USER_REFRESH_ERROR: `${prefix}_CURRENT_USER_REFRESH_ERROR`,

  PASSWORD_RESET_EMAIL_START: `${prefix}_PASSWORD_RESET_EMAIL_START`,
  PASSWORD_RESET_EMAIL_SUCCESS: `${prefix}_PASSWORD_RESET_EMAIL_SUCCESS`,
  PASSWORD_RESET_EMAIL_ERROR: `${prefix}_PASSWORD_RESET_EMAIL_ERROR`,

  PASSWORD_RESET_START: `${prefix}_PASSWORD_RESET_START`,
  PASSWORD_RESET_SUCCESS: `${prefix}_PASSWORD_RESET_SUCCESS`,
  PASSWORD_RESET_ERROR: `${prefix}_PASSWORD_RESET_ERROR`,

  PASSWORD_CHANGE_START: `${prefix}_PASSWORD_CHANGE_START`,
  PASSWORD_CHANGE_SUCCESS: `${prefix}_PASSWORD_CHANGE_SUCCESS`,
  PASSWORD_CHANGE_ERROR: `${prefix}_PASSWORD_CHANGE_ERROR`,

  EMAIL_VERIFY_START: `${prefix}_EMAIL_VERIFY_START`,
  EMAIL_VERIFY_SUCCESS: `${prefix}_EMAIL_VERIFY_SUCCESS`,
  EMAIL_VERIFY_ERROR: `${prefix}_EMAIL_VERIFY_ERROR`,

  EMAIL_CONFIRMATION_START: `${prefix}_EMAIL_CONFIRMATION_START`,
  EMAIL_CONFIRMATION_SUCCESS: `${prefix}_EMAIL_CONFIRMATION_SUCCESS`,
  EMAIL_CONFIRMATION_ERROR: `${prefix}_EMAIL_CONFIRMATION_ERROR`,

  ACCEPT_INVITATION_START: `${prefix}_ACCEPT_INVITATION_START`,
  ACCEPT_INVITATION_SUCCESS: `${prefix}_ACCEPT_INVITATION_SUCCESS`,
  ACCEPT_INVITATION_ERROR: `${prefix}_ACCEPT_INVITATION_ERROR`,

  INVITATION_TOKEN_VERIFY_START: `${prefix}_INVITATION_TOKEN_VERIFY_START`,
  INVITATION_TOKEN_VERIFY_SUCCESS: `${prefix}_INVITATION_TOKEN_VERIFY_SUCCESS`,
  INVITATION_TOKEN_VERIFY_ERROR: `${prefix}_INVITATION_TOKEN_VERIFY_ERROR`,

  DOCS_TOKEN_FETCH_START: `${prefix}_DOCS_TOKEN_FETCH_START`,
  DOCS_TOKEN_FETCH_SUCCESS: `${prefix}_DOCS_TOKEN_FETCH_SUCCESS`,
  DOCS_TOKEN_FETCH_ERROR: `${prefix}_DOCS_TOKEN_FETCH_ERROR`,

  VERIFY_UPDATE_MFA_START: `${prefix}_VERIFY_UPDATE_MFA_START`,
  VERIFY_UPDATE_MFA_SUCCESS: `${prefix}_VERIFY_UPDATE_MFA_SUCCESS`,
  VERIFY_UPDATE_MFA_ERROR: `${prefix}_VERIFY_UPDATE_MFA_ERROR`,

  GET_MFA_DETAILS_START: `${prefix}_GET_MFA_DETAILS_START`,
  GET_MFA_DETAILS_SUCCESS: `${prefix}_GET_MFA_DETAILS_SUCCESS`,
  GET_MFA_DETAILS_ERROR: `${prefix}_GET_MFA_DETAILS_ERROR`,

  GET_PASSWORD_RESET_DETAILS_START: `${prefix}_GET_PASSWORD_RESET_DETAILS_START`,
  GET_PASSWORD_RESET_DETAILS_SUCCESS: `${prefix}_GET_PASSWORD_RESET_DETAILS_SUCCESS`,
  GET_PASSWORD_RESET_DETAILS_ERROR: `${prefix}_GET_PASSWORD_RESET_DETAILS_ERROR`,

  doSignup:
    ({
      email,
      password,
      account_type,
      first_name,
      last_name,
      country,
      organization_name,
      organization_address,
      organization_size,
      build_description,
      vocation,
      acquisition,
      interested_features,
    }) =>
    async dispatch => {
      try {
        dispatch({ type: actions.AUTH_START });

        // Build up the body
        const signupResponse = await service.signup({
          first_name,
          last_name,
          email,
          password,
          account_type,
          organization_name,
          organization_address,
          interested_features,
          organization_size,
          role: vocation,
          acquisition,
          country,
          build_description,
          organization_legal_entity: organization_name,
        });

        Message.success(i18n('auth.signupSuccess'));
        Message.success(signupResponse.data.message);

        await analytics.identify(email, {
          email,
          first_name,
          last_name,
          organization_name,
        });
        await analytics.track('userSignupSuccess', {
          email,
          first_name,
          organization_name,
        });

        dispatch({
          type: actions.AUTH_SUCCESS,
          payload: {
            currentUser: { email },
          },
        });

        getHistory().push('/verify-email');
      } catch (error) {
        await service.signout();

        await analytics.track('userSignupError', {
          error: Errors.selectMessage(error),
        });

        if (Errors.errorCode(error) === 403) {
          dispatch({
            type: actions.AUTH_DISABLED,
            payload: Errors.selectMessage(error),
          });
        }

        if (Errors.errorCode(error) !== 400) {
          Errors.handle(error);
        }

        dispatch({
          type: actions.AUTH_ERROR,
          payload: Errors.selectMessage(error),
        });
      }
    },

  doSigninUsernameEmailPassword:
    ({ usernameOrEmail, password, rememberMeFlag, otpCode }) =>
    async (dispatch, getState) => {
      const email = usernameOrEmail.toLowerCase();
      const previousPath = authSelectors.selectFromPath(getState());
      const rememberMe = rememberMeFlag;

      try {
        await dispatch({
          type: actions.AUTH_START,
        });

        let data;

        if (otpCode) {
          data = await service.verifySignInMfa(otpCode);
        } else {
          data = await service.signinUsernameEmailPassword(
            email,
            password,
            featureFlags.isOn(FeatureFlags.rolloutMfa),
          );
        }

        if (data.status === SignInResponseStatus.mfaRequired) {
          const searchParams = new URLSearchParams();

          dispatch({
            type: actions.AUTH_ERROR,
          });

          searchParams.append('email', email);
          searchParams.append('rememberMe', rememberMe);

          if (previousPath && previousPath !== '/') {
            searchParams.append('previousPath', previousPath);
          }

          getHistory().push(`/verify-mfa?${searchParams.toString()}`);
          return;
        }

        if (data.status === SignInResponseStatus.passwordUpdateRequired) {
          const searchParams = new URLSearchParams();

          dispatch({
            type: actions.AUTH_ERROR,
          });

          searchParams.append('token', data.password_reset_token);
          searchParams.append('status', data.status);

          getHistory().push(`/auth/reset-password?${searchParams.toString()}`);
          return;
        }

        const accessToken = data.access_token;

        AuthToken.set(accessToken, rememberMe);
        const currentUser = await service.fetchProfile();

        const decodedJWT = JWTDecode(accessToken);
        const { scope: scopes } = decodedJWT;

        await analytics.track('userSigninSuccess', {});
        await analytics.identify(currentUser.username, {});

        await dispatch({
          type: actions.AUTH_SUCCESS,
          payload: {
            currentUser,
            scopes,
          },
        });

        const redirectTo =
          previousPath && previousPath !== '/'
            ? `/?previousPath=${previousPath}`
            : '/';
        getHistory().push(redirectTo);
      } catch (error) {
        await service.signout();

        const errorMessage = Errors.selectMessage(error);

        if (Errors.errorCode(error) === 401) {
          if (errorMessage.includes('validated')) {
            // Send user to resend page if email is not validated
            const currentUser = { email };

            dispatch({
              type: actions.AUTH_SUCCESS,
              payload: {
                currentUser,
              },
            });

            await analytics.track('userSigninFail', {
              error: errorMessage,
            });

            getHistory().push('/auth/resend-email');
          } else {
            // display error toast with the message Unauthorized
            Message.error(errorMessage);

            analytics.track('userSigninError', {
              error: errorMessage,
            });

            dispatch({
              type: actions.AUTH_ERROR,
              payload: i18n(errorMessage),
            });
          }
        } else if (Errors.errorCode(error) === 403) {
          analytics.track('userSinginLockedAccount', {
            error: errorMessage,
          });

          dispatch({
            type: actions.AUTH_ERROR,
          });

          getHistory().push('/locked-account');
        } else {
          // let Errors.handle function handle the error (redirect to 429 if error status is 429)
          Errors.handle(error);

          analytics.track('userSigninError', {
            error: errorMessage,
          });

          dispatch({
            type: actions.AUTH_ERROR,
            payload: i18n(errorMessage),
          });
        }
      }
    },

  doSignout: () => async dispatch => {
    try {
      dispatch({ type: actions.AUTH_START });
      dispatch(resetBootstrapState());
      await service.signout();

      analytics.track('userSignoutSuccess', {});
      analytics.reset();
      await dispatch({
        type: actions.AUTH_SUCCESS,
        payload: {
          currentUser: null,
          scopes: [],
        },
      });
      await dispatch({
        type: actions.RESET_STATE,
      });
    } catch (error) {
      Errors.handle(error);

      await analytics.track('userSignoutError', {});
      dispatch({
        type: actions.AUTH_ERROR,
      });
    }
  },

  doUpdateProfile: (id, body) => async dispatch => {
    try {
      dispatch({
        type: actions.UPDATE_PROFILE_START,
      });

      const response = await service.updateProfile(id, body);

      if (response.status !== 200) {
        await analytics.track('userUpdateProfileError', {});
        dispatch({
          type: actions.UPDATE_PROFILE_ERROR,
        });
      }

      dispatch({
        type: actions.UPDATE_PROFILE_SUCCESS,
      });
      dispatch(actions.doRefreshCurrentUser());
      Message.success(i18n('auth.profile.success'));

      await analytics.track('userUpdateProfileSuccess', {});
      const currentUser = await service.fetchProfile();
      await analytics.identify(currentUser.username, {
        email: currentUser.email,
        first_name: currentUser.first_name,
        last_name: currentUser.last_name,
        organization_name: currentUser.organization_name,
        organization_address: currentUser.organization_address,
      });

      getHistory().push('/');
    } catch (error) {
      Errors.handle(error);
      await analytics.track('userUpdateProfileError', {});
      dispatch({
        type: actions.UPDATE_PROFILE_ERROR,
      });
    }
  },

  doUpdateAccount: body => async dispatch => {
    try {
      dispatch({
        type: actions.UPDATE_ACCOUNT_START,
      });

      await service.updateAccount(body);

      dispatch({
        type: actions.UPDATE_PROFILE_SUCCESS,
      });
      dispatch(actions.doRefreshCurrentUser());
      Message.success(i18n('auth.account.update.success'));
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: actions.UPDATE_PROFILE_ERROR,
      });
    }
  },

  // Email Verification
  doVerifyEmail: token => async dispatch => {
    try {
      dispatch({
        type: actions.EMAIL_VERIFY_START,
      });

      const response = await service.verifyEmail(token);

      if (response.includes('badUser')) {
        dispatch(actions.doRefreshCurrentUser());
        Message.error(i18n('auth.verifyEmail.error'));
        await analytics.track('userVerifyEmailError', {});
        dispatch({
          type: actions.EMAIL_VERIFY_ERROR,
        });
        // TODO: redirect to resend verification email page
        getHistory().push(`/`);
      } else {
        dispatch(actions.doRefreshCurrentUser());
        Message.success(i18n('auth.verifyEmail.success'));
        await analytics.track('userVerifyEmailSuccess', {});
        dispatch({
          type: actions.EMAIL_VERIFY_SUCCESS,
        });
        getHistory().push('/');
      }
    } catch (error) {
      Errors.handle(error);

      analytics.track('doVerifyEmailError', {
        error: Errors.selectMessage(error),
      });

      dispatch({
        type: actions.EMAIL_VERIFY_ERROR,
      });
      dispatch(actions.doSignout());
      getHistory().push('/');
    }
  },

  doResendEmailVerification: email => async dispatch => {
    await service.resendEmailVerification(email.toLowerCase());
    try {
      dispatch(actions.doRefreshCurrentUser());
      Message.success(i18n('auth.resendEmailVerification.success'));
      dispatch({
        type: actions.EMAIL_VERIFY_SUCCESS,
      });
      getHistory().push('/');
    } catch (error) {
      Errors.handle(error);

      analytics.track('doPostEmailVerificationError', {
        error: Errors.selectMessage(error),
      });

      dispatch({
        type: actions.EMAIL_VERIFY_ERROR,
      });
      dispatch(actions.doSignout());
      getHistory().push('/');
    }
  },

  // Password Reset
  doSendPasswordResetEmail: email => async dispatch => {
    try {
      dispatch({
        type: actions.PASSWORD_RESET_EMAIL_START,
      });

      await service.sendPasswordResetEmail(email.toLowerCase());
      Message.success(i18n('auth.passwordResetEmailSuccess'));

      dispatch({
        type: actions.PASSWORD_RESET_EMAIL_SUCCESS,
      });

      getHistory().push('/');
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: actions.PASSWORD_RESET_EMAIL_ERROR,
      });
    }
  },

  doResetPassword: (token, password, code) => async dispatch => {
    try {
      dispatch({
        type: actions.PASSWORD_RESET_START,
      });

      await service.passwordReset(token, password, code);

      Message.success(i18n('auth.passwordResetSuccess'));
      dispatch({
        type: actions.PASSWORD_RESET_SUCCESS,
      });
      await analytics.track('userResetPasswordSuccess', {});
      getHistory().push('/auth/signin');
    } catch (error) {
      if (
        Errors.errorCode(error) === 401 &&
        Errors.errorStatusOrStatusCode(error) === STATUS_MFA_REQUIRED
      ) {
        dispatch({
          type: actions.PASSWORD_RESET_ERROR,
          payload: i18n(Errors.selectMessage(error)),
        });

        await analytics.track('userResetPasswordError', {});
      } else if (Errors.errorCode(error) === 403) {
        getHistory().push('/locked-account');

        await analytics.track('userResetPasswordError', {});
      } else {
        Errors.handle(error);

        // TODO - add further reset password error cases here.

        dispatch({
          type: actions.PASSWORD_RESET_ERROR,
        });

        dispatch(actions.doSignout());
        await analytics.track('userResetPasswordError', {});
      }
    }
  },

  doChangePassword: (password, code) => async dispatch => {
    try {
      dispatch({
        type: actions.PASSWORD_CHANGE_START,
      });

      await service.passwordChange(password, code);

      Message.success(i18n('auth.passwordResetSuccess'));
      dispatch({
        type: actions.PASSWORD_CHANGE_SUCCESS,
      });

      await analytics.track('userChangePasswordSuccess', {});
      getHistory().push('/auth/signin');
      dispatch(actions.doSignout());
      return { isSuccessful: true };
    } catch (error) {
      if (
        Errors.errorCode(error) === 401 &&
        Errors.errorStatusOrStatusCode(error) === STATUS_MFA_REQUIRED
      ) {
        dispatch({
          type: actions.PASSWORD_CHANGE_ERROR,
          payload: i18n(Errors.selectMessage(error)),
        });

        await analytics.track('userChangePasswordError', {});
      } else if (Errors.errorCode(error) === 403) {
        getHistory().push('/locked-account');

        await analytics.track('userChangePasswordError', {});
      } else {
        Errors.handle(error);

        // TODO - add further reset password error cases here.

        dispatch({
          type: actions.PASSWORD_CHANGE_ERROR,
        });

        await analytics.track('userChangePasswordError', {});
      }
      return { isSuccessful: false };
    }
  },

  doAcceptInvitation: (values, invitationTokenData) => async dispatch => {
    try {
      // @todo: push this event to analytics
      dispatch({
        type: actions.ACCEPT_INVITATION_START,
      });
      const { access_token: accessToken } = await service.acceptInvitation(
        values,
      );
      await AuthToken.set(accessToken);
      await dispatch(actions.doInit());
      dispatch({
        type: actions.ACCEPT_INVITATION_SUCCESS,
      });
      await analytics.track('userAcceptInvitationSuccess', {
        email: invitationTokenData.email,
        organization_name: invitationTokenData.organization_name,
        first_name: values.first_name,
        last_name: values.last_name,
      });
      getHistory().push('/');
    } catch (error) {
      // @todo: push this event to analytics
      Errors.handle(error);
      dispatch({
        type: actions.ACCEPT_INVITATION_ERROR,
      });
      await service.signout();
      await analytics.track('userAcceptInvitationError', {
        error: Errors.selectMessage(error),
      });
      getHistory().push('/');
    }
  },

  doVerifyInvitationToken: token => async dispatch => {
    try {
      dispatch({
        type: actions.INVITATION_TOKEN_VERIFY_START,
      });
      if (!token) {
        getHistory().push('/404');
      }
      const data = await service.verifyInvitationToken(token);
      dispatch({
        type: actions.INVITATION_TOKEN_VERIFY_SUCCESS,
        payload: {
          email: data.email,
          organization_name: data.organization_name,
        },
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: actions.INVITATION_TOKEN_VERIFY_ERROR,
      });
    }
  },

  doVerifyUserUpdateMfa: code => async dispatch => {
    try {
      dispatch({
        type: actions.VERIFY_UPDATE_MFA_START,
      });

      await service.verifyUserUpdateMfa(code);
      getHistory().push('/mfa/setup?action=update');

      dispatch({
        type: actions.VERIFY_UPDATE_MFA_SUCCESS,
      });
    } catch (error) {
      if (
        Errors.errorCode(error) === 401 &&
        Errors.errorStatusOrStatusCode(error) === STATUS_MFA_REQUIRED
      ) {
        dispatch({
          type: actions.VERIFY_UPDATE_MFA_ERROR,
          payload: i18n(Errors.selectMessage(error)),
        });
      } else {
        Errors.handle(error);
        dispatch({
          type: actions.VERIFY_UPDATE_MFA_ERROR,
        });
      }
    }
  },

  doConfirmUserUpdateMfa: code => async dispatch => {
    try {
      dispatch({
        type: actions.VERIFY_UPDATE_MFA_START,
      });

      await service.confirmUserUpdateMfa(code);
      getHistory().push('/');
      window.location.reload();

      dispatch({
        type: actions.VERIFY_UPDATE_MFA_SUCCESS,
      });
    } catch (error) {
      if (
        Errors.errorCode(error) === 401 &&
        Errors.errorStatusOrStatusCode(error) === STATUS_MFA_REQUIRED
      ) {
        dispatch({
          type: actions.VERIFY_UPDATE_MFA_ERROR,
          payload: i18n(Errors.selectMessage(error)),
        });
      } else {
        Errors.handle(error);
        dispatch({
          type: actions.VERIFY_UPDATE_MFA_ERROR,
        });
      }
    }
  },

  doGetMfaDetails: () => async dispatch => {
    try {
      dispatch({
        type: actions.GET_MFA_DETAILS_START,
      });

      const data = await service.getMfaDetails();

      dispatch({
        type: actions.GET_MFA_DETAILS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: actions.GET_MFA_DETAILS_ERROR,
      });
    }
  },

  doGetPasswordResetDetails: token => async dispatch => {
    try {
      dispatch({
        type: actions.GET_PASSWORD_RESET_DETAILS_START,
      });

      const data = await service.getPasswordResetDetails(token);

      dispatch({
        type: actions.GET_PASSWORD_RESET_DETAILS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: actions.GET_PASSWORD_RESET_DETAILS_ERROR,
      });
    }
  },

  // =============================================

  doClearErrorMessage() {
    return {
      type: actions.ERROR_MESSAGE_CLEARED,
    };
  },
  doInit: () => async dispatch => {
    try {
      const token = await AuthToken.get();
      let currentUser = null;
      let scopes = [];

      featureFlags.setAttributes({
        [FeatureFlagsAttributes.country]: COUNTRIES_CONFIG.countryCode,
      });

      if (token) {
        currentUser = await service.fetchProfile();

        const decodedJWT = JWTDecode(token);
        const { scope } = decodedJWT;
        scopes = scope;
      }

      dispatch({
        type: actions.AUTH_INIT_SUCCESS,
        payload: {
          currentUser,
          scopes,
        },
      });
    } catch (error) {
      service.signout();
      Errors.handle(error);

      dispatch({
        type: actions.AUTH_INIT_ERROR,
        payload: error,
      });
    }
  },

  doRefreshCurrentUser: () => async dispatch => {
    try {
      dispatch({
        type: actions.CURRENT_USER_REFRESH_START,
      });

      let currentUser = null;
      const token = await AuthToken.get();

      if (token) {
        currentUser = await service.fetchProfile();
      }

      dispatch({
        type: actions.CURRENT_USER_REFRESH_SUCCESS,
        payload: {
          currentUser,
        },
      });
    } catch (error) {
      service.signout();
      Errors.handle(error);

      dispatch({
        type: actions.CURRENT_USER_REFRESH_ERROR,
        payload: error,
      });
    }
  },

  doFetchDocsToken:
    (appToken, environmentFromArgs) => async (dispatch, getState) => {
      try {
        dispatch({
          type: actions.DOCS_TOKEN_FETCH_START,
        });

        let docsToken = null;
        const token = await AuthToken.get();

        if (token) {
          const { environment: currentEnvironment } = getState().root.global;

          const appEnvironment = environmentFromArgs || currentEnvironment;

          docsToken = await service.fetchReadmeToken(appToken, {
            appEnvironment,
          });
        }

        dispatch({
          type: actions.DOCS_TOKEN_FETCH_SUCCESS,
          payload: {
            docsToken,
          },
        });
      } catch (error) {
        dispatch({
          type: actions.DOCS_TOKEN_FETCH_ERROR,
          payload: error,
        });
      }
    },
};

export default actions;

import styled from 'styled-components/macro';
import { rgba } from 'polished';

const $Footer = styled.div`
  color: ${({ theme }) => theme.colors.neon.black};
  display: flex;
  flex-direction: column;
  font-size: ${({ theme }) => theme.font.size.label.sm};
  margin-top: auto;
  padding: 0 8px;

  a {
    display: block;
    margin-bottom: 5px;

    &.copyright {
      color: ${({ theme }) =>
        rgba(theme.colors.neon.black, 0.4)};
    }
  }
`;

export default $Footer;

import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { $CopyToClipboard } from 'styles';
import { copyToClipboard } from 'modules/shared/strings/copyToClipboard';
import Icon from './Icon';

const $Text = styled.span`
  margin-right: 13px;
`;
const CopyToClipboard = ({ name, children }) => (
  <$CopyToClipboard>
    <$Text data-cy={`${name}_text`}>{children}</$Text>
    <Icon
      data-cy={`${name}_copy_button`}
      name="Copy"
      size={16}
      onClick={() => copyToClipboard(children)}
    />
  </$CopyToClipboard>
);

CopyToClipboard.propTypes = {
  children: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

CopyToClipboard.defaultProps = {};

export default CopyToClipboard;

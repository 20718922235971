import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  ENVIRONMENT_SANDBOX,
  ENVIRONMENT_PRE_LIVE,
  ENVIRONMENT_PRODUCTION,
  environmentNames,
} from 'utils/constants';
import applicationListSelectors from 'modules/application/list/applicationListSelectors';
import DropdownButton, { SelectOptionsContent } from '../form/DropdownButton';
import {
  $DropdownContentContainer,
  $DropdownText,
  $DropdownTitle,
  $EnvironmentSection,
  $Title,
} from './styles';

const DropdownContentComponent = ({
  appId,
  appName,
  userEmail,
  environment,
  toggleEnvironmentValue,
  isToggleEnvironmentPossible,
  hasProductionApplication,
  hasPreLiveApplication,
}) => {
  const contactUsLink = `mailto:devsupport@leantech.me?subject=Get Production Access&body=Hello! Our application is ready to start a live data integration to Lean ecosystem.
\n
Application details:\n 
${appName} \n
${appId} \n
${userEmail}`;

  if (isToggleEnvironmentPossible) {
    const options = [
      {
        value: ENVIRONMENT_SANDBOX,
        label: environmentNames[ENVIRONMENT_SANDBOX],
      },
    ];

    if (hasPreLiveApplication) {
      options.push({
        value: ENVIRONMENT_PRE_LIVE,
        label: environmentNames[ENVIRONMENT_PRE_LIVE],
      });
    }

    if (hasProductionApplication) {
      options.push({
        value: ENVIRONMENT_PRODUCTION,
        label: environmentNames[ENVIRONMENT_PRODUCTION],
      });
    }

    return (
      <SelectOptionsContent
        onSubmit={toggleEnvironmentValue}
        options={options}
        value={environment}
      />
    );
  }

  // Only Sandbox
  if (environment === ENVIRONMENT_SANDBOX) {
    return (
      <$DropdownContentContainer data-cy="no_prod_app_tooltip">
        <$DropdownTitle>Ready to go live?</$DropdownTitle>
        <$DropdownText>
          The sandbox environment is the first step towards integrating Lean in
          your application. To get access to real bank data and payments in a
          production environment <a href={contactUsLink}>contact us</a>.
        </$DropdownText>
      </$DropdownContentContainer>
    );
  }

  // Only pre-live
  if (environment === ENVIRONMENT_PRE_LIVE) {
    return (
      <$DropdownContentContainer data-cy="no_prod_app_tooltip">
        <$DropdownTitle>Pre-live environment</$DropdownTitle>
        <$DropdownText>
          This account only has a pre-live environment application. If you’re
          looking for the sandbox or live environment you may need to sign in to
          another account. If you’re having trouble, please{' '}
          <a href={contactUsLink}>contact us</a>
        </$DropdownText>
      </$DropdownContentContainer>
    );
  }

  // Only production
  if (environment === ENVIRONMENT_PRODUCTION) {
    return (
      <$DropdownContentContainer data-cy="no_prod_app_tooltip">
        <$DropdownTitle>Production environment</$DropdownTitle>
        <$DropdownText>
          This account only has a production environment application. If you’re
          looking for the sandbox environment you may need to sign in to another
          account. If you’re having trouble, please{' '}
          <a href={contactUsLink}>contact us</a>
        </$DropdownText>
      </$DropdownContentContainer>
    );
  }

  return null;
};

const mapStateToProps = state => ({
  hasPreLiveApplication:
    applicationListSelectors.selectHasPreLiveApplication(state),
  hasProductionApplication:
    applicationListSelectors.selectHasProductionApplication(state),
});

const DropdownContent = connect(mapStateToProps)(DropdownContentComponent);

const EnvironmentSection = ({
  appId,
  appName,
  userEmail,
  environment,
  toggleEnvironmentValue,
  isToggleEnvironmentPossible,
}) => (
  <$EnvironmentSection>
    <$Title>Environment</$Title>
    <DropdownButton
      // lowercase for cypress tests
      title={environmentNames[environment].toLowerCase()}
      name="environment"
      iconRight={isToggleEnvironmentPossible ? 'ChevronDown' : null}
      isActive
      styledProps={{
        $environment: environment,
        backgroundColor: 'transparent',
      }}
    >
      <DropdownContent
        appId={appId}
        appName={appName}
        userEmail={userEmail}
        environment={environment}
        toggleEnvironmentValue={toggleEnvironmentValue}
        isToggleEnvironmentPossible={isToggleEnvironmentPossible}
      />
    </DropdownButton>
  </$EnvironmentSection>
);

EnvironmentSection.propTypes = {
  environment: PropTypes.string.isRequired,
  isToggleEnvironmentPossible: PropTypes.bool.isRequired,
  toggleEnvironmentValue: PropTypes.func.isRequired,
  appId: PropTypes.string.isRequired,
  appName: PropTypes.string.isRequired,
  userEmail: PropTypes.string.isRequired,
};

DropdownContentComponent.propTypes = {
  environment: PropTypes.string.isRequired,
  isToggleEnvironmentPossible: PropTypes.bool.isRequired,
  hasPreLiveApplication: PropTypes.bool.isRequired,
  hasProductionApplication: PropTypes.bool.isRequired,
  toggleEnvironmentValue: PropTypes.func.isRequired,
  appId: PropTypes.string.isRequired,
  appName: PropTypes.string.isRequired,
  userEmail: PropTypes.string.isRequired,
};

export default EnvironmentSection;

import React, { useEffect } from 'react';
import { getLanguageCode, i18n } from 'i18n';
import QueryString from 'query-string';
import { Button } from 'components';
import { getHistory } from 'modules/history';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from 'types/global';
import { postBankRequest, selectHyperlink } from 'modules/hyperlink';
import HyperlinkContainer from './HyperlinkContainer';
import { hyperlinkUserSteps } from './constants';
import { getAppName } from './utils';
import {
  $HyperlinkLayoutBody,
  $HyperlinkPageParagraph,
  $HyperlinkPageTitle,
} from './hyperlink.styled';

function RequestBank({ tppCustomerId }: { tppCustomerId: string }) {
  const dispatch = useDispatch();
  const history = getHistory();
  const queryParams = QueryString.parse(window.location.search);

  const query = new URLSearchParams(window.location.search);
  const bankName = query.get('bankName') || '';
  const bankIdentifier = query.get('bankIdentifier') || '';
  const sandbox = Boolean(query.get('sandbox'));
  const { isSubmitting } = useTypedSelector(selectHyperlink);

  const { data } = useTypedSelector(selectHyperlink);
  const languageCode = getLanguageCode();
  const appName = getAppName(data, languageCode);

  useEffect(() => {
    if (!bankName || !bankIdentifier) {
      gotoBankSelection();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bankName, bankIdentifier]);

  const gotoBankSelection = () => {
    const newParams = {
      ...queryParams,
      bankName: undefined,
      bankIdentifier: undefined,
      step: hyperlinkUserSteps.BankSelection.name,
    };
    history.push({ search: `${QueryString.stringify(newParams)}` });
  };

  const requestBank = () => {
    dispatch(
      postBankRequest({
        sandbox,
        bankIdentifier,
        customerId: tppCustomerId,
      }),
    );
  };

  return (
    <HyperlinkContainer>
      <$HyperlinkLayoutBody className="request-bank-content">
        <$HyperlinkPageTitle data-cy="hyperlink_start_a_bank_check">
          {i18n('hyperlink.request_bank.title', bankName)}
        </$HyperlinkPageTitle>
        <$HyperlinkPageParagraph>
          {i18n('hyperlink.request_bank.subtitle', appName)}
        </$HyperlinkPageParagraph>

        <div>
          <Button
            onClick={gotoBankSelection}
            styledProps={{ variant: 'secondary' }}
            className="connect-another-bank-button"
            data-cy="hyperlink_return_to_bank_selection_button"
          >
            {i18n('hyperlink.request_bank.connect_other_bank_btn')}
          </Button>
          <Button
            onClick={requestBank}
            isLoading={isSubmitting}
            className="request-bank-button"
            data-cy="hyperlink_request_bank_button"
          >
            {i18n('hyperlink.request_bank.request_bank_btn', appName)}
          </Button>
        </div>
      </$HyperlinkLayoutBody>
    </HyperlinkContainer>
  );
}

export default RequestBank;

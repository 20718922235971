import styled, { css } from 'styled-components/macro';
import { ENVIRONMENT_PRE_LIVE, ENVIRONMENT_SANDBOX } from 'utils/constants';
import Icon from '../../Icon';

const defaultStyles = css`
  background-color: ${({ theme, backgroundColor }) =>
    backgroundColor || theme.colors.neutral._20};
  border-radius: 4px;

  &::placeholder {
    color: ${({ theme }) => theme.colors.neutral._40};
  }
`;

const focusStyles = css`
  box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.trust._30};
  outline: none;
`;

const activeStyles = css`
  color: ${({ theme }) => theme.colors.trust._60};
  outline: none;

  &:hover {
    color: ${({ theme }) => theme.colors.trust._60};
  }
`;

const disabledStyles = css`
  outline: none;
  color: ${({ theme }) => theme.colors.neutral._50};
  cursor: auto;
  pointer-events: none;

  &:active {
    color: ${({ theme }) => theme.colors.neutral._50};
  }

  &:hover {
    color: ${({ theme }) => theme.colors.neutral._50};
  }
`;

const hoverStyles = css`
  color: ${({ theme }) => theme.colors.neutral._100};
`;

export const $Title = styled.span`
  line-height: 1rem;
  margin-right: 6px;
`;

const environmentTitleColor = css`
  color: ${({ theme, $environment }) => {
    if ($environment === ENVIRONMENT_SANDBOX) {
      return theme.colors.sun._60;
    }

    if ($environment === ENVIRONMENT_PRE_LIVE) {
      return theme.colors.purple._60;
    }

    return theme.colors.trust._60;
  }};
`;

const environmentDropdownStyles = css`
  ${environmentTitleColor};
  text-transform: capitalize;
  padding: 0;

  &:hover {
    ${environmentTitleColor}
  }
  &:focus {
    ${environmentTitleColor}
  }
  &:active {
    ${environmentTitleColor}
    &:hover {
      ${environmentTitleColor};
    }
  }
`;
export const $DropdownButton = styled.button`
  appearance: none;
  font-size: ${({ theme }) => theme.font.size.label.lg};
  height: 32px;
  padding: 0 16px;

  color: ${({ theme }) => theme.colors.neutral._80};

  display: flex;
  justify-content: center;
  align-items: center;

  ${defaultStyles}

  &:disabled {
    ${disabledStyles}
  }

  &:hover {
    ${hoverStyles}
  }

  &:focus {
    ${focusStyles}
  }

  ${({ active }) => active && activeStyles}
  &:active {
    ${activeStyles}
  }

  ${({ $environment }) => $environment && environmentDropdownStyles}
`;

export const $HeaderRow = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  min-width: 100px;
  margin-bottom: 16px;
`;

export const $BottomRow = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  min-width: 100px;
  margin-top: 16px;
`;

export const $TooltipTitle = styled.span`
  font-size: ${({ theme }) => theme.font.size.body.md};
`;

export const $ClearButton = styled.button`
  font-size: ${({ theme }) => theme.font.size.body.sm};
  color: ${({ theme, disabled }) =>
    disabled ? theme.colors.neutral._50 : theme.colors.trust._60};
`;

export const $ApplyButton = styled.button`
  font-size: ${({ theme }) => theme.font.size.body.md};
  color: ${({ theme, disabled }) =>
    disabled ? theme.colors.neutral._50 : theme.colors.trust._60};
`;

export const $Options = styled.div`
  margin: 4px 0;
  font-size: 20px;
`;

export const $TooltipContentContainer = styled.div`
  padding: 16px;
  min-width: 240px;
`;

export const $InputFieldsContainer = styled.div`
  margin-top: 16px;
`;

// SelectOptionsContent
export const $SelectOption = styled.li`
  color: ${({ theme }) => theme.colors.black};
  padding: 16px 4px;
  list-style-type: none;

  display: flex;
  justify-content: space-between;

  :hover {
    background-color: ${({ theme }) => theme.colors.neutral._20};
    cursor: pointer;
    border-radius: 4px;
  }
`;

export const $CheckmarkIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.trust._60};
`;

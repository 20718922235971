import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { $Helptext, $Label, $Input } from 'styles';
import { styledInputPropTypes } from 'utils/proptypes';
import { $SelectContainer } from 'styles/components/form/Select';
import { $Option } from './styles';

const Radio = ({
  isErrored,
  label,
  name,
  onChange,
  // defaultValue,
  options,
  helptext,
  styledProps,
  value: radioValue,
}) => {
  const getOptions = () =>
    options.map(({ value, label: labelValue }) => (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <$Option key={value} {...styledProps}>
        <$Input
          type="radio"
          id={value}
          key={value}
          value={value}
          name={name}
          checked={value === radioValue}
          onChange={e => onChange(e.target.value)}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...styledProps}
        />
        <$Label data-cy={`radio_option_${value}`} htmlFor={value}>
          {labelValue}
        </$Label>
      </$Option>
    ));

  return (
    <>
      <$Label htmlFor={name} id={`${name}Label`}>
        {label}
      </$Label>
      <$SelectContainer data-cy="select_container">
        {getOptions()}
      </$SelectContainer>
      <$Helptext id={`${name}Description`} isErrored={isErrored}>
        {helptext}
      </$Helptext>
    </>
  );
};

Radio.propTypes = {
  // defaultValue: PropTypes.string,
  helptext: PropTypes.string,
  isErrored: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ).isRequired,
  styledProps: styledInputPropTypes,
  value: PropTypes.string,
};

Radio.defaultProps = {
  // defaultValue: '',
  helptext: '',
  isErrored: false,
  label: '',
  onChange: () => {},
  styledProps: {
    fontSize: 'md',
    // optionHeight: 38
  },
  value: '',
};

export default Radio;

import React from 'react';
import QueryString from 'query-string';
import { Icon, Logo } from 'components';
import { getHistory } from 'modules/history';
import { LANGUAGE__DIR_LTR } from 'modules/shared/language';
import { getLanguageCode, getLanguageDirection } from 'i18n';
import { $BankInfoRightElement, $HyperlinkListItem } from './hyperlink.styled';
import { UnsupportedBank } from './unsupportedBanks';
import { hyperlinkUserSteps } from './constants';
import { getLocalisedBankName } from './utils';

interface BaseBankData {
  name: string;
  logo_alt: string;
  identifier: string;
  arabic_name: string | null;
  is_active: boolean;
}

function BankListItem<T extends BaseBankData>({ bank }: { bank: T }) {
  const languageCode = getLanguageCode();
  const history = getHistory();
  const queryParams = QueryString.parse(window.location.search);

  const handleClick = () => {
    if (bank.is_active) {
      gotoLinkAccount(bank.identifier);
    } else {
      gotoRequestBank(bank);
    }
  };

  const gotoLinkAccount = (bankIdentifier: string) => {
    const newParams = {
      ...queryParams,
      step: hyperlinkUserSteps.LinkAccount.name,
      bankIdentifier,
    };
    history.push({ search: `${QueryString.stringify(newParams)}` });
  };

  const gotoRequestBank = (unsupportedBank: UnsupportedBank) => {
    const newParams = {
      ...queryParams,
      step: hyperlinkUserSteps.RequestBank.name,
      bankName: getLocalisedBankName(unsupportedBank, languageCode),
      bankIdentifier: unsupportedBank.identifier,
    };
    history.push({ search: `${QueryString.stringify(newParams)}` });
  };

  return (
    <$HyperlinkListItem
      key={bank.identifier}
      data-cy="hyperlink_select_bank"
      onClick={handleClick}
    >
      <Logo name={bank.name} url={bank.logo_alt} />
      <span>{getLocalisedBankName(bank, languageCode)}</span>

      <$BankInfoRightElement>
        <Icon
          name={
            getLanguageDirection() === LANGUAGE__DIR_LTR
              ? 'ChevronForward'
              : 'ChevronBack'
          }
          size={16}
        />
      </$BankInfoRightElement>
    </$HyperlinkListItem>
  );
}

export default BankListItem;

export const downloadPdfFromArrayBuffer = (
  arrayBuffer: ArrayBuffer,
  fileName: string,
) => {
  const blob = new Blob([arrayBuffer], { type: 'application/pdf' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');

  a.href = url;
  a.download = fileName;
  a.click();

  URL.revokeObjectURL(url);
};

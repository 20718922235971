import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import paginate from 'jw-paginate';
import { formatNumber } from 'utils';

import {
  $Divider,
  $IconRight,
  $Pagination,
  $ArrowButton,
  $NumberOfItems,
  $CurrentPageInput,
  $PaginationControl,
  $ArrowButtonsContainer,
  $NumberOfPagesContainer,
} from './styles';

const Pagination = ({
  totalItems,
  onChangePage,
  confirmNextPage,
  pageSize = 10,
  maxPages = 5,
  initialPage = 1,
}) => {
  const [pager, setPager] = useState({});
  const [inputValue, setInputValue] = useState(initialPage);

  useEffect(() => {
    if (totalItems) {
      const newPager = paginate(totalItems, initialPage, pageSize, maxPages);
      setPager(newPager);
      setInputValue(newPager.currentPage);
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalItems]);

  const onEnterPress = e => {
    const keyCode = e.code || e.key;

    if (keyCode === 13 || keyCode === 'Enter') {
      // Enter pressed
      setPage(e.target.value);
    }
  };

  const handleOnChange = e => {
    setInputValue(e.target.value);
  };

  const setPage = page => {
    let newPage = page;
    // making sure wanted page isn't larger than number of total pages.
    if (pager.maxPages && newPage > pager.maxPages) {
      newPage = pager.maxPages;
    }

    // get new pager object for specified page
    const newPager = paginate(totalItems, newPage, pageSize, maxPages);

    // check if confirmation is required before switching page
    if (confirmNextPage) {
      return onChangePage({
        pageNumber: newPager.currentPage,
        startIndex: newPager.startIndex,
        endIndex: newPager.endIndex,
      });
    }

    // update state
    setPager(newPager);
    setInputValue(newPager.currentPage);

    // call change page function in parent component with currentPage
    return onChangePage({
      pageNumber: newPager.currentPage,
      startIndex: newPager.startIndex,
      endIndex: newPager.endIndex,
    });
  };

  if (!pager.pages || pager.pages.length <= 1) {
    // don't display pager if there is only 1 page
    return null;
  }

  const isCurrentPageExists = !!pager && !!pager.currentPage;
  const isFirstPage = isCurrentPageExists && pager.currentPage === 1;
  const isLastPage =
    isCurrentPageExists && pager.currentPage === pager.totalPages;
  const numberOfCurrentPageItems = pager.endIndex - pager.startIndex + 1;

  return (
    <$Pagination data-cy="pagination_component">
      <$NumberOfItems>
        {`${numberOfCurrentPageItems} of `}
        <span data-cy="total_number_of_items" data-total-items={totalItems}>
          {formatNumber(totalItems)}
        </span>
        {` items`}
      </$NumberOfItems>
      <$PaginationControl>
        <$NumberOfPagesContainer>
          Page
          <div style={{ position: 'relative' }}>
            <$CurrentPageInput
              type="text"
              onChange={handleOnChange}
              value={inputValue}
              onKeyPress={onEnterPress}
              data-cy="current_page_input"
            />
            <$IconRight>
              <img
                alt="Press Enter to submit"
                src="/icons/enter-arrow.svg"
                height={16}
              />
            </$IconRight>
          </div>
          of&nbsp;
          <span data-cy="total_number_of_pages">{pager.totalPages}</span>
        </$NumberOfPagesContainer>
        <$ArrowButtonsContainer>
          <$ArrowButton
            name="ArrowBack"
            onClick={() => setPage(Number(pager.currentPage) - 1)}
            size={16}
            isDisabled={isFirstPage}
            data-cy="last_page_button"
          />
          <$Divider />
          <$ArrowButton
            name="ArrowForward"
            onClick={() => setPage(Number(pager.currentPage) + 1)}
            size={16}
            isDisabled={isLastPage}
            data-cy="next_page_button"
          />
        </$ArrowButtonsContainer>
      </$PaginationControl>
    </$Pagination>
  );
};

Pagination.propTypes = {
  totalItems: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  pageSize: PropTypes.number,
  maxPages: PropTypes.number,
  initialPage: PropTypes.number,
  confirmNextPage: PropTypes.bool,
};

Pagination.defaultProps = {
  pageSize: 10,
  maxPages: 5,
  initialPage: 1,
  confirmNextPage: false,
};

export default Pagination;

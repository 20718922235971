import React from 'react';
import PropTypes from 'prop-types';
import {
  $TooltipContentContainer,
  $SelectOption,
  $CheckmarkIcon,
} from './styles';

const SelectOptionsContent = ({ onSubmit, options, value }) => {
  const onOptionClicked = newValue => {
    // no option selected, don't call onSubmit
    if (value === newValue) return;

    onSubmit(newValue);
  };
  return (
    <$TooltipContentContainer>
      {options.map(item => (
        <$SelectOption
          onClick={() => onOptionClicked(item.value)}
          value={item.value}
          data-cy={`select_option_${item.value}`}
          key={item.value}
        >
          {item.label}
          {value === item.value && (
            <$CheckmarkIcon name="Checkmark" size={16} />
          )}
        </$SelectOption>
      ))}
    </$TooltipContentContainer>
  );
};

SelectOptionsContent.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ).isRequired,
  value: PropTypes.string,
};

SelectOptionsContent.defaultProps = {
  value: undefined,
};

export default SelectOptionsContent;

import { actions } from './webhooksActions';

const initialState = {
  // Logs
  webhookUrl: '',
  webhookLogs: [],
  webhooksTotalElements: 0,

  // Destinations
  loadingWebhookDestinations: false,
  webhookDestinations: [],
  webhookDestinationsTableData: [],
  webhookDestinationsHasError: false,
  defaultWebhookDestination: null,

  // Event Types
  webhookEventTypes: [],
};

export default function reducer(state = initialState, { type, payload }) {
  if (type === actions.GET_WEBHOOKS_LOGS_SUCCESS) {
    return {
      ...state,
      webhookUrl: payload?.webhook_url,
      webhookLogs: payload?.webhooks?.content,
      webhooksTotalElements: payload?.webhooks?.total_elements,
    };
  }

  if (type === actions.GET_WEBHOOKS_DESTINATIONS_STARTED) {
    return {
      ...state,
      loadingWebhookDestinations: true,
    };
  }

  if (type === actions.GET_WEBHOOKS_DESTINATIONS_SUCCESS) {
    return {
      ...state,
      webhookDestinations: payload,
      webhookDestinationsTableData: payload.map(({ handle, url, ...rest }) => ({
        handle,
        'webhook URL': url,
        hidden: rest,
        actions: '',
      })),
      loadingWebhookDestinations: false,
    };
  }

  if (type === actions.GET_WEBHOOKS_DESTINATIONS_ERROR) {
    return {
      ...state,
      loadingWebhookDestinations: false,
      webhookDestinationsHasError: true,
    };
  }

  if (
    type === actions.GET_DEFAULT_WEBHOOK_SUCCESS ||
    type === actions.UPDATE_DEFAULT_WEBHOOK_SUCCESS
  ) {
    return {
      ...state,
      defaultWebhookDestination: payload,
    };
  }

  if (type === actions.GET_WEBHOOK_EVENT_TYPES_SUCCESS) {
    return {
      ...state,
      webhookEventTypes: payload,
    };
  }

  return state;
}

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Accordion as AccordionReach } from '@reach/accordion';

// Get the index to use for the default open item
const getDefaultIndex = defaultIndex => {
  if (defaultIndex < 0) {
    return [];
  }

  // If the default index is not a number or is not provided, use the first item
  if (typeof defaultIndex !== 'number' || !defaultIndex) {
    return [0];
  }

  return [defaultIndex];
};

const Accordion = ({ defaultIndex, children }) => {
  const [indices, setIndices] = useState(getDefaultIndex(defaultIndex));

  const toggleItem = toggledIndex => {
    if (indices.includes(toggledIndex)) {
      setIndices(indices.filter(currentIndex => currentIndex !== toggledIndex));
    } else {
      setIndices([toggledIndex]);
    }
  };
  return (
    <AccordionReach index={indices} onChange={toggleItem}>
      {children}
    </AccordionReach>
  );
};

Accordion.propTypes = {
  defaultIndex: PropTypes.number,
  children: PropTypes.node.isRequired,
};

export default Accordion;

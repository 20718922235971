/* eslint-disable camelcase */
import { apiFetch } from 'modules/shared/fetch';
import Errors from 'modules/shared/error/errors';
import { createSelector } from 'reselect';
import { getDateAndTimeAsNumbers } from 'utils';

const prefix = 'dev-portal/logs/dataRequests';
// Actions
const actionTypes = {
  GET_DATA_REQUESTS_LOGS_STARTED: `${prefix}/GET_DATA_REQUESTS_LOGS_STARTED`,
  GET_DATA_REQUESTS_LOGS_SUCCESS: `${prefix}/GET_DATA_REQUESTS_LOGS_SUCCESS`,
  GET_DATA_REQUESTS_LOGS_ERROR: `${prefix}/GET_DATA_REQUESTS_LOGS_ERROR`,

  GET_SINGLE_DATA_REQUESTS_LOG_STARTED: `${prefix}/GET_SINGLE_DATA_REQUESTS_LOG_STARTED`,
  GET_SINGLE_DATA_REQUESTS_LOG_SUCCESS: `${prefix}/GET_SINGLE_DATA_REQUESTS_LOG_SUCCESS`,
  GET_SINGLE_DATA_REQUESTS_LOG_ERROR: `${prefix}/GET_SINGLE_DATA_REQUESTS_LOG_ERROR`,
};

// Reducer
const initialState = {
  loading: false,
  items: [],
  entries_total: 0,
  singleItem: {},
  singleItemLoading: false,
};

export default function reducer(state = initialState, { type, payload }) {
  if (type === actionTypes.GET_DATA_REQUESTS_LOGS_STARTED) {
    return {
      ...state,
      loading: true,
    };
  }

  if (type === actionTypes.GET_DATA_REQUESTS_LOGS_SUCCESS) {
    return {
      ...state,
      loading: false,
      ...payload,
    };
  }

  if (type === actionTypes.GET_DATA_REQUESTS_LOGS_ERROR) {
    return {
      ...initialState,
    };
  }

  if (type === actionTypes.GET_SINGLE_DATA_REQUESTS_LOG_STARTED) {
    return {
      ...state,
      singleItemLoading: true,
    };
  }

  if (type === actionTypes.GET_SINGLE_DATA_REQUESTS_LOG_SUCCESS) {
    return {
      ...state,
      singleItemLoading: false,
      singleItem: payload,
    };
  }

  if (type === actionTypes.GET_SINGLE_DATA_REQUESTS_LOG_ERROR) {
    return {
      ...state,
      singleItemLoading: false,
      singleItem: {},
    };
  }

  return state;
}

// Action Creators
const doGetDataRequestsLogs =
  (appToken, body) => async (dispatch, getState) => {
    try {
      dispatch({
        type: actionTypes.GET_DATA_REQUESTS_LOGS_STARTED,
      });

      const { environment } = getState().root.global;
      const response = await getDataRequestsLogs(appToken, body, {
        appEnvironment: environment,
      });

      dispatch({
        type: actionTypes.GET_DATA_REQUESTS_LOGS_SUCCESS,
        payload: response,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: actionTypes.GET_DATA_REQUESTS_LOGS_ERROR,
      });
    }
  };

const doGetSingleDataRequestsLog =
  (appToken, appId) => async (dispatch, getState) => {
    try {
      dispatch({
        type: actionTypes.GET_SINGLE_DATA_REQUESTS_LOG_STARTED,
      });

      const { environment } = getState().root.global;
      const response = await getSingleDataRequestsLog(appToken, appId, {
        appEnvironment: environment,
      });

      dispatch({
        type: actionTypes.GET_SINGLE_DATA_REQUESTS_LOG_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: actionTypes.GET_SINGLE_DATA_REQUESTS_LOG_ERROR,
      });
    }
  };

export const actions = {
  doGetDataRequestsLogs,
  doGetSingleDataRequestsLog,
};

// APIs
/**
 * POST /logs/applications/:appToken/data-requests
 * @param {string} appToken
 * @param {object} body
 * @param {string} appEnvironment
 */
const getDataRequestsLogs = async (appToken, body, { appEnvironment }) => {
  const response = await apiFetch(
    `/logs/applications/${appToken}/data-requests`,
    { body, appEnvironment },
  );

  return response.data;
};

/**
 * GET /logs/applications/:appToken/data-requests/:logId
 * @param {string} appToken
 * @param {string} logId
 * @param {string} appEnvironment
 */
const getSingleDataRequestsLog = async (
  appToken,
  logId,
  { appEnvironment },
) => {
  const response = await apiFetch(
    `/logs/applications/${appToken}/data-requests/${logId}`,
    { appEnvironment },
  );

  return response.data;
};

// Selectors
const selectDataRequests = state => state.root.logs.dataRequests;

const selectLogs = createSelector([selectDataRequests], dataRequests => {
  const { loading, ...rest } = dataRequests;
  return rest;
});

const selectFilteredTableItems = createSelector([selectLogs], logs =>
  logs.items.map(item => {
    const { endpoint, status, response_code, timestamp, request_id } = item;
    return {
      status: { status, response_code },
      endpoint: { endpoint },
      'Results ID': { request_id },
      Date: { timestamp },
    };
  }),
);

const selectSingleLogDetails = createSelector(
  [selectDataRequests],
  dataRequests => {
    const { singleItem } = dataRequests;
    if (!singleItem.request_id) return { isError: true };
    const { timestamp, ...rest } = singleItem;
    const { date, time } = getDateAndTimeAsNumbers(new Date(timestamp));
    return {
      ...rest,
      timestamp: `${time} ${date}`,
    };
  },
);

const selectLoading = createSelector(
  [selectDataRequests],
  dataRequests => dataRequests.loading,
);

const selectSingleItemLoading = createSelector(
  [selectDataRequests],
  dataRequests => dataRequests.singleItemLoading,
);

export const selectors = {
  selectLogs,
  selectLoading,
  selectFilteredTableItems,
  selectSingleLogDetails,
  selectSingleItemLoading,
};

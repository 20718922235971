import { v4 as uuid } from 'uuid';
import { i18n } from 'i18n';
import filesize from 'filesize';
import Errors from 'modules/shared/error/errors';
import { apiFetch } from 'modules/shared/fetch';

function extractExtensionFrom(filename) {
  if (!filename) {
    return null;
  }

  const regex = /(?:\.([^.]+))?$/;
  return regex.exec(filename)[1];
}

export default class FileUploader {
  static validate(file, schema) {
    if (!schema) {
      return;
    }

    if (schema.image) {
      if (!file.type.startsWith('image')) {
        throw new Error(i18n('fileUploader.image'));
      }
    }

    if (schema.size && file.size > schema.size) {
      throw new Error(i18n('fileUploader.size', filesize(schema.size)));
    }

    const extension = extractExtensionFrom(file.name);

    if (schema.formats && !schema.formats.includes(extension)) {
      throw new Error(i18n('fileUploader.formats', schema.formats.join('/')));
    }
  }

  static uploadFromRequest(
    path,
    file,
    schema,
    appToken,
    environment,
    onSuccess,
    onError,
  ) {
    try {
      this.validate(file, schema);
    } catch (error) {
      onError(error);
      return;
    }

    const extension = extractExtensionFrom(file.name);
    const id = uuid();
    const filename = `${id}.${extension}`;
    const privateUrl = `${path}/${filename}`;

    this.uploadToServer(file, appToken, environment)
      .then(publicUrl => {
        onSuccess({
          id,
          name: file.name,
          sizeInBytes: file.size,
          privateUrl,
          publicUrl,
          new: true,
        });
      })
      .catch(error => {
        onError(error);
      });
  }

  static async uploadToServer(file, appToken, environment) {
    try {
      const formData = new FormData();
      formData.append('file', file);

      // Get PreSigned URL
      const response = await apiFetch(`/applications/${appToken}/upload-logo`, {
        appEnvironment: environment,
        method: 'POST',
        headers: {
          'lean-app-token': appToken,
        },
        body: formData,
        stringifyBody: false,
        removeContentType: true,
      });
      return response.data;
    } catch (err) {
      Errors.handle(err);
      return err;
    }
  }
}

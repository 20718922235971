import { customAlphabet } from 'nanoid';

const nanoid = customAlphabet(
  '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz',
  21,
);

export function createUniqueId(organizationName) {
  return organizationName.replace(/[^a-zA-Z0-9]+/gi, '') + nanoid();
}

export function createCorrelationId() {
  return nanoid(16);
}

import { actions } from './depositsActions';
import {
  STATUS__FAILED,
  STATUS__SUCCESS,
  DEPOSITS_STATUSES__FAILED,
  DEPOSITS_STATUSES__PROCESSING,
} from '../../view/deposits/constants';

const initialState = {
  banks: [],
  accounts: [],
  customerId: '',
  depositsSourceId: '',
  depositsReconnectId: '',
  isSelectBankStep: false,
  fetchAccountsStatus: '',
  depositsUploadStatus: '',
  isFetchingAccounts: false,
  isUpdateDepositsStep: false,
  isDepositsDialogOpen: false,
  addDepositBankAccountStatus: '',
  isAddingDepositBankDetails: false,
  depositsUploadStatusRequestId: '',
  uploadedDepositsSourceList: [],
  isDepositsSourceUploadStarted: false,
  selectedDepositsBankAccountDetails: {},
};

export default function reducer(state = initialState, { type, payload }) {
  if (type === actions.GET_DEPOSITS_SOURCE_BANKS_LIST_SUCCESS) {
    return {
      ...state,
      banks: payload,
    };
  }

  if (type === actions.GET_UPLOADED_DEPOSITS_SOURCE_LIST_SUCCESS) {
    return {
      ...state,
      uploadedDepositsSourceList: payload,
    };
  }

  if (type === actions.ADD_DEPOSITS_SOURCE_STARTED) {
    return {
      ...state,
      isDepositsSourceUploadStarted: true,
    };
  }

  if (type === actions.ADD_DEPOSITS_SOURCE_SUCCESS) {
    return {
      ...state,
      isDepositsSourceUploadStarted: false,
      depositsUploadStatus: DEPOSITS_STATUSES__PROCESSING,
      depositsUploadStatusRequestId: payload.request_id,
    };
  }

  if (type === actions.ADD_DEPOSITS_SOURCE_ERROR) {
    return {
      ...state,
      isDepositsSourceUploadStarted: false,
      depositsUploadStatus: DEPOSITS_STATUSES__FAILED,
    };
  }

  if (type === actions.REFRESH_BANK_DEPOSIT_SOURCE_STARTED) {
    return {
      ...state,
      isDepositsSourceUploadStarted: true,
    };
  }

  if (type === actions.REFRESH_BANK_DEPOSIT_SOURCE_SUCCESS) {
    return {
      ...state,
      isDepositsSourceUploadStarted: false,
      depositsReconnectId: payload?.reconnect_id,
      depositsUploadStatus: DEPOSITS_STATUSES__PROCESSING,
      depositsUploadStatusRequestId: payload.request_id,
    };
  }

  if (type === actions.REFRESH_BANK_DEPOSIT_SOURCE_ERROR) {
    return {
      ...state,
      isDepositsSourceUploadStarted: false,
      depositsUploadStatus: DEPOSITS_STATUSES__FAILED,
    };
  }

  if (type === actions.CLEAR_RECONNECT_ID) {
    return {
      ...state,
      depositsReconnectId: '',
    };
  }

  if (type === actions.ADD_DEPOSITS_BANK_DETAILS_STARTED) {
    return {
      ...state,
      addDepositBankAccountStatus: '',
      isAddingDepositBankDetails: true,
    };
  }

  if (type === actions.ADD_DEPOSITS_BANK_DETAILS_SUCCESS) {
    return {
      ...state,
      isAddingDepositBankDetails: false,
      addDepositBankAccountStatus: STATUS__SUCCESS,
      depositsSourceId: payload.source_id,
    };
  }

  if (type === actions.ADD_DEPOSITS_BANK_DETAILS_ERROR) {
    return {
      ...state,
      isAddingDepositBankDetails: false,
      addDepositBankAccountStatus: STATUS__FAILED,
    };
  }

  if (type === actions.CANCEL_ADD_DEPOSITS_BANK_DETAILS_STATUS) {
    return {
      ...state,
      addDepositBankAccountStatus: '',
    };
  }

  if (type === actions.GET_CUSTOMER_ID_SUCCESS) {
    return {
      ...state,
      customerId: payload.customer_id,
    };
  }

  if (type === actions.GET_DEPOSITS_REQUEST_STATUS_SUCCESS) {
    return {
      ...state,
      depositsUploadStatus: payload.status,
    };
  }

  if (type === actions.CANCEL_GET_DEPOSITS_UPLOAD_STATUS) {
    return {
      ...state,
      depositsUploadStatus: '',
    };
  }

  if (type === actions.OPEN_DEPOSITS_DIALOG) {
    return {
      ...state,
      isDepositsDialogOpen: true,
    };
  }

  if (type === actions.CLOSE_DEPOSITS_DIALOG) {
    return {
      ...state,
      isDepositsDialogOpen: false,
    };
  }

  if (type === actions.SET_UPDATE_DEPOSITS_STEP) {
    return {
      ...state,
      // Note: in the future when we support deleting of a deposit source,
      // we need to reset this state in case a user's deposit sources all gets deleted
      isSelectBankStep: false, // reset select bank state
      isUpdateDepositsStep: true,
    };
  }

  if (type === actions.SET_SELECT_BANK_ACCOUNT_STEP) {
    return {
      ...state,
      isSelectBankStep: payload.isSelectBankStep,
      isUpdateDepositsStep: false, // reset update deposit state
    };
  }

  if (type === actions.SET_SELECTED_BANK_DETAILS) {
    return {
      ...state,
      selectedDepositsBankAccountDetails: payload,
    };
  }

  if (type === actions.GET_ENTITY_BANK_ACCOUNTS_STARTED) {
    return {
      ...state,
      isFetchingAccounts: true,
    };
  }

  if (type === actions.GET_ENTITY_BANK_ACCOUNTS_SUCCESS) {
    return {
      ...state,
      isFetchingAccounts: false,
      accounts: payload,
      fetchAccountsStatus: STATUS__SUCCESS,
    };
  }

  if (type === actions.GET_ENTITY_BANK_ACCOUNTS_ERROR) {
    return {
      ...state,
      isFetchingAccounts: false,
      fetchAccountsStatus: STATUS__FAILED,
    };
  }

  return state;
}

import { apiFetch } from 'modules/shared/fetch';
import { AuthToken } from 'modules/auth/authToken';
import { generateFingerprint } from 'utils';

export default class AuthService {
  static async signup(body) {
    const fingerprint = await generateFingerprint();

    return apiFetch('/auth/signup', {
      body: {
        ...body,
        device_hash: fingerprint,
      },
    });
  }

  static async signinUsernameEmailPassword(
    userNameOrEmail,
    password,
    includeCredentials = false,
  ) {
    const fingerprint = await generateFingerprint();

    const config = {
      body: {
        user_name_or_email: userNameOrEmail,
        password,
        device_hash: fingerprint,
      },
    };

    if (includeCredentials) {
      config.credentials = 'include';
    }

    const response = await apiFetch('/auth/signin', config);

    return response.data;
  }

  static async sendPasswordResetEmail(email) {
    const response = await apiFetch('/auth/reset-password', {
      body: {
        email,
      },
    });

    return response.data;
  }

  static async getPasswordResetDetails(token) {
    const response = await apiFetch(`/auth/reset-password?token=${token}`);

    return response.data;
  }

  static async passwordReset(token, password, code) {
    const response = await apiFetch('/auth/set-new-password', {
      body: {
        token,
        password,
        code,
      },
    });

    return response.data;
  }

  static async passwordChange(password, code) {
    const response = await apiFetch('/auth/change-password', {
      body: {
        password,
        code,
      },
    });

    return response.data;
  }

  static async fetchProfile() {
    const response = await apiFetch('/users/current');
    const { data } = response;

    // TEMPORARY HACK !!!! =========👇

    data.phone_number = '12345678';
    data.disabled = false;
    data.email_verified = true;

    // TEMPORARY HACK !!!! ========👆

    return data;
  }

  static async fetchReadmeToken(appToken, { appEnvironment }) {
    const response = await apiFetch(`/applications/${appToken}/docs-token`, {
      appEnvironment,
    });

    const { data } = response;
    return data.token;
  }

  static async verifyEmail(token) {
    const response = await apiFetch(`/auth/confirmEmail?token=${token} `);
    return response.data;
  }

  static async resendEmailVerification(email) {
    const response = await apiFetch(`/auth/resendEmailVerification`, {
      body: {
        email,
      },
    });

    return response.data;
  }

  static signout() {
    AuthToken.set(null, true);
  }

  static async updateProfile(id, body) {
    const response = await apiFetch(`/users/${id}`, {
      method: 'PATCH',
      body,
    });

    return response.data;
  }

  static async updateAccount(body) {
    const response = await apiFetch(`/users/v1/update`, {
      method: 'PUT',
      body,
    });

    return response.data;
  }

  static async verifyInvitationToken(token) {
    const response = await apiFetch(`/users/v1/invites/${token}`, {
      method: 'GET',
    });

    return response.data;
  }

  static async acceptInvitation(body) {
    const fingerprint = await generateFingerprint();

    const response = await apiFetch(`/users/v1/invites/join`, {
      method: 'POST',
      body: {
        ...body,
        device_hash: fingerprint,
      },
    });

    return response.data;
  }

  static async verifySignInMfa(code) {
    const fingerprint = await generateFingerprint();

    const response = await apiFetch('/auth/signin/verify-mfa', {
      body: {
        code,
        device_hash: fingerprint,
      },
      method: 'POST',
      credentials: 'include',
    });

    return response.data;
  }

  static async verifyUserUpdateMfa(code) {
    const response = await apiFetch('/users/update/verify-mfa', {
      body: { code },
      method: 'POST',
    });

    return response.data;
  }

  static async confirmUserUpdateMfa(code) {
    const response = await apiFetch('/users/update/confirm-mfa', {
      body: { code },
      method: 'POST',
    });

    return response.data;
  }

  static async getMfaDetails() {
    const response = await apiFetch('/users/update/mfa-details');

    return response.data;
  }
}

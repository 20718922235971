import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import CertificateService from 'modules/certificate/certificateService';
import Errors from 'modules/shared/error/errors';

import Message from 'view/shared/message';
import { i18n } from 'i18n';
import ApplicationService from 'modules/application/applicationService';

const prefix = 'dev-portal/certificate';
const { analytics } = window;

const actions = {
  GET_CERTCHAIN_STARTED: `${prefix}/CERTCHAIN_STARTED`,
  GET_CERTCHAIN_SUCCESS: `${prefix}/CERTCHAIN_SUCCESS`,
  GET_CERTCHAIN_ERROR: `${prefix}/CERTCHAIN_ERROR`,

  CREATE_STARTED: `${prefix}/CREATE_STARTED`,
  CREATE_SUCCESS: `${prefix}/CREATE_SUCCESS`,
  CREATE_ERROR: `${prefix}/CREATE_ERROR`,

  REVOKE_STARTED: `${prefix}/REVOKE_STARTED`,
  REVOKE_SUCCESS: `${prefix}/REVOKE_SUCCESS`,
  REVOKE_ERROR: `${prefix}/REVOKE_ERROR`,

  OAUTH_SECRET_STARTED: `${prefix}/OAUTH_SECRET_STARTED`,
  OAUTH_SECRET_SUCCESS: `${prefix}/OAUTH_SECRET_SUCCESS`,
  OAUTH_SECRET_ERROR: `${prefix}/OAUTH_SECRET_ERROR`,
  OAUTH_SECRET_CLEAR: `${prefix}/OAUTH_SECRET_CLEAR`,

  SHOW_CONFIRM_DIALOG: `${prefix}/SHOW_CONFIRM_DIALOG`,
  HIDE_CONFIRM_DIALOG: `${prefix}/HIDE_CONFIRM_DIALOG`,
  SHOW_SECRET_CONFIRM_DIALOG: `${prefix}/SHOW_SECRET_CONFIRM_DIALOG`,
  HIDE_SECRET_CONFIRM_DIALOG: `${prefix}/HIDE_SECRET_CONFIRM_DIALOG`,

  // Get Lean's public certificate chain
  doGetPublicChain: () => async (dispatch, getState) => {
    try {
      dispatch({
        type: actions.GET_CERTCHAIN_STARTED,
      });
      const { environment } = getState().root.global;

      const certChain = await CertificateService.getPublicCertChain({
        appEnvironment: environment,
      });
      const { data } = certChain;

      // Download public certificate chain
      const zip = new JSZip();
      zip.file(`lean_public_cert_chain.pem`, data);
      zip.generateAsync({ type: 'blob' }).then(content => {
        saveAs(content, `lean_public_cert_chain.zip`);
      });

      dispatch({
        type: actions.GET_CERTCHAIN_SUCCESS,
      });
      analytics.track('certificatesGetCertChainSuccess', {});
    } catch (error) {
      Errors.handle(error);

      analytics.track('certificatesGetCertChainError', {});
      dispatch({
        type: actions.GET_CERTCHAIN_ERROR,
      });
    }
  },

  doCreateBack: id => async (dispatch, getState) => {
    try {
      dispatch({
        type: actions.CREATE_STARTED,
      });

      const { environment } = getState().root.global;

      const application = await ApplicationService.get(id, {
        appEnvironment: environment,
      });

      // get certificate chain
      const certChain = await CertificateService.getPublicCertChain({
        appEnvironment: environment,
      });
      const { data: certChainData } = certChain;

      const { forge } = window;

      forge.pki.rsa.generateKeyPair(
        { bits: 2048, workers: 2 },
        async (err, keys) => {
          if (err) throw err;

          // Convert the Public Key to PEM
          const publicKey = await forge.pki.publicKeyToPem(keys.publicKey);

          // Convert the Private Key to PEM
          // convert a Forge private key to an ASN.1 RSAPrivateKey
          const rsaPrivateKey = await forge.pki.privateKeyToAsn1(
            keys.privateKey,
          );
          // wrap an RSAPrivateKey ASN.1 object in a PKCS#8 ASN.1 PrivateKeyInfo
          const privateKeyInfo = await forge.pki.wrapRsaPrivateKey(
            rsaPrivateKey,
          );
          // convert a PKCS#8 ASN.1 PrivateKeyInfo to PEM
          const privateKey = await forge.pki.privateKeyInfoToPem(
            privateKeyInfo,
          );

          // Generate hash from Public Key + Common Name
          const publicKeyHash = forge.md.sha256.create();
          publicKeyHash.update(publicKey);

          // Get the app token id
          const { id: appId } = application;
          const appToken = appId;

          // Generate Certificate
          const csr = await forge.pki.createCertificationRequest();
          csr.publicKey = keys.publicKey;
          csr.setSubject([
            {
              name: 'commonName',
              value: application.common_name,
            },
          ]);
          await csr.sign(keys.privateKey);

          // Convert Certificate Request to PEM
          const certificateRequest = await forge.pki.certificationRequestToPem(
            csr,
          );

          // Call Certificate Service
          const signedCertificate = await CertificateService.signCertificate(
            certificateRequest,
            'signedCert',
            appToken,
            { appEnvironment: environment },
          );

          // concatenating the public certificate chain and the signed certificate.
          // See https://leantechnologies.atlassian.net/browse/SDK-285
          const publicCertConcatenatedWithChain = `${signedCertificate}${certChainData}`;

          dispatch({
            type: actions.SHOW_CONFIRM_DIALOG,
            payload: {
              privateKey,
              applicationId: id,
              applicationName: application.name,
              publicCertConcatenatedWithChain,
            },
          });
        },
      );
    } catch (error) {
      Errors.handle(error);
      analytics.track('certificatesRenewError', {
        applicationId: id,
      });
      dispatch({
        type: actions.CREATE_ERROR,
      });
    }
  },

  doRevoke: appToken => async (dispatch, getState) => {
    try {
      dispatch({
        type: actions.REVOKE_STARTED,
      });

      const { environment } = getState().root.global;

      await CertificateService.revoke(appToken, {
        appEnvironment: environment,
      });

      dispatch({
        type: actions.REVOKE_SUCCESS,
      });

      Message.success(i18n('entities.certificate.revoke.success'));

      analytics.track('certificatesRevokeSuccess', {
        applicationToken: appToken,
      });
      // getHistory().push('/application');
    } catch (error) {
      Errors.handle(error);

      analytics.track('certificatesRevokeError', {
        applicationToken: appToken,
      });
      dispatch({
        type: actions.REVOKE_ERROR,
      });
    }
  },

  doGetClientSecret: appToken => async (dispatch, getState) => {
    try {
      dispatch({
        type: actions.OAUTH_SECRET_STARTED,
      });

      const { environment } = getState().root.global;

      const response = await CertificateService.getOrCreateClientSecret(appToken, {
        appEnvironment: environment,
      });

      dispatch({
        type: actions.OAUTH_SECRET_SUCCESS,
        payload: response
      });

      analytics.track('oauthSecretSuccess', {
        applicationToken: appToken,
      });
    } catch (error) {
      Errors.handle(error);

      analytics.track('oauthSecretError', {
        applicationToken: appToken,
      });
      dispatch({
        type: actions.OAUTH_SECRET_ERROR,
      });
    }
  },

  doCancelViewSecret: () => dispatch => {
    dispatch({
      type: actions.HIDE_SECRET_CONFIRM_DIALOG,
    });
  },

  doShowSecretDialog: () => dispatch => {
    dispatch({
      type: actions.SHOW_SECRET_CONFIRM_DIALOG,
    });
  },

  doClearOauthSecret: () => dispatch => {
    dispatch({
      type: actions.OAUTH_SECRET_CLEAR,
    });
  },

  doConfirmCertificateDownload:
    ({
      privateKey,
      applicationId,
      applicationName,
      publicCertConcatenatedWithChain,
    }) =>
    async dispatch => {
      Message.success(i18n('entities.certificate.regenerate.success'));

      // Download private key and certificate
      const zip = new JSZip();

      zip.file(
        `${applicationName}_certificate.crt`,
        publicCertConcatenatedWithChain,
      );
      zip.file(`${applicationName}_private_key.pem`, privateKey);
      zip.generateAsync({ type: 'blob' }).then(content => {
        saveAs(content, `${applicationName}.zip`);
      });

      analytics.track('certificatesRenewSuccess', {
        applicationId,
      });

      dispatch({
        type: actions.CREATE_SUCCESS,
      });

      dispatch({
        type: actions.HIDE_CONFIRM_DIALOG,
      });
    },

  doCancelCertificateDownload: applicationId => dispatch => {
    analytics.track('certificatesRenewCancel', {
      applicationId,
    });

    dispatch({
      type: actions.CREATE_ERROR,
    });

    dispatch({
      type: actions.HIDE_CONFIRM_DIALOG,
    });
  },
};

export default actions;

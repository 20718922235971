export type UnsupportedBank = {
  name: string;
  arabic_name: string | null;
  identifier: string;
  logo_alt: string;
  is_active: boolean;
};

export const unsupportedBanks: UnsupportedBank[] = [
  {
    name: 'Alrajhi Bank',
    arabic_name: 'مصرف الراجحي',
    identifier: 'RAJHI_SAU',
    logo_alt:
      'https://cdn.leantech.me/img/bank-assets/sau/glyphs/rajhi/g-color-rajhi_sau.png',
    is_active: false,
  },
  {
    name: 'D360 Bank',
    arabic_name: 'بنك D360',
    identifier: 'D360_SAU',
    logo_alt:
      'https://cdn.leantech.me/img/bank-assets/sau/glyphs/d360/g-color-d360_sau.png',
    is_active: false,
  },
  {
    name: 'Emirates NBD',
    arabic_name: 'بنك الإمارات دبي الوطني',
    identifier: 'ENBD_SAU',
    logo_alt:
      'https://cdn.leantech.me/img/bank-assets/sau/glyphs/enbd/g-color-enbd_sau.png',
    is_active: false,
  },
  {
    name: 'FAB',
    arabic_name: 'بنك أبوظبي الأول',
    identifier: 'FAB_SAU',
    logo_alt:
      'https://cdn.leantech.me/img/bank-assets/sau/glyphs/fab/g-color-fab_sau.png',
    is_active: false,
  },
  {
    name: 'STC Bank',
    arabic_name: 'المدفوعات الرقمية السعودية',
    identifier: 'STC_SAU',
    logo_alt:
      'https://cdn.leantech.me/img/bank-assets/sau/glyphs/stc/g-color-stc_sau.png',
    is_active: false,
  },
];

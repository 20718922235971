import styled from 'styled-components/macro';

const $Topnav = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: center;

  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral._20};
  background-color: ${({ theme }) => theme.colors.blender._10};
  backdrop-filter: blur(8px);
  position: sticky;
  top: 0;
  z-index: 1;

  section {
    display: flex;
    align-items: center;
  }
`;

export const $NavigationAndBreadcrumbs = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${({ theme }) => theme.layout.topnav.height};
`;

export default $Topnav;

import styled from 'styled-components/macro';

export const $Title = styled.span`
  font-family: ${({ theme }) => theme.font.family.heading};
  font-size: ${({ theme }) => theme.font.size.heading.sm};
  margin-right: 8px;
`;

export const $ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 80%;
`;

export const $HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;
`;

export const $DetailsCell = styled.div`
  display: flex;
  flex-direction: ${({ styledProps }) => styledProps.direction};
  align-items: ${({ styledProps }) =>
    styledProps.direction === 'row' ? 'center' : 'flex-start'};
  justify-content: ${({ styledProps }) =>
    styledProps.direction === 'row' ? 'flex-start' : 'center'};
`;

export const $CellTitle = styled.span`
  font-size: ${({ theme }) => theme.font.size.body.sm};
  color: ${({ theme }) => theme.colors.neutral._80};
  ${({ styledProps }) =>
    styledProps.direction === 'row'
      ? 'margin-right: 8px;'
      : 'margin-bottom: 6px;'}
`;

export const $CellData = styled.span`
  font-size: ${({ theme }) => theme.font.size.body.md};
  color: ${({ theme }) => theme.colors.black};
  font-family: ${({ theme, styledProps }) =>
    theme.font.family[styledProps.font]};
`;

export const $DetailsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 8px 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral._30};
`;

export const $EmptyStateText = styled.span`
  font-size: ${({ theme }) => theme.font.size.body.md};
  color: ${({ theme }) => theme.colors.neutral._60};
`;

export const $EmptyStateImage = styled.img`
  width: 362px;
`;

export const $BackButton = styled.div`
  background-color: ${({ theme }) => theme.colors.neutral._30};
  cursor: pointer;
  width: 28px;
  height: 28px;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
`;

export const $TitleAndBackButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

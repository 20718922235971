import React from 'react';
import { connect } from 'react-redux';
import { Loading, Main, Menu, Sidenav, Topnav } from 'components';
import authSelectors from 'modules/auth/authSelectors';
import applicationListSelectors from 'modules/application/list/applicationListSelectors';
import { toggleEnvironmentValue, selectEnvironment } from 'modules/global';
import { $Layout } from 'styles';
import { currentUserPropTypes } from 'utils/proptypes';

const Layout = ({
  appId,
  match,
  scopes,
  appName,
  children,
  appLogoUrl,
  environment,
  currentUser,
  toggleEnvironmentValue,
  isToggleEnvironmentPossible,
}) => {
  if (!currentUser) {
    return <Loading />;
  }

  const handleToggleEnvironment = environment =>
    toggleEnvironmentValue({ currentRoutePath: match.path, environment });

  return (
    <$Layout>
      <Topnav match={match} currentUser={currentUser} />

      <Sidenav>
        <Menu
          appId={appId}
          match={match}
          scopes={scopes}
          appName={appName}
          appLogoUrl={appLogoUrl}
          environment={environment}
          currentUser={currentUser}
          toggleEnvironmentValue={handleToggleEnvironment}
          isToggleEnvironmentPossible={isToggleEnvironmentPossible}
        />
      </Sidenav>

      <Main>{children}</Main>
    </$Layout>
  );
};

Layout.propTypes = {
  currentUser: currentUserPropTypes,
  ...Menu.propTypes,
};

Layout.defaultProps = {
  ...Menu.defaultProps,
};

const mapStateToProps = state => ({
  currentUser: authSelectors.selectCurrentUser(state),
  environment: selectEnvironment(state),
  isToggleEnvironmentPossible:
    applicationListSelectors.isToggleEnvironmentPossible(state),
  scopes: authSelectors.selectScopes(state),
});

const mapDispatchToProps = dispatch => ({
  toggleEnvironmentValue: ({ currentRoutePath, environment }) =>
    dispatch(toggleEnvironmentValue({ currentRoutePath, environment })),
});

export default connect(mapStateToProps, mapDispatchToProps)(Layout);

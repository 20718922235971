// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import Select from 'components/form/Select';
import {
  $HeaderRow,
  $TooltipTitle,
  $ClearButton,
  $BottomRow,
  $ApplyButton,
  $TooltipContentContainer,
} from './styles';

const $Label = styled.div`
  font-size: ${({ theme }) => theme.font.size.body.sm};
  margin-bottom: 11px;
`;

const $Spacer = styled.div`
  margin-bottom: ${({ size }) => (size ? `${size}px` : '0px')};
`;

const MonthYearOptionsContent = ({
  onSubmit,
  title,
  onClear,
  yearOptions,
  monthOptions,
  selectedYear,
  selectedMonth,
  updateSelectedYear,
  updateSelectedMonth,
}) => {
  const handleYearChange = event => {
    updateSelectedYear(event.target.value);
  };

  const handleMonthChange = event => {
    updateSelectedMonth(event.target.value);
  };

  return (
    <$TooltipContentContainer>
      <$HeaderRow>
        <$TooltipTitle>{title}</$TooltipTitle>

        <$ClearButton
          onClick={onClear}
          disabled={!selectedYear && !selectedMonth}
          data-cy="clear_filter_button"
        >
          Clear
        </$ClearButton>
      </$HeaderRow>

      <$Label>Month</$Label>
      <Select
        name="month"
        value={selectedMonth}
        defaultValue="Select a month"
        onChange={handleMonthChange}
        options={monthOptions}
        data-cy="select_billing_month"
      />

      <$Spacer size={29} />

      <$Label>Year</$Label>
      <Select
        name="year"
        value={selectedYear}
        defaultValue="Select a year"
        onChange={handleYearChange}
        options={yearOptions}
        data-cy="select_billing_year"
      />

      <$Spacer size={28} />

      <$BottomRow>
        <$ApplyButton
          onClick={onSubmit}
          data-cy="apply_filter_button"
          disabled={!selectedYear || !selectedMonth}
        >
          Apply
        </$ApplyButton>
      </$BottomRow>
    </$TooltipContentContainer>
  );
};

MonthYearOptionsContent.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  onClear: PropTypes.func.isRequired,
  selectedYear: PropTypes.string.isRequired,
  selectedMonth: PropTypes.string.isRequired,
  updateSelectedYear: PropTypes.func.isRequired,
  updateSelectedMonth: PropTypes.func.isRequired,
  yearOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ).isRequired,
  monthOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ).isRequired,
};

export default MonthYearOptionsContent;

import ApplicationService from 'modules/application/applicationService';
import Errors from 'modules/shared/error/errors';
import Message from 'view/shared/message';
import { createUniqueId } from 'utils/createUniqueId';

import { i18n } from 'i18n';

const prefix = 'APPLICATION_FORM';
const { analytics } = window;

const actions = {
  RESET: `${prefix}_RESET`,

  FIND_STARTED: `${prefix}_FIND_STARTED`,
  FIND_SUCCESS: `${prefix}_FIND_SUCCESS`,
  FIND_ERROR: `${prefix}_FIND_ERROR`,

  SANDBOX_APP_CREATION_STARTED: `${prefix}_SANDBOX_APP_CREATION_STARTED`,
  SANDBOX_APP_CREATION_SUCCESS: `${prefix}_SANDBOX_APP_CREATION_SUCCESS`,
  SANDBOX_APP_CREATION_ERROR: `${prefix}_SANDBOX_APP_CREATION_ERROR`,

  UPDATE_STARTED: `${prefix}_UPDATE_STARTED`,
  UPDATE_SUCCESS: `${prefix}_UPDATE_SUCCESS`,
  UPDATE_ERROR: `${prefix}_UPDATE_ERROR`,

  doGet: (id, environmentFromArgs) => async (dispatch, getState) => {
    try {
      dispatch({
        type: actions.FIND_STARTED,
      });

      const { environment: currentEnvironment } = getState().root.global;

      const appEnvironment = environmentFromArgs || currentEnvironment;

      const record = await ApplicationService.get(id, { appEnvironment });
      record.permissions = await ApplicationService.getConsents(record.id, {
        appEnvironment,
      });

      dispatch({
        type: actions.FIND_SUCCESS,
        payload: record,
      });
      analytics.track('applicationsViewSuccess', {
        applicationId: id,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: actions.FIND_ERROR,
      });

      analytics.track('applicationsViewError', {
        applicationId: id,
      });
    }
  },

  doCreateSandboxApplication: () => async (dispatch, getState) => {
    try {
      dispatch({
        type: actions.SANDBOX_APP_CREATION_STARTED,
      });

      const { organization_name: organizationName, account_type: bankType } =
        getState().root.auth.currentUser;

      // API call to create sandbox app
      await ApplicationService.createSandboxApplication({
        name: organizationName,
        description: '',
        active: true,
        common_name: createUniqueId(organizationName),
        bank_type: bankType,
      });

      dispatch({
        type: actions.SANDBOX_APP_CREATION_SUCCESS,
      });
    } catch (error) {
      dispatch({
        type: actions.SANDBOX_APP_CREATION_ERROR,
      });
    }
  },

  doUpdate: (id, values, environmentFromArgs) => async (dispatch, getState) => {
    try {
      dispatch({
        type: actions.UPDATE_STARTED,
      });

      const { environment: currentEnvironment } = getState().root.global;

      const record = await ApplicationService.update(id, values, {
        appEnvironment: environmentFromArgs || currentEnvironment,
      });

      dispatch({
        type: actions.UPDATE_SUCCESS,
        payload: record,
      });

      Message.success(i18n('entities.application.update.success'));
      analytics.track('applicationsUpdateSuccess', {
        applicationId: id,
      });
    } catch (error) {
      Errors.handle(error);

      analytics.track('applicationsUpdateError', {
        applicationId: id,
      });
      dispatch({
        type: actions.UPDATE_ERROR,
      });
    }
  },
};

export default actions;

import { GrowthBook } from '@growthbook/growthbook-react';
import { FeatureFlagsAttributes, FeatureFlags } from 'modules/featureFlags';

const devMode = process.env.NODE_ENV === 'development';

const growthbook = new GrowthBook({
  apiHost: 'https://cdn.growthbook.io',
  clientKey: process.env.REACT_APP_GROWTHBOOK_TOKEN,
  enableDevMode: devMode,
  debug: false,
});

growthbook.init({
  streaming: true,
});

const featureFlags = {
  growthbook,
  setAttributes: (attributes: Record<FeatureFlagsAttributes, string>) => {
    growthbook.setAttributes({
      ...growthbook.getAttributes(),
      ...attributes,
    });
  },
  isOn: (name: FeatureFlags) => {
    return growthbook.isOn(name);
  },
};

export default featureFlags;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import FullScreen from './FullScreen';
import LeanLogoAnimated from './LeanLogoAnimated';

const $CenteredBox = styled.div`
  display: flex;
  flex-direction: column;
`;

const $LeanLogoAnimated = styled(LeanLogoAnimated)`
  margin-bottom: 16px;
`;

const $Text = styled.span`
  font-size: ${({ theme }) => theme.font.size.body.md};
`;
const FullScreenLoading = ({ text }) => (
  <FullScreen>
    <$CenteredBox>
      <$LeanLogoAnimated size={48} />
      <$Text data-cy="loading_text">{text}</$Text>
    </$CenteredBox>
  </FullScreen>
);

FullScreenLoading.propTypes = {
  text: PropTypes.string.isRequired,
};

export default FullScreenLoading;

import actions from './applicationFormActions';

const initialData = {
  findLoading: false,
  saveLoading: false,
  record: null,
  sandboxAppCreationLoading: false,
  sandboxAppCreationErrored: false,
};

const applicationFormReducers = (state = initialData, { type, payload }) => {
  if (type === actions.RESET) {
    return {
      ...initialData,
    };
  }

  if (type === actions.FIND_STARTED) {
    return {
      ...state,
      record: null,
      findLoading: true,
    };
  }

  if (type === actions.FIND_SUCCESS) {
    return {
      ...state,
      record: payload,
      findLoading: false,
    };
  }

  if (type === actions.FIND_ERROR) {
    return {
      ...state,
      record: null,
      findLoading: false,
    };
  }

  if (type === actions.UPDATE_STARTED) {
    return {
      ...state,
      saveLoading: true,
    };
  }

  if (type === actions.UPDATE_SUCCESS) {
    return {
      ...state,
      saveLoading: false,
      record: payload,
    };
  }

  if (type === actions.UPDATE_ERROR) {
    return {
      ...state,
      saveLoading: false,
    };
  }

  if (type === actions.SANDBOX_APP_CREATION_STARTED) {
    return {
      ...state,
      sandboxAppCreationLoading: true,
      sandboxAppCreationErrored: false,
    };
  }

  if (type === actions.SANDBOX_APP_CREATION_SUCCESS) {
    return {
      ...state,
      sandboxAppCreationLoading: false,
      sandboxAppCreationErrored: false,
    };
  }

  if (type === actions.SANDBOX_APP_CREATION_ERROR) {
    return {
      ...state,
      sandboxAppCreationLoading: false,
      sandboxAppCreationErrored: true,
    };
  }

  return state;
};

export default applicationFormReducers;

import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { $Helptext, $Select, $Label } from 'styles';
import { styledInputPropTypes } from 'utils/proptypes';
import { $SelectArrow, $SelectContainer } from 'styles/components/form/Select';

const getOptions = options =>
  options.map(({ value, label }) => (
    <option key={value} value={value}>
      {label}
    </option>
  ));

const Select = ({
  isErrored,
  label,
  name,
  onChange,
  defaultValue,
  options,
  helptext,
  styledProps,
  value,
  'data-cy': dataCy,
}) => (
  <>
    <$Label htmlFor={name} id={`${name}Label`} {...styledProps}>
      {label}
    </$Label>
    <$SelectContainer>
      <$Select
        aria-describedby={`${name}Description`}
        aria-labelledby={`${name}Label`}
        id={name}
        isErrored={isErrored}
        name={name}
        onChange={onChange}
        value={value}
        data-cy={dataCy}
        isDefaultLabel={value === '' || value === defaultValue}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...styledProps}
      >
        {defaultValue && (
          <option disabled value="">
            {defaultValue}
          </option>
        )}
        {getOptions(options)}
      </$Select>
      <$SelectArrow>
        <ion-icon name="chevron-down-outline" />{' '}
      </$SelectArrow>
    </$SelectContainer>
    <$Helptext id={`${name}Description`} isErrored={isErrored}>
      {helptext}
    </$Helptext>
  </>
);

Select.propTypes = {
  defaultValue: PropTypes.string,
  helptext: PropTypes.string,
  isErrored: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ).isRequired,
  styledProps: styledInputPropTypes,
  value: PropTypes.string,
  'data-cy': PropTypes.string,
};

Select.defaultProps = {
  defaultValue: '',
  helptext: '',
  isErrored: false,
  label: '',
  onChange: () => {},
  styledProps: {},
  value: '',
  'data-cy': '',
};

export default Select;

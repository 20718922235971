import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '../form/Checkbox';
import {
  $TableCheckbox,
  $TableHeadCell,
  $TableHeaderIcon,
  $TableHeaderWrapper,
  $TableHeadGroupCell,
} from './styles';
import Icon from '../Icon';
import { SORT_DESCENDING } from './helpers';

const TableSuperHeaderCells = ({ superHeaderValues }) =>
  superHeaderValues.map(header => (
    <$TableHeadGroupCell
      scope="colgroup"
      key={header.id}
      colSpan={header.numberOfChildren}
    >
      {header.title}
    </$TableHeadGroupCell>
  ));

TableSuperHeaderCells.propTypes = {
  superHeaderValues: PropTypes.array.isRequired,
};

const TableHeaderCells = ({
  onSort,
  titles,
  sortPattern,
  tableDataCount,
  sortableColumns,
  checkboxSelection,
  selectedRowsCount,
  headerCellStyleProps,
  handleAllRowsSelected,
  customHeaderCell: CustomHeaderCell,
}) => (
  <>
    {checkboxSelection ? (
      <$TableHeadCell>
        <$TableCheckbox>
          <Checkbox
            name="table_select_all"
            onChange={handleAllRowsSelected}
            value="data-table-checkbox-select-all"
            data-cy="data-table-checkbox-select-all"
            isChecked={
              tableDataCount > 0 && tableDataCount === selectedRowsCount
            }
          />
        </$TableCheckbox>
      </$TableHeadCell>
    ) : null}

    {titles.map((title, index) => {
      const isSortable = sortableColumns.find(
        column => column.toLowerCase() === title.toLowerCase(),
      );

      const isDescending = sortPattern[title.toLowerCase()] === SORT_DESCENDING;

      return (
        <$TableHeadCell
          key={title}
          scope="col"
          headerCellStyleProps={headerCellStyleProps}
        >
          <$TableHeaderWrapper>
            {isSortable !== undefined ? (
              <$TableHeaderIcon
                onClick={() => onSort(title)}
                className={isDescending ? 'initiated' : ''}
              >
                {isDescending ? (
                  <Icon name="ArrowDown" size={12} />
                ) : (
                  <Icon name="ArrowUp" size={12} />
                )}
              </$TableHeaderIcon>
            ) : null}

            {CustomHeaderCell ? (
              <CustomHeaderCell title={title} headerPosition={index} />
            ) : (
              title
            )}
          </$TableHeaderWrapper>
        </$TableHeadCell>
      );
    })}
  </>
);

TableHeaderCells.propTypes = {
  checkboxSelection: PropTypes.bool,
  onSort: PropTypes.func.isRequired,
  titles: PropTypes.array.isRequired,
  headerCellStyleProps: PropTypes.any,
  handleAllRowsSelected: PropTypes.func,
  customHeaderCell: PropTypes.elementType,
  sortableColumns: PropTypes.array.isRequired,
  tableDataCount: PropTypes.number.isRequired,
  selectedRowsCount: PropTypes.number.isRequired,
  sortPattern: PropTypes.shape({}).isRequired,
};

TableHeaderCells.defaultProps = {
  checkboxSelection: false,
  headerCellStyleProps: [],
  customHeaderCell: undefined,
  handleAllRowsSelected: () => {},
};

const TableHeaders = ({
  titles,
  onSort,
  sortPattern,
  isSuperHeader,
  tableDataCount,
  sortableColumns,
  customHeaderCell,
  selectedRowsCount,
  checkboxSelection,
  superHeaderValues,
  headerCellStyleProps,
  handleAllRowsSelected,
}) => (
  <>
    {isSuperHeader && (
      <tr>
        <TableSuperHeaderCells superHeaderValues={superHeaderValues} />
      </tr>
    )}

    <tr>
      <TableHeaderCells
        titles={titles}
        onSort={onSort}
        sortPattern={sortPattern}
        tableDataCount={tableDataCount}
        sortableColumns={sortableColumns}
        customHeaderCell={customHeaderCell}
        checkboxSelection={checkboxSelection}
        selectedRowsCount={selectedRowsCount}
        headerCellStyleProps={headerCellStyleProps}
        handleAllRowsSelected={handleAllRowsSelected}
      />
    </tr>
  </>
);

TableHeaders.propTypes = {
  isSuperHeader: PropTypes.bool,
  sortableColumns: PropTypes.array,
  onSort: PropTypes.func.isRequired,
  checkboxSelection: PropTypes.bool,
  titles: PropTypes.array.isRequired,
  superHeaderValues: PropTypes.array,
  headerCellStyleProps: PropTypes.any,
  handleAllRowsSelected: PropTypes.func,
  customHeaderCell: PropTypes.elementType,
  tableDataCount: PropTypes.number.isRequired,
  selectedRowsCount: PropTypes.number.isRequired,
  sortPattern: PropTypes.shape({}).isRequired,
};

TableHeaders.defaultProps = {
  sortableColumns: [],
  isSuperHeader: false,
  superHeaderValues: [],
  checkboxSelection: false,
  headerCellStyleProps: [],
  customHeaderCell: undefined,
  handleAllRowsSelected: () => {},
};

export default TableHeaders;

import consentExplanation from 'modules/consentExplanation/consentExplanation.slice';

export {
  selectConsentExplanationBenefitStatement,
  selectConsentExplanationPurposeStatement,
  selectConsentExplanationShowConsentScreenFlag,
} from 'modules/consentExplanation/consentExplanation.slice';

export {
  getConsentExplanations,
  updateConsentExplanations,
} from 'modules/consentExplanation/consentExplanation.actions';

export default consentExplanation;

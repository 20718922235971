import { createDraftSafeSelector, createSlice } from '@reduxjs/toolkit';
import Message from 'view/shared/message';
import Errors from 'modules/shared/error/errors';
import { apiFetch } from 'modules/shared/fetch';
import { COUNTRIES_CONFIG } from 'config/countriesEnvironments';
import config from '../config';
import { ENVIRONMENT_SANDBOX } from '../utils/constants';

const isOpenBanking = COUNTRIES_CONFIG.openBanking;

const initialState = {
  customerId: '',
  createCustomerIdResponse: null,
  createCustomerIdLoading: false,
  getApiAccessTokenLoading: false,
  getCustomerAccessTokenLoading: false,
  entities: [],
  getIdentityResponse: null,
  getIdentityLoading: false,
  getAccountsResponse: null,
  getAccountsLoading: false,
  accounts: [],
  getBalanceResponse: null,
  getCustomerAccessTokenResponse: null,
  getBalanceLoading: false,
};

export const gettingStartedSlice = createSlice({
  name: 'gettingStarted',
  initialState,
  reducers: {
    createCustomerIdStarted: (state, action) => {
      state.createCustomerIdResponse = null;
      state.customerId = '';
      state.createCustomerIdLoading = true;
    },
    createCustomerIdSuccess: (state, action) => {
      state.createCustomerIdResponse = action.payload;
      state.customerId = action.payload.customer_id;
      state.createCustomerIdLoading = false;
    },
    createCustomerIdErrored: (state, action) => {
      state.createCustomerIdResponse = null;
      state.customerId = '';
      state.createCustomerIdLoading = false;
    },
    getApiAccessTokenStarted: (state, action) => {
      state.getApiAccessTokenResponse = null;
      state.getApiAccessTokenLoading = true;
    },
    getApiAccessTokenSuccess: (state, action) => {
      state.getApiAccessTokenResponse = action.payload;
      state.getApiAccessTokenLoading = false;
    },
    getApiAccessTokenErrored: (state, action) => {
      state.getApiAccessTokenResponse = null;
      state.getApiAccessTokenLoading = false;
    },
    getCustomerAccessTokenStarted: (state, action) => {
      state.getCustomerAccessTokenResponse = null;
      state.getCustomerAccessTokenLoading = true;
    },
    getCustomerAccessTokenSuccess: (state, action) => {
      state.getCustomerAccessTokenResponse = action.payload;
      state.getCustomerAccessTokenLoading = false;
    },
    getCustomerAccessTokenErrored: (state, action) => {
      state.getCustomerAccessTokenResponse = null;
      state.getCustomerAccessTokenLoading = false;
    },
    getEntitiesStarted: (state, action) => {
      state.entities = [];
    },
    getEntitiesSuccess: (state, action) => {
      state.entities = action.payload;
    },
    getEntitiesErrored: (state, action) => {
      state.entities = [];
    },
    getIdentityStarted: (state, action) => {
      state.getIdentityResponse = null;
      state.getIdentityLoading = true;
    },
    getIdentitySuccess: (state, action) => {
      state.getIdentityResponse = action.payload;
      state.getIdentityLoading = false;
    },
    getIdentityErrored: (state, action) => {
      state.getIdentityResponse = null;
      state.getIdentityLoading = false;
    },
    getAccountsStarted: (state, action) => {
      state.getAccountsResponse = null;
      state.accounts = [];
      state.getAccountsLoading = true;
    },
    getAccountsSuccess: (state, action) => {
      state.getAccountsResponse = action.payload;
      state.accounts = action.payload.payload.accounts;
      state.getAccountsLoading = false;
    },
    getAccountsErrored: (state, action) => {
      state.getAccountsResponse = null;
      state.accounts = [];
      state.getAccountsLoading = false;
    },
    getBalanceStarted: (state, action) => {
      state.getBalanceResponse = null;
      state.getBalanceLoading = true;
    },
    getBalanceSuccess: (state, action) => {
      state.getBalanceResponse = action.payload;
      state.getBalanceLoading = false;
    },
    getBalanceErrored: (state, action) => {
      state.getBalanceResponse = null;
      state.getBalanceLoading = false;
    },
    createOauthSecretStated: (state, action) => {
      state.oauthSecretResponse = null;
      state.oauthSecretLoading = true;
    },
    createOauthSecretSuccess: (state, action) => {
      state.oauthSecretResponse = action.payload;
      state.oauthSecretLoading = false;
    },
    createOauthSecretErrored: (state, action) => {
      state.oauthSecretResponse = null;
      state.oauthSecretLoading = false;
    },
  },
});

// APIs
const { apiSandboxUrl } = config;

/**
 * POST /customers/v1
 * @param {string} appToken
 * @param {string} apiAccessToken
 * @param {object} body
 */
const createCustomerId = async (appToken, apiAccessToken, body) => {
  const headers = { 'lean-app-token': appToken };

  if (apiAccessToken) {
    headers.Authorization = `Bearer ${apiAccessToken}`;
  }

  const response = await apiFetch('/customers/v1', {
    body,
    noCustomHeader: true,
    baseURL: apiSandboxUrl,
    headers,
  });

  return response.data;
};

const getAccessToken = async (appToken, oauthSecret, scope) => {
  const response = await apiFetch(`/oauth2/token`, {
    authURL: true,
    method: 'POST',
    appEnvironment: ENVIRONMENT_SANDBOX,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    noCustomHeader: true,
    body: new URLSearchParams({
      client_id: appToken,
      client_secret: oauthSecret,
      grant_type: 'client_credentials',
      scope,
    }).toString(),
  });
  return response.data;
};

/**
 * GET /customers/v1/:customer_id/entities
 * @param {string} appToken
 * @param {object} customerId
 */
const getEntities = async (appToken, customerId, apiAccessToken) => {
  const headers = { 'lean-app-token': appToken };

  if (apiAccessToken) {
    headers.Authorization = `Bearer ${apiAccessToken}`;
  }

  const response = await apiFetch(`/customers/v1/${customerId}/entities`, {
    noCustomHeader: true,
    baseURL: apiSandboxUrl,
    headers,
  });
  return response.data;
};

const getIdentity = async (appToken, body, apiAccessToken) => {
  const headers = { 'lean-app-token': appToken };

  if (apiAccessToken) {
    headers.Authorization = `Bearer ${apiAccessToken}`;
  }

  const apiPrefix = isOpenBanking ? 'data/v2' : 'data/v1';
  const response = await apiFetch(`/${apiPrefix}/identity`, {
    body,
    noCustomHeader: true,
    baseURL: apiSandboxUrl,
    headers,
  });
  return response.data;
};

const getAccounts = async (appToken, body, apiAccessToken) => {
  const headers = { 'lean-app-token': appToken };

  if (apiAccessToken) {
    headers.Authorization = `Bearer ${apiAccessToken}`;
  }

  const apiPrefix = isOpenBanking ? 'data/v2' : 'data/v1';
  const response = await apiFetch(`/${apiPrefix}/accounts`, {
    body,
    noCustomHeader: true,
    baseURL: apiSandboxUrl,
    headers,
  });
  return response.data;
};

const getBalance = async (appToken, body, apiAccessToken) => {
  const headers = { 'lean-app-token': appToken };

  if (apiAccessToken) {
    headers.Authorization = `Bearer ${apiAccessToken}`;
  }

  const apiPrefix = isOpenBanking ? 'data/v2' : 'data/v1';
  const response = await apiFetch(`/${apiPrefix}/balance`, {
    body,
    noCustomHeader: true,
    baseURL: apiSandboxUrl,
    headers,
  });
  return response.data;
};

const createOauthSecret = async (appToken) => {
  const response = await apiFetch(`/applications/${appToken}/secrets`, {
    authURL: true,
    method: 'POST',
    appEnvironment: ENVIRONMENT_SANDBOX,
  });
  return response.data;
};

// Action creators
const {
  createCustomerIdStarted,
  createCustomerIdSuccess,
  createCustomerIdErrored,
  getApiAccessTokenStarted,
  getApiAccessTokenSuccess,
  getApiAccessTokenErrored,
  getCustomerAccessTokenStarted,
  getCustomerAccessTokenSuccess,
  getCustomerAccessTokenErrored,
  getEntitiesStarted,
  getEntitiesSuccess,
  getEntitiesErrored,
  getIdentityStarted,
  getIdentitySuccess,
  getIdentityErrored,
  getAccountsStarted,
  getAccountsSuccess,
  getAccountsErrored,
  getBalanceStarted,
  getBalanceSuccess,
  getBalanceErrored,
  createOauthSecretStated,
  createOauthSecretSuccess,
  createOauthSecretErrored,
} = gettingStartedSlice.actions;

// Actions

const doCreateCustomerId =
  (apiAccessToken, appToken, appUserId) => async dispatch => {
    try {
      dispatch(createCustomerIdStarted());
      const body = {
        app_user_id: appUserId,
      };

      const data = await createCustomerId(appToken, apiAccessToken, body);
      dispatch(createCustomerIdSuccess(data));
      Message.success('New customer created successfully.');
    } catch (error) {
      dispatch(createCustomerIdErrored());
      Errors.showMessage(error);
    }
  };

const doGetApiAccessToken = (appToken, oauthSecret) => async dispatch => {
  try {
    dispatch(getApiAccessTokenStarted());
    const data = await getAccessToken(appToken, oauthSecret, 'api');
    dispatch(getApiAccessTokenSuccess(data));
    Message.success('API access token successfully retrieved');
  } catch (error) {
    dispatch(getApiAccessTokenErrored());
    Errors.showMessage(error);
  }
};

const doGetCustomerAccessToken =
  (appToken, oauthSecret, customerId) => async dispatch => {
    try {
      dispatch(getCustomerAccessTokenStarted());
      const data = await getAccessToken(
        appToken,
        oauthSecret,
        `customer.${customerId}`,
      );
      dispatch(getCustomerAccessTokenSuccess(data));
      Message.success('Customer access token successfully retrieved');
    } catch (error) {
      dispatch(getCustomerAccessTokenErrored());
      Errors.showMessage(error);
    }
  };

const doGetEntities =
  (appToken, customerId, apiAccessToken) => async dispatch => {
    try {
      dispatch(getEntitiesStarted());
      const data = await getEntities(appToken, customerId, apiAccessToken);
      dispatch(getEntitiesSuccess(data));
    } catch (error) {
      dispatch(getEntitiesErrored());
      Errors.showMessage(error);
    }
  };

const doGetIdentity =
  (appToken, entityId, apiAccessToken) => async dispatch => {
    try {
      dispatch(getIdentityStarted());
      const data = await getIdentity(
        appToken,
        {
          entity_id: entityId,
        },
        apiAccessToken,
      );
      dispatch(getIdentitySuccess(data));
      Message.success('Identity fetched successfully.');
    } catch (error) {
      dispatch(getIdentityErrored());
      Errors.showMessage(error);
    }
  };

const doGetAccounts =
  (appToken, entityId, apiAccessToken) => async dispatch => {
    try {
      dispatch(getAccountsStarted());
      const data = await getAccounts(
        appToken,
        {
          entity_id: entityId,
        },
        apiAccessToken,
      );
      dispatch(getAccountsSuccess(data));
      Message.success('Accounts fetched successfully.');
    } catch (error) {
      dispatch(getAccountsErrored());
      Errors.showMessage(error);
    }
  };

const doGetBalance =
  (appToken, entityId, accountId, apiAccessToken) => async dispatch => {
    try {
      dispatch(getBalanceStarted());
      const data = await getBalance(
        appToken,
        {
          entity_id: entityId,
          account_id: accountId,
        },
        apiAccessToken,
      );
      dispatch(getBalanceSuccess(data));
      Message.success('Balance fetched successfully.');
    } catch (error) {
      dispatch(getBalanceErrored());
      Errors.showMessage(error);
    }
  };

const doCreateOauthSecret = appToken => async dispatch => {
  try {
    dispatch(createOauthSecretStated());
    const data = await createOauthSecret(appToken);
    dispatch(createOauthSecretSuccess(data));
  } catch (error) {
    dispatch(createOauthSecretErrored());
    Errors.showMessage(error);
  }
};

export const actions = {
  doCreateCustomerId,
  doGetApiAccessToken,
  doGetCustomerAccessToken,
  doGetEntities,
  doGetIdentity,
  doGetAccounts,
  doGetBalance,
  doCreateOauthSecret,
};

// Selectors
export const selectCustomerId = state => state.root.gettingStarted.customerId;
export const selectGetApiAccessTokenResponse = state =>
  state.root.gettingStarted.getApiAccessTokenResponse;
export const selectGetCustomerAccessTokenResponse = state =>
  state.root.gettingStarted.getCustomerAccessTokenResponse;
export const selectCreateCustomerIdResponse = state =>
  state.root.gettingStarted.createCustomerIdResponse;
export const selectEntities = state => state.root.gettingStarted.entities;
export const selectFirstEntity = createDraftSafeSelector(
  selectEntities,
  entities => entities && entities[0],
);
export const selectGetIdentityResponse = state =>
  state.root.gettingStarted.getIdentityResponse;
export const selectGetAccountsResponse = state =>
  state.root.gettingStarted.getAccountsResponse;
export const selectAccounts = state => state.root.gettingStarted.accounts;
export const selectCurrentAccount = createDraftSafeSelector(
  selectAccounts,
  accounts => accounts && accounts.find(account => account.type === 'CURRENT'),
);
export const selectGetBalanceResponse = state =>
  state.root.gettingStarted.getBalanceResponse;
export const selectOauthSecretResponse = state =>
  state.root.gettingStarted.oauthSecretResponse;
export const selectCreateCustomerIdLoading = state =>
  state.root.gettingStarted.createCustomerIdLoading;
export const selectGetApiAccessTokenLoading = state =>
  state.root.gettingStarted.getApiAccessTokenLoading;
export const selectGetCustomerAccessTokenLoading = state =>
  state.root.gettingStarted.getCustomerAccessTokenLoading;
export const selectGetIdentityLoading = state =>
  state.root.gettingStarted.getIdentityLoading;
export const selectGetAccountsLoading = state =>
  state.root.gettingStarted.getAccountsLoading;
export const selectGetBalanceLoading = state =>
  state.root.gettingStarted.getBalanceLoading;
export const selectOauthSecretLoading = state =>
  state.root.gettingStarted.oauthSecretLoading;

export default gettingStartedSlice.reducer;

import { selectEnvironment } from 'modules/global';
import { DEPOSITS_LOADED_STATUSES } from 'view/deposits/constants';
import Errors from '../shared/error/errors';
import DepositsService from './depositsService';

const prefix = 'dev-portal/deposits';

export const actions = {
  GET_DEPOSITS_SOURCE_BANKS_LIST_STARTED: `${prefix}/GET_DEPOSITS_SOURCE_BANKS_LIST_STARTED`,
  GET_DEPOSITS_SOURCE_BANKS_LIST_SUCCESS: `${prefix}/GET_DEPOSITS_SOURCE_BANKS_LIST_SUCCESS`,
  GET_DEPOSITS_SOURCE_BANKS_LIST_ERROR: `${prefix}/GET_DEPOSITS_SOURCE_BANKS_LIST_ERROR`,

  GET_CUSTOMER_ID_STARTED: `${prefix}/GET_CUSTOMER_ID_STARTED`,
  GET_CUSTOMER_ID_SUCCESS: `${prefix}/GET_CUSTOMER_ID_SUCCESS`,
  GET_CUSTOMER_ID_ERROR: `${prefix}/GET_CUSTOMER_ID_ERROR`,

  ADD_DEPOSITS_SOURCE_STARTED: `${prefix}/ADD_DEPOSITS_SOURCE_STARTED`,
  ADD_DEPOSITS_SOURCE_SUCCESS: `${prefix}/ADD_DEPOSITS_SOURCE_SUCCESS`,
  ADD_DEPOSITS_SOURCE_ERROR: `${prefix}/ADD_DEPOSITS_SOURCE_ERROR`,

  ADD_DEPOSITS_BANK_DETAILS_STARTED: `${prefix}/ADD_DEPOSITS_BANK_DETAILS_STARTED`,
  ADD_DEPOSITS_BANK_DETAILS_SUCCESS: `${prefix}/ADD_DEPOSITS_BANK_DETAILS_SUCCESS`,
  ADD_DEPOSITS_BANK_DETAILS_ERROR: `${prefix}/ADD_DEPOSITS_BANK_DETAILS_ERROR`,

  REFRESH_BANK_DEPOSIT_SOURCE_STARTED: `${prefix}/REFRESH_BANK_DEPOSIT_SOURCE_STARTED`,
  REFRESH_BANK_DEPOSIT_SOURCE_SUCCESS: `${prefix}/REFRESH_BANK_DEPOSIT_SOURCE_SUCCESS`,
  REFRESH_BANK_DEPOSIT_SOURCE_ERROR: `${prefix}/REFRESH_BANK_DEPOSIT_SOURCE_ERROR`,

  GET_ENTITY_BANK_ACCOUNTS_STARTED: `${prefix}/GET_ENTITY_BANK_ACCOUNTS_STARTED`,
  GET_ENTITY_BANK_ACCOUNTS_SUCCESS: `${prefix}/GET_ENTITY_BANK_ACCOUNTS_SUCCESS`,
  GET_ENTITY_BANK_ACCOUNTS_ERROR: `${prefix}/GET_ENTITY_BANK_ACCOUNTS_ERROR`,

  GET_DEPOSITS_REQUEST_STATUS_STARTED: `${prefix}/GET_DEPOSITS_REQUEST_STATUS_STARTED`,
  GET_DEPOSITS_REQUEST_STATUS_SUCCESS: `${prefix}/GET_DEPOSITS_REQUEST_STATUS_SUCCESS`,
  GET_DEPOSITS_REQUEST_STATUS_ERROR: `${prefix}/GET_DEPOSITS_REQUEST_STATUS_ERROR`,

  CANCEL_GET_DEPOSITS_UPLOAD_STATUS: `${prefix}/CANCEL_GET_DEPOSITS_UPLOAD_STATUS`,
  CANCEL_ADD_DEPOSITS_BANK_DETAILS_STATUS: `${prefix}/CANCEL_ADD_DEPOSITS_BANK_DETAILS_STATUS`,
  CLEAR_RECONNECT_ID: `${prefix}/CLEAR_RECONNECT_ID`,

  GET_UPLOADED_DEPOSITS_SOURCE_LIST_STARTED: `${prefix}/GET_UPLOADED_DEPOSITS_SOURCE_LIST_STARTED`,
  GET_UPLOADED_DEPOSITS_SOURCE_LIST_SUCCESS: `${prefix}/GET_UPLOADED_DEPOSITS_SOURCE_LIST_SUCCESS`,
  GET_UPLOADED_DEPOSITS_SOURCE_LIST_ERROR: `${prefix}/GET_UPLOADED_DEPOSITS_SOURCE_LIST_ERROR`,

  OPEN_DEPOSITS_DIALOG: `${prefix}/OPEN_DEPOSITS_DIALOG`,
  CLOSE_DEPOSITS_DIALOG: `${prefix}/CLOSE_DEPOSITS_DIALOG`,
  SET_UPDATE_DEPOSITS_STEP: `${prefix}/SET_UPDATE_DEPOSITS_STEP`,
  SET_SELECT_BANK_ACCOUNT_STEP: `${prefix}/SET_SELECT_BANK_ACCOUNT_STEP`,
  SET_SELECTED_BANK_DETAILS: `${prefix}/SET_SELECTED_BANK_DETAILS`,

  doGetBanksList: appToken => async (dispatch, state) => {
    try {
      dispatch({
        type: actions.GET_DEPOSITS_SOURCE_BANKS_LIST_STARTED,
      });

      const environment = selectEnvironment(state());

      const banksList = await DepositsService.getBanksList({
        appToken,
        appEnvironment: environment,
      });

      dispatch({
        type: actions.GET_DEPOSITS_SOURCE_BANKS_LIST_SUCCESS,
        payload: banksList,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: actions.GET_DEPOSITS_SOURCE_BANKS_LIST_ERROR,
      });
    }
  },

  doGetCustomerId: appToken => async (dispatch, state) => {
    try {
      dispatch({
        type: actions.GET_CUSTOMER_ID_STARTED,
      });

      const environment = selectEnvironment(state());

      const response = await DepositsService.getCustomerId({
        appToken,
        appEnvironment: environment,
      });

      dispatch({
        type: actions.GET_CUSTOMER_ID_SUCCESS,
        payload: response,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: actions.GET_CUSTOMER_ID_ERROR,
      });
    }
  },

  doGetUploadedDepositsSourceList: appToken => async (dispatch, state) => {
    try {
      dispatch({
        type: actions.GET_UPLOADED_DEPOSITS_SOURCE_LIST_STARTED,
      });

      const environment = selectEnvironment(state());

      const uploadedDepositsSourceList =
        await DepositsService.getUploadedDepositsSourceList({
          appToken,
          appEnvironment: environment,
        });

      dispatch({
        type: actions.GET_UPLOADED_DEPOSITS_SOURCE_LIST_SUCCESS,
        payload: uploadedDepositsSourceList,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: actions.GET_UPLOADED_DEPOSITS_SOURCE_LIST_ERROR,
      });
    }
  },

  doUploadDepositsSource: (appToken, payload) => async (dispatch, state) => {
    try {
      dispatch({
        type: actions.ADD_DEPOSITS_SOURCE_STARTED,
      });

      const environment = selectEnvironment(state());

      const uploadSourceAndGetRequestId =
        await DepositsService.uploadDepositsSource({
          appToken,
          appEnvironment: environment,
          payload,
        });

      dispatch({
        type: actions.ADD_DEPOSITS_SOURCE_SUCCESS,
        payload: uploadSourceAndGetRequestId,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: actions.ADD_DEPOSITS_SOURCE_ERROR,
      });
    }
  },

  doSaveDepositsBankDetails: (appToken, payload) => async (dispatch, state) => {
    try {
      dispatch({
        type: actions.ADD_DEPOSITS_BANK_DETAILS_STARTED,
      });

      const environment = selectEnvironment(state());

      const saveAccountAndGetSourceId =
        await DepositsService.saveDepositsBankDetails({
          appToken,
          appEnvironment: environment,
          payload,
        });

      dispatch({
        type: actions.ADD_DEPOSITS_BANK_DETAILS_SUCCESS,
        payload: saveAccountAndGetSourceId,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: actions.ADD_DEPOSITS_BANK_DETAILS_ERROR,
      });
    }
  },

  doCancelAddDepositsBankDetailsStatus: () => async dispatch => {
    dispatch({
      type: actions.CANCEL_ADD_DEPOSITS_BANK_DETAILS_STATUS,
    });
  },

  doClearReconnectId: () => async dispatch => {
    dispatch({
      type: actions.CLEAR_RECONNECT_ID,
    });
  },

  doRefreshBankDepositSource:
    (appToken, payload) => async (dispatch, state) => {
      try {
        dispatch({
          type: actions.REFRESH_BANK_DEPOSIT_SOURCE_STARTED,
        });

        const environment = selectEnvironment(state());

        const refreshSourceAndGetRequestId =
          await DepositsService.refreshBankDepositSource({
            appToken,
            appEnvironment: environment,
            payload,
          });

        dispatch({
          type: actions.REFRESH_BANK_DEPOSIT_SOURCE_SUCCESS,
          payload: refreshSourceAndGetRequestId,
        });
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: actions.REFRESH_BANK_DEPOSIT_SOURCE_ERROR,
        });
      }
    },

  doGetDepositsRequestStatus:
    (appToken, requestId) => async (dispatch, state) => {
      try {
        dispatch({
          type: actions.GET_DEPOSITS_REQUEST_STATUS_STARTED,
        });

        const environment = selectEnvironment(state());

        const depositsRequestStatus =
          await DepositsService.getDepositsRequestStatus({
            appToken,
            requestId,
            appEnvironment: environment,
          });

        dispatch({
          type: actions.GET_DEPOSITS_REQUEST_STATUS_SUCCESS,
          payload: depositsRequestStatus,
        });

        // get user's deposits source after the loading the deposits is completed
        if (DEPOSITS_LOADED_STATUSES.includes(depositsRequestStatus)) {
          actions.doGetUploadedDepositsSourceList();
        }
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: actions.GET_DEPOSITS_REQUEST_STATUS_ERROR,
        });
      }
    },

  doCancelGetDepositsUploadStatus: () => async dispatch => {
    dispatch({
      type: actions.CANCEL_GET_DEPOSITS_UPLOAD_STATUS,
    });
  },

  doOpenDepositsDialog: () => async dispatch => {
    dispatch({
      type: actions.OPEN_DEPOSITS_DIALOG,
    });
  },

  doCloseDepositsDialog: () => async dispatch => {
    dispatch({
      type: actions.CLOSE_DEPOSITS_DIALOG,
    });
  },

  doSetUpdateDepositsStep: () => async dispatch => {
    dispatch({
      type: actions.SET_UPDATE_DEPOSITS_STEP,
    });
  },

  doSetSelectBankStep:
    ({ isSelectBankStep = true }) =>
    async dispatch => {
      dispatch({
        type: actions.SET_SELECT_BANK_ACCOUNT_STEP,
        payload: { isSelectBankStep },
      });
    },

  doSetSelectedDepositsBankAccountDetails: bankDetails => async dispatch => {
    dispatch({
      type: actions.SET_SELECTED_BANK_DETAILS,
      payload: bankDetails,
    });
  },

  doGetEntityBankAccounts:
    (appToken, bankIdentifier) => async (dispatch, state) => {
      try {
        dispatch({
          type: actions.GET_ENTITY_BANK_ACCOUNTS_STARTED,
        });

        const environment = selectEnvironment(state());

        const accounts = await DepositsService.getEntityBankAccounts({
          appToken,
          appEnvironment: environment,
          bankIdentifier,
        });

        dispatch({
          type: actions.GET_ENTITY_BANK_ACCOUNTS_SUCCESS,
          payload: accounts,
        });
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: actions.GET_ENTITY_BANK_ACCOUNTS_ERROR,
        });
      }
    },
};

import actions from 'modules/auth/authActions';

const initialData = {
  currentUser: null,
  scopes: [],
  loadingInit: true,
  loadingEmailConfirmation: false,
  loadingPasswordResetEmail: false,
  loadingVerifyEmail: false,
  loadingPasswordReset: false,
  loadingPasswordChange: false,
  loadingUpdateProfile: false,
  loading: false,
  errorMessage: null,
  invitationTokenData: { email: '', organization_name: '' },
  docsToken: null,
  verifyingUpdateMfa: false,
  fetchingMfaDetails: false,
  fetchingPasswordResetDetails: false,
  passwordResetDetails: { mfa_settings: { status: 'NOT_SET' } },
  mfaDetails: null,
};

const authReducers = (state = initialData, { type, payload }) => {
  if (type === actions.ERROR_MESSAGE_CLEARED) {
    return {
      ...state,
      errorMessage: null,
    };
  }

  if (type === actions.CURRENT_USER_REFRESH_SUCCESS) {
    return {
      ...state,
      currentUser: payload.currentUser || null,
    };
  }

  if (type === actions.AUTH_START) {
    return {
      ...state,
      errorMessage: null,
      loading: true,
    };
  }

  if (type === actions.AUTH_SUCCESS) {
    return {
      ...state,
      currentUser: payload.currentUser || null,
      scopes: payload.scopes || null,
      errorMessage: null,
      loading: false,
    };
  }

  if (type === actions.AUTH_DISABLED) {
    return {
      ...state,
      currentUser: null,
      scopes: [],
      errorMessage: null,
      loading: false,
      docsToken: null,
    };
  }

  if (type === actions.AUTH_ERROR) {
    return {
      ...state,
      currentUser: null,
      scopes: [],
      errorMessage: payload || null,
      loading: false,
      docsToken: null,
    };
  }

  if (type === actions.EMAIL_CONFIRMATION_START) {
    return {
      ...state,
      loadingEmailConfirmation: true,
    };
  }

  if (type === actions.EMAIL_CONFIRMATION_SUCCESS) {
    return {
      ...state,
      loadingEmailConfirmation: false,
    };
  }

  if (type === actions.EMAIL_CONFIRMATION_ERROR) {
    return {
      ...state,
      loadingEmailConfirmation: false,
    };
  }

  if (type === actions.PASSWORD_RESET_EMAIL_START) {
    return {
      ...state,
      loadingPasswordResetEmail: true,
    };
  }

  if (type === actions.PASSWORD_RESET_EMAIL_SUCCESS) {
    return {
      ...state,
      loadingPasswordResetEmail: false,
    };
  }

  if (type === actions.PASSWORD_RESET_EMAIL_ERROR) {
    return {
      ...state,
      loadingPasswordResetEmail: false,
    };
  }

  if (type === actions.PASSWORD_RESET_START) {
    return {
      ...state,
      loadingPasswordReset: true,
    };
  }

  if (type === actions.PASSWORD_RESET_SUCCESS) {
    return {
      ...state,
      loadingPasswordReset: false,
    };
  }

  if (type === actions.PASSWORD_RESET_ERROR) {
    return {
      ...state,
      loadingPasswordReset: false,
      errorMessage: payload || null,
    };
  }

  if (type === actions.PASSWORD_CHANGE_START) {
    return {
      ...state,
      loadingPasswordChange: true,
    };
  }

  if (type === actions.PASSWORD_CHANGE_SUCCESS) {
    return {
      ...state,
      loadingPasswordChange: false,
    };
  }

  if (type === actions.PASSWORD_CHANGE_ERROR) {
    return {
      ...state,
      loadingPasswordChange: false,
      errorMessage: payload || null,
    };
  }

  if (type === actions.UPDATE_PROFILE_START) {
    return {
      ...state,
      loadingUpdateProfile: true,
    };
  }

  if (type === actions.UPDATE_PROFILE_SUCCESS) {
    return {
      ...state,
      loadingUpdateProfile: false,
    };
  }

  if (type === actions.UPDATE_PROFILE_ERROR) {
    return {
      ...state,
      loadingUpdateProfile: false,
    };
  }

  if (type === actions.AUTH_INIT_SUCCESS) {
    return {
      ...state,
      currentUser: payload.currentUser || null,
      scopes: payload.scopes || null,
      loadingInit: false,
      docsToken: null,
    };
  }

  if (type === actions.AUTH_INIT_ERROR) {
    return {
      ...state,
      currentUser: null,
      scopes: [],
      loadingInit: false,
      docsToken: null,
    };
  }

  if (type === actions.INVITATION_TOKEN_VERIFY_SUCCESS) {
    return {
      ...state,
      invitationTokenData: payload,
    };
  }

  if (type === actions.DOCS_TOKEN_FETCH_START) {
    return {
      ...state,
      docsToken: null,
    };
  }

  if (type === actions.DOCS_TOKEN_FETCH_SUCCESS) {
    return {
      ...state,
      docsToken: payload.docsToken,
    };
  }

  if (type === actions.DOCS_TOKEN_FETCH_ERROR) {
    return {
      ...state,
      docsToken: null,
    };
  }

  if (type === actions.RESET_STATE) {
    return {
      auth: {
        loadingInit: false,
      },
    };
  }

  if (type === actions.VERIFY_UPDATE_MFA_START) {
    return {
      ...state,
      verifyingUpdateMfa: true,
    };
  }

  if (type === actions.VERIFY_UPDATE_MFA_SUCCESS) {
    return {
      ...state,
      verifyingUpdateMfa: false,
    };
  }

  if (type === actions.VERIFY_UPDATE_MFA_ERROR) {
    return {
      ...state,
      verifyingUpdateMfa: false,
      errorMessage: payload || null,
    };
  }

  if (type === actions.GET_MFA_DETAILS_START) {
    return {
      ...state,
      fetchingMfaDetails: true,
    };
  }

  if (type === actions.GET_MFA_DETAILS_SUCCESS) {
    return {
      ...state,
      fetchingMfaDetails: false,
      mfaDetails: payload,
    };
  }

  if (type === actions.GET_MFA_DETAILS_ERROR) {
    return {
      ...state,
      fetchingMfaDetails: false,
    };
  }

  if (type === actions.GET_PASSWORD_RESET_DETAILS_START) {
    return {
      ...state,
      fetchingPasswordResetDetails: true,
    };
  }

  if (type === actions.GET_PASSWORD_RESET_DETAILS_SUCCESS) {
    return {
      ...state,
      fetchingPasswordResetDetails: false,
      passwordResetDetails: payload,
    };
  }

  if (type === actions.GET_PASSWORD_RESET_DETAILS_ERROR) {
    return {
      ...state,
      fetchingPasswordResetDetails: false,
    };
  }

  return state;
};

export default authReducers;

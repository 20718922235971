import { isKSACountry } from '../config/countriesEnvironments';
import {
  LOGIN_BEHAVIOUR_TYPES,
  BENEFICIARY_BEHAVIOUR_TYPES,
  PAYMENT_BEHAVIOUR_TYPES,
} from './constants';

export function generateFlowChallengesFromBehaviours(users, isWritePermission) {
  return users.map(user => {
    const {
      username,
      password,
      mockbank_user_id: mockUserId,
      login_behaviour: loginBehaviour,
      beneficiary_behaviour: beneficiaryBehaviour,
      payment_behaviour: paymentBehaviour,
    } = user;

    let createPaymentSource = [];
    const link = [];
    const reconnect = [];
    const pay = [];

    const hidden = {
      hidden: { mockUserId, reconnect },
    };

    const actionsColumn = {
      actions: {
        actions: '',
      },
    };

    if (isKSACountry) {
      return {
        credentials: { username, password },
        hidden,
        ...(isWritePermission ? actionsColumn : {}),
      };
    }

    // handles users with `null` values in behaviours.
    if (!loginBehaviour || !beneficiaryBehaviour || !paymentBehaviour) {
      return {
        credentials: { username, password },
        challenges: {
          link,
          reconnect,
          'create payment source': createPaymentSource,
          pay,
        },
        hidden,
      };
    }

    // possible challenges values
    const NO_CHALLENGE = 'NO_CHALLENGE';
    const OTP_REQUIRED = 'OTP_REQUIRED';
    const SECURITY_QUESTIONS_REQUIRED = 'SECURITY_QUESTIONS_REQUIRED';
    const APP_AUTH_REQUIRED = 'APP_AUTH_REQUIRED';
    const CAPTCHA_REQUIRED = 'CAPTCHA_REQUIRED';
    const INSTANT = 'INSTANT';
    const COOL_OFF_15MIN = 'COOL_OFF_15MIN';
    const USER_CREDENTIALS = 'USER_CREDENTIALS';
    const USER_CONFIRMATION = 'USER_CONFIRMATION';

    const LOGIN_BEHAVIOUR_SWITCH = {
      // OK: NONE,
      [LOGIN_BEHAVIOUR_TYPES.STATUS_MFA_REQUIRED]: OTP_REQUIRED,
      [LOGIN_BEHAVIOUR_TYPES.STATUS_SECURITY_QUESTIONS_REQUIRED]:
        SECURITY_QUESTIONS_REQUIRED,
      [LOGIN_BEHAVIOUR_TYPES.STATUS_APP_AUTH_REQUIRED]: APP_AUTH_REQUIRED,
      [LOGIN_BEHAVIOUR_TYPES.STATUS_CAPTCHA_REQUIRED]: CAPTCHA_REQUIRED,
    };

    const BENEFICIARY_BEHAVIOUR_SWITCH = {
      [BENEFICIARY_BEHAVIOUR_TYPES.STATUS_ACTIVE]: INSTANT,
      [BENEFICIARY_BEHAVIOUR_TYPES.STATUS_MFA_REQUIRED]: [
        OTP_REQUIRED,
        INSTANT,
      ],
      [BENEFICIARY_BEHAVIOUR_TYPES.STATUS_PENDING_COOL_OFF]: COOL_OFF_15MIN,
    };

    // #link
    // link always requires user credentials as a first step
    link.push(USER_CREDENTIALS);
    const linkSwitch = {
      [LOGIN_BEHAVIOUR_TYPES.STATUS_MFA_REQUIRED]: OTP_REQUIRED,
      [LOGIN_BEHAVIOUR_TYPES.STATUS_SECURITY_QUESTIONS_REQUIRED]:
        SECURITY_QUESTIONS_REQUIRED,
      [LOGIN_BEHAVIOUR_TYPES.STATUS_APP_AUTH_REQUIRED]: APP_AUTH_REQUIRED,
      [LOGIN_BEHAVIOUR_TYPES.STATUS_CAPTCHA_REQUIRED]: CAPTCHA_REQUIRED,
    };

    if (
      loginBehaviour === LOGIN_BEHAVIOUR_TYPES.STATUS_MFA_REQUIRED ||
      loginBehaviour ===
        LOGIN_BEHAVIOUR_TYPES.STATUS_SECURITY_QUESTIONS_REQUIRED
    ) {
      link.push(linkSwitch[loginBehaviour]);
    }

    // #reconnect
    const reconnectSwitch = {
      [LOGIN_BEHAVIOUR_TYPES.STATUS_OK]: NO_CHALLENGE,
      [LOGIN_BEHAVIOUR_TYPES.STATUS_MFA_REQUIRED]: OTP_REQUIRED,
      [LOGIN_BEHAVIOUR_TYPES.STATUS_SECURITY_QUESTIONS_REQUIRED]:
        SECURITY_QUESTIONS_REQUIRED,
      [LOGIN_BEHAVIOUR_TYPES.STATUS_APP_AUTH_REQUIRED]: APP_AUTH_REQUIRED,
      [LOGIN_BEHAVIOUR_TYPES.STATUS_CAPTCHA_REQUIRED]: CAPTCHA_REQUIRED,
    };

    reconnect.push(reconnectSwitch[loginBehaviour]);

    // #create payment source
    // createPaymentSource always requires user credentials as a first step
    createPaymentSource.push(USER_CREDENTIALS);
    if (
      loginBehaviour === LOGIN_BEHAVIOUR_TYPES.STATUS_MFA_REQUIRED ||
      loginBehaviour ===
        LOGIN_BEHAVIOUR_TYPES.STATUS_SECURITY_QUESTIONS_REQUIRED
    ) {
      createPaymentSource.push(LOGIN_BEHAVIOUR_SWITCH[loginBehaviour]);
    }

    createPaymentSource = createPaymentSource.concat(
      BENEFICIARY_BEHAVIOUR_SWITCH[beneficiaryBehaviour],
    );

    // #pay
    // pay always requires user confirmation as a first step
    pay.push(USER_CONFIRMATION);

    const PAYMENT_BEHAVIOUR_SWITCH = {
      [PAYMENT_BEHAVIOUR_TYPES.STATUS_MFA_REQUIRED]: OTP_REQUIRED,
      [PAYMENT_BEHAVIOUR_TYPES.STATUS_SECURITY_QUESTIONS_REQUIRED]:
        SECURITY_QUESTIONS_REQUIRED,
      [PAYMENT_BEHAVIOUR_TYPES.STATUS_CAPTCHA_REQUIRED]: CAPTCHA_REQUIRED,
    };
    if (
      loginBehaviour === LOGIN_BEHAVIOUR_TYPES.STATUS_MFA_REQUIRED ||
      loginBehaviour ===
        LOGIN_BEHAVIOUR_TYPES.STATUS_SECURITY_QUESTIONS_REQUIRED
    ) {
      pay.push(LOGIN_BEHAVIOUR_SWITCH[loginBehaviour]);
    }

    if (
      paymentBehaviour === PAYMENT_BEHAVIOUR_TYPES.STATUS_MFA_REQUIRED ||
      paymentBehaviour ===
        PAYMENT_BEHAVIOUR_TYPES.STATUS_SECURITY_QUESTIONS_REQUIRED
    ) {
      pay.push(PAYMENT_BEHAVIOUR_SWITCH[paymentBehaviour]);
    }

    const credentialsColumns = {
      credentials: { username, password },
      challenges: {
        link,
        reconnect,
        'create payment source': createPaymentSource,
        pay,
      },
    };

    return {
      ...credentialsColumns,
      hidden,
      ...(isWritePermission ? actionsColumn : {}),
    };
  });
}

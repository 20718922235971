import styled from 'styled-components';

// Logs
export const $EmptyStateContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  flex-direction: column;
  padding: 32px 0;
`;

export const $LoadingStateContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  flex-direction: column;
  padding: 130px 0;
`;

// PageHeaderRow
export const $HeaderRow = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;
`;
export const $Title = styled.h1`
  font-size: ${({ theme }) => theme.font.size.heading.md};
  font-family: ${({ theme }) => theme.font.family.heading};
  margin-bottom: 8px;
`;
export const $Description = styled.span`
  font-size: ${({ theme }) => theme.font.size.body.md};
`;

export const hasDuplicates = (list, value) => {
  if (!list || !value) return false;
  return (
    list.filter(v => v?.trim()?.toLowerCase() === value?.trim()?.toLowerCase())
      .length > 1
  );
};

export const removeDuplicates = list => {
  if (!list) return false;
  return list.filter((v, i) => list.indexOf(v) === i);
};

import actions from 'modules/certificate/certificateActions';

const initialData = {
  loading: false,
  certificateData: {},
  showConfirmDownloadDialog: false,
  showConfirmViewClientSecret: false,
  secret: null
};

const certificateReducer = (state = initialData, { type, payload }) => {
  if (type === actions.OAUTH_SECRET_STARTED) {
    return {
      ...state,
      loading: true,
      secret: null
    };
  }

  if (type === actions.OAUTH_SECRET_SUCCESS) {
    return {
      ...state,
      loading: false,
      secret: payload.secret,
      showConfirmViewClientSecret: false,
    };
  }

  if (type === actions.OAUTH_SECRET_CLEAR) {
    return {
      ...state,
      loading: false,
      secret: null
    };
  }

  if (type === actions.OAUTH_SECRET_ERROR) {
    return {
      ...state,
      loading: false,
      secret: null,
      showConfirmViewClientSecret: false,
    };
  }

  if (type === actions.GET_CERTCHAIN_STARTED) {
    return {
      ...state,
      loading: true,
    };
  }

  if (type === actions.GET_CERTCHAIN_SUCCESS) {
    return {
      ...state,
      loading: false,
    };
  }

  if (type === actions.GET_CERTCHAIN_ERROR) {
    return {
      ...state,
      loading: false,
    };
  }

  if (type === actions.CREATE_STARTED) {
    return {
      ...state,
      loading: true,
    };
  }

  if (type === actions.CREATE_SUCCESS) {
    return {
      ...state,
      loading: false,
    };
  }

  if (type === actions.CREATE_ERROR) {
    return {
      ...state,
      loading: false,
    };
  }

  if (type === actions.REVOKE_STARTED) {
    return {
      ...state,
      loading: true,
    };
  }

  if (type === actions.REVOKE_SUCCESS) {
    return {
      ...state,
      loading: false,
    };
  }

  if (type === actions.REVOKE_ERROR) {
    return {
      ...state,
      loading: false,
    };
  }

  if (type === actions.SHOW_CONFIRM_DIALOG) {
    return {
      ...state,
      certificateData: payload,
      showConfirmDownloadDialog: true,
    };
  }

  if (type === actions.HIDE_CONFIRM_DIALOG) {
    return {
      ...state,
      certificateData: {},
      showConfirmDownloadDialog: false,
    };
  }

  if (type === actions.SHOW_SECRET_CONFIRM_DIALOG) {
    return {
      ...state,
      certificateData: payload,
      showConfirmViewClientSecret: true,
    };
  }

  if (type === actions.HIDE_SECRET_CONFIRM_DIALOG) {
    return {
      ...state,
      certificateData: {},
      showConfirmViewClientSecret: false,
    };
  }

  return state;
};

export default certificateReducer;

import { RootState } from 'modules/store';
import { ErrorObject } from 'types/global';
import Errors from 'modules/shared/error/errors';
import { createAsyncThunk } from '@reduxjs/toolkit';
import Message from 'view/shared/message';
import {
  AvsConfiguration,
  AvsConfigurationError,
  AvsUpdateConfigPayload,
} from './avs.types';
import AvsService from './avs.service';

export const getAvsConfigurations = createAsyncThunk<
  AvsConfiguration[],
  { appToken: string },
  {
    state: RootState;
    rejectValue: AvsConfigurationError | ErrorObject;
  }
>(
  'avs/get-configurations',
  async ({ appToken }, { getState, rejectWithValue }) => {
    try {
      const { environment: appEnvironment } = getState().root.global;

      return await AvsService.getAvsConfigurations({
        appToken,
        appEnvironment,
      });
    } catch (error: unknown) {
      return rejectWithValue({
        message: Errors.selectMessage(error),
        errorCode: Errors.errorCode(error),
      });
    }
  },
);

export const updateAvsConfigurations = createAsyncThunk<
  unknown,
  {
    appToken: string;
    data: AvsUpdateConfigPayload[];
  },
  {
    state: RootState;
    rejectValue: { message: string } | AvsConfigurationError | ErrorObject;
  }
>(
  'avs/update-configurations',
  async ({ appToken, data }, { getState, rejectWithValue, dispatch }) => {
    const { environment: appEnvironment } = getState().root.global;
    try {
      const response = await AvsService.updateAvsConfigurations({
        appToken,
        appEnvironment,
        data,
      });

      // re-fetch configuration list after successful update
      dispatch(getAvsConfigurations({ appToken }));

      return response;
    } catch (error: unknown) {
      const genericError =
        'Failed to update Account Verification configurations';
      Message.error(Errors.selectMessage(error) || genericError);

      return rejectWithValue({
        message: Errors.selectMessage(error),
        errorCode: Errors.errorCode(error),
      });
    }
  },
);

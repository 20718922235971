import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components/macro';
import {
  Key,
  Time,
  Person,
  Flash,
  Flag,
  ChatboxEllipses,
  HelpCircle,
  Terminal,
  DocumentText,
  Build,
  Search,
  Close,
  ChevronForward,
  ChevronBack,
  ChevronDown,
  ChevronUp,
  Today,
  Eye,
  EyeOff,
  ArrowBack,
  ArrowForward,
  Cash,
  Receipt,
  Settings,
  Copy,
  Checkmark,
  Construct,
  Clipboard,
  CheckmarkCircle,
  CloudOffline,
  SyncCircle,
  People,
  Sad,
  HandLeft,
  Warning,
  AlertCircle,
  CheckmarkDoneCircle,
  EllipsisHorizontal,
  EllipsisVertical,
  ArrowDown,
  ArrowUp,
  Download,
  CloseCircle,
  ArrowUndo,
  InformationCircle,
  PersonAdd,
  Albums,
  Card,
} from '@styled-icons/ionicons-solid';
import {
  RemoveCircle as RemoveCircleOutline,
  CheckmarkCircle as CheckmarkCircleOutline,
  Ellipse as EllipseOutline,
  Link as LinkOutline,
} from '@styled-icons/ionicons-outline';
import {
  Apps as AppsSharp,
  Grid as GridSharp,
  Key as KeySharp,
  Settings as SettingsSharp,
  AddCircle,
} from '@styled-icons/ionicons-sharp';
import { Dashboard } from '@styled-icons/boxicons-solid';
import { ArrowReturnLeft, CircleHalf, Table } from '@styled-icons/bootstrap';
import { BuildingBank } from '@styled-icons/fluentui-system-filled';
import { Webhooks, Payouts } from './icons';

const icons = {
  ChevronForward,
  ChevronBack,
  Key,
  Time,
  Person,
  Flash,
  Flag,
  ChatboxEllipses,
  HelpCircle,
  Terminal,
  Build,
  Search,
  Close,
  Today,
  Eye,
  EyeOff,
  ChevronDown,
  ChevronUp,
  ArrowBack,
  ArrowForward,
  Cash,
  Receipt,
  Copy,
  AppsSharp,
  GridSharp,
  KeySharp,
  SettingsSharp,
  Settings,
  Dashboard,
  Checkmark,
  Construct,
  AddCircle,
  Clipboard,
  BuildingBank,
  CheckmarkCircle,
  CloudOffline,
  SyncCircle,
  Sad,
  HandLeft,
  Warning,
  People,
  CheckmarkDoneCircle,
  EllipsisHorizontal,
  EllipsisVertical,
  ArrowDown,
  ArrowUp,
  ArrowReturnLeft,
  Download,
  CloseCircle,
  ArrowUndo,
  InformationCircle,
  AlertCircle,
  CheckmarkCircleOutline,
  RemoveCircleOutline,
  EllipseOutline,
  CircleHalf,
  PersonAdd,
  LinkOutline,
  Albums,
  Card,
  DocumentText,
  Table,
};

/* iconName: name of the exported icon in './icons/index.js' */
const localIcons = {
  Webhooks,
  Payouts,
};

const $ClickableIcon = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    opacity: 1;
  }
`;

const $LocalIcon = styled.div`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
`;

const Icon = ({
  name,
  size,
  onClick,
  className,
  isDisabled,
  'data-cy': dataCy,
}) => {
  // fall back to `build` icon if the icon isn't found inside icons object. Check name(case sensitive)/make sure it's imported.
  const IconComponent = icons[name] || icons.Build;

  function handleClick(event) {
    // prevent default behavior of button such as form submission
    event.preventDefault();
    onClick();
  }

  if (Object.keys(localIcons).includes(name)) {
    const LocalIconComponent = localIcons[name];
    return (
      <$LocalIcon size={size}>
        <LocalIconComponent fill="inherent" />
      </$LocalIcon>
    );
  }

  // return clickable icon
  if (onClick) {
    return (
      <$ClickableIcon
        className={className}
        onClick={event => handleClick(event)}
        disabled={isDisabled}
        type="button"
        data-cy={dataCy}
      >
        <IconComponent size={size} />
      </$ClickableIcon>
    );
  }

  return (
    <IconComponent className={className} size={size} data-cy={dataCy} /> // this is an svg tag
  );
};

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.number,
  onClick: PropTypes.func,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
  'data-cy': PropTypes.string,
};

Icon.defaultProps = {
  size: 14,
  onClick: undefined,
  isDisabled: false,
  className: '',
  'data-cy': '',
};

export default Icon;

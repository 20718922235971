import { PAYOUTS_BULK, PAYOUTS_SINGLE } from 'view/payoutsPage/constants';
import { apiFetch } from 'modules/shared/fetch';
import { createCorrelationId } from 'utils/createUniqueId';
import { GetAuthorizablesRequestParams } from './payout.types';

interface PaymentDetailsRequestParams {
  appToken: string;
  appEnvironment: string;
  body?: object;
}

const correlationId = createCorrelationId();

export default class PayoutService {
  static async getPayoutDetailsForCurrentUser({
    appToken,
    appEnvironment,
  }: PaymentDetailsRequestParams) {
    const response = await apiFetch('/payouts/users/current/payout-details', {
      // @ts-ignore
      appEnvironment,
      headers: {
        'lean-app-token': appToken,
        'lean-correlation-id': correlationId,
      },
    });

    return response?.data;
  }

  static async getPayoutDetailsForUsers({
    appToken,
    appEnvironment,
  }: PaymentDetailsRequestParams) {
    const response = await apiFetch('/payouts/users/payout-details', {
      // @ts-ignore
      appEnvironment,
      headers: {
        'lean-app-token': appToken,
        'lean-correlation-id': correlationId,
      },
    });

    return response?.data;
  }

  static async optInCurrentUserForPayouts({
    appToken,
    appEnvironment,
  }: PaymentDetailsRequestParams) {
    const response = await apiFetch('/payouts/users/sign-up', {
      // @ts-ignore
      method: 'POST',
      appEnvironment,
      headers: {
        'lean-app-token': appToken,
        'lean-correlation-id': correlationId,
      },
    });

    return response?.data;
  }

  static async getAuthorizables({
    body,
    appToken,
    appEnvironment,
    mechanism,
  }: GetAuthorizablesRequestParams) {
    const searchParams = new URLSearchParams();

    Object.entries(body).forEach(([key, value]) => {
      searchParams.append(key, value);
    });

    let url = '';

    if (mechanism === PAYOUTS_SINGLE) {
      url = `/payouts/single-payments?${searchParams.toString()}`;
    }

    if (mechanism === PAYOUTS_BULK) {
      url = `/payouts/bulk-payments?${searchParams.toString()}`;
    }

    const response = await apiFetch(url, {
      // @ts-ignore
      appEnvironment,
      headers: {
        'lean-app-token': appToken,
        'lean-correlation-id': correlationId,
      },
    });

    return response?.data;
  }
}

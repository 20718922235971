const backendUrl = `https://app.sa02.leantech.me`;
const sandboxUrl = `https://app.sandbox.sa02.leantech.me`;
const url = 'https://dev.sa02.leantech.me';
const hostname = 'dev.sa02.leantech.me';

const apiSandboxUrl = `https://sandbox.sa02.leantech.me`;
const hyperlinkBackendUrl = `https://hyperlink-api.sa02.leantech.me`;
const hyperlinkSandboxBackendUrl = `https://hyperlink-api.sa02.leantech.me`;
const oauthUrl = `https://auth.sa02.leantech.me`;
const oauthSandboxUrl = `https://auth.sa02.leantech.me`;
const linkSDKCDN = `https://cdn.leantech.me/link/sdk/web/staging/sa/latest/Lean.min.js`;
const linkSDKv2CDN =
  'https://cdn.leantech.me/link/sdk/web/v2/staging/sa/latest/Lean.min.js';

const linkApiUrl = 'https://link.sa02.leantech.me';
const linkSandboxApiUrl = 'https://link.sandbox.sa02.leantech.me';

const sa02 = {
  backendUrl,
  apiSandboxUrl,
  linkSDKCDN,
  sandboxUrl,
  url,
  hostname,
  hyperlinkBackendUrl,
  hyperlinkSandboxBackendUrl,
  oauthUrl,
  oauthSandboxUrl,
  linkApiUrl,
  linkSandboxApiUrl,
  linkSDKv2CDN,
};

export default sa02;

import { selectEnvironment } from 'modules/global';
import applicationListSelectors from 'modules/application/list/applicationListSelectors';

import Errors from '../shared/error/errors';
import WebhooksService from './webhooksService';
import Message from '../../view/shared/message';
import { i18n } from '../../i18n';

const prefix = 'dev-portal/webhooks';
const { analytics } = window;

export const actions = {
  GET_WEBHOOKS_DESTINATIONS_STARTED: `${prefix}/GET_WEBHOOKS_DESTINATIONS_STARTED`,
  GET_WEBHOOKS_DESTINATIONS_SUCCESS: `${prefix}/GET_WEBHOOKS_DESTINATIONS_SUCCESS`,
  GET_WEBHOOKS_DESTINATIONS_ERROR: `${prefix}/GET_WEBHOOKS_DESTINATIONS_ERROR`,

  GET_WEBHOOKS_LOGS_STARTED: `${prefix}/GET_WEBHOOKS_LOGS_STARTED`,
  GET_WEBHOOKS_LOGS_SUCCESS: `${prefix}/GET_WEBHOOKS_LOGS_SUCCESS`,
  GET_WEBHOOKS_LOGS_ERROR: `${prefix}/GET_WEBHOOKS_LOGS_ERROR`,

  UPDATE_DEFAULT_WEBHOOK_STARTED: `${prefix}/UPDATE_DEFAULT_WEBHOOK_STARTED`,
  UPDATE_DEFAULT_WEBHOOK_SUCCESS: `${prefix}/UPDATE_DEFAULT_WEBHOOK_SUCCESS`,
  UPDATE_DEFAULT_WEBHOOK_ERROR: `${prefix}/UPDATE_DEFAULT_WEBHOOK_ERROR`,

  GET_DEFAULT_WEBHOOK_STARTED: `${prefix}/GET_DEFAULT_WEBHOOK_STARTED`,
  GET_DEFAULT_WEBHOOK_SUCCESS: `${prefix}/GET_DEFAULT_WEBHOOK_SUCCESS`,
  GET_DEFAULT_WEBHOOK_ERROR: `${prefix}/GET_DEFAULT_WEBHOOK_ERROR`,

  RESEND_WEBHOOK_STARTED: `${prefix}/RESEND_WEBHOOK_STARTED`,
  RESEND_WEBHOOK_SUCCESS: `${prefix}/RESEND_WEBHOOK_SUCCESS`,
  RESEND_WEBHOOK_ERROR: `${prefix}/RESEND_WEBHOOK_ERROR`,

  CREATE_WEBHOOK_DESTINATION_STARTED: `${prefix}/CREATE_WEBHOOK_DESTINATION_STARTED`,
  CREATE_WEBHOOK_DESTINATION_SUCCESS: `${prefix}/CREATE_WEBHOOK_DESTINATION_SUCCESS`,
  CREATE_WEBHOOK_DESTINATION_ERROR: `${prefix}/CREATE_WEBHOOK_DESTINATION_ERROR`,

  ENABLE_WEBHOOK_DESTINATION_STARTED: `${prefix}/ENABLE_WEBHOOK_DESTINATION_STARTED`,
  ENABLE_WEBHOOK_DESTINATION_SUCCESS: `${prefix}/ENABLE_WEBHOOK_DESTINATION_SUCCESS`,
  ENABLE_WEBHOOK_DESTINATION_ERROR: `${prefix}/ENABLE_WEBHOOK_DESTINATION_ERROR`,

  GET_WEBHOOK_EVENT_TYPES_STARTED: `${prefix}/GET_WEBHOOK_EVENT_TYPES_STARTED`,
  GET_WEBHOOK_EVENT_TYPES_SUCCESS: `${prefix}/GET_WEBHOOK_EVENT_TYPES_SUCCESS`,
  GET_WEBHOOK_EVENT_TYPES_ERROR: `${prefix}/GET_WEBHOOK_EVENT_TYPES_ERROR`,

  DELETE_WEBHOOK_DESTINATION_STARTED: `${prefix}/DELETE_WEBHOOK_DESTINATION_STARTED`,
  DELETE_WEBHOOK_DESTINATION_SUCCESS: `${prefix}/DELETE_WEBHOOK_DESTINATION_SUCCESS`,
  DELETE_WEBHOOK_DESTINATION_ERROR: `${prefix}/DELETE_WEBHOOK_DESTINATION_ERROR`,

  doGetWebhookDestinations: () => async (dispatch, getState) => {
    try {
      dispatch({
        type: actions.GET_WEBHOOKS_DESTINATIONS_STARTED,
      });

      const appId = applicationListSelectors.selectApplicationId(getState());
      const environment = selectEnvironment(getState());

      const webhookDestinations = await WebhooksService.getWebhookDestinations(
        appId,
        {
          appEnvironment: environment,
        },
      );

      dispatch({
        type: actions.GET_WEBHOOKS_DESTINATIONS_SUCCESS,
        payload: webhookDestinations,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: actions.GET_WEBHOOKS_DESTINATIONS_ERROR,
      });
    }
  },

  doCreateWebhookDestination: body => async (dispatch, getState) => {
    try {
      dispatch({
        type: actions.CREATE_WEBHOOK_DESTINATION_STARTED,
      });

      const appId = applicationListSelectors.selectApplicationId(getState());
      const environment = selectEnvironment(getState());

      await WebhooksService.createWebhookDestination(appId, body, {
        appEnvironment: environment,
      });

      dispatch({
        type: actions.CREATE_WEBHOOK_DESTINATION_SUCCESS,
      });

      dispatch(actions.doGetWebhookDestinations());
    } catch (error) {
      Errors.handle(error);

      Message.error('Adding webhook destination failed');

      dispatch({
        type: actions.CREATE_WEBHOOK_DESTINATION_ERROR,
      });
    }
  },

  doUpdateDefaultDestination:
    (id, url, environmentFromArgs) => async (dispatch, getState) => {
      try {
        dispatch({
          type: actions.UPDATE_DEFAULT_WEBHOOK_STARTED,
        });

        const environment = selectEnvironment(getState());

        const record = await WebhooksService.updateDefaultDestination(id, url, {
          appEnvironment: environmentFromArgs || environment,
        });

        dispatch({
          type: actions.UPDATE_DEFAULT_WEBHOOK_SUCCESS,
          payload: record,
        });

        Message.success(i18n('entities.application.update.success'));
        analytics.track('applicationsUpdateSuccess', {
          applicationId: id,
        });
      } catch (error) {
        Errors.handle(error);

        analytics.track('applicationsUpdateError', {
          applicationId: id,
        });
        dispatch({
          type: actions.UPDATE_DEFAULT_WEBHOOK_ERROR,
        });
      }
    },

  doGetDefaultDestination:
    (id, environmentFromArgs) => async (dispatch, getState) => {
      try {
        dispatch({
          type: actions.GET_DEFAULT_WEBHOOK_STARTED,
        });

        const environment = selectEnvironment(getState());

        const record = await WebhooksService.getDefaultDestination(id, {
          appEnvironment: environmentFromArgs || environment,
        });

        dispatch({
          type: actions.GET_DEFAULT_WEBHOOK_SUCCESS,
          payload: record,
        });
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: actions.GET_DEFAULT_WEBHOOK_ERROR,
        });
      }
    },

  doGetWebhookLogs:
    (appToken, destinationId, queryStringParams) =>
    async (dispatch, getState) => {
      try {
        dispatch({
          type: actions.GET_WEBHOOKS_LOGS_STARTED,
        });

        const environment = selectEnvironment(getState());

        const webhookLogs = await WebhooksService.getWebhookLogs(
          appToken,
          destinationId,
          queryStringParams,
          {
            appEnvironment: environment,
          },
        );

        dispatch({
          type: actions.GET_WEBHOOKS_LOGS_SUCCESS,
          payload: webhookLogs,
        });
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: actions.GET_WEBHOOKS_LOGS_ERROR,
        });
      }
    },

  doResendWebhook:
    (appToken, destinationId, messageId) => async (dispatch, getState) => {
      try {
        dispatch({
          type: actions.RESEND_WEBHOOK_STARTED,
        });
        const environment = selectEnvironment(getState());
        const { success } = await WebhooksService.resendWebhook(
          appToken,
          destinationId,
          messageId,
          {
            appEnvironment: environment,
          },
        );
        if (success) {
          dispatch({ type: actions.RESEND_WEBHOOK_SUCCESS });
          Message.success(i18n('webhooks.resendWebhook.success'));
        } else {
          dispatch({ type: actions.RESEND_WEBHOOK_ERROR });
          Message.warning(i18n('webhooks.resendWebhook.failure'));
        }
      } catch (error) {
        Errors.handle(error);
        dispatch({
          type: actions.RESEND_WEBHOOK_ERROR,
        });
        throw new Error(error);
      }
    },

  doSetDestinationEnabled:
    (destinationId, enabled) => async (dispatch, getState) => {
      const action = enabled ? 'Enable' : 'Disable';
      try {
        dispatch({
          type: actions.ENABLE_WEBHOOK_DESTINATION_STARTED,
        });
        const appId = applicationListSelectors.selectApplicationId(getState());
        const environment = selectEnvironment(getState());
        await WebhooksService.setDestinationEnabled(
          appId,
          destinationId,
          enabled,
          {
            appEnvironment: environment,
          },
        );
        dispatch({ type: actions.ENABLE_WEBHOOK_DESTINATION_SUCCESS });
        Message.success(`Webhook ${action.toLowerCase()}d`);
        dispatch(actions.doGetWebhookDestinations());
      } catch (error) {
        Errors.handle(error);
        Message.error(`${action} Webhook failed`);
        dispatch({
          type: actions.ENABLE_WEBHOOK_DESTINATION_ERROR,
        });
      }
    },

  doGetWebhookEventTypes: () => async (dispatch, getState) => {
    try {
      dispatch({
        type: actions.GET_WEBHOOK_EVENT_TYPES_STARTED,
      });

      const environment = selectEnvironment(getState());

      const eventTypes = await WebhooksService.getEventTypes({
        appEnvironment: environment,
      });

      dispatch({
        type: actions.GET_WEBHOOK_EVENT_TYPES_SUCCESS,
        payload: eventTypes,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: actions.GET_WEBHOOK_EVENT_TYPES_ERROR,
      });
    }
  },

  doDeleteDestination: destinationId => async (dispatch, getState) => {
    try {
      dispatch({
        type: actions.DELETE_WEBHOOK_DESTINATION_STARTED,
      });
      const appId = applicationListSelectors.selectApplicationId(getState());
      const environment = selectEnvironment(getState());
      await WebhooksService.deleteWebhookDestination(appId, destinationId, {
        appEnvironment: environment,
      });
      dispatch({ type: actions.DELETE_WEBHOOK_DESTINATION_SUCCESS });
      Message.success(`Webhook deleted`);
      dispatch(actions.doGetWebhookDestinations());
    } catch (error) {
      Errors.handle(error);
      Message.error(`Failed to delete webhook`);
      dispatch({
        type: actions.DELETE_WEBHOOK_DESTINATION_ERROR,
      });
    }
  },
};

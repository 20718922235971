const backendUrl = `https://app.ae02.leantech.me`;
const sandboxUrl = `https://app.sandbox.ae02.leantech.me`;
const url = 'https://dev.ae02.leantech.me';
const hostname = 'dev.ae02.leantech.me';
const apmUrl = 'https://apm.ae02.leantech.me';
const oauthUrl = `https://auth.ae02.leantech.me`;
const oauthSandboxUrl = `https://auth.ae02.leantech.me`;
const apiSandboxUrl = `https://sandbox.ae02.leantech.me`;

const linkSDKCDN = `https://cdn.leantech.me/link/sdk/web/staging/ae/latest/Lean.min.js`;

const ae02 = {
  backendUrl,
  apiSandboxUrl,
  linkSDKCDN,
  sandboxUrl,
  url,
  hostname,
  apmUrl,
  oauthUrl,
  oauthSandboxUrl
};

export default ae02;

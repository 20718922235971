/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';

import Layout from 'view/layout/Layout';
import { FullScreenLoading, FullScreenError } from 'components';
import PermissionChecker from 'modules/auth/permissionChecker';
import applicationListSelectors from 'modules/application/list/applicationListSelectors';
import applicationFormSelectors from 'modules/application/form/applicationFormSelectors';
import authSelectors from 'modules/auth/authSelectors';
import {
  bootstrapApp,
  selectInitialised,
  selectLoading,
} from 'modules/bootstrap';
import { selectEnvironment } from 'modules/global';
import { applicationPropTypes, currentUserPropTypes } from 'utils/proptypes';
import featureFlags from 'modules/featureFlags';

const PrivateRoute = ({
  component: Component,
  currentUser,
  scopes,
  dispatch,
  permissionRequired,
  bootstrapApp,
  isAppInitialised,
  application,
  environment,
  isLoading,
  isSandboxAppCreationErrored,
  isSandboxAppCreationLoading,
  featureFlag,
  ...rest
}) => {
  useEffect(() => {
    if (currentUser) {
      if (!isLoading && !isAppInitialised) {
        bootstrapApp();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Route
      {...rest}
      render={routeProps => {
        const permissionChecker = new PermissionChecker(
          currentUser,
          scopes,
          environment,
        );

        if (!permissionChecker.isAuthenticated) {
          return (
            <Redirect
              to={{
                pathname: '/auth/signin',
                state: { from: routeProps.location },
              }}
            />
          );
        }

        if (permissionChecker.isEmptyPermissions) {
          return <Redirect to="/auth/empty-permissions" />;
        }

        if (isSandboxAppCreationLoading) {
          return (
            <FullScreenLoading text="Lean is loading your sandbox application" />
          );
        }

        // while getting applications
        if (isLoading || !isAppInitialised) {
          return <FullScreenLoading text="Connecting to Lean" />;
        }

        if (featureFlag && !featureFlags.isOn(featureFlag)) {
          return (
            <Redirect
              to={{
                pathname: '/',
              }}
            />
          );
        }

        if (!permissionChecker.check(permissionRequired)) {
          return <Redirect to="/403" />;
        }

        if (isSandboxAppCreationErrored) {
          return (
            <FullScreenError
              email={currentUser.email}
              appName={currentUser.organization_name}
              text="Sorry, we couldn’t create your application at this moment. Please try again or contact us if the problem persists."
            />
          );
        }

        const layoutProps = {
          ...(application && {
            appName: application.name,
            appLogoUrl: application.logo_url,
            appId: application.id,
          }),
        };

        // Do not use dashboard layout for MFA setup and update pages
        if (
          ['/mfa/setup', '/mfa/update'].includes(routeProps.location.pathname)
        ) {
          return (
            <Component
              {...routeProps}
              {...layoutProps}
              environment={environment}
            />
          );
        }

        return (
          <Layout {...layoutProps} {...routeProps}>
            <Component
              {...routeProps}
              {...layoutProps}
              environment={environment}
            />
          </Layout>
        );
      }}
    />
  );
};

PrivateRoute.propTypes = {
  // component: reactElementPropTypes.isRequired, // Broken
  currentUser: currentUserPropTypes,
  scopes: PropTypes.arrayOf(PropTypes.string),
  // dispatch: PropTypes.func.isRequired, // Broken
  application: applicationPropTypes,
  // permissionRequired: permissionRequiredPropTypes.isRequired, // Broken
  bootstrapApp: PropTypes.func.isRequired,
  isAppInitialised: PropTypes.bool,
  isLoading: PropTypes.bool,
  isSandboxAppCreationLoading: PropTypes.bool,
  isSandboxAppCreationErrored: PropTypes.bool,
  environment: PropTypes.string.isRequired,
  featureFlag: PropTypes.string,
};

PrivateRoute.defaultProps = {
  currentUser: undefined,
  scopes: [],
  application: undefined,
  isAppInitialised: false,
  isLoading: false,
  isSandboxAppCreationLoading: false,
  isSandboxAppCreationErrored: false,
};

function mapStateToProps(state) {
  return {
    application: applicationListSelectors.selectApplication(state),
    isAppInitialised: selectInitialised(state),
    isLoading: selectLoading(state),
    environment: selectEnvironment(state),
    isSandboxAppCreationLoading:
      applicationFormSelectors.selectSandboxAppCreationLoading(state),
    isSandboxAppCreationErrored:
      applicationFormSelectors.selectSandboxAppCreationErrored(state),
    scopes: authSelectors.selectScopes(state),
  };
}

const mapDispatchToProps = dispatch => ({
  bootstrapApp: () => dispatch(bootstrapApp()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);

import { actions } from './reconciliationActions';

const initialState = {
  banks: [],
  deposits: {},
  unreceivedPayments: [],
  reconciledPayments: [],
  unreconciledPayments: [],
  paymentsCount: {
    reconciled: 0,
    unreceived: 0,
    unreconciled: 0,
    matches: {
      unique: 0,
      partial: 0,
      no_match: 0,
    },
  },
  totals: {
    reconciled: 0,
    unreceived: 0,
    unreconciled: 0,
    matches: {
      unique: 0,
      partial: 0,
      no_match: 0,
    },
  },
  loadingBanks: false,
  updatingPaymentStatus: false,
  loadingUnreceivedPayments: false,
  loadingReconciledPayments: false,
  loadingUnreconciledPayments: false,
  paymentStatusUpdateHasError: false,
  reconciliationConfiguration: {
    reconcileAutomatically: false,
  },
  fetchingReconciliationConfiguration: false,
  updatingReconciliationConfiguration: false,
};

export default function reducer(state = initialState, { type, payload }) {
  if (type === actions.GET_UNRECONCILED_PAYMENTS_STARTED) {
    return {
      ...state,
      loadingUnreconciledPayments: true,
    };
  }

  if (type === actions.GET_UNRECONCILED_PAYMENTS_SUCCESS) {
    const { totals, deposits, payments, paymentsCount } = payload;

    return {
      ...state,
      totals,
      deposits,
      paymentsCount,
      unreconciledPayments: payments,
      loadingUnreconciledPayments: false,
    };
  }

  if (type === actions.GET_UNRECONCILED_PAYMENTS_ERROR) {
    return {
      ...state,
      loadingUnreconciledPayments: false,
    };
  }

  if (type === actions.GET_UNRECEIVED_PAYMENTS_STARTED) {
    return {
      ...state,
      loadingUnreceivedPayments: true,
    };
  }

  if (type === actions.GET_UNRECEIVED_PAYMENTS_SUCCESS) {
    const { totals, deposits, payments, paymentsCount } = payload;

    return {
      ...state,
      totals,
      deposits,
      paymentsCount,
      unreceivedPayments: payments,
      loadingUnreceivedPayments: false,
    };
  }

  if (type === actions.GET_UNRECEIVED_PAYMENTS_ERROR) {
    return {
      ...state,
      loadingUnreceivedPayments: false,
    };
  }

  if (type === actions.GET_RECONCILED_PAYMENTS_STARTED) {
    return {
      ...state,
      loadingReconciledPayments: true,
    };
  }

  if (type === actions.GET_RECONCILED_PAYMENTS_SUCCESS) {
    const { totals, deposits, payments, paymentsCount } = payload;

    return {
      ...state,
      totals,
      deposits,
      paymentsCount,
      reconciledPayments: payments,
      loadingReconciledPayments: false,
    };
  }

  if (type === actions.GET_RECONCILED_PAYMENTS_ERROR) {
    return {
      ...state,
      loadingReconciledPayments: false,
    };
  }

  if (type === actions.GET_BANKS_LIST_STARTED) {
    return {
      ...state,
      loadingBanks: true,
    };
  }

  if (type === actions.GET_BANKS_LIST_SUCCESS) {
    return {
      ...state,
      loadingBanks: false,
      banks: payload,
    };
  }

  if (type === actions.GET_BANKS_LIST_ERROR) {
    return {
      ...state,
      loadingBanks: false,
    };
  }

  if (type === actions.UPDATE_PAYMENT_STATUS_STARTED) {
    return {
      ...state,
      updatingPaymentStatus: true,
      paymentStatusUpdateHasError: false,
    };
  }

  if (type === actions.UPDATE_PAYMENT_STATUS_SUCCESS) {
    return {
      ...state,
      updatingPaymentStatus: false,
    };
  }

  if (type === actions.UPDATE_PAYMENT_STATUS_ERROR) {
    return {
      ...state,
      updatingPaymentStatus: false,
      paymentStatusUpdateHasError: true,
    };
  }

  if (type === actions.GET_RECONCILIATION_CONFIGURATION_STARTED) {
    return {
      ...state,
      fetchingReconciliationConfiguration: true,
    };
  }

  if (type === actions.GET_RECONCILIATION_CONFIGURATION_SUCCESS) {
    return {
      ...state,
      fetchingReconciliationConfiguration: false,
      reconciliationConfiguration: {
        ...state.reconciliationConfiguration,
        reconcileAutomatically: payload.reconcile_automatically,
      },
    };
  }

  if (type === actions.RECONCILIATION_CONFIGURATION_NOT_SET) {
    return {
      ...state,
      fetchingReconciliationConfiguration: false,
    };
  }

  if (type === actions.GET_RECONCILIATION_CONFIGURATION_ERROR) {
    return {
      ...state,
      fetchingReconciliationConfiguration: false,
    };
  }

  if (type === actions.UPDATE_RECONCILIATION_CONFIGURATION_STARTED) {
    return {
      ...state,
      updatingReconciliationConfiguration: true,
    };
  }

  if (type === actions.UPDATE_RECONCILIATION_CONFIGURATION_SUCCESS) {
    return {
      ...state,
      updatingReconciliationConfiguration: false,
      reconciliationConfiguration: {
        ...state.reconciliationConfiguration,
        reconcileAutomatically: payload.reconcile_automatically,
      },
    };
  }

  if (type === actions.UPDATE_RECONCILIATION_CONFIGURATION_ERROR) {
    return {
      ...state,
      updatingReconciliationConfiguration: false,
    };
  }

  return state;
}

import { actionTypes } from './actionTypes';

const initialData = {
  production: [],
  sandbox: [],
  preLive: [],
  loading: false,
  loadingProduction: false,
  loadingSandbox: false,
};

const applicationListReducers = (state = initialData, { type, payload }) => {
  if (type === actionTypes.FETCH_PRODUCTION_STARTED) {
    return {
      ...state,
      loadingProduction: true,
    };
  }

  if (type === actionTypes.FETCH_SANDBOX_STARTED) {
    return {
      ...state,
      loadingSandbox: true,
    };
  }

  if (type === actionTypes.FETCH_SANDBOX_AND_PRODUCTION_STARTED) {
    return {
      ...state,
      loading: true,
    };
  }

  if (type === actionTypes.FETCH_PRODUCTION_SUCCESS) {
    return {
      ...state,
      loadingProduction: false,
      production: payload.production,
      preLive: payload.preLive,
    };
  }

  if (type === actionTypes.FETCH_SANDBOX_SUCCESS) {
    return {
      ...state,
      loadingSandbox: false,
      sandbox: payload,
    };
  }

  if (type === actionTypes.FETCH_SANDBOX_AND_PRODUCTION_SUCCESS) {
    return {
      ...state,
      sandbox: payload.sandbox,
      production: payload.production,
      preLive: payload.preLive,
      loading: false,
    };
  }

  if (type === actionTypes.FETCH_PRODUCTION_ERROR) {
    return {
      ...state,
      loadingProduction: false,
      production: [],
      preLive: [],
    };
  }

  if (type === actionTypes.FETCH_SANDBOX_ERROR) {
    return {
      ...state,
      loadingSandbox: false,
      sandbox: [],
    };
  }

  if (type === actionTypes.FETCH_SANDBOX_AND_PRODUCTION_ERROR) {
    return {
      ...state,
      loading: false,
    };
  }

  return state;
};

export default applicationListReducers;

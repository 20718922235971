import { showToast } from 'components/Toaster';

export default class Message {
  static success(title, description) {
    showToast({ type: 'success', title, description });
  }

  static error(title, description) {
    showToast({ type: 'error', title, description });
  }

  static warning(title, description) {
    showToast({ type: 'warning', title, description });
  }

  static neutral(title, description) {
    showToast({ type: 'neutral', title, description });
  }

  static informative(title, description) {
    showToast({ type: 'informative', title, description });
  }
}

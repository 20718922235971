import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components/macro';
import { styledFormPropsPropTypes } from 'utils/proptypes';
import { $Helptext, $Label } from 'styles';
import { $Fieldset } from 'styles/components/form/FormGroup';
import Checkbox from './Checkbox';

const $CheckboxHelptext = styled($Helptext)`
  padding-left: 32px;
`;

const CheckboxGroup = ({
  helptext,
  isErrored,
  groupLabel,
  groupName,
  onBlur,
  onChange,
  styledProps,
  options,
  valuesArray,
  'data-cy': dataCy,
  entireGroupValidation,
  chkBoxStyledProps,
}) => {
  const getOptions = () =>
    options.map(({ value, label }) => (
      <Checkbox
        isChecked={valuesArray.includes(value)}
        key={value}
        isErrored={!entireGroupValidation && isErrored}
        label={label}
        name={groupName}
        onBlur={onBlur}
        onChange={onChange}
        value={value}
        data-cy={`${dataCy}_${value}`}
        styledProps={chkBoxStyledProps}
      />
    ));

  const getGroupId = groupLabel
    ? groupLabel.replace(/[^A-Z0-9]/gi, '-')
    : false;

  // If it's just a single option it's unlikely we'll need the inline style
  const getVariantFromLength =
    options.length === 1 ? { variant: 'stacked' } : {};

  return (
    <>
      {getGroupId ? (
        <$Label as="legend" id={getGroupId} fontSize="md">
          {groupLabel}
        </$Label>
      ) : null}
      <$Fieldset
        aria-labelledby={groupLabel && getGroupId}
        role="group"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...styledProps}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...getVariantFromLength}
      >
        {getOptions()}
      </$Fieldset>
      <$CheckboxHelptext
        id={`${groupName}Description`}
        isErrored={isErrored}
        data-cy={`${groupName}_checkbox_description`}
      >
        {helptext}
      </$CheckboxHelptext>
    </>
  );
};

CheckboxGroup.propTypes = {
  groupLabel: PropTypes.string,
  groupName: PropTypes.string.isRequired,
  helptext: PropTypes.string,
  isErrored: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ).isRequired,
  styledProps: styledFormPropsPropTypes,
  valuesArray: PropTypes.arrayOf(PropTypes.string),
  'data-cy': PropTypes.string,
  entireGroupValidation: PropTypes.bool,
  chkBoxStyledProps: styledFormPropsPropTypes,
};

CheckboxGroup.defaultProps = {
  groupLabel: '',
  helptext: '',
  isErrored: false,
  onBlur: () => {},
  onChange: () => {},
  styledProps: {
    variant: 'inline',
  },
  valuesArray: [],
  'data-cy': '',
  entireGroupValidation: false,
  chkBoxStyledProps: {},
};

export default CheckboxGroup;

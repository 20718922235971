import { apiFetch } from 'modules/shared/fetch';
import { createCorrelationId } from 'utils/createUniqueId';

const correlationId = createCorrelationId();

export default class DepositsService {
  static async getBanksList({ appToken, appEnvironment }) {
    const response = apiFetch(`/v1/reconciliation/deposits/banks`, {
      appEnvironment,
      headers: {
        'lean-app-token': appToken,
        'lean-correlation-id': correlationId,
      },
    });
    const { data } = await response;
    return data;
  }

  static async getUploadedDepositsSourceList({ appToken, appEnvironment }) {
    const response = apiFetch(`/v1/reconciliation/deposits/banks/accounts`, {
      appEnvironment,
      headers: {
        'lean-app-token': appToken,
        'lean-correlation-id': correlationId,
      },
    });
    const { data } = await response;
    return data;
  }

  static async uploadDepositsSource({ appToken, appEnvironment, payload }) {
    const response = apiFetch(`/v1/reconciliation/deposits/files/upload`, {
      appEnvironment,
      headers: {
        'lean-app-token': appToken,
        'lean-correlation-id': correlationId,
      },
      method: 'POST',
      body: payload,
      stringifyBody: false,
      removeContentType: true,
    });
    const { data } = await response;
    return data;
  }

  static async saveDepositsBankDetails({ appToken, appEnvironment, payload }) {
    const response = apiFetch(`/v1/reconciliation/deposits/sources`, {
      appEnvironment,
      headers: {
        'lean-app-token': appToken,
        'lean-correlation-id': correlationId,
      },
      method: 'PUT',
      body: payload,
    });
    const { data } = await response;
    return data;
  }

  static async refreshBankDepositSource({ appToken, appEnvironment, payload }) {
    const response = apiFetch(`/v1/reconciliation/deposits/sources/refresh`, {
      appEnvironment,
      headers: {
        'lean-app-token': appToken,
        'lean-correlation-id': correlationId,
      },
      method: 'POST',
      body: payload,
    });
    const { data } = await response;
    return data;
  }

  static async getEntityBankAccounts({
    appToken,
    appEnvironment,
    bankIdentifier,
  }) {
    const response = apiFetch(
      `/v1/reconciliation/deposits/sources/banks/${bankIdentifier}`,
      {
        appEnvironment,
        headers: {
          'lean-app-token': appToken,
          'lean-correlation-id': correlationId,
        },
      },
    );
    const { data } = await response;
    return data;
  }

  static async getDepositsRequestStatus({
    appToken,
    appEnvironment,
    requestId,
  }) {
    const response = apiFetch(
      `/v1/reconciliation/deposits/requests/${requestId}`,
      {
        appEnvironment,
        headers: {
          'lean-app-token': appToken,
          'lean-correlation-id': correlationId,
        },
      },
    );
    const { data } = await response;
    return data;
  }

  static async getCustomerId({ appToken, appEnvironment }) {
    const response = apiFetch(`/v1/reconciliation/customers`, {
      appEnvironment,
      headers: {
        'lean-app-token': appToken,
        'lean-correlation-id': correlationId,
      },
    });
    const { data } = await response;
    return data;
  }
}

import styled, { css } from 'styled-components';
import Icon from '../Icon';

export const $Pagination = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
`;

const hiddenStyles = css`
  cursor: default;
  color: ${({ theme }) => theme.colors.neutral._50};

  :hover {
    opacity: 1;
  }
`;

export const $ArrowButton = styled(Icon)`
  ${({ isDisabled }) => isDisabled && hiddenStyles}

  padding: 8px 16px 8px 12px; // increasing clickable area
  margin: -8px -16px -8px -12px;
`;

export const $NumberOfItems = styled.span`
  font-size: ${({ theme }) => theme.font.size.body.sm};
  margin-right: 12px;
  text-transform: lowercase;
`;

export const $ArrowButtonsContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.neutral._20};
  padding: 8px 16px;
  border-radius: 4px;
  width: 96px;
  height: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const $Divider = styled.div`
  height: 16px;
  width: 1px;
  background-color: ${({ theme }) => theme.colors.neutral._30};
`;

export const $NumberOfPagesContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 24px;
  color: ${({ theme }) => theme.colors.neutral._80};
`;

export const $CurrentPageInput = styled.input`
  border: 1px solid ${({ theme }) => theme.colors.neutral._40};
  border-radius: 4px;
  height: 32px;
  width: 72px;
  margin: 0 8px;
  color: ${({ theme }) => theme.colors.black};
  padding: 0 22px 0 8px;
`;

export const $IconRight = styled.div`
  position: absolute;
  right: 12px;
  top: 0;
  padding: 9px 4px;
  line-height: normal;
  color: ${({ theme }) => theme.colors.neutral._60};
`;

export const $PaginationControl = styled.div`
  display: flex;
`;

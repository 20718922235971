/* eslint-disable camelcase */
export interface CustomerData {
  id: string;
  app_user_id: string;
  accounts_count: number;
  state: string;
  timestamp: string;
  user_portal_link: string;
}

export interface CustomersState {
  total: number;
  error: string;
  loading: boolean;
  data: CustomerData[];
  fetchingCustomer: boolean;
  reFetchingCustomer: boolean;
  creatingCustomer: boolean;
  isGeneratingReport: boolean;
  customer: CustomerDetailsData;
  isLoadingReport: boolean;
  report: ReportDetailsData | null;
  isLoadingTransactions: boolean;
  isLoadingSalaryTransactions: boolean;
  transactions: ReportsTransactionsPayload['transactions'] | null;
  isLoadingCorporateTransactions: ReportsCorporateIsLoadingTransactions;
  corporateTransactions: ReportsCorporateTransactionsPayload | null;
  salaryTransactions: ReportTransactionData[] | null;
  isLoadingCashflow: boolean;
  cashflow: ReportCashflowResponse | null;
  isLoadingLoans: boolean;
  loans: ReportLoansResponse | null;
  isLoadingCreditCards: boolean;
  creditCards: ReportCreditCardsResponse | null;
  isLoadingStats: boolean;
  stats: ReportStatsPayload | null;
  isLoadingExpensesBreakdown: boolean;
  expensesBreakdown: ReportExpenseCategory[] | null;
  isLoadingEvents: boolean;
  events: ReportEvent[] | null;
  isRefreshingCustomerEntity: boolean;
  customerEntityRefreshStatus: RefreshCustomerResponse | null;
}

export interface ReportsError {}

export interface ReportsPayload {
  customers: {
    total_pages: number;
    total_elements: number;
    content: CustomerData[];
  };
}

export interface CustomerBanksAccountData {
  type: string | null;
  number: string | null;
}

export enum BankStatus {
  CONNECTED = 'CONNECTED',
  FETCHED = 'FETCHED',
  FAILED = 'FAILED',
  PARTIALLY_FETCHED = 'PARTIALLY_FETCHED',
}

export enum TransactionsCategoryType {
  CREDIT_CARD = 'CREDIT_CARD',
  LOANS_RECEIVED = 'LOANS_RECEIVED',
  LOANS_REPAYMENTS = 'LOANS_REPAYMENTS',
  INWARD_TRANSACTIONS = 'INWARD_TRANSACTIONS',
  OUTWARD_TRANSACTIONS = 'OUTWARD_TRANSACTIONS',
}

export enum DataFetchStatus {
  FETCHED = 'FETCHED',
  FAILED = 'FAILED',
  PARTIALLY_FETCHED = 'PARTIALLY_FETCHED',
}

export enum CustomerStatus {
  SUBMITTED = 'SUBMITTED',
  CONNECTED = 'CONNECTED',
  FETCHED = 'FETCHED',
  PARTIALLY_FETCHED = 'PARTIALLY_FETCHED',
  FAILED = 'FAILED',
}

export enum AppEvent {
  CUSTOMER_CREATION = 'CUSTOMER_CREATION',
  ENTITY_CONNECTION = 'ENTITY_CONNECTION',
  DATA_FETCHING = 'DATA_FETCHING',
  REPORT_GENERATION = 'REPORT_GENERATION',
  REPORT_DOWNLOAD = 'REPORT_DOWNLOAD',
  FAILED = 'FAILED',
}

export interface CustomerBanksData {
  id: string;
  entity_id: string;
  status: BankStatus;
  bank_name: string;
  shared_accounts_count: number;
  last_connect_timestamp: string;
  is_active_consent: boolean;
  accounts: CustomerBanksAccountData[];
}

export interface CustomerReportsData {
  id: string;
  report_id: string;
  report_name: string;
  timestamp: string;
}

export interface CustomerDetailsData {
  id: string;
  app_user_id: string;
  account_holder_abbreviated_name: string | null;
  account_holder_name: string | null;
  customer_account_type: string | null;
  state: string;
  created_at: string;
  banks: CustomerBanksData[];
  reports: CustomerReportsData[] | null;
  user_portal_link: string;
}

export interface ReportDetailsData {
  generated_by: string | null;
  id: string;
  name: string;
  timestamp: string;
  data_from: string;
  data_to: string;
  fetching_status: { [key: string]: string };
}

export interface GenerateReportError {
  message: string;
}

export interface ReportTransactionData {
  id: string;
  category: string;
  corporate_insight_type: TransactionsCategoryType;
  lender: string | null;
  card_holder: string | null;
  card_ending: string | null;
  description: string;
  amount: number;
  currency: string;
  timestamp: string;
}

export interface ReportsTransactionsPayload {
  transactions: {
    total_pages: number;
    total_elements: number;
    content: ReportTransactionData[];
  };
}

export interface ReportsCorporateTransactionsPayload {
  credit_card: ReportsTransactionsPayload['transactions'] | null;
  loans_received: ReportsTransactionsPayload['transactions'] | null;
  loans_repayments: ReportsTransactionsPayload['transactions'] | null;
  inward_transactions: ReportsTransactionsPayload['transactions'] | null;
  outward_transactions: ReportsTransactionsPayload['transactions'] | null;
}

export interface ReportsCorporateIsLoadingTransactions {
  credit_card: boolean;
  loans_received: boolean;
  loans_repayments: boolean;
  inward_transactions: boolean;
  outward_transactions: boolean;
}

export interface SalaryTransactionsPayload {
  transactions: ReportTransactionData[];
}

export interface ReportStatsMonthDetails {
  month: number;
  year: number;
  income: {
    salary_amount: number;
    currency: string;
  };
  expenses: {
    amount: number;
    currency: string;
  };
}

export interface ReportStatsPayload {
  monthly_average_income: {
    amount: number;
    currency: string;
  };
  monthly_average_expenses: {
    amount: number;
    currency: string;
  };
  months: ReportStatsMonthDetails[];
}
export interface ReportCashflowResponse {
  currency: string;
  summary: Summary;
  inward_transactions: InwardTransactions;
  outward_transactions: OutwardTransactions;
}

export interface ReportLoansResponse {
  currency: string;
  count: number;
  total_loans_received: number;
  total_loans_repayments: number;
  loans_received: ReceivedLoanDetail[];
}

export interface ReportCreditCardsResponse {
  currency: string;
  count: number;
  total_cards_spent: number;
  total_cards_repayments: number;
  average_balance: number;
  historic_balances: HistoricBalancesBreakdown[];
}

export type SummaryKey = keyof Summary;

interface Summary {
  overall_cashflow: TransactionDetail;
  net_cashflow: TransactionDetail;
  monthly_cashflow_breakdown: MonthlyCashflowBreakdown[];
}

interface MonthlyCashflowBreakdown {
  month: number;
  year: number;
  overall_cashflow: number;
  net_cashflow: number;
}

export type BreakdownKey = keyof MonthlyCashflowBreakdown;

export type InwardTransactionsKey = keyof InwardTransactions;

interface InwardTransactions {
  credited_transactions: TransactionDetail;
  inflow_transactions: TransactionDetail;
  revenue: TransactionDetail;
  monthly_inward_transactions_breakdown: MonthlyInwardTransactionsBreakdown[];
}

export interface TransactionDetail {
  count: number;
  currency: string;
  amount: number;
}

export interface ReceivedLoanDetail {
  lender: string;
  currency: string;
  amount: number;
}

export interface HistoricBalancesBreakdown {
  month: number;
  year: number;
  balances: BalanceDetail[];
}

export interface BalanceDetail {
  card: number;
  amount: number;
}

interface MonthlyInwardTransactionsBreakdown {
  month: number;
  year: number;
  credited_transactions: number;
  inflow_transactions: number;
}

export type OutwardTransactionsKey = keyof OutwardTransactions;

interface OutwardTransactions {
  debited_transactions: TransactionDetail;
  outflow_transactions: TransactionDetail;
  operating_expenses: TransactionDetail;
  financing_expenses: TransactionDetail;
  profit_distributions: TransactionDetail;
  monthly_outward_transactions_breakdown: MonthlyOutwardTransactionsBreakdown[];
}

export const cashflowDetailKeys = [
  'overall_cashflow',
  'net_cashflow',
  'debited_transactions',
  'outflow_transactions',
  'revenue',
  'operating_expenses',
  'financing_expenses',
  'profit_distributions',
  'credited_transactions',
  'inflow_transactions',
];

interface MonthlyOutwardTransactionsBreakdown {
  month: number;
  year: number;
  debited_transactions: number;
  outflow_transactions: number;
}

export interface ReportExpenseCategory {
  id: string;
  colour: string;
  category: string;
  category_name: string;
  amount: number;
  currency: string;
  percentage: number;
  payments: number;
}

export interface ReportEvent {
  status: string;
  event_type: string;
  event_by: string;
  timestamp: string;
  additional_info: { [key: string]: string };
}

export interface RefreshCustomerResponse {
  status: string;
  message: string;
}

export const TransactionsCategoryMap: Record<
  TransactionsCategoryType,
  keyof ReportsCorporateIsLoadingTransactions
> = {
  [TransactionsCategoryType.CREDIT_CARD]: 'credit_card',
  [TransactionsCategoryType.LOANS_RECEIVED]: 'loans_received',
  [TransactionsCategoryType.LOANS_REPAYMENTS]: 'loans_repayments',
  [TransactionsCategoryType.INWARD_TRANSACTIONS]: 'inward_transactions',
  [TransactionsCategoryType.OUTWARD_TRANSACTIONS]: 'outward_transactions',
};

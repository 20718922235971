import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import TableHeaders from './TableHeaders';
import { $Table, $TableContainer } from './styles';
import {
  SORT_ASCENDING,
  SORT_DESCENDING,
  sortTableByColumnName,
} from './helpers';
import TableRows from './TableRows';

const TableMarkup = ({
  data,
  titles,
  pageSize,
  customCell,
  onRowClick,
  isSuperHeader,
  sortableColumns,
  isPaginatedTable,
  customHeaderCell,
  onAllRowsSelected,
  checkboxSelection,
  superHeaderValues,
  defaultSelectedRows,
  headerCellStyleProps,
  isMultiRowSelectable,
  isSingleRowSelectable,
  selectedRowIdentifierPath,
}) => {
  // ensure all required props for data table are provided
  if (isSingleRowSelectable && isMultiRowSelectable) {
    throw new Error(
      'isSingleRowSelectable and isMultiRowSelectable cannot be used together',
    );
  }

  const isRowSelectable = isSingleRowSelectable || isMultiRowSelectable;

  if (
    isRowSelectable &&
    (onRowClick === undefined || !selectedRowIdentifierPath)
  ) {
    throw new Error(
      'onRowClick, and selectedRowIdentifierPath props are required for isSingleRowSelectable',
    );
  }

  if (
    checkboxSelection &&
    (!isRowSelectable || onAllRowsSelected === undefined)
  ) {
    throw new Error(
      'isSingleRowSelectable, and onAllRowsSelected props are required for checkboxSelection',
    );
  }

  const [tableData, setTableData] = useState([]);
  const [sortPattern, setSortPattern] = useState({});
  const [selectedRows, setSelectedRows] = useState(defaultSelectedRows);

  useEffect(() => setTableData(data), [data]);

  useEffect(() => setSelectedRows(defaultSelectedRows), [defaultSelectedRows]);

  useEffect(() => {
    // initialize table sort patterns
    if (sortableColumns.length) {
      const pattern = sortableColumns.reduce((acc, column) => {
        acc[column.toLowerCase()] = SORT_ASCENDING;

        return acc;
      }, {});

      setSortPattern(pattern);
    }
  }, [sortableColumns]);

  const handleAllRowsSelected = () => {
    if (!onAllRowsSelected) return undefined;

    // reset selection if all rows are already selected
    if (tableData.length === selectedRows.length) {
      setSelectedRows([]);
      return onAllRowsSelected([]);
    }

    setSelectedRows(tableData);
    return onAllRowsSelected(tableData);
  };

  // check if current row is selected
  const isRowSelected = currentRowId =>
    selectedRows.some(row => {
      const selectedRowId = _get(row, selectedRowIdentifierPath, '');
      return selectedRowId === currentRowId;
    });

  const markRowAsSelected = ({ row, multiSelect }) => {
    const currentRowId = _get(row, selectedRowIdentifierPath, '');
    const isAlreadySelected = isRowSelected(currentRowId);

    if (multiSelect) {
      if (isAlreadySelected) {
        const result = selectedRows.filter(selectedRow => {
          const selectedRowId = _get(
            selectedRow,
            selectedRowIdentifierPath,
            '',
          );
          return selectedRowId !== currentRowId;
        });

        setSelectedRows(result);
        // forward data to caller
        return onRowClick(row, result);
      }

      const result = [...selectedRows, row];

      setSelectedRows(result);
      // forward data to caller
      return onRowClick(row, result);
    }

    // single select
    setSelectedRows([row]);
    // forward data to caller
    return onRowClick(row, [row]);
  };

  const handleRowClick = row => {
    if (!onRowClick) return undefined;

    // run selection code only if isSingleRowSelectable
    if (isSingleRowSelectable) {
      return markRowAsSelected({ row, multiSelect: false });
    }

    // run selection code only if isSingleRowSelectable
    if (isMultiRowSelectable) {
      return markRowAsSelected({ row, multiSelect: true });
    }

    // forward data to caller
    return onRowClick(row);
  };

  const handleTableSort = column => {
    const columnToSort = column.toLowerCase();

    // sort data in current direction
    const sorted = sortTableByColumnName({
      data: tableData,
      column,
      direction: sortPattern[columnToSort],
    });

    // update sort direction for next sort
    setSortPattern({
      ...sortPattern,
      [columnToSort]:
        sortPattern[columnToSort] === SORT_ASCENDING
          ? SORT_DESCENDING
          : SORT_ASCENDING,
    });

    // update table data state
    setTableData(sorted);
  };

  return (
    <$TableContainer
      isSuperHeader={isSuperHeader}
      isPaginatedTable={isPaginatedTable}
      pageSize={pageSize}
      data-cy="table_component"
    >
      <$Table>
        <thead>
          <TableHeaders
            titles={titles}
            onSort={handleTableSort}
            sortPattern={sortPattern}
            isSuperHeader={isSuperHeader}
            tableDataCount={tableData.length}
            sortableColumns={sortableColumns}
            customHeaderCell={customHeaderCell}
            checkboxSelection={checkboxSelection}
            superHeaderValues={superHeaderValues}
            selectedRowsCount={selectedRows.length}
            headerCellStyleProps={headerCellStyleProps}
            handleAllRowsSelected={handleAllRowsSelected}
          />
        </thead>

        <TableRows
          titles={titles}
          data={tableData}
          customCell={customCell}
          isRowSelected={isRowSelected}
          handleRowClick={handleRowClick}
          checkboxSelection={checkboxSelection}
          selectedRowIdentifierPath={selectedRowIdentifierPath}
        />
      </$Table>
    </$TableContainer>
  );
};

TableMarkup.propTypes = {
  pageSize: PropTypes.number,
  onRowClick: PropTypes.func,
  isSuperHeader: PropTypes.bool,
  data: PropTypes.array.isRequired,
  sortableColumns: PropTypes.array,
  isPaginatedTable: PropTypes.bool,
  checkboxSelection: PropTypes.bool,
  customCell: PropTypes.elementType,
  onAllRowsSelected: PropTypes.func,
  headerCellStyleProps: PropTypes.any,
  defaultSelectedRows: PropTypes.array,
  isMultiRowSelectable: PropTypes.bool,
  isSingleRowSelectable: PropTypes.bool,
  customHeaderCell: PropTypes.elementType,
  selectedRowIdentifierPath: PropTypes.string,
  titles: PropTypes.arrayOf(PropTypes.string).isRequired,
  superHeaderValues: PropTypes.arrayOf(PropTypes.shape({})),
};

TableMarkup.defaultProps = {
  sortableColumns: [],
  pageSize: undefined,
  isSuperHeader: false,
  customCell: undefined,
  onRowClick: undefined,
  superHeaderValues: [],
  defaultSelectedRows: [],
  isPaginatedTable: false,
  checkboxSelection: false,
  headerCellStyleProps: [],
  isMultiRowSelectable: false,
  customHeaderCell: undefined,
  onAllRowsSelected: undefined,
  isSingleRowSelectable: false,
  selectedRowIdentifierPath: '',
};

export default TableMarkup;

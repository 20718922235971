import React from 'react';
import { i18n } from 'i18n';
import {
  leanCopyright,
  privacyURL,
  responsibleURL,
  touURL,
} from 'modules/shared/legal/legal';
import { $Footer } from '../styles';

const Footer = () => {
  const footerLinks = [
    {
      href: 'https://help.leantech.me',
      label: 'Submit an issue',
      dataCy: 'submit_an_issue',
    },
    {
      href: privacyURL,
      label: i18n(`links.privacy`),
      dataCy: 'privacy_link',
    },
    {
      href: responsibleURL,
      label: i18n(`links.responsible`),
      dataCy: 'responsible_disclosure_link',
    },
    {
      href: touURL,
      label: i18n(`links.termsOfUse`),
      dataCy: 'terms_of_use_link',
    },
    {
      className: 'copyright',
      href: 'https://leantech.me',
      label: leanCopyright,
      dataCy: 'copyright_link',
    },
  ];
  return (
    <$Footer data-cy="footer_links">
      {footerLinks.map(({ href, label, className, dataCy }) => (
        <a
          className={className}
          href={href}
          key={href}
          rel="noopener noreferrer"
          target="_blank"
          data-cy={dataCy}
        >
          {label}
        </a>
      ))}
    </$Footer>
  );
};

export default Footer;

/* eslint-disable react/jsx-props-no-spreading */
import PermissionChecker from 'modules/auth/permissionChecker';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';

function DisabledRoute({ component: Component, currentUser, ...rest }) {
  return (
    <Route
      {...rest}
      render={props => {
        const permissionChecker = new PermissionChecker(currentUser);

        if (!permissionChecker.isDisabled) {
          return (
            <Redirect
              to={{
                pathname: '/',
              }}
            />
          );
        }
        return <Component {...props} />;
      }}
    />
  );
}

DisabledRoute.propTypes = {
  component: PropTypes.node.isRequired,
  currentUser: PropTypes.shape({}),
};

DisabledRoute.defaultProps = {
  currentUser: {},
};

export default DisabledRoute;

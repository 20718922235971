import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Dialog, DialogContent, Button } from 'components';
import {
  getFormTextField,
  getFormSelectField,
} from 'utils/buildFormFieldFromModel';
import EnumeratorField from 'modules/shared/fields/enumeratorField';
import { countryOptions } from 'modules/auth/userModelEnumeratorOptions';
import { actions as organisationActions } from 'modules/organisation/organisationActions';
import {
  $Name,
  $Email,
  $UserInfoRow,
  $Header,
  $DialogBody,
  $Footer,
  $OverflowContainer,
} from './styles';

const verifyOwnerSchema = Yup.object().shape({
  nationality: Yup.string().required('The nationality is required'),
  phone_number: Yup.string()
    .required('The phone number is required')
    .matches(/^((\+?966)|0(5|11))\d{9}$/, 'Not a valid saudi number'),
  identification_type: Yup.string().required(
    'The type of identity document is required',
  ),
  identification: Yup.string().required(
    'The identification number is required',
  ),
});

const VerifyOwnerDialog = ({ isOpen, currentUser, toggleVerifyDialog }) => {
  const dispatch = useDispatch();

  const [isProcessing, setIsProcessing] = useState(false);

  const [nationalities, setNationalities] = useState({});
  const [documentTypes, setDocumentTypes] = useState({});

  useEffect(() => {
    setNationalities(
      new EnumeratorField(
        'nationality',
        'Select your nationality',
        [{ label: 'Select...', value: '' }, ...countryOptions],
        {
          required: true,
        },
      ),
    );
    setDocumentTypes(
      new EnumeratorField(
        'identification_type',
        'Select a type of identity document',
        [
          { label: 'Select...', value: '' },
          { label: 'Citizen', value: 'CITIZEN' },
          { label: 'Resident', value: 'RESIDENT' },
          { label: 'Border', value: 'BORDER' },
          { label: 'Visa', value: 'VISA' },
          { label: 'Passport', value: 'PASSPORT' },
        ],
        {
          required: true,
        },
      ),
    );
  }, []);

  const handleSubmit = async values => {
    setIsProcessing(true);
    await dispatch(organisationActions.doActivateOrganisation(values));
    setIsProcessing(false);
    await dispatch(organisationActions.doGetOrganisation());
    toggleVerifyDialog();
  };

  return (
    <Dialog open={isOpen} onOpenChange={toggleVerifyDialog}>
      <DialogContent
        width={720}
        isCloseButton
        title="Identity verification"
        data-cy="verify_owner_dialog"
      >
        <$UserInfoRow>
          <$Name>{`${currentUser.first_name} ${currentUser.last_name}`}</$Name>
          <$Email>{currentUser.email}</$Email>
        </$UserInfoRow>

        <$DialogBody>
          <$Header>
            Please fill in the following data to verify your identity.
          </$Header>

          <Formik
            initialValues={{
              nationality: '',
              phone_number: '',
              identification_type: '',
              identification: '',
            }}
            validationSchema={verifyOwnerSchema}
            onSubmit={handleSubmit}
          >
            {form => (
              <div data-cy="verify_owner_form">
                <$OverflowContainer>
                  {getFormSelectField(form, nationalities)}
                  {getFormTextField(form, {
                    label: 'Enter your phone number',
                    name: 'phone_number',
                    placeholder: '+966',
                  })}
                  {getFormSelectField(form, documentTypes)}
                  {getFormTextField(form, {
                    label: 'Enter your identification number',
                    name: 'identification',
                  })}
                </$OverflowContainer>

                <$Footer>
                  <Button
                    onClick={form.submitForm}
                    isDisabled={
                      form.isSubmitting || !form.isValid || isProcessing
                    }
                    isLoading={form.isSubmitting || isProcessing}
                    data-cy="verify_owner_details_btn"
                    // styledProps={{
                    //   size: 'medium',
                    //   align: 'end',
                    // }}
                  >
                    Verify details
                  </Button>
                </$Footer>
              </div>
            )}
          </Formik>
        </$DialogBody>
      </DialogContent>
    </Dialog>
  );
};

VerifyOwnerDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  currentUser: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string,
  }),
  toggleVerifyDialog: PropTypes.func.isRequired,
};

VerifyOwnerDialog.defaultProps = {
  currentUser: {},
};

export default VerifyOwnerDialog;

import { actions } from './organisationActions';

const initialState = {
  loadingOrganisation: false,
  organisation: null,
  loadingOwnerVerification: false,
};

export default function reducer(state = initialState, { type, payload }) {
  if (type === actions.GET_ORGANISATION_STARTED) {
    return {
      ...state,
      loadingOrganisation: true,
    };
  }

  if (type === actions.GET_ORGANISATION_SUCCESS) {
    return {
      ...state,
      loadingOrganisation: false,
      organisation: payload,
    };
  }

  if (type === actions.GET_ORGANISATION_ERROR) {
    return {
      ...state,
      loadingOrganisation: false,
    };
  }

  if (type === actions.ACTIVATE_ORGANISATION_STARTED) {
    return {
      ...state,
      loadingOwnerVerification: true,
    };
  }

  if (type === actions.ACTIVATE_ORGANISATION_SUCCESS) {
    return {
      ...state,
      loadingOwnerVerification: false,
    };
  }

  if (type === actions.ACTIVATE_ORGANISATION_ERROR) {
    return {
      ...state,
      loadingOwnerVerification: false,
    };
  }

  return state;
}

import { connect } from 'react-redux';
import React, { useEffect, useRef } from 'react';
import ProgressBar from 'view/shared/ProgressBar';
import authSelectors from 'modules/auth/authSelectors';
import CustomLoadable from 'view/shared/CustomLoadable';
import PublicRoute from 'view/shared/routes/PublicRoute';
import PrivateRoute from 'view/shared/routes/PrivateRoute';
import layoutSelectors from 'modules/layout/layoutSelectors';
import { Route, Switch, withRouter } from 'react-router-dom';
import DisabledRoute from 'view/shared/routes/DisabledRoute';
import EmailUnverifiedRoute from 'view/shared/routes/EmailUnverifiedRoute';
import EmptyPermissionsRoute from 'view/shared/routes/EmptyPermissionsRoute';
import {
  disabledRoutes,
  emailUnverifiedRoutes,
  emptyPermissionsRoutes,
  privateRoutes,
  publicRoutes,
  simpleRoutes,
} from 'view/routes';
import PropTypes from 'prop-types';

function RoutesComponent({ loading, currentUser }) {
  useEffect(() => {
    if (loading) {
      ProgressBar.start();
    }
  });

  const prevLoadingState = useRef(null);

  useEffect(() => {
    if (prevLoadingState.current === true && !loading) {
      ProgressBar.done();
    }

    prevLoadingState.current = loading;
  }, [loading]);

  if (loading) {
    return <div />;
  }

  return (
    <Switch>
      {publicRoutes.map(route => (
        <PublicRoute
          component={CustomLoadable({
            loader: route.loader,
          })}
          currentUser={currentUser}
          exact
          key={route.path}
          path={route.path}
          featureFlag={route.featureFlag}
        />
      ))}

      {emptyPermissionsRoutes.map(route => (
        <EmptyPermissionsRoute
          component={CustomLoadable({
            loader: route.loader,
          })}
          currentUser={currentUser}
          exact
          key={route.path}
          path={route.path}
        />
      ))}

      {emailUnverifiedRoutes.map(route => (
        <EmailUnverifiedRoute
          component={CustomLoadable({
            loader: route.loader,
          })}
          currentUser={currentUser}
          exact
          key={route.path}
          path={route.path}
        />
      ))}

      {disabledRoutes.map(route => (
        <DisabledRoute
          component={CustomLoadable({
            loader: route.loader,
          })}
          currentUser={currentUser}
          exact
          key={route.path}
          path={route.path}
        />
      ))}

      {privateRoutes.map(route => (
        <PrivateRoute
          component={CustomLoadable({
            loader: route.loader,
          })}
          currentUser={currentUser}
          exact={route.exact}
          key={route.path}
          path={route.path}
          permissionRequired={route.permissionRequired}
          featureFlag={route.featureFlag}
        />
      ))}

      {simpleRoutes.map(route => (
        <Route
          component={CustomLoadable({
            loader: route.loader,
          })}
          exact
          key={route.path}
          path={route.path}
        />
      ))}
    </Switch>
  );
}

RoutesComponent.propTypes = {
  loading: PropTypes.bool.isRequired,
  currentUser: PropTypes.object,
};

RoutesComponent.defaultProps = {
  currentUser: {},
};

const select = state => ({
  loading:
    authSelectors.selectLoadingInit(state) ||
    layoutSelectors.selectLoading(state),
  currentUser: authSelectors.selectCurrentUser(state),
});

export default withRouter(connect(select)(RoutesComponent));

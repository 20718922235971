import styled from 'styled-components/macro';
import {
  defaultStyles,
  errorStyles,
  focusStyles,
  hoverStyles,
} from 'styles/components/form/Input';

const $TextArea = styled.textarea`
  font-size: ${({ theme }) => theme.font.size.body.sm};
  height: 63px;
  margin-bottom: 5px;
  padding: 5px 8px;
  width: 100%;

  ${defaultStyles}

  /* Extra padding for inside label */
  ${({ isShowInsideLabel }) => isShowInsideLabel && 'padding-left: 76px'};
  text-align: ${({ textAlign }) => textAlign};

  &:hover {
    ${hoverStyles}
  }

  &:focus {
    ${focusStyles}
  }

  ${props => props.isErrored && errorStyles}
`;

export default $TextArea;

import $Label from 'styles/components/form/Label';
import styled, { css } from 'styled-components/macro';
import { math } from 'polished';

const getTopValueBasedOnFontSize = fontSize => {
  switch (fontSize) {
    case 'lg':
      return 1.5;
    case 'xl':
      return 3;
    default:
      return 0;
  }
};
export const defaultStyles = css`
  background-color: ${({ theme }) => theme.colors.neutral._10};
  border: 0.5px solid ${({ theme }) => theme.colors.neutral._40};
  border-radius: ${({ borderRadius }) => borderRadius || '2px'};

  &::placeholder {
    color: ${({ theme }) => theme.colors.neutral_40};
  }
`;

export const hoverStyles = css``;

export const focusStyles = css`
  box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.focus};
  outline: none;
`;

export const activeStyles = css`
  box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.active};
  outline: none;
`;

export const disabledStyles = css`
  background-color: ${({ theme }) => theme.colors.neutral._20};
  color: ${({ theme }) => theme.colors.neutral_40};
  outline: none;

  &:active {
    box-shadow: none;
  }
`;

export const errorStyles = css`
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid #ff5533;

  &:hover {
    border: 1px solid #ff5533;
  }
`;

const $Input = styled.input`
  font-size: ${({ theme }) => theme.font.size.body.sm};
  text-align: ${({ textAlign }) => textAlign};
  height: 32px;
  padding-right: 8px;
  padding-left: 8px;

  /* Extra padding for inside label */
  ${({ isShowInsideLabel }) => isShowInsideLabel && 'padding-left: 76px'};

  /* Extra padding for left icon */
  ${({ isShowRightIcon }) => isShowRightIcon && 'padding-right: 40px'};

  /* Extra padding for left icon */
  ${({ isShowLeftIcon }) => isShowLeftIcon && 'padding-left: 24px'};

  width: 100%;

  /* Most inputs are simple */
  &[type='text'],
  &[type='date'],
  &[type='number'],
  &[type='password'],
  &[type='search'] {
    ${defaultStyles}
    &:hover {
      ${hoverStyles}
    }

    &:focus {
      ${focusStyles}
    }

    &:active {
      ${activeStyles}
    }

    &:disabled {
      ${disabledStyles}
    }

    ${props => props.isErrored && errorStyles}
  }

  /* Custom ones need more work */
  &[type='checkbox']:not(:checked),
  &[type='checkbox']:checked {
    position: absolute;
    left: -9999px;
    clip: rect(0, 0, 0, 0);

    + ${$Label} {
      position: relative;
      padding-left: 32px;
      cursor: pointer;
      font-size: ${({ theme, fontSize }) => theme.font.size.label[fontSize]};
      display: flex;
      flex-direction: column;
      justify-content: center;

      /* :before is the box  */
      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: auto;
        width: 16px;
        height: 16px;
        ${defaultStyles}

        ${props => props.isErrored && errorStyles}
      }

      /* :after is the checkmark  */
      &:after {
        content: '';
        /* This is a bit yucky, update when we do disabled tick */
        background-image: url("data:image/svg+xml,%3Csvg width='12' height='10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 1L4 9 1 6' stroke='%232F69D9' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        position: absolute;
        top: auto;
        left: 2px;
        line-height: 1;
        width: 12px;
        height: 10px;
      }
    }

    /* Hover */
    &:hover + ${$Label}:before {
      ${hoverStyles}
    }

    /* Focus */
    &:focus + ${$Label}:before {
      ${focusStyles}
    }

    /* Hide and show the checkmark */
    &:not(:checked) + label:after {
      opacity: 0;
    }

    &:checked + label:after {
      opacity: 1;
    }

    &.disabled + label:before {
      ${disabledStyles}
    }
  }

  /* Custom ones need more work */
  &[type='radio']:not(:checked),
  &[type='radio']:checked {
    position: absolute;
    left: -9999px;
    clip: rect(0, 0, 0, 0);

    + ${$Label} {
      position: relative;
      padding-left: 32px;
      cursor: pointer;

      font-size: ${({ theme, fontSize }) => theme.font.size.label[fontSize]};

      /* :before is the box  */
      &:before {
        ${defaultStyles}
        content: '';
        position: absolute;
        left: 0;
        top: ${({ fontSize }) =>
          math(`${getTopValueBasedOnFontSize(fontSize)}px`)};
        width: 16px;
        height: 16px;
        border-radius: 8px;

        ${props => props.isErrored && errorStyles}
      }

      /* :after is the checkmark  */
      &:after {
        content: '';
        background-color: ${({ theme }) => theme.colors.trust._60};
        border-radius: 4px;
        position: absolute;
        top: ${({ fontSize }) =>
          math(`4px + ${getTopValueBasedOnFontSize(fontSize)}px`)};
        left: 4px;
        line-height: 1;
        width: 8px;
        height: 8px;
      }
    }

    /* Hover */
    &:hover + ${$Label}:before {
      ${hoverStyles}
    }

    /* Focus */
    &:focus + ${$Label}:before {
      ${focusStyles}
    }

    /* Hide and show the checkmark */
    &:not(:checked) + label:after {
      opacity: 0;
    }

    &:checked + label:after {
      opacity: 1;
    }
  }
`;

export const $IconRight = styled.div`
  position: absolute;
  right: 0;
  top: 4px;
  padding: 2px 10px 0 5px;
  line-height: normal;
  color: ${({ theme, disabled }) =>
    disabled ? theme.colors.neutral._40 : theme.colors.neutral._50};
`;

export const $IconLeft = styled.div`
  position: absolute;
  left: 0;
  top: 4px;
  padding: 2px 10px 0 5px;
  line-height: normal;
  color: ${({ theme, disabled }) =>
    disabled ? theme.colors.neutral._40 : theme.colors.neutral._80};
`;

export const $SearchKeyboardShortcut = styled.div`
  width: 13px;
  height: 22px;
  padding: 2px 4px;
  background-color: ${({ theme }) => theme.colors.neutral._20};
`;

export const $RightIconWrapper = styled.div`
  width: 26px;
  height: 22px;
  padding: 2px 4px;
  background-color: ${({ theme }) => theme.colors.neutral._20};
`;

export const $InsideLabel = styled.label`
  position: absolute;
  left: 8px;
  top: 5px;
  height: 22px;
  width: 60px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  line-height: normal;
  padding: 2px 6px;
  background-color: ${({ theme }) => theme.colors.neutral._20};
  border-radius: 2px;
  color: ${({ theme, disabled }) =>
    disabled ? theme.colors.neutral._40 : theme.colors.neutral._80};
`;

export default $Input;

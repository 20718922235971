import { css } from 'styled-components';
import styled from 'styled-components/macro';
import { DialogContent } from 'components';
import { getLanguageDirection } from 'i18n';

export const $HyperlinkLayoutContainer = styled.div`
  margin: 0 auto;
  padding-top: 80px;
  width: 720px;

  direction: ${() => getLanguageDirection()};

  @media (max-width: 767px) {
    margin: 0 auto;
    padding: ${({ theme }) => theme.layout.spacing.md} 0;
    width: 343px;
  }
`;

export const $HyperlinkLayoutCard = styled.div`
  border-radius: 16px;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.neutral._30};
`;

export const $HyperlinkLayoutHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 64px;
  padding: 16px;
  font-weight: 400;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral._30};
`;

export const $HyperlinkLayoutBody = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  padding: ${({ theme }) => theme.layout.spacing.md};
  margin: 0 auto;
  max-width: 400px;
  height: 536px;
  overflow-y: scroll;

  &.request-bank-content {
    justify-content: center;
    text-align: center;
  }

  button {
    width: 175px;
    height: 42px;
    justify-content: center;
  }

  .request-bank-button,
  .connect-another-bank-button {
    width: 295px;
    margin-left: 0;
    margin-right: 0;
  }

  .connect-another-bank-button {
    margin-bottom: 12px;
  }

  .get-started-button {
    margin-top: 60px;
  }

  .connect-again-btn {
    width: 214px;
  }

  .text-center {
    text-align: center;
  }

  &.content-center {
    justify-content: center;
  }

  @media (max-width: 767px) {
    max-width: 295px;

    button {
      width: 295px;
    }
  }
`;

export const $HyperlinkConnectBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 40px;
  margin: 0 auto;
  min-height: 536px;

  .connect,
  .permissions {
    flex-basis: 47%;
  }

  .permissions {
    @media (max-width: 767px) {
      margin-top: 48px;
    }
  }

  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

export const $HyperlinkConnectFooter = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  margin-top: 24px;

  .link-account {
    width: 177px;
    height: 40px;
    justify-content: center;
  }

  .t-and-c {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.neutral._90};
  }

  @media (max-width: 767px) {
    .link-account {
      width: 295px;
    }
  }
`;

export const $HyperlinkPageTitle = styled.h4`
  width: 100%;
  font-weight: 700;
  margin-bottom: 16px;
  color: ${({ theme }) => theme.colors.neutral._100};
  font-size: ${({ theme }) => theme.font.size.heading.md};

  &.text-center {
    text-align: center;
  }

  &.status-page-header {
    padding-left: 32px;
    padding-right: 32px;
  }

  @media (max-width: 767px) {
    &.status-page-header {
      padding: 0;
    }
  }
`;

export const $HyperlinkPageSubtitle = styled.h4`
  width: 100%;
  margin-bottom: 24px;
  color: ${({ theme }) => theme.colors.neutral._100};

  font-weight: 700;
  font-size: ${({ theme }) => theme.font.size.heading.xs};

  &.text-center {
    text-align: center;
  }
`;

export const $HyperlinkPageParagraph = styled.p`
  width: 100%;
  font-weight: 400;
  margin-bottom: 18px;
  color: ${({ theme }) => theme.colors.neutral._80};
  font-size: ${({ theme }) => theme.font.size.body.sm};

  &.small {
    font-size: ${({ theme }) => theme.font.size.body.xs};
  }

  &.subtitle {
    color: ${({ theme }) => theme.colors.neutral._70};
  }

  &.no-margin {
    margin-bottom: 0;
  }

  &.text-center {
    text-align: center;
  }
`;

export const $HyperlinkAccessInfo = styled.h4`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-basis: 33%;
`;

export const $HyperlinkBankImage = styled.div`
  height: 120px;
  width: 90px;
  margin-top: 46px;
  margin-bottom: 24px;

  @media (max-width: 767px) {
    height: 112px;
    width: 82px;
  }

  img {
    height: 100%;
    width: 100%;
  }
`;

export const $HyperlinkRedirectImage = styled.div`
  height: 171px;
  width: 171px;
  margin: 40px 0;

  @media (max-width: 767px) {
    height: 112px;
    width: 112px;
  }

  img {
    height: 100%;
    width: 100%;
  }
`;

export const $HyperlinkAccordionWrapper = styled.div`
  [data-reach-accordion-item] {
    margin: 0;
    padding: 16px 8px;
    background: ${({ theme }) => theme.colors.white};
    border-top: 0.5px solid ${({ theme }) => theme.colors.neutral._30};
    border-bottom: 0.5px solid ${({ theme }) => theme.colors.neutral._30};
  }

  [data-reach-accordion-item],
  [data-reach-accordion-item] button {
    border-radius: 0;
    font-weight: 400;
    font-size: ${({ theme }) => theme.font.size.body.sm};
  }

  [data-reach-accordion-panel] {
    padding: 0;

    div {
      padding-top: 10px;
      padding-bottom: 4px;
      color: ${({ theme }) => theme.colors.neutral._70};
    }
  }
`;

export const $HyperlinkAccordionTitle = styled.div`
  display: flex;
  align-items: center;

  // spacing for icon
  svg {
    margin-inline-end: 12px;
  }
`;

export const $HyperlinkDialogContent = styled(DialogContent)`
  width: 500px;
  overflow-y: scroll;
  font-size: ${({ theme }) => theme.font.size.body.sm};

  @media (max-width: 767px) {
    width: 340px;
  }
`;

export const $HyperlinkDialogFooter = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: ${({ theme }) => theme.font.size.body.sm};
  padding-top: ${({ theme }) => theme.layout.spacing.md};
`;

export const $BankInfoRightElement = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row-reverse;
`;

export const $HyperlinkListItem = styled.div<{ $disabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 4px;
  height: 72px;
  gap: 16px;
  width: 100%;
  cursor: pointer;

  ${({ $disabled }) =>
    $disabled &&
    css`
      cursor: not-allowed;
      color: rgba(0, 4, 26, 0.51);
      background: rgba(255, 255, 255, 0);

      img {
        opacity: 0.5;
      }
    `}

  :hover {
    background-color: rgba(0, 0, 65, 0.05);
  }

  img {
    height: 48px;
    width: 48px;
  }

  button {
    height: 24px;
    width: 64px;
    color: #1c2024;
    padding: 0 8px;
    font-size: 12px;
    border-radius: 3px;
    border: 1px solid rgba(0, 7, 19, 0.62);
  }

  button:hover {
    background-color: rgba(0, 0, 65, 0.05);
  }
`;

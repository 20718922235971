export const theme = {
  colors: {
    beige: '#f5f3eb',
    black: '#081717',
    black80: 'rgba(8, 23, 23, 0.8)',
    black60: 'rgba(8, 23, 23, 0.6)',
    black50: 'rgba(8, 23, 23, 0.6)',
    black40: 'rgba(8, 23, 23, 0.4)',
    black30: 'rgba(8, 23, 23, 0.3)',
    black20: 'rgba(8, 23, 23, 0.2)',
    black10: 'rgba(8, 23, 23, 0.1)',
    white: '#ffffff',
    neon: {
      black: '#0f2424',
      shade: '#277047',
      primary: '#2bd675',
      light1: '#73dea1',
      light2: '#c5f0d7',
      light3: '#f0f5f0',
      _20: '#E6FEEC',
      _30: '#CCFFDD',
      _70: '#00cc88',
      _80: '#008060',
    },
    trust: {
      black: '#05153d',
      shade: '#0b3078',
      primary: '#2f69d9',
      light1: '#557cc9',
      light2: '#7798d9',
      light3: '#c4d7fd',
      hover: '#2154b5',
      _10: '#F6FCFE',
      _20: '#E8F9FC',
      _30: '#c2ecfa',
      _40: '#9edbfa',
      _60: '#0080ff',
      _70: '#0546c7',
      _80: '#081F91',
      _100: '#000029',
    },
    forest: {
      primary: '#183f3f',
      light1: '#3e5c5c',
      light2: '#799494',
      light3: '#bad6d6',
    },
    neutral: {
      shade: '#b5b5b5',
      primary: '#e8e8e8',
      light1: '#f6f6f6',
      light2: '#fafafa',
      _10: '#fcfcfc',
      _20: '#f4f5f5',
      _30: '#e4e7e7',
      _40: '#cacece',
      _50: '#afb6b6',
      _60: '#959d9d',
      _70: '#7a8585',
      _80: '#626a6a',
      _90: '#495050',
      _100: '#313535',
    },
    coral: {
      primary: '#f5674e',
      light: '#ffcdc5',
      _20: '#FFDDCC',
      _50: '#ff5533',
      _60: '#F54D3D',
    },
    sun: {
      _10: '#F5EEA3',
      _40: '#FFAA00',
      _50: '#FF9500',
      _60: '#FF6A00',
    },
    purple: {
      primary: '#aa6de7',
      _60: '#6634D1',
    },
    tyrian: {
      _80: '#532673',
    },
    focus: ({ theme: thisObject }) => thisObject.colors.trust._30,
    active: ({ theme: thisObject }) => thisObject.colors.trust._60,
    blender: {
      _10: 'rgba(255, 255, 255, 0.8)',
      _40: 'rgba(255, 255, 255, 0.2)',
      _60: 'rgba(23, 28, 28, 0.1)',
      _80: 'rgba(23, 28, 28, 0.4)',
    },
  },
  elevation: {
    _10: '0px 2px 4px rgba(8, 23, 23, 0.08)',
    _20: '0px 4px 8px -1px rgba(8, 23, 23, 0.16)',
    _30: '0px 8px 16px -2px rgba(8, 23, 23, 0.16)',
    _40: '0px 16px 24px -4px rgba(8, 23, 23, 0.16)',
    _50: '0px 24px 32px -8px rgba(8, 23, 23, 0.16)',
  },
  layout: {
    spacing: {
      xl: '48px',
      lg: '32px',
      md: '24px',
      sm: '16px',
      xs: '12px',
      xxs: '8px',
    },
    sidenav: {
      width: '242px',
    },
    topnav: {
      height: '69px',
    },
    main: {
      padding: '32px',
      maxWidth: '1000px',
      minWidth: '650px',
      maxWidthFullScreen: '1486px',
    },
  },
  font: {
    family: {
      body: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif',
      heading: 'Inter, sans-serif',
      mono: '"JetBrains Mono", "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier, monospace',
    },
    size: {
      body: {
        xl: '20px',
        lg: '18px',
        md: '16px',
        sm: '14px',
        xs: '12px',
      },
      heading: {
        xl: '40px',
        lg: '32px',
        md: '24px',
        sm: '18px',
        xs: '16px',
      },
      label: {
        xl: '16px',
        lg: '14px',
        md: '12px',
        sm: '10px',
        xs: '8px',
      },
    },
  },
};

export const ERROR_SIGNUP_EMAIL_TAKEN = 'Email is already taken!';

export const STATUS_OK = 'OK';
export const STATUS_MFA_REQUIRED = 'MFA_REQUIRED';
export const STATUS_SECURITY_QUESTIONS_REQUIRED = 'SECURITY_QUESTIONS_REQUIRED';
export const STATUS_APP_AUTH_REQUIRED = 'APP_AUTH_REQUIRED';
export const STATUS_CAPTCHA_REQUIRED = 'CAPTCHA_REQUIRED';
export const STATUS_ACTIVE = 'ACTIVE';
export const STATUS_PENDING_COOL_OFF = 'PENDING_COOL_OFF';
export const STATUS_ACCEPTED = 'ACCEPTED';
export const OAUTH_AUTHENTICATION = 'OAUTH_AUTHENTICATION';
export const STATUS_REJECTED = 'REJECTED';

export const ENVIRONMENT_SANDBOX = 'sandbox';
export const ENVIRONMENT_PRODUCTION = 'production';
export const ENVIRONMENT_PRE_LIVE = 'preLive';

export const environmentNames = {
  [ENVIRONMENT_SANDBOX]: 'Sandbox',
  [ENVIRONMENT_PRE_LIVE]: 'Pre-live',
  [ENVIRONMENT_PRODUCTION]: 'Production',
};

export const LOGIN_BEHAVIOUR_TYPES = {
  STATUS_OK,
  STATUS_MFA_REQUIRED,
  STATUS_SECURITY_QUESTIONS_REQUIRED,
  STATUS_APP_AUTH_REQUIRED,
  STATUS_CAPTCHA_REQUIRED,
};

export const BENEFICIARY_BEHAVIOUR_TYPES = {
  STATUS_ACTIVE,
  STATUS_MFA_REQUIRED,
  STATUS_PENDING_COOL_OFF,
};

export const PAYMENT_BEHAVIOUR_TYPES = {
  STATUS_ACCEPTED,
  STATUS_MFA_REQUIRED,
  STATUS_SECURITY_QUESTIONS_REQUIRED,
  STATUS_APP_AUTH_REQUIRED,
  STATUS_CAPTCHA_REQUIRED,
  STATUS_REJECTED,
};

export const APPLICATION_TRAITS = {
  OAUTH_AUTHENTICATION,
};

export const testUsersFlowChallenges = {
  NO_CHALLENGE: {
    value: 'N/A',
    tooltipTitle: 'Not applicable',
    tooltipText:
      'Since the user does not require an OTP or Security Question in the Link flow, this user’s entity will not require the Reconnect flow.',
  },
  OTP_REQUIRED: {
    icon: 'ChatboxEllipses',
    iconHeight: 16,
    tooltipTitle: 'OTP required',
    tooltipText:
      'The user will be asked for an OTP that their bank sent to their mobile phone. For our mock bank, the OTP will always be `1111`.',
  },
  SECURITY_QUESTIONS_REQUIRED: {
    icon: 'HelpCircle',
    iconHeight: 16,
    tooltipTitle: 'Security questions',
    tooltipText:
      'The user will be asked to answer a security question that they’ve previously submitted to the bank. For our mock bank, we’ve made the questions and answers easy and obvious but in the real world, only your users will know the answer.',
  },
  APP_AUTH_REQUIRED: {
    icon: 'HelpCircle',
    iconHeight: 16,
    tooltipTitle: 'Polling app auth',
    tooltipText: 'Polling app auth',
  },
  INSTANT: {
    icon: 'Flash',
    iconHeight: 16,
    tooltipTitle: 'Instant activation',
    tooltipText:
      'This scenario links the payment source and makes the account active to initiate payments instantly.',
  },
  COOL_OFF_15MIN: {
    icon: 'Time',
    iconHeight: 16,
    tooltipTitle: 'Cool-off required',
    tooltipText:
      'After the user authorises the payment source, there will be a cool-off period of 15 minutes before the user can make a payment. You’ll get a webhook when the payment source is active. This mirrors the cool-off behaviour in some real-world banks.',
  },
  USER_CONFIRMATION: {
    icon: 'Person',
    iconHeight: 16,
    tooltipTitle: 'User confirmation',
    tooltipText:
      'The user needs to view the payment details and subsequently confirm they want to proceed with the payment.',
  },
  USER_CREDENTIALS: {
    icon: 'Key',
    iconHeight: 16,
    tooltipTitle: 'User credentials',
    tooltipText:
      'The test user will be asked to submit their username and password for the account, which can be found at the beginning of this row.',
  },
  CAPTCHA_REQUIRED: {
    icon: 'HelpCircle',
    iconHeight: 16,
    tooltipTitle: 'Security questions (captcha)',
    tooltipText:
      'The user will be asked to answer a security question that they’ve previously submitted to the bank. For our mock bank, we’ve made the questions and answers easy and obvious but in the real world, only your users will know the answer.',
  },
};

export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const OPEN_BANKING_DEFAULT_SUCCESS_REDIRECT_URL =
  'https://docs.leantech.me/v2.0-KSA/page/successful-connection';
export const OPEN_BANKING_DEFAULT_FAIL_REDIRECT_URL =
  'https://docs.leantech.me/v2.0-KSA/page/failed-connection';

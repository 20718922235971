import React from 'react';
import PropTypes from 'prop-types';

import { $AppProfile } from '../styles';

import Logo from './Logo';

const AppProfile = ({ appName, appLogoUrl }) => (
  <$AppProfile>
    <Logo name={appName} url={appLogoUrl} />
    <div className="label">{appName}</div>
  </$AppProfile>
);

AppProfile.propTypes = {
  appName: PropTypes.string,
  appLogoUrl: PropTypes.string || null,
};

AppProfile.defaultProps = {
  appName: '',
  appLogoUrl: null,
};

export default AppProfile;

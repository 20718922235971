/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import React from 'react';
import {
  segementAnalyticsPropTypes,
  styledButtonPropsPropTypes,
} from 'utils/proptypes';
import { $ChildrenWrapper, $SpinnerBackground } from 'styles/components/Button';
import Icon from './Icon';
import { $Button, $Spinner } from '../styles';

const Button = ({
  analytics,
  children,
  isDisabled,
  icon,
  iconEnd,
  iconStart,
  onClick,
  isLoading,
  path,
  styledProps,
  styledIconStart,
  type,
  'data-cy': dataCy,
  // temp until we refactor ion-icon out of this component
  styledIcon,
  className,
}) => {
  const handleOnClick = e => {
    onClick(e);
    handleAnalytics();
  };

  const handleAnalytics = () => {
    const { event, properties } = analytics;

    if (event) {
      window.analytics.track(event, properties);
    }
  };

  const linkProps = path ? { as: Link, to: path } : {};

  return (
    <$Button
      $isLoading={isLoading}
      disabled={isDisabled}
      onClick={handleOnClick}
      type={!path ? type : undefined}
      data-cy={dataCy}
      className={className}
      {...styledProps}
      {...linkProps}
    >
      {isLoading && (
        <$SpinnerBackground {...styledProps}>
          <$Spinner variant={styledProps.variant} />
        </$SpinnerBackground>
      )}
      <$ChildrenWrapper>
        {iconStart && (
          <ion-icon class="ion-icon ion-icon-start" name={iconStart} />
        )}
        {styledIconStart && (
          <Icon
            size={16}
            name={styledIconStart}
            className="styled-icon-start"
          />
        )}
        {children}
        {iconEnd && (
          <ion-icon class="ion-icon ion-icon-end" name={iconEnd || icon} />
        )}
        {styledIcon && <Icon name={styledIcon} size={12} />}
      </$ChildrenWrapper>
    </$Button>
  );
};

Button.propTypes = {
  analytics: segementAnalyticsPropTypes,
  children: PropTypes.node.isRequired,
  icon: PropTypes.string,
  iconEnd: PropTypes.string,
  iconStart: PropTypes.string,
  path: PropTypes.string,
  onClick: PropTypes.func,
  styledProps: styledButtonPropsPropTypes,
  type: PropTypes.string,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  'data-cy': PropTypes.string,
  styledIcon: PropTypes.string,
  styledIconStart: PropTypes.string,
  className: PropTypes.string,
};

Button.defaultProps = {
  analytics: {
    event: '',
  },
  icon: '',
  iconEnd: '',
  iconStart: '',
  onClick: () => {},
  path: '',
  styledProps: {
    align: 'start',
    display: 'flex',
    $fullwidth: false,
    size: 'small',
    variant: 'primary',
    justifyContent: 'start',
    danger: false,
    active: false,
  },
  type: 'button',
  isDisabled: false,
  isLoading: false,
  'data-cy': '',
  styledIconStart: '',
  styledIcon: undefined,
  className: undefined,
};

export default Button;

import ApplicationService from 'modules/application/applicationService';
import applicationListSelectors from 'modules/application/list/applicationListSelectors';
import Errors from 'modules/shared/error/errors';
import Message from 'view/shared/message';
import viewSelectors from 'modules/application/view/applicationViewSelectors';
import { MockUsersFilter } from 'modules/application/constants';
import featureFlags, { FeatureFlags } from 'modules/featureFlags';
import { selectApplicationAccountType } from 'modules/application/accountType/applicationAccountType.slice';
import { ApplicationAccountType } from 'modules/application/accountType/applicationAccountType.types';

const prefix = 'APPLICATION_VIEW';

const actions = {
  GET_MOCK_USERS_STARTED: `${prefix}_GET_MOCK_USERS_STARTED`,
  GET_MOCK_USERS_SUCCESS: `${prefix}_GET_MOCK_USERS_SUCCESS`,
  GET_MOCK_USERS_ERROR: `${prefix}_GET_MOCK_USERS_ERROR`,

  POST_MOCK_USER_ADD_FUNDS_STARTED: `${prefix}_POST_MOCK_USER_ADD_FUNDS_STARTED`,
  POST_MOCK_USER_ADD_FUNDS_SUCCESS: `${prefix}_POST_MOCK_USER_ADD_FUNDS_SUCCESS`,
  POST_MOCK_USER_ADD_FUNDS_ERROR: `${prefix}_POST_MOCK_USER_ADD_FUNDS_ERROR`,

  POST_MOCK_USER_ADD_TRANSACTIONS_STARTED: `${prefix}_POST_MOCK_USER_ADD_TRANSACTIONS_STARTED`,
  POST_MOCK_USER_ADD_TRANSACTIONS_SUCCESS: `${prefix}_POST_MOCK_USER_ADD_TRANSACTIONS_SUCCESS`,
  POST_MOCK_USER_ADD_TRANSACTIONS_ERROR: `${prefix}_POST_MOCK_USER_ADD_TRANSACTIONS_ERROR`,

  POST_MOCK_USER_EXPIRE_SESSION_STARTED: `${prefix}_POST_MOCK_USER_EXPIRE_SESSION_STARTED`,
  POST_MOCK_USER_EXPIRE_SESSION_SUCCESS: `${prefix}_POST_MOCK_USER_EXPIRE_SESSION_SUCCESS`,
  POST_MOCK_USER_EXPIRE_SESSION_ERROR: `${prefix}_POST_MOCK_USER_EXPIRE_SESSION_ERROR`,

  DELETE_MOCK_USER_STARTED: `${prefix}_DELETE_MOCK_USER_STARTED`,
  DELETE_MOCK_USER_SUCCESS: `${prefix}_DELETE_MOCK_USER_SUCCESS`,
  DELETE_MOCK_USER_ERROR: `${prefix}_DELETE_MOCK_USER_ERROR`,

  POST_ADD_MOCK_USERS_STARTED: `${prefix}_POST_ADD_MOCK_USERS_STARTED`,
  POST_ADD_MOCK_USERS_SUCCESS: `${prefix}_POST_ADD_MOCK_USERS_SUCCESS`,
  POST_ADD_MOCK_USERS_ERROR: `${prefix}_POST_ADD_MOCK_USERS_ERROR`,

  SET_MOCK_USERS_FILTER: `${prefix}_SET_MOCK_USERS_FILTER`,

  // MockUsers
  doGetMockUsers: id => async (dispatch, getState) => {
    try {
      dispatch({
        type: actions.GET_MOCK_USERS_STARTED,
      });

      const isRolloutAccountTypes = featureFlags.isOn(
        FeatureFlags.rolloutAccountTypes,
      );
      const accountType = isRolloutAccountTypes
        ? selectApplicationAccountType(getState())
        : undefined;
      const response = await ApplicationService.getMockUsers(id, accountType);

      dispatch({
        type: actions.GET_MOCK_USERS_SUCCESS,
        payload: response,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: actions.GET_MOCK_USERS_ERROR,
      });
    }
  },

  doPostMockUserAddFunds:
    (mockUserId, amount) => async (dispatch, getState) => {
      const appId = applicationListSelectors.selectApplicationId(getState());

      try {
        dispatch({
          type: actions.POST_MOCK_USER_ADD_FUNDS_STARTED,
        });

        const response = await ApplicationService.postMockUserAddFunds(
          appId,
          mockUserId,
          { amount },
        );

        dispatch({
          type: actions.POST_MOCK_USER_ADD_FUNDS_SUCCESS,
          payload: response,
        });

        if (response.error) {
          Message.error('Adding funds failed');
        } else {
          Message.success('Funds added');
        }
      } catch (error) {
        Errors.handle(error);

        Message.error('Adding funds failed');

        dispatch({
          type: actions.POST_MOCK_USER_ADD_FUNDS_ERROR,
        });
      }
    },

  doPostMockUserAddTransactions:
    (mockUserId, amount) => async (dispatch, getState) => {
      const appId = applicationListSelectors.selectApplicationId(getState());

      try {
        dispatch({
          type: actions.POST_MOCK_USER_ADD_TRANSACTIONS_STARTED,
        });

        const response = await ApplicationService.postMockUserAddTransactions(
          appId,
          mockUserId,
          { count: amount },
        );

        dispatch({
          type: actions.POST_MOCK_USER_ADD_TRANSACTIONS_SUCCESS,
          payload: response,
        });

        if (response.error) {
          Message.error('Generating transactions failed');
        } else {
          Message.success('Transactions generated');
        }
      } catch (error) {
        Errors.handle(error);

        Message.error('Generating transactions failed');

        dispatch({
          type: actions.POST_MOCK_USER_ADD_TRANSACTIONS_ERROR,
        });
      }
    },

  doPostMockUserExpireSession: mockUserId => async (dispatch, getState) => {
    const appId = applicationListSelectors.selectApplicationId(getState());

    try {
      dispatch({
        type: actions.POST_MOCK_USER_EXPIRE_SESSION_STARTED,
      });

      const response = await ApplicationService.postMockUserExpireSession(
        appId,
        mockUserId,
      );

      dispatch({
        type: actions.POST_MOCK_USER_EXPIRE_SESSION_SUCCESS,
        payload: response,
      });

      if (response.error) {
        Message.error('Expire session request failed');
      } else {
        Message.success('Expire session requested');
      }
    } catch (error) {
      Errors.handle(error);

      Message.error('Expire session request failed');

      dispatch({
        type: actions.POST_MOCK_USER_EXPIRE_SESSION_ERROR,
      });
    }
  },

  doDeleteMockUser: mockUserId => async (dispatch, getState) => {
    const appId = applicationListSelectors.selectApplicationId(getState());

    try {
      dispatch({
        type: actions.DELETE_MOCK_USER_STARTED,
      });

      const response = await ApplicationService.deleteMockUser(
        appId,
        mockUserId,
      );

      dispatch({
        type: actions.DELETE_MOCK_USER_SUCCESS,
        payload: response,
      });

      if (response.error) {
        Message.error('Delete user request failed');
      } else {
        Message.success('Delete user requested');
      }

      dispatch(actions.doGetMockUsers(appId));
    } catch (error) {
      Errors.handle(error);

      Message.error('Delete user request failed');

      dispatch({
        type: actions.DELETE_MOCK_USER_ERROR,
      });
    }
  },

  doPostAddMockUsers: amount => async (dispatch, getState) => {
    const appId = applicationListSelectors.selectApplicationId(getState());
    const mockUsersFilter = viewSelectors.selectMockUsersFilter(getState());

    try {
      dispatch({
        type: actions.POST_ADD_MOCK_USERS_STARTED,
      });

      let response;
      const isRolloutAccountTypes = featureFlags.isOn(
        FeatureFlags.rolloutAccountTypes,
      );
      const accountType = isRolloutAccountTypes
        ? selectApplicationAccountType(getState())
        : undefined;

      if (
        mockUsersFilter === MockUsersFilter.sme ||
        accountType === ApplicationAccountType.business
      ) {
        response = await ApplicationService.postAddSMEMockUsers(appId);
      } else {
        response = await ApplicationService.postAddMockUsers(appId, {
          count: amount,
        });
      }

      dispatch({
        type: actions.POST_ADD_MOCK_USERS_SUCCESS,
        payload: response,
      });

      if (Array.isArray(response.errors) && response.errors.length) {
        Message.error('Adding test users failed');
      } else {
        Message.success('Test users added');
      }

      dispatch(actions.doGetMockUsers(appId));
    } catch (error) {
      Errors.handle(error);

      Message.error('Adding test users failed');

      dispatch({
        type: actions.POST_ADD_MOCK_USERS_ERROR,
      });
    }
  },

  setMockUsersFilter: filter => ({
    type: actions.SET_MOCK_USERS_FILTER,
    payload: filter,
  }),
};

export default actions;

import React from 'react';
import PropTypes from 'prop-types';
import { $DetailsRow } from './styles';

const DetailsRow = ({ data, children }) => {
  if (!data) return null;
  return <$DetailsRow>{children}</$DetailsRow>;
};

DetailsRow.propTypes = {
  children: PropTypes.node,
  data: PropTypes.string,
};

DetailsRow.defaultProps = {
  children: undefined,
  data: undefined,
};

export default DetailsRow;

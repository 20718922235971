import React, { useEffect, useState } from 'react';
import { matchPropTypes } from 'utils/proptypes';
import { getHistory, pushToHistory } from 'modules/history';
import generateBreadcrumbs from './generateBreadcrumbs';
import { $BreadCrumbs, $Container } from './styles';
import Button from '../Button';
import Icon from '../Icon';

const BreadCrumbs = ({ match }) => {
  const history = getHistory();
  const [crumbs, setCrumbs] = useState([]);

  useEffect(() => {
    const crumbs = generateBreadcrumbs(match);
    setCrumbs(crumbs);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.path]);

  return (
    <$BreadCrumbs>
      {crumbs.length > 1 ? (
        <Icon name="ArrowBack" size={16} onClick={() => history.goBack()} />
      ) : null}

      {crumbs.map(({ label, path }, index) => {
        const isLastItem = index === crumbs.length - 1;

        return isLastItem ? (
          <p className="current-page" key={path}>
            {label}
          </p>
        ) : (
          <$Container key={path}>
            <Button
              key={path}
              onClick={() => pushToHistory(path)}
              styledProps={{ variant: 'text' }}
            >
              {label}
            </Button>
            <Icon name="ChevronForward" size={16} />
          </$Container>
        );
      })}
    </$BreadCrumbs>
  );
};

BreadCrumbs.propTypes = {
  match: matchPropTypes,
};

BreadCrumbs.defaultProps = {
  match: {},
};

export default BreadCrumbs;

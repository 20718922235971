export enum MockUsersFilter {
  all = 'all',
  sme = 'sme',
  retail = 'retail',
}

export enum MockUsersRoles {
  retail = 'RETAIL_USER',
  authorizer = 'AUTHORIZER',
  maker = 'MAKER',
}

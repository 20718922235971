import { getFingerprint, setOption } from '@thumbmarkjs/thumbmarkjs';

export const generateFingerprint = async (exclude = []) => {
  try {
    if (exclude.length > 0) {
      setOption('exclude', exclude);
    }

    return getFingerprint();
  } catch (e) {
    return null;
  }
};

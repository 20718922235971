import React from 'react';
import { getLanguageCode, i18n } from 'i18n';
import { useTypedSelector } from 'types/global';
import { selectHyperlink } from 'modules/hyperlink';
import {
  $HyperlinkLayoutBody,
  $HyperlinkPageParagraph,
  $HyperlinkPageTitle,
} from './hyperlink.styled';
import HyperlinkContainer from './HyperlinkContainer';
import { unsupportedBanks } from './unsupportedBanks';
import BankListItem from './BankListItem';
import { getAppName } from './utils';

function BankSelection() {
  const { activeBanks, disabledBanks } = useTypedSelector(selectHyperlink);

  const { data } = useTypedSelector(selectHyperlink);
  const languageCode = getLanguageCode();
  const appName = getAppName(data, languageCode);

  return (
    <HyperlinkContainer>
      <$HyperlinkLayoutBody>
        <$HyperlinkPageTitle data-cy="hyperlink_connect_bank_title">
          {i18n('hyperlink.bank_selection.title')}
        </$HyperlinkPageTitle>
        <$HyperlinkPageParagraph>
          {i18n('hyperlink.bank_selection.subtitle', appName)}
        </$HyperlinkPageParagraph>

        {[...activeBanks, ...disabledBanks].map(bank => (
          <BankListItem key={bank.identifier} bank={bank} />
        ))}

        {unsupportedBanks.map(bank => (
          <BankListItem key={bank.identifier} bank={bank} />
        ))}
      </$HyperlinkLayoutBody>
    </HyperlinkContainer>
  );
}

export default BankSelection;

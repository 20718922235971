import styled from 'styled-components/macro';

const $Helptext = styled.div`
  color: ${props => (props.isErrored ? '#ff5533' : props.theme.colors.black40)};
  font-size: ${({ theme }) => theme.font.size.label.md};
`;

export const $MaintenanceHelpText = styled.div`
  color: ${({ theme }) => theme.colors.black};
  font-size: ${({ theme }) => theme.font.size.body.sm};
`;

export default $Helptext;

import { v4 as uuid } from 'uuid';
import { RootState } from 'modules/store';
import { createSlice } from '@reduxjs/toolkit';

import { Payment, PaymentsState } from './payments.types';
import { getPayments, getPaymentById } from './payments.actions';

const initialState: PaymentsState = {
  payment: {
    isFetching: false,
    data: null,
  },
  payments: {
    total: 0,
    isFetching: false,
    data: [],
  },
};

const paymentsSlice = createSlice({
  name: 'payments',
  initialState,
  reducers: {},
  extraReducers: builder => {
    // Get customer payments
    builder.addCase(getPayments.pending, state => {
      state.payments.isFetching = true;
    });
    builder.addCase(getPayments.fulfilled, (state, { payload }) => {
      state.payments.total = payload.entries_total;
      state.payments.data = payload.items.map((payment: Payment) => ({
        ...payment,
        id: uuid(), // assign unique ids to meet table row constrain
      }));
      state.payments.isFetching = false;
    });
    builder.addCase(getPayments.rejected, state => {
      state.payments.isFetching = false;
    });

    // Get single customer payment
    builder.addCase(getPaymentById.pending, state => {
      state.payment.isFetching = true;
    });
    builder.addCase(getPaymentById.fulfilled, (state, { payload }) => {
      state.payment.data = payload;
      state.payment.isFetching = false;
    });
    builder.addCase(getPaymentById.rejected, state => {
      state.payment.isFetching = false;
    });
  },
});

export default paymentsSlice.reducer;
export const selectPayments = (state: RootState) =>
  state.root.payments.payments;
export const selectPayment = (state: RootState) => state.root.payments.payment;

import { i18n } from 'i18n';
import QueryString from 'query-string';
import { RootState } from 'modules/store';
import { ErrorObject } from 'types/global';
import Errors from 'modules/shared/error/errors';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { hyperlinkUserSteps } from 'view/hyperlinkPages/constants';
import { getBankAvailability } from 'utils/getBankAvailability';

import HyperlinkService from './hyperlink.service';
import { BankData, HyperlinkError, TppData } from './hyperlink.types';
import { getHistory } from '../history';

export const getTppData = createAsyncThunk<
  TppData,
  { customerId: string; sandbox: boolean },
  {
    state: RootState;
    rejectValue: HyperlinkError | ErrorObject;
  }
>(
  'hyperlink/get-tpp-data',
  async ({ customerId, sandbox }, { getState, rejectWithValue }) => {
    const { environment: appEnvironment } = getState().root.global;

    try {
      return await HyperlinkService.getTppData({
        sandbox,
        customerId,
        appEnvironment,
      });
    } catch (error: unknown) {
      return rejectWithValue({
        message: Errors.selectMessage(error),
        errorCode: Errors.errorCode(error),
      });
    }
  },
);

export const getBanks = createAsyncThunk<
  BankData[],
  {
    sandbox: boolean;
    customerId: string;
    appToken: string;
    accountType: string | null;
  },
  {
    state: RootState;
    rejectValue: HyperlinkError | ErrorObject;
  }
>(
  'hyperlink/get-banks',
  async (
    { sandbox, customerId, appToken, accountType },
    { rejectWithValue },
  ) => {
    try {
      const response = await HyperlinkService.getBanks({
        sandbox,
        appToken,
        customerId,
        accountType,
      });

      return response.map(bank => {
        const {
          availability = {
            active: { data: true },
            enabled: { data: true },
          },
        } = bank;

        return {
          ...bank,
          is_active: getBankAvailability(availability),
        };
      });
    } catch (error: unknown) {
      return rejectWithValue({
        message: Errors.selectMessage(error),
        errorCode: Errors.errorCode(error),
      });
    }
  },
);

export const postBankRequest = createAsyncThunk<
  unknown,
  {
    sandbox: boolean;
    customerId: string;
    bankIdentifier: string;
  },
  {
    state: RootState;
    rejectValue: { message: string } | HyperlinkError | ErrorObject;
  }
>(
  'hyperlink/post-bank-request',
  async (
    { sandbox, customerId, bankIdentifier },
    { getState, rejectWithValue },
  ) => {
    const { environment: appEnvironment } = getState().root.global;
    try {
      const payload = {
        event_type: 'UNSUPPORTED_BANK_REQUEST',
        bank_identifier: bankIdentifier,
      };

      const response = await HyperlinkService.postBankRequest({
        sandbox,
        customerId,
        appEnvironment,
        data: payload,
      });

      const history = getHistory();
      const queryParams = QueryString.parse(window.location.search);

      const newParams = {
        ...queryParams,
        step: hyperlinkUserSteps.RequestBankSuccess.name,
      };
      history.push({ search: `${QueryString.stringify(newParams)}` });

      return response;
    } catch (error: unknown) {
      const errorObj: ErrorObject = error as ErrorObject;

      if (errorObj.response.status === 409) {
        return rejectWithValue({
          message: i18n('hyperlink.bank_request_failed.already_requested'),
        });
      }

      if (errorObj.response.status === 429) {
        return rejectWithValue({
          message: i18n('hyperlink.bank_request_failed.too_many_requests'),
        });
      }

      return rejectWithValue({
        message: i18n('hyperlink.generic_error.title'),
        errorCode: Errors.errorCode(error),
      });
    }
  },
);

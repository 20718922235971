import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  leanCopyright,
  responsibleURL,
  privacyURL,
  touURL,
  contactUs,
} from 'modules/shared/legal/legal';

const $FullScreen = styled.div`
  width: 100vw;
  height: 100vh;

  background-color: ${({ theme }) => theme.colors.white};

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1;
`;

const $Content = styled.div`
  flex: 1;
  display: flex;

  justify-content: center;
  align-items: center;
`;

const $BottomBar = styled.div`
  height: 72px;
  width: 100%;
  padding: 0 16px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  border-top: 0.5px solid ${({ theme }) => theme.colors.neutral._30};
`;

const $Link = styled.a`
  color: ${({ theme }) => theme.colors.black};
`;

const $Links = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  & > ${$Link} {
    margin-right: 28px;
  }
`;

const $LeanCopyright = styled.span`
  font-size: ${({ theme }) => theme.font.size.body.sm};
  color: ${({ theme }) => theme.colors.neutral._60};
`;

const FullScreen = ({ children, isFooterVisible }) => (
  <$FullScreen>
    <$Content>{children}</$Content>
    {isFooterVisible && (
      <$BottomBar>
        <$LeanCopyright>{leanCopyright}</$LeanCopyright>
        <$Links>
          <$Link href={contactUs} target="_blank">
            Contact Us
          </$Link>
          <$Link href={privacyURL} target="_blank">
            Privacy Notice
          </$Link>
          <$Link href={responsibleURL} target="_blank">
            Responsibility disclosure
          </$Link>
          <$Link href={touURL} target="_blank">
            Terms of Use
          </$Link>
        </$Links>
      </$BottomBar>
    )}
  </$FullScreen>
);

FullScreen.propTypes = {
  children: PropTypes.node.isRequired,
  isFooterVisible: PropTypes.bool,
};

FullScreen.defaultProps = {
  isFooterVisible: false,
};

export default FullScreen;

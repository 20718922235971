import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from 'modules/store';
import { ErrorObject } from 'types/global';
import Errors from 'modules/shared/error/errors';
import selectors from 'modules/auth/authSelectors';
import { AccountType } from 'modules/auth/constants';
import applicationListSelectors from 'modules/application/list/applicationListSelectors';
import ApplicationService from 'modules/application/applicationService';

import {
  OptInError,
  PayoutDetails,
  AuthorizablesPayload,
  UsersPayoutDetails,
} from './payout.types';
import PayoutService from './payouts.service';

export const getPayoutDetails = createAsyncThunk<
  PayoutDetails,
  void,
  {
    state: RootState;
    rejectValue: OptInError | ErrorObject;
  }
>('payouts/get-payout-details', async (p, { getState, rejectWithValue }) => {
  const { environment: appEnvironment, appToken } = getState().root.global;

  try {
    return await PayoutService.getPayoutDetailsForCurrentUser({
      appToken,
      appEnvironment,
    });
  } catch (error: unknown) {
    const errorObj: ErrorObject = error as ErrorObject;

    if (
      errorObj.response.status === 404 &&
      Errors.errorStatusOrStatusCode(errorObj) === 'UNKNOWN_APP_USER'
    ) {
      return rejectWithValue({ isOptInRequired: true });
    }

    Errors.handle(errorObj);
    return rejectWithValue(errorObj);
  }
});

export const getQueuedPayments = createAsyncThunk<
  AuthorizablesPayload,
  {
    page: number;
    pageSize: number;
    dateTo: string;
    dateFrom: string;
    maxAmount: number;
    minAmount: number;
    status: string;
    mechanism: string;
  },
  {
    state: RootState;
    rejectValue: OptInError | ErrorObject;
  }
>(
  'payouts/get-queued-payments',
  async (
    {
      page,
      pageSize,
      dateTo,
      dateFrom,
      maxAmount,
      minAmount,
      status,
      mechanism,
    },
    { getState, rejectWithValue },
  ) => {
    const { environment: appEnvironment, appToken } = getState().root.global;

    const body = {
      page_number: page,
      page_size: pageSize,
      ...(dateTo ? { to: dateTo } : {}),
      ...(dateFrom ? { from: dateFrom } : {}),
      ...(maxAmount ? { amount_max: maxAmount } : {}),
      ...(minAmount ? { amount_min: minAmount } : {}),
      ...(status ? { status_type: status } : {}),
    };

    try {
      return await PayoutService.getAuthorizables({
        body,
        appToken,
        appEnvironment,
        mechanism,
      });
    } catch (error: unknown) {
      const errorObj: ErrorObject = error as ErrorObject;

      if (
        errorObj.response.status === 404 &&
        Errors.errorStatusOrStatusCode(errorObj) === 'UNKNOWN_APP_USER'
      ) {
        return rejectWithValue({ isOptInRequired: true });
      }

      Errors.handle(errorObj);
      return rejectWithValue(errorObj);
    }
  },
);

export const getUsersPayoutDetails = createAsyncThunk<
  UsersPayoutDetails,
  void,
  {
    state: RootState;
    rejectValue: OptInError | ErrorObject;
  }
>(
  'payouts/get-users-payout-details',
  async (p, { getState, rejectWithValue }) => {
    const { environment: appEnvironment, appToken } = getState().root.global;

    try {
      return await PayoutService.getPayoutDetailsForUsers({
        appToken,
        appEnvironment,
      });
    } catch (error: unknown) {
      const errorObj: ErrorObject = error as ErrorObject;

      if (
        errorObj.response.status === 404 &&
        Errors.errorStatusOrStatusCode(errorObj) === 'UNKNOWN_APP_USER'
      ) {
        return rejectWithValue({ isOptInRequired: true });
      }

      Errors.handle(errorObj);
      return rejectWithValue(errorObj);
    }
  },
);

export const optInForPayouts = createAsyncThunk<
  PayoutDetails,
  void,
  { state: RootState }
>(
  'payouts/opt-in-for-payouts',
  async (p, { dispatch, getState, rejectWithValue }) => {
    const { environment: appEnvironment, appToken } = getState().root.global;
    const accountType = selectors.selectAccountType(getState());

    try {
      const response = await PayoutService.optInCurrentUserForPayouts({
        appToken,
        appEnvironment,
      });

      if (accountType === AccountType.retail) {
        dispatch(createSMEMockUsersForPayouts());
      }

      return response;
    } catch (error: unknown) {
      Errors.handle(error);
      return rejectWithValue(error);
    }
  },
);

export const createSMEMockUsersForPayouts = createAsyncThunk<
  void,
  void,
  { state: RootState }
>('payouts/create-sme-mock-users', async (p, { getState, rejectWithValue }) => {
  const appId = applicationListSelectors.selectApplicationId(getState());

  try {
    return await ApplicationService.postAddSMEMockUsers(appId);
  } catch (error: unknown) {
    Errors.handle(error);
    return rejectWithValue(error);
  }
});

export const LANGUAGE__EN = 'en';
export const LANGUAGE__AR = 'ar';
export const LANGUAGE__DIR_LTR = 'ltr';
export const LANGUAGE__DIR_RTL = 'rtl';

const getDefaultLanguage = () => {
  // If path includes hyperlink, set default to Arabic
  if (window.location.pathname.includes('/hyperlink/')) {
    return LANGUAGE__AR;
  }

  // Default to English otherwise
  return LANGUAGE__EN;
};

export const LANGUAGE__DEFAULT = getDefaultLanguage();

export const supportedLanguages = [LANGUAGE__EN, LANGUAGE__AR];

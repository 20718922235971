/* eslint-disable no-template-curly-in-string */
const arEG = {
  common: {
    or: 'أو',
    cancel: 'إلغاء',
    reset: 'إعادة تعيين',
    save: 'حفظ',
    update: 'تحديث',
    return: 'إرجاع',
    confirm: 'تؤكد',
    create: 'خلق',
    search: 'بحث',
    edit: 'تعديل',
    remove: 'إزالة',
    back: 'عودة',
    continue: 'استمر',
    copy: 'نسخ',
    run: 'يركض',
    start: 'بداية',
    finish: 'إنهاء',
    try: 'محاولة',
    demo: 'تجريبي',
    new: 'جديد',
    export: 'تصدير إلى Excel',
    noDataToExport: 'لا توجد بيانات للتصدير',
    import: 'استيراد',
    discard: 'تجاهل',
    yes: 'نعم',
    no: 'لا',
    pause: 'وقفة',
    areYouSure: 'هل أنت واثق؟',
    view: 'رأي',
    destroy: 'حذف',
    activate: 'تفعيل',
    deactivate: 'إبطال',
    active: 'نشيط',
    inactive: 'غير نشط',
    revoke: 'سحب او إبطال',
    contact: 'اتصل بنا',
    help: 'اتصل بنا',
    documentation: 'توثيق',
    fulldocumentation: 'التوثيق الكامل',
    mustSelectARow: 'يجب تحديد صف',
  },

  social: {
    twitter: 'تويتر',
    linkedin: 'لينكد إن',
  },

  app: {
    title: 'الخالية من',
  },

  entities: {
    application: {
      name: 'تطبيق',
      label: 'التطبيقات',
      menu: 'التطبيقات',
      exporterFileName: 'application_export',
      list: {
        menu: 'التطبيقات',
        title: 'التطبيقات',
      },
      create: {
        success: 'تم حفظ التطبيق بنجاح',
      },
      update: {
        success: 'تم تحديث التطبيق بنجاح',
      },
      revoke: {
        tooltip: 'إبطال شهادة التقديم. سيؤدي هذا إلى حظر جميع الأنشطة.',
        success: 'تم إبطال الشهادة بنجاح',
      },
      destroy: {
        tooltip: 'حذف التطبيق من حسابك.',
        success: 'تم حذف التطبيق بنجاح',
      },
      toggle: {
        success: 'تم إلغاء تنشيط التطبيق بنجاح',
      },
      destroyAll: {
        success: 'تم حذف التطبيق (التطبيقات) بنجاح',
      },
      edit: {
        tooltip: 'قم بتحديث تفاصيل التطبيق.',
        title: 'تحرير التطبيق',
      },
      form: {
        alertTitle: 'انتباه!',
        alert:
          'ستتم مطالبتك بتنزيل المفتاح والشهادة الخاصين والحفاظ عليهما بأمان. لأسباب أمنية ، نحن لا نحتفظ بها. سيؤدي فقدها إلى منعك أنت أو فريقك من الوصول إلى هذا التطبيق',
        name: 'تطبيق Fintech',
        description:
          'Fintech App عبارة عن منصة لإدارة التمويل الشخصي والإقراض P2P.',
        link_redirect_url: 'https://fintech.app/auth/users/redirect',
        permissions: 'الهوية والحساب والرصيد والمعاملات',
      },
      fields: {
        id: 'هوية شخصية',
        name: 'اسم التطبيق',
        description: 'وصف',
        logo_url: 'شعار',
        active: 'نشيط',
        cert_public_key: 'المفتاح العمومي',
        link_redirect_url: 'عاود الاتصال بالعنوان',
        token: 'رمز التطبيق',
        permissions: 'أذونات',
        common_name: 'اسم شائع',
        created_at: 'أنشئت في',
        updated_at: 'تم التحديث في',
        created_at_range: 'أنشئت في',
      },
      placeholders: {
        id: 'هوية شخصية',
        name: 'اسم التطبيق',
        description: 'صِف تطبيقك',
        logo_url: 'شعار',
        active: 'نشيط',
        cert_public_key: 'المفتاح العمومي',
        link_redirect_url: 'أدخل عنوان URL لرد الاتصال',
        token: 'رمز التطبيق',
        permissions: 'حدد أذونات التطبيق الخاص بك',
        common_name: 'اسم شائع',
        created_at: 'أنشئت في',
        updated_at: 'تم التحديث في',
        created_at_range: 'أنشئت في',
      },
      tooltip: {
        name: 'اسم التطبيق. سيتم عرض هذا في Link SDK للمستخدمين النهائيين',
        description:
          'ملخص التطبيق. سيتم عرض هذا في Link SDK للمستخدمين النهائيين',
        active: 'تبديل حالة التطبيق لتكون نشطة أو غير نشطة.',
        cert_public_key: 'المفتاح العام المستخدم للتحقق من صحة شهادة التطبيق',
        token:
          'الرمز المميز للتطبيق المستخدم بواسطة رؤوس طلبات واجهة برمجة التطبيقات.',
        logo_url:
          'شعار التطبيق أو الصورة التي سيتم عرضها في عناصر واجهة المستخدم SDK Link.',
        link_redirect_url:
          'عنوان URL لرد الاتصال الذي سيتصل به Lean بعد مصادقة المستخدم النهائي.',
        permissions: 'الأذونات المطلوبة لتطبيق Lean بواسطة التطبيق',
        common_name:
          'اسم التطبيق المستخدم بواسطة رؤوس طلبات واجهة برمجة التطبيقات.',
        userId: 'معرف المستخدم الداخلي. يستخدمها المطور.',
        codeSnippet: 'مقتطف مثال لكيفية بحث Lean Link SDK عن الويب.',
        copyCode: 'نسخ إلى الحافظة',
        created_at: 'أنشئت في',
        updated_at: 'تم التحديث في',
        created_at_range: 'أنشئت في',
      },
      new: {
        title: 'تطبيق جديد',
      },
      view: {
        title: 'عرض التطبيق',
        testURL: 'اختبار URL',
      },
      importer: {
        title: 'تطبيقات الاستيراد',
        fileName: 'application_import_template',
        hint: 'يجب أن تكون أعمدة الملفات / الصور عناوين URL للملفات مفصولة بمسافات.',
      },
    },
    certificate: {
      name: 'شهادة',
      label: 'الشهادات',
      menu: 'الشهادات',
      exporterFileName: 'شهادة تصدير',
      list: {
        menu: 'الشهادات',
        title: 'الشهادات',
      },
      create: {
        success: 'تم حفظ الشهادة بنجاح',
      },
      update: {
        success: 'تم حفظ الشهادة بنجاح',
      },
      destroy: {
        success: 'تم حذف الشهادة بنجاح',
      },
      regenerate: {
        success: 'تم تجديد الشهادة بنجاح',
      },
      revoke: {
        success: 'تم إبطال الشهادة بنجاح',
      },
      destroyAll: {
        success: 'تم حذف الشهادة (الشهادات) بنجاح',
      },
      edit: {
        title: 'تحرير الشهادة',
      },
      download: {
        cta: 'التحميل الان',
        confirmation: 'تأكد من استعدادك لتنزيل شهادتك والمفتاح الخاص.',
        description: 'يمكنك إلغاء الشهادة أو حذف التطبيق لاحقًا.',
      },
      fields: {
        id: 'هوية شخصية',
        tag: 'بطاقة شعار',
        issued_range: 'نشر',
        issued: 'نشر',
        valid_until_range: 'صالح حتى',
        valid_until: 'صالح حتى',
        cert_public_key: 'المفتاح العمومي',
        created_at: 'أنشئت في',
        updated_at: 'تم التحديث في',
        created_at_range: 'أنشئت في',
      },
      new: {
        title: 'شهادة جديدة',
      },
      view: {
        title: 'عرض الشهادة',
      },
      importer: {
        title: 'شهادات الاستيراد',
        fileName: 'شهادة_استيراد_قالب',
        hint: 'يجب أن تكون أعمدة الملفات / الصور عناوين URL للملفات مفصولة بمسافات.',
      },
    },
    demo: {
      name: 'تجريبي',
      label: 'تجريبي',
      menu: 'تجريبي',
      view: {
        title: 'تجريبي',
        text: {
          base: {
            subtitle: 'ابدء',
            description:
              'تعرف على كيفية بدء الدمج مع Lean. انظر كيف سيبدو الاتصال بالحسابات المصرفية للمستخدمين النهائيين بالنسبة لهم.',
            alertTitle: 'حول البيانات',
            alertDescription:
              'يتم استخدام المعلومات التي تراها في الخطوات الأخيرة بواسطة Lean لأغراض الاختبار ولا تنتمي إلى أي شخص. اتصل بنا على contact@leantech.me إذا كانت لديك أسئلة.',
            redirectApplications:
              'على استعداد للتحرك في؟ قم بزيارة قسم التطبيق لإنشاء تطبيق جديد.',
            two: 'الهدف من هذا العرض التوضيحي هو إنشاء دليل سهل المتابعة للبدء.',
            three: 'من المثالي أن تكون حالات استخدامك على النحو التالي:',
          },
          configure: {
            developer: 'مطور',
            headline:
              'الخطوة الأولى في دورة تطوير Lean هي عندما تطلب أنت ، المطور ، من المستخدم الاتصال بحسابه المصرفي.',
            subheadline:
              'تعتني وحدة الارتباط لدينا بهذا. كل ما عليك فعله هو تحديد الأذونات التي تطلبها من المستخدم ، وأخبر الرابط كيف تشير إلى المستخدم في قاعدة بيانات المستخدم الداخلية.',
            createTitle: 'إنشاء التطبيق',
            fieldName: 'اسم',
            fieldRedirectUrl: 'عاود الاتصال بالعنوان',
            fieldAppToken: 'رمز التطبيق',
            fieldAppName: 'اسم التطبيق',
            configureTitle: 'تكوين الارتباط',
            fieldPermissions: 'أذونات',
            permissions: {
              identity: 'هوية',
              balance: 'توازن',
              accounts: 'حسابات',
              transactions: 'المعاملات',
            },
            fieldAppUserID: 'معرف المستخدم الداخلي',
            fieldUserID: 'اسم المستخدم',
            appUserIDDescription:
              'كيف تشير إلى المستخدم في قاعدة البيانات الخاصة بك',
            fieldCodeSnippet: 'مقتطف الشفرة',
            userBrowser: 'متصفح المستخدم',
            developerPortal: 'بوابة مطور لين',
          },
          connect: {
            user: 'المستعمل',
            userBrowser: 'متصفح المستخدم',
            developerPortal: 'بوابة مطور لين',
          },
          callback: {
            success: 'يحدث هذا عند تقديم بيانات اعتماد المستخدم الصحيحة',
            fail: 'يحدث هذا عند تقديم بيانات اعتماد مستخدم غير صحيحة أو حدث خطأ',
            frontend: 'نهاية المقدمة',
            backend: 'الخلفية',
            response: 'يستجيب الرابط مع:',
            reception: 'يتلقى عنوان رد الاتصال الطلب التالي:',
          },
          retrieve: {
            requestTitle: 'طلب',
            requestHeaders: 'الرؤوس',
            requestBody: 'الجسم',
            responseTitle: 'استجابة',
            responseBody: 'الجسم',
            message: 'استرداد البيانات',
            description:
              'نقترح عليك أن تذهب من خلال نقاط النهاية بالترتيب المقدم. ستحتاج إلى الحصول على uuids للحسابات لإجراء / موازنة أو / إجراء مكالمات ستحتاج من أجلها لاستخدام نقطة نهاية / accounts.',
          },
          finish: {
            title: 'إنهاء',
            description:
              'مبروك للوصول إلى هذا الحد. نأمل مخلصين أن يكون لديك فهم أفضل لـ Lean وكيفية دمج تطبيقك معنا. بمجرد القيام بذلك ، ستكون على بعد خطوة واحدة من استخدام البيانات الحقيقية.',
            docs: 'يرجى الرجوع إلى وثائقنا لمعرفة المزيد.',
            migrateDescription: 'نقوم حاليًا بمراجعة الطلبات على أساس يدوي.',
            contactDetails:
              'إذا كنت تشعر أنك مستعد للهجرة ، يرجى الاتصال بنا على',
            redirectApplications:
              'على استعداد للتحرك في؟ قم بزيارة قسم التطبيق لإنشاء تطبيق جديد.',
          },
          code: {
            copy: 'تم نسخ الرمز',
          },
        },
      },
    },
    docs: {
      name: 'المستندات',
      label: 'المستندات',
      menu: 'المستندات',
      view: {
        title: 'توثيق',
      },
    },
  },

  auth: {
    profile: {
      title: 'تعديل الملف الشخصي',
      success: 'تم تحديث الملف الشخصي بنجاح',
    },
    createAnAccount: 'انشئ حساب',
    remember_me: 'تذكرنى',
    forgotPassword: 'هل نسيت كلمة المرور',
    signin: 'تسجيل الدخول',
    signup: 'سجل',
    signout: 'خروج',
    alreadyHaveAnAccount: 'هل لديك حساب؟ تسجيل الدخول.',
    signinWithAnotherAccount: 'تسجيل الدخول بحساب آخر',
    forbidden: {
      message: 'المستخدم / البريد الإلكتروني أو كلمة المرور غير صحيحة',
    },
    disabled: {
      message: 'أنت حاليا في قائمة الانتظار. يرجى التحلي بالصبر.',
      error:
        'لم يتم التحقق من صحة المستخدم أو أنه موجود بالفعل في قائمة الانتظار',
      regulation: {
        message:
          '<strong>{0}</strong> ، شكرًا لك على الاهتمام بـ Lean. نجري حاليًا محادثات مع العديد من الهيئات التنظيمية الإقليمية حتى نتمكن من العمل. نحن نقدر صبرك.',
        submit: 'اسمحوا لي أن أعرف عندما يتغير هذا.',
      },
      validation: {
        message: 'شكرًا للتسجيل في Lean!',
        description:
          'نحن نتحكم في الوصول إلى منتجاتنا حتى نتمكن من قضاء المزيد من الوقت في العمل على التعليقات الواردة من كل مستخدم من أوائل المستخدمين. لهذا السبب نضعك في قائمة الانتظار في الوقت الحالي.',
        description2:
          'بمجرد أن يكون لدينا المزيد من النطاق الترددي ، سنرسل إليك بريدًا إلكترونيًا يحتوي على كل ما تحتاجه لبدء العمل مع واجهات برمجة التطبيقات الخاصة بنا. لا يجب أن يستغرق الأمر وقتًا طويلاً!',
        redirect: 'حتى ذلك الحين ، لماذا لا تبدأ في النظر',
        cta: 'مستنداتنا.',
        social: 'تابعنا على مواقع التواصل الاجتماعي للحصول على آخر الأخبار.',
      },
    },
    emailUnverified: {
      message: 'انتهت صلاحية رسالة التحقق الخاصة بك.',
      submit: 'إعادة ارسال بريد التحقق',
    },
    resendEmail: {
      message: 'انقر هنا لإعادة إرسال رسالة التحقق الخاصة بك.',
      submessage: 'تأكد من التحقق من مجلد البريد المزعج',
      submit: 'إعادة ارسال بريد التحقق',
    },
    emptyPermissions: {
      message: 'ليس لديك أذونات حتى الآن. انتظر حتى يمنحك المشرف الامتيازات.',
    },
    passwordResetEmail: {
      message: 'إرسال بريد إلكتروني لإعادة تعيين كلمة المرور',
      error: 'لم يتم التعرف على البريد الإلكتروني',
    },
    passwordSave: {
      message: 'حفظ كلمة المرور',
    },
    passwordChange: {
      message: 'تغيير كلمة المرور',
    },
    emailAddressVerificationEmail: {
      error: 'لم يتم التعرف على البريد الإلكتروني',
    },
    signupSuccess: 'الحساب اقيم بنجاح',
    interestSuccess: 'تمت إضافة المستخدم إلى قائمة الانتظار',
    verificationEmailSuccess: 'تم إرسال رسالة التحقق بنجاح',
    passwordResetEmailSuccess:
      'تم إرسال البريد الإلكتروني لإعادة تعيين كلمة المرور بنجاح',
    passwordResetSuccess: 'تم تغيير الرقم السري بنجاح',
    verifyEmail: {
      success: 'تم التحقق من البريد الإلكتروني بنجاح',
      message: 'لحظة واحدة ، يتم التحقق من بريدك الإلكتروني ...',
      error:
        'لم يتم التحقق من بريدك الإلكتروني. انقر فوق إعادة إرسال لإعادة المحاولة',
    },
    resendEmailVerification: {
      success: 'تم إرسال رسالة التحقق بنجاح. تحقق من بريدك الوارد',
      error: 'لم يتم إرسال رسالة التحقق الخاصة بك. حاول مجددا',
    },
  },

  roles: {
    owner: {
      label: 'صاحب',
      description: 'الوصول الكامل إلى جميع الموارد',
    },
    editor: {
      label: 'محرر',
      description: 'تحرير الوصول إلى جميع الموارد',
    },
    viewer: {
      label: 'مشاهد',
      description: 'عرض الوصول إلى جميع الموارد',
    },
    auditLogViewer: {
      label: 'عارض سجل التدقيق',
      description: 'الوصول لعرض سجلات التدقيق',
    },
    iamSecurityReviewer: {
      label: 'مراجع الأمن',
      description: 'الوصول الكامل لإدارة أدوار المستخدمين',
    },
    entityEditor: {
      label: 'محرر الكيان',
      description: 'تحرير الوصول إلى جميع الكيانات',
    },
    entityViewer: {
      label: 'عارض الكيان',
      description: 'عرض الوصول إلى جميع الكيانات',
    },
    applicationEditor: {
      label: 'محرر التطبيق',
      description: 'تحرير الوصول إلى التطبيقات',
    },
    applicationViewer: {
      label: 'عارض التطبيق',
      description: 'عرض الوصول إلى التطبيقات',
    },
    certificateEditor: {
      label: 'محرر الشهادات',
      description: 'تحرير الوصول إلى الشهادات',
    },
    certificateViewer: {
      label: 'عارض الشهادة',
      description: 'عرض الوصول إلى الشهادات',
    },
  },

  iam: {
    title: 'إدارة الهوية والوصول',
    menu: 'انا',
    disable: 'تعطيل',
    disabled: 'معاق',
    enabled: 'ممكّن',
    enable: 'ممكن',
    doEnableSuccess: 'تم تمكين المستخدم بنجاح',
    doDisableSuccess: 'تم تعطيل المستخدم بنجاح',
    doDisableAllSuccess: 'تم تعطيل المستخدم (المستخدمين) بنجاح',
    doEnableAllSuccess: 'تم تمكين المستخدم (المستخدمين) بنجاح',
    doAddSuccess: 'تم حفظ المستخدم (المستخدمين) بنجاح',
    doUpdateSuccess: 'تم حفظ المستخدم بنجاح',
    viewBy: 'عرض حسب',
    users: {
      name: 'المستخدمين',
      label: 'المستخدمون',
      exporterFileName: 'users_export',
      doRemoveAllSelectedSuccess: 'تمت إزالة الأذونات بنجاح',
    },
    roles: {
      label: 'الأدوار',
      doRemoveAllSelectedSuccess: 'تمت إزالة الأذونات بنجاح',
    },
    edit: {
      title: 'تحرير العضو',
    },
    new: {
      title: 'مستخدمون جدد)',
      titleModal: 'مستخدم جديد',
      emailsHint:
        'افصل بين عناوين البريد الإلكتروني المتعددة باستخدام حرف الفاصلة.',
    },
    view: {
      title: 'عرض المستخدم',
      activity: 'نشاط',
    },
    importer: {
      title: 'استيراد مستخدمين',
      fileName: 'users_import_template',
      hint: 'يجب أن تكون أعمدة الملفات / الصور عناوين URL للملفات مفصولة بمسافات. يجب أن تكون العلاقات هي معرف السجلات المرجعية المفصولة بمسافة. يجب أن تكون الأدوار هي معرفات الأدوار مفصولة بمسافة.',
    },
    errors: {
      userAlreadyExists:
        'المستخدم الذي لديه هذا البريد الإلكتروني موجود بالفعل',
      userNotFound: 'المستخدم ليس موجود',
      disablingHimself: 'لا يمكنك تعطيل نفسك',
      revokingOwnPermission: 'لا يمكنك إلغاء إذن المالك الخاص بك',
    },
  },

  user: {
    fields: {
      id: 'هوية شخصية',
      authentication_uid: 'المصادقة Uid',
      avatars: 'الصورة الرمزية',
      email: 'البريد الإلكتروني',
      emails: 'البريد الإلكتروني',
      fullName: 'اسم',
      first_name: 'الاسم الاول',
      last_name: 'الكنية',
      organization_name: 'اسم المنظمة',
      organization_address: 'عنوان المنظمة',
      status: 'الحالة',
      disabled: 'معاق',
      phone_number: 'رقم الهاتف',
      role: 'وظيفة',
      account_type: 'حدد نوع الحساب الذي تحتاجه',
      account_type_retail: 'تجاري',
      account_type_sme: 'شركات',
      created_at: 'أنشئت في',
      updated_at: 'تم التحديث في',
      role_user: 'الدور / المستخدم',
      roles: 'الأدوار',
      created_at_range: 'أنشئت في',
      password: 'كلمه السر',
      remember_me: 'تذكرنى',
    },
    enabled: 'ممكّن',
    disabled: 'معاق',
    validations: {
      email: 'البريد الإلكتروني $ {value} غير صالح',
    },
  },

  demo: {
    menu: 'تجريبي',
    message: 'الغرض من هذا القسم هو عرض تدفق البيانات الهزيلة',
  },

  links: {
    privacy: 'سياسة الخصوصية',
    termsOfUse: 'تعليمات الاستخدام',
    responsible: 'الإفشاء المسؤول',
  },

  errors: {
    backToHome: 'العودة إلى المنزل',
    403: `آسف ، لا يمكنك الوصول إلى هذه الصفحة`,
    404: 'عفوًا ، الصفحة التي قمت بزيارتها غير موجودة',
    500: 'عذرًا ، يبلغ الخادم عن خطأ',
    forbidden: {
      message: 'ممنوع',
    },
    validation: {
      message: 'حدث خطأ',
    },
    defaultErrorMessage: 'العمليات ، حدث خطأ',
  },

  validation: {
    mixed: {
      default: '${path} غير صالح',
      required: '${path} مطلوب',
      oneOf: '${path} must be one of the following values: ${values}',
      notOneOf: '${path} must not be one of the following values: ${values}',
      notType: ({ path, type, value, originalValue }) =>
        `${path} must be a ${type}`,
    },
    string: {
      length: '${path} must be exactly ${length} characters',
      min: '${path} must be at least ${min} characters',
      max: '${path} must be at most ${max} characters',
      matches: '${path} must match the following: "${regex}"',
      email: '${path} must be a valid email',
      url: '${path} must be a valid URL',
      trim: '${path} must be a trimmed string',
      lowercase: '${path} must be a lowercase string',
      uppercase: '${path} must be a upper case string',
      selected: '${path} must be selected',
    },
    number: {
      min: '${path} must be greater than or equal to ${min}',
      max: '${path} must be less than or equal to ${max}',
      lessThan: '${path} must be less than ${less}',
      moreThan: '${path} must be greater than ${more}',
      notEqual: '${path} must be not equal to ${notEqual}',
      positive: '${path} must be a positive number',
      negative: '${path} must be a negative number',
      integer: '${path} must be an integer',
    },
    date: {
      min: '${path} field must be later than ${min}',
      max: '${path} field must be at earlier than ${max}',
    },
    boolean: {},
    object: {
      noUnknown:
        '${path} field cannot have keys not specified in the object shape',
    },
    array: {
      min: '${path} field must have at least ${min} items',
      max: '${path} field must have less than or equal to ${max} items',
    },
  },
  /* eslint-disable */
  fileUploader: {
    upload: 'Upload',
    image: 'You must upload an image',
    size: 'File is too big. Max allowed size is {0}',
    formats: `Invalid format. Must be '{0}'.`,
  },
  importer: {
    line: 'Line',
    status: 'Status',
    pending: 'Pending',
    imported: 'Imported',
    error: 'Error',
    total: `{0} imported, {1} pending and {2} with error`,
    importedMessage: `Processed {0} of {1}.`,
    noNavigateAwayMessage:
      'Do not navigate away from this page or import will be stopped.',
    completed: {
      success: 'Import completed. All rows were successfully imported.',
      someErrors:
        'Processing completed, but some rows were unable to be imported.',
      allErrors: 'Import failed. There are no valid rows.',
    },
    form: {
      downloadTemplate: 'Download the template',
      hint: 'Click or drag the file to this area to continue',
    },
    list: {
      discardConfirm: 'Are you sure? Non-imported data will be lost.',
    },
    errors: {
      invalidFileEmpty: 'The file is empty',
      invalidFileExcel: 'Only excel (.xlsx) files are allowed',
      invalidFileUpload:
        'Invalid file. Make sure you are using the last version of the template.',
      importHashRequired: 'Import hash is required',
      importHashExistent: 'Data has already been imported',
    },
  },

  autocomplete: {
    loading: 'جار التحميل...',
  },

  imagesViewer: {
    noImage: 'لا توجد صورة',
  },

  hyperlink: {
    title: 'قم بربط حسابك بـ {0}',
    getting_started: {
      title: 'ابدأ عملية التحقق من خلال البنك',
      description_personal:
        'لقد عقدت شركة {0} إتفاقية مع شركة لين للحصول على بياناتك المصرفية بشكل آمن. يرجى أن تربط حسابك البنكي (حساباتك البنكية) الذي تتلقى فيه راتبك.',
      description_business:
        '{0} قد تعاقدت مع لين التقنية للوصول بأمان إلى بيانات حسابك البنكي. يرجى ربط الحسابات البنكية المستخدمة في أنشطة عملك.',
      get_started: 'ابدأ',
    },
    bank_selection: {
      title: 'اختر البنك الخاص بك',
      subtitle: 'اختر البنك الذي تريد الاتصال به مع {0}',
      fail: 'فشل تحميل البنوك',
      request_btn: 'طلب',
    },
    request_bank: {
      title: 'طلب {0}',
      subtitle:
        'لا يمكننا ربط هذا البنك في الوقت الحالي. يرجى تجربة بنك آخر أو إبلاغ {0} لتوفير طريقة تحقق بديلة.',
      success:
        'لقد أرسلنا إشعارًا إلى {0} لتوفير طريقة تحقق بديلة. يرجى الاتصال بهم لمزيد من الدعم.',
      request_bank_btn: 'أخبر {0}',
      connect_other_bank_btn: 'الاتصال ببنك آخر',
    },
    link_account: {
      connect: {
        title: 'أربط البنك الذي تتعامل معه',
        paragraph_1_personal:
          'لتوفير خدمة التمويل، تحتاج شركة {0} لتحليل أنماط الدخل والإنفاق والادخار لديك.',
        paragraph_1_business:
          'لتوفير خدمة التمويل، يحتاج {0} إلى تحليل نمط دخلك، ونفقاتك، ومدخراتك الخاصة بعملك.',
        paragraph_2:
          'وبذلك تكون شركة {0} قادرة على تقييم أهليتك بالحصول على التمويل.',
        consent_details: {
          title: 'تفاصيل الموافقة',
          paragraph:
            'أنت تسمح لشركة لين تكنولوجيز السعودية لانظمة وتقنيات المعلومات و شركة {0} بالحصول على المعلومات الخاصة بك.',
          access_from: 'الإذن من',
          access_to: 'الإذن إلى',
          expires: 'تنتهي صلاحية الإذن في',
        },
      },
      permission: {
        title: 'ما هي البيانات التي تشاركها؟',
        account_details: {
          title: 'تفاصيل حسابك المصرفي',
          content: 'رصيد حسابك المصرفي<br/>اسم ورقم حسابك المصرفي',
        },
        account_transactions: {
          title: 'المعاملات المصرفية الخاصة بك',
          content: 'تفاصيل المعاملات المصرفية الخاصة بك',
        },
        identity: {
          title: 'تفاصيل الشخص والاطراف',
          content:
            'اسم الحساب المصرفي واسمك القانوني الكامل. أحياناً، يمكن أن يتضمن هذا أيضًا عنوانك وأرقام هواتفك وبريدك الإلكتروني ' +
            'اسم الحساب المصرفي والعنوان وتاريخ الميلاد والهوية الوطنية ورقم الإقامة<br/>اسم الحساب المصرفي والاسم (الأسماء) القانوني الكامل لجميع الأطراف. أحياناً، يمكن أن يتضمن هذا أيضًا عنوانهم أو عناوينهم وأرقام هواتفهم',
        },
        tc: {
          content: 'إذا قمت بالمتابعة، فإنك توافق',
          link_text: 'على شروط وأحكام شركة {0}.',
        },
        link_account: 'ربط الحساب المصرفي',
      },
    },
    terms_and_conditions: {
      title: 'وصول الخدمات المصرفية المفتوحة',
      continue: 'استمرار',
      content: `
        <div>
          لتقديم خدمة القروض لك، نحتاج إلى تحليل دخلك ونفقاتك وعادات التوفير الخاصة بك. بالاستمرار، فإنك توافق على السماح لـ {0} بالاتصال بحساباتك المصرفية بأمان من خلال شركة لين تكنولوجيز السعودية لتقنية نظم المعلومات (الجهة الموفرة الثالثة للخدمات المصرفية المفتوحة) (<strong>‘لين’</strong>) للخدمات المصرفية للحصول على بياناتك المالية. لين هي جهة مصرح بها في المملكة العربية السعودية وتعمل في إطار البيئة التجريبية التشريعية، حيث تخضع للتنظيم من قبل البنك المركزي السعودي، وفقًا للترخيص رقم
          ١٠١٠٦٢٢٠٩٠.
          <br />
          <br />
          سينطبق هذا الشرط عند استخدامك لخدمات لين المتعلقة بالخدمات المصرفية المفتوحة. ستستخدم {0} بياناتك فقط لغرض تقديم خدمة القروض لك. لن نشارك بياناتك المالية مع أي طرف آخر بدون إذنك. وفقًا لإطار التنظيمي للمصرفية المفتوحة في السعودية، يمكنك الاحتفاظ بالسيطرة الكاملة لسحب الاستيفاء على وصول بياناتك المالية. يمكنك الاتصال بنا في أي وقت لسحب موافقتك على وصول بياناتك المالية.
          <br />
          <br />
          عندما تسمح للين بالوصول إلى معلومات من حساباتك المصرفية المتصلة، سيُطلب منك تقديم بعض الإذنات المتعلقة بحسابك واستخدامه. تُحكم الخدمات المصرفية المفتوحة المتعلقة بلين باتفاقية للمستخدم النهائي وسياسة الخصوصية، والتي يمكن الوصول إليهما قبل توصيل أي من حساباتك المصرفية مع {0}.
          إذا كنت تشتبه في وجود وصول غير مصرح به أو ترغب في تقديم شكوى بشأن خدمات الخدمات المصرفية المفتوحة، يُرجى التواصل مع
          <a href="mailto:complaints@leantech.sa">complaints@leantech.sa</a>
           للحصول على المساعدة.
        <div>
      `,
    },
    link_successful: {
      title: 'تم ربط البنك الذي تتعامل معه بنجاح',
      subtitle: 'يمكنك ربط حساب مصرفي آخر أو إغلاق هذه النافذة.',
      connect_another_account: 'ربط حساب مصرفي آخر',
    },
    link_failed: {
      title: 'لم نتمكن من ربط البنك الذي تتعامل معه',
      subtitle:
        'الرجاء المحاولة مرة اخرى. إذا استمرت المشكلة، الرجاء الإتصال بـشركة {0}.',
      try_again: 'حاول مرة أخرى',
    },
    generic_error: {
      title: 'هناك خطأ ما',
      subtitle: 'يرجى إعادة تحميل الصفحة أو المحاولة مجددا في وقت لاحق',
    },
    bank_request_failed: {
      too_many_requests: 'هناك خطأ ما',
      already_requested: 'لقد قمت بالفعل بإرسال طلب لهذا الاتصال المصرفي.',
    },
  },
};

export default arEG;

import {
  OUTSTANDING,
  RECONCILED,
  UNRECEIVED,
} from 'view/reconciliationPage/constants';
import ReconciliationService from './reconciliationService';
import Errors from '../shared/error/errors';
import Message from '../../view/shared/message';

const prefix = 'dev-portal/reconciliation';

export const actions = {
  GET_UNRECONCILED_PAYMENTS_STARTED: `${prefix}/GET_UNRECONCILED_PAYMENTS_STARTED`,
  GET_UNRECONCILED_PAYMENTS_SUCCESS: `${prefix}/GET_UNRECONCILED_PAYMENTS_SUCCESS`,
  GET_UNRECONCILED_PAYMENTS_ERROR: `${prefix}/GET_UNRECONCILED_PAYMENTS_ERROR`,

  GET_UNRECEIVED_PAYMENTS_STARTED: `${prefix}/GET_UNRECEIVED_PAYMENTS_STARTED`,
  GET_UNRECEIVED_PAYMENTS_SUCCESS: `${prefix}/GET_UNRECEIVED_PAYMENTS_SUCCESS`,
  GET_UNRECEIVED_PAYMENTS_ERROR: `${prefix}/GET_UNRECEIVED_PAYMENTS_ERROR`,

  GET_RECONCILED_PAYMENTS_STARTED: `${prefix}/GET_RECONCILED_PAYMENTS_STARTED`,
  GET_RECONCILED_PAYMENTS_SUCCESS: `${prefix}/GET_RECONCILED_PAYMENTS_SUCCESS`,
  GET_RECONCILED_PAYMENTS_ERROR: `${prefix}/GET_RECONCILED_PAYMENTS_ERROR`,

  UPDATE_PAYMENT_STATUS_STARTED: `${prefix}/UPDATE_PAYMENT_STATUS_STARTED`,
  UPDATE_PAYMENT_STATUS_SUCCESS: `${prefix}/UPDATE_PAYMENT_STATUS_SUCCESS`,
  UPDATE_PAYMENT_STATUS_ERROR: `${prefix}/UPDATE_PAYMENT_STATUS_ERROR`,

  GET_BANKS_LIST_STARTED: `${prefix}/GET_BANKS_LIST_STARTED`,
  GET_BANKS_LIST_SUCCESS: `${prefix}/GET_BANKS_LIST_SUCCESS`,
  GET_BANKS_LIST_ERROR: `${prefix}/GET_BANKS_LIST_ERROR`,

  GET_RECONCILIATION_CONFIGURATION_STARTED: `${prefix}/GET_RECONCILIATION_CONFIGURATION_STARTED`,
  GET_RECONCILIATION_CONFIGURATION_SUCCESS: `${prefix}/GET_RECONCILIATION_CONFIGURATION_SUCCESS`,
  GET_RECONCILIATION_CONFIGURATION_ERROR: `${prefix}/GET_RECONCILIATION_CONFIGURATION_ERROR`,
  RECONCILIATION_CONFIGURATION_NOT_SET: `${prefix}/RECONCILIATION_CONFIGURATION_NOT_SET`,

  UPDATE_RECONCILIATION_CONFIGURATION_STARTED: `${prefix}/UPDATE_RECONCILIATION_CONFIGURATION_STARTED`,
  UPDATE_RECONCILIATION_CONFIGURATION_SUCCESS: `${prefix}/UPDATE_RECONCILIATION_CONFIGURATION_SUCCESS`,
  UPDATE_RECONCILIATION_CONFIGURATION_ERROR: `${prefix}/UPDATE_RECONCILIATION_CONFIGURATION_ERROR`,

  doGetUnreconciledPayments:
    (payload, appToken) => async (dispatch, getState) => {
      try {
        dispatch({
          type: actions.GET_UNRECONCILED_PAYMENTS_STARTED,
        });

        const { environment } = getState().root.global;
        const response = await ReconciliationService.getPaymentObjects({
          appToken,
          payload, // no need to add payment status as BE defaults to unreconciled
          appEnvironment: environment,
        });

        dispatch({
          type: actions.GET_UNRECONCILED_PAYMENTS_SUCCESS,
          payload: response,
        });
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: actions.GET_UNRECONCILED_PAYMENTS_ERROR,
        });
      }
    },
  doGetUnreceivedPayments:
    (payload, appToken) => async (dispatch, getState) => {
      try {
        dispatch({
          type: actions.GET_UNRECEIVED_PAYMENTS_STARTED,
        });

        // add payment status
        payload.status = [UNRECEIVED];

        const { environment } = getState().root.global;
        const response = await ReconciliationService.getPaymentObjects({
          appToken,
          payload,
          appEnvironment: environment,
        });

        dispatch({
          type: actions.GET_UNRECEIVED_PAYMENTS_SUCCESS,
          payload: response,
        });
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: actions.GET_UNRECEIVED_PAYMENTS_ERROR,
        });
      }
    },
  doGetReconciledPayments:
    (payload, appToken) => async (dispatch, getState) => {
      try {
        dispatch({
          type: actions.GET_RECONCILED_PAYMENTS_STARTED,
        });

        // add payment status
        payload.status = [RECONCILED];

        const { environment } = getState().root.global;
        const response = await ReconciliationService.getPaymentObjects({
          appToken,
          payload,
          appEnvironment: environment,
        });

        dispatch({
          type: actions.GET_RECONCILED_PAYMENTS_SUCCESS,
          payload: response,
        });
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: actions.GET_RECONCILED_PAYMENTS_ERROR,
        });
      }
    },
  doGetBanksList: appToken => async (dispatch, getState) => {
    try {
      dispatch({
        type: actions.GET_BANKS_LIST_STARTED,
      });

      const { environment } = getState().root.global;
      const data = await ReconciliationService.getBanksList({
        appToken,
        appEnvironment: environment,
      });

      dispatch({
        type: actions.GET_BANKS_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: actions.GET_BANKS_LIST_ERROR,
      });
    }
  },
  doUpdatePaymentStatus:
    ({ appToken, payload }) =>
    async (dispatch, getState) => {
      try {
        dispatch({
          type: actions.UPDATE_PAYMENT_STATUS_STARTED,
        });

        const { environment } = getState().root.global;
        await ReconciliationService.updatePaymentStatus({
          payload,
          appToken,
          appEnvironment: environment,
        });

        dispatch({
          type: actions.UPDATE_PAYMENT_STATUS_SUCCESS,
        });

        if (payload[0]?.status === RECONCILED) {
          Message.success(
            `Reconciled ${payload?.length} payment${
              payload?.length > 1 ? 's' : ''
            }`,
          );
        }
        if (payload[0]?.status === OUTSTANDING) {
          Message.success(
            `Moved ${payload?.length} payment${
              payload?.length > 1 ? 's' : ''
            } to outstanding`,
          );
        }

        if (payload[0]?.status === UNRECEIVED) {
          Message.success(
            `Moved ${payload?.length} payment${
              payload?.length > 1 ? 's' : ''
            } to unreceived`,
          );
        }
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: actions.UPDATE_PAYMENT_STATUS_ERROR,
        });
      }
    },
  doGetReconciliationConfiguration: appToken => async (dispatch, getState) => {
    try {
      dispatch({
        type: actions.GET_RECONCILIATION_CONFIGURATION_STARTED,
      });

      const { environment } = getState().root.global;
      const data = await ReconciliationService.getReconciliationConfiguration({
        appToken,
        appEnvironment: environment,
      });

      dispatch({
        type: actions.GET_RECONCILIATION_CONFIGURATION_SUCCESS,
        payload: data,
      });
    } catch (error) {
      if (error.response.status === 404) {
        dispatch({
          type: actions.RECONCILIATION_CONFIGURATION_NOT_SET,
        });

        return;
      }

      Errors.handle(error);

      dispatch({
        type: actions.GET_RECONCILIATION_CONFIGURATION_ERROR,
      });
    }
  },
  doUpdateReconciliationConfiguration:
    ({ appToken, payload }) =>
    async (dispatch, getState) => {
      try {
        dispatch({
          type: actions.UPDATE_RECONCILIATION_CONFIGURATION_STARTED,
        });

        const { environment } = getState().root.global;
        const data =
          await ReconciliationService.updateReconciliationConfiguration({
            appToken,
            payload,
            appEnvironment: environment,
          });
        dispatch({
          type: actions.UPDATE_RECONCILIATION_CONFIGURATION_SUCCESS,
          payload: data,
        });

        Message.success('Settings updated');
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: actions.UPDATE_RECONCILIATION_CONFIGURATION_ERROR,
        });
      }
    },
};

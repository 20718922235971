import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Radio from '../Radio';
import {
  $HeaderRow,
  $TooltipTitle,
  $ClearButton,
  $Options,
  $BottomRow,
  $ApplyButton,
  $TooltipContentContainer,
} from './styles';

const RadioOptionsContent = ({
  onSubmit,
  name,
  title,
  options,
  onClear,
  defaultValue,
}) => {
  const [value, setValue] = useState(defaultValue);
  const onSubmitValues = () => {
    // no option selected, don't call onSubmit
    if (!value) return;

    onSubmit({ [name]: value });
  };
  const onClearClicked = () => {
    setValue(null);
    onClear();
  };
  return (
    <$TooltipContentContainer>
      <$HeaderRow>
        <$TooltipTitle>{title}</$TooltipTitle>
        <$ClearButton
          onClick={onClearClicked}
          disabled={!value}
          data-cy="clear_button"
        >
          Clear
        </$ClearButton>
      </$HeaderRow>
      <$Options>
        <Radio
          options={options}
          name={name}
          styledProps={{ fontSize: 'xl', optionHeight: 38 }}
          value={value}
          onChange={newValue => setValue(newValue)}
        />
      </$Options>
      <$BottomRow>
        <$ApplyButton onClick={onSubmitValues} data-cy="apply_button">
          Apply
        </$ApplyButton>
      </$BottomRow>
    </$TooltipContentContainer>
  );
};

RadioOptionsContent.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ).isRequired,
  onClear: PropTypes.func.isRequired,
  defaultValue: PropTypes.string,
};

RadioOptionsContent.defaultProps = {
  defaultValue: undefined,
};

export default RadioOptionsContent;

import React from 'react';
import moment from 'moment';
import _get from 'lodash/get';
import { setLocale as setYupLocale } from 'yup';

import en from 'i18n/en';
import ar from 'i18n/ar-EG';
import {
  LANGUAGE__AR,
  LANGUAGE__DEFAULT,
  LANGUAGE__DIR_LTR,
  LANGUAGE__DIR_RTL,
  LANGUAGE__EN,
  supportedLanguages,
} from 'modules/shared/language';

let currentLanguageCode = null;

const languages = {
  en: {
    id: LANGUAGE__EN,
    label: 'English',
    flag: '/images/flags/24/United-States.png',
    dictionary: en,
    direction: LANGUAGE__DIR_LTR,
  },
  ar: {
    id: LANGUAGE__AR,
    label: 'عربى',
    flag: '/images/flags/24/Saudi-Arabia.png',
    dictionary: ar,
    direction: LANGUAGE__DIR_RTL,
  },
};

function init() {
  currentLanguageCode = localStorage.getItem('language');
  supportedLanguages.includes(currentLanguageCode) ||
    (currentLanguageCode = LANGUAGE__DEFAULT);

  setLanguageCode(currentLanguageCode);
}

function getLanguage() {
  return languages[getLanguageCode()];
}

function format(message, args) {
  if (!message) {
    return null;
  }

  try {
    return message.replace(/{(\d+)}/g, (match, number) =>
      typeof args[number] != 'undefined' ? args[number] : match,
    );
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(message, error);
    throw error;
  }
}

export function getLanguages() {
  return Object.keys(languages).map(language => languages[language]);
}

export function getLanguageDirection() {
  return getLanguage().direction;
}

export function getLanguageCode() {
  if (!currentLanguageCode) {
    init();
  }

  return currentLanguageCode;
}

export function setLanguageCode(arg) {
  if (!languages[arg]) {
    throw new Error(`Invalid language ${arg}.`);
  }

  moment.locale(arg);
  localStorage.setItem('language', arg);

  if (getLanguage().dictionary.validation) {
    setYupLocale(getLanguage().dictionary.validation);
  }
}

export function i18nExists(key) {
  const message = _get(getLanguage().dictionary, key);
  return !!message;
}

export function i18n(key, ...args) {
  const message = _get(getLanguage().dictionary, key);

  if (!message) {
    return key;
  }

  return format(message, args);
}

export function i18nHtml(key, ...args) {
  return (
    <span
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: i18n(key, ...args),
      }}
    />
  );
}

import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';

export const $Tabs = styled.div`
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral._30};
  width: fit-content;
`;

export const $Button = styled.button<{ active: boolean }>`
  position: relative;
  margin: 0;
  width: 300px;
  padding: 13px 8px;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.layout.spacing.xxs};
  color: ${({ theme }) => theme.colors.neutral._80};
  font-size: ${({ theme }) => theme.font.size.body.sm};
  line-height: 1.35;
  transition: color 0.2s;

  ${({ active }) =>
    active &&
    css`
      color: ${({ theme }) => theme.colors.black};
      background-color: ${({ theme }) => theme.colors.neutral._10};

      &::before {
        content: '';
        position: absolute;
        left: 0;
        bottom: -1px;
        width: 100%;
        height: 2px;
        background-color: ${({ theme }) => theme.colors.trust._60};
      }
    `};

  &:hover,
  &:focus {
    color: ${({ theme }) => theme.colors.black};
  }
`;

interface Props {
  options: Array<{
    value: string;
    children: ReactNode;
  }>;
  value: string;
  onChange: (value: string) => any;
  disabled?: boolean;
}

const Tabs = ({
  options,
  value,
  onChange,
  disabled = false,
  ...rest
}: Props) => {
  return (
    <$Tabs {...rest}>
      {options.map(option => (
        <$Button
          key={option.value}
          type="button"
          active={value === option.value}
          onClick={() => onChange(option.value)}
          disabled={disabled}
        >
          {option.children}
        </$Button>
      ))}
    </$Tabs>
  );
};

export default Tabs;

import styled, { css } from 'styled-components/macro';
import $Label from 'styles/components/form/Label';

const compactStyles = css`
  padding: 0 16px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral.primary};
  height: 40px;
  align-items: center;
  margin-bottom: 0;

  &:last-of-type {
    border-bottom: none;
  }

  ${$Label} {
    margin-right: 28px;
    text-align: left;
  }
`;

const horizontalStyles = css`
  ${$Label} {
    flex: 0 0 16.666667%;
    margin-right: 24px;
    max-width: 16.666667%;
    text-align: right;
  }
`;

const stackedStyles = css`
  align-items: normal;
  flex-direction: column;

  ${$Label} {
    flex: 1;
    max-width: 100%;
    margin-right: 0;
    margin-top: 0;
    margin-bottom: 8px;
    text-align: left;
  }
`;

const alignAbsoluteStyles = css`
  align-items: flex-start;

  ${$Label} {
    margin-top: 8px;
  }
`;

const alignCenterStyles = css`
  align-items: center;
`;

const $FormGroup = styled.div`
  align-items: baseline;
  display: flex;
  margin-bottom: 24px;

  ${props => props.align === 'absolute' && alignAbsoluteStyles}
  ${props => props.align === 'center' && alignCenterStyles}
  ${props => props.variant === 'compact' && compactStyles}
  ${props => props.variant === 'stacked' && stackedStyles}
  ${props => props.variant === 'horizontal' && horizontalStyles}
`;

const stackedFieldsetStyles = css`
  display: block;
`;

export const $Fieldset = styled.fieldset`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -8px;

  ${props => props.variant === 'stacked' && stackedFieldsetStyles}
`;

export default $FormGroup;

import { apiFetch } from 'modules/shared/fetch';
import { ENVIRONMENT_SANDBOX } from 'utils/constants';

export default class ApplicationService {
  static async list() {
    const response = await apiFetch('/applications', {});
    const { data } = response;

    return data.filter(application => application.active);
  }

  static async getProductionApplications() {
    const response = await apiFetch('/applications');

    return response.data;
  }

  static async getSandboxApplications() {
    const response = await apiFetch('/applications', {
      appEnvironment: ENVIRONMENT_SANDBOX,
    });

    return response.data;
  }

  static async createSandboxApplication(body) {
    const response = await apiFetch('/applications', {
      body,
      appEnvironment: ENVIRONMENT_SANDBOX,
    });

    return response.data;
  }

  static async get(id, { appEnvironment }) {
    const response = await apiFetch(`/applications/${id}`, {
      appEnvironment,
    });

    return response.data;
  }

  static async getConsents(id, { appEnvironment }) {
    const response = await apiFetch(`/applications/consents/${id}`, {
      appEnvironment,
    });

    return response.data;
  }

  static async getMockUsers(id, accountType) {
    const query = accountType ? `?account_types=${accountType}` : '';

    const response = await apiFetch(`/applications/${id}/mock-users${query}`, {
      appEnvironment: ENVIRONMENT_SANDBOX,
    });

    return response.data;
  }

  static async postMockUserAddFunds(appId, mockUserId, body) {
    const response = await apiFetch(
      `/applications/${appId}/mock-users/${mockUserId}/deposit-cash`,
      {
        body,
        appEnvironment: ENVIRONMENT_SANDBOX,
      },
    );

    return response.data;
  }

  static async postMockUserAddTransactions(appId, mockUserId, body) {
    const response = await apiFetch(
      `/applications/${appId}/mock-users/${mockUserId}/transactions`,
      {
        body,
        appEnvironment: ENVIRONMENT_SANDBOX,
      },
    );

    return response.data;
  }

  static async postMockUserExpireSession(appId, mockUserId) {
    const response = await apiFetch(
      `/applications/${appId}/mock-users/${mockUserId}/expire-session`,
      {
        method: 'POST',
        appEnvironment: ENVIRONMENT_SANDBOX,
      },
    );

    return response.data;
  }

  static async deleteMockUser(appId, mockUserId) {
    const response = await apiFetch(
      `/applications/${appId}/mock-users/${mockUserId}`,
      {
        method: 'DELETE',
        appEnvironment: ENVIRONMENT_SANDBOX,
      },
    );

    return response.data;
  }

  static async postAddMockUsers(appId, body) {
    const response = await apiFetch(`/applications/${appId}/mock-users/bulk`, {
      body,
      appEnvironment: ENVIRONMENT_SANDBOX,
    });

    return response.data;
  }

  static async postAddSMEMockUsers(appId) {
    const response = await apiFetch(`/applications/${appId}/mock-users/sme`, {
      appEnvironment: ENVIRONMENT_SANDBOX,
      method: 'POST',
    });

    return response.data;
  }

  static async update(id, data, { appEnvironment }) {
    const response = await apiFetch('/applications', {
      body: {
        id,
        ...data,
      },
      appEnvironment,
    });

    return response.data;
  }

  static async getDataRequestsLogs(appToken, body) {
    const response = await apiFetch(
      `/logs/applications/${appToken}/data-requests`,
      { body },
    );

    return response.data;
  }
}

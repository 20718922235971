import Roles from 'security/roles';
import { privateRoutes } from 'view/routes';
import { getReadScopeForPage } from 'security/scopes';
import { ENVIRONMENT_PRE_LIVE, ENVIRONMENT_PRODUCTION } from 'utils/constants';
import featureFlags from 'modules/featureFlags';

const { userRoles } = Roles;

export default class PermissionChecker {
  constructor(currentUser, scopes, environment) {
    this.currentUser = currentUser;
    this.userRoles = currentUser ? currentUser.roles : [];
    this.scopes = scopes;
    this.environment = environment;

    // Pre live environment is the same as the production environment,
    // so there is no special scope for pre-live environment
    if (environment === ENVIRONMENT_PRE_LIVE) {
      this.environment = ENVIRONMENT_PRODUCTION;
    }
  }

  check(permission) {
    if (!permission) {
      return true;
    }

    // adding current environment to the scope and then checking against that
    const permissionArray = permission.split('.');
    const permissionWithEnvironment = `${permissionArray[0]}.${this.environment}.${permissionArray[1]}`;
    return this.scopes.includes(permissionWithEnvironment);
  }

  /**
   * returns whether this page is accessible form it's pathname
   * @param {string} pathname
   * @returns {boolean}
   */
  isPageAccessibleOnEnvironment(pathname) {
    // check if user has access to the same current page on the other environment
    const currentPage = pathname.split('/')[3];
    const readScopeForCurrentPage = getReadScopeForPage(currentPage);

    return this.check(readScopeForCurrentPage);
  }

  /**
   * returns the first accessible page from the menu
   * @param {string} appToken
   * @returns {string}
   */
  getFirstAccessiblePage(appToken) {
    const accessibleRoutes = privateRoutes
      .filter(route => route.menu)
      .filter(route => {
        if (!route.featureFlag) {
          return true;
        }

        return featureFlags.isOn(route.featureFlag);
      })
      .filter(route => this.check(route.permissionRequired));
    // [0] is always the /id/:id, [1] is the first menu page item
    return accessibleRoutes[1].path.replace(':id', appToken);
  }

  /**
   * returns the parent page from a route (/id/:id/parent-page)
   * @param appToken
   * @param route
   * @returns string
   */
  getParentPage(appToken, route) {
    const parentRoute = route.split('/').slice(0, -1).join('/');

    // confirm that parent route exists, otherwise return first accessible route
    if (privateRoutes.some(route => route.path === parentRoute)) {
      return parentRoute.replace(':id', appToken);
    }

    return this.getFirstAccessiblePage(appToken);
  }

  match(permission) {
    if (!permission) {
      return true;
    }

    return this.rolesMatchOneOf(permission.allowedRoles);
  }

  rolesMatchOneOf(arg) {
    if (!this.userRoles) {
      return false;
    }

    if (!arg) {
      return false;
    }

    if (Array.isArray(arg)) {
      if (!arg.length) {
        return false;
      }

      return arg.some(role => this.userRoles.includes(role));
    }

    return this.userRoles.includes(arg);
  }

  isAdmin() {
    return this.userRoles.indexOf(userRoles.ADMIN) > -1;
  }

  isOwner() {
    return this.userRoles.indexOf(userRoles.OWNER) > -1;
  }

  get isEmptyPermissions() {
    if (!this.isAuthenticated) {
      return false;
    }

    return !this.userRoles || !this.userRoles.length;
  }

  get isDisabled() {
    return !!this.currentUser && this.currentUser.disabled;
  }

  get isAuthenticated() {
    return !!this.currentUser && !!this.currentUser.id;
  }

  get isEmailVerified() {
    if (!this.isAuthenticated) {
      return false;
    }

    return this.currentUser.email_verified;
  }
}

import { createSelector } from 'reselect';

const selectApplicationList = state => state.root.application.list;
const selectEnvironment = state => state.root.global.environment;

const selectLoading = createSelector(
  [selectApplicationList],
  list => list.loading,
);

const selectApplication = createSelector(
  selectApplicationList,
  selectEnvironment,
  (list, environment) => list && list[environment] && list[environment][0],
);

const selectApplicationId = createSelector(
  [selectApplication],
  application => application && application.id,
);

const selectSandboxApplication = createSelector(
  [selectApplicationList],
  list => list && list.sandbox && list.sandbox[0],
);

const selectSandboxApplicationId = createSelector(
  [selectSandboxApplication],
  application => application && application.id,
);

const isToggleEnvironmentPossible = createSelector(
  selectApplicationList,
  list =>
    (list.production.length > 0 || list.preLive.length > 0) &&
    list.sandbox.length > 0,
);

const selectWebhookSharedSecret = createSelector(
  [selectApplication],
  app => app && app.webhook_shared_secret,
);

const selectHasProductionApplication = createSelector(
  [selectApplicationList],
  list => list && list.production && !!list.production[0],
);

const selectHasPreLiveApplication = createSelector(
  [selectApplicationList],
  list => list && list.preLive && !!list.preLive[0],
);

const applicationListSelectors = {
  selectLoading,
  selectApplicationList,
  selectWebhookSharedSecret,
  selectApplication,
  selectSandboxApplication,
  isToggleEnvironmentPossible,
  selectHasProductionApplication,
  selectApplicationId,
  selectSandboxApplicationId,
  selectHasPreLiveApplication,
};

export default applicationListSelectors;

import config from 'config';
import { getLanguageCode } from 'i18n';

import { ENVIRONMENT_PRODUCTION, ENVIRONMENT_SANDBOX } from 'utils/constants';
import { AuthToken } from '../../auth/authToken';

const apiFetch = async (
  url,
  { body, ignoreResponseBody, ...customConfig } = {},
) => {
  const token = await AuthToken.get();

  const headers = {
    'Content-Type': 'application/json',
    'Accept-Language': getLanguageCode(),
    'lean-access-level': ENVIRONMENT_PRODUCTION,
  };

  // delete Content-Type to upload files using FormData
  // let browser set the correct boundary for Content-Type: multipart/form-data
  if (customConfig.removeContentType) {
    delete headers['Content-Type'];
  }

  const options = {
    method: customConfig.method || body ? 'POST' : 'GET',
    baseURL: config.backendUrl,
    stringifyBody: true,
    ...customConfig,
  };

  if (body !== undefined) {
    // do not stringify string payloads or requests with noStringify set to true
    if (!options.stringifyBody || typeof body === 'string') {
      options.body = body;
    } else {
      options.body = JSON.stringify(body);
    }
  }

  // remove headers if not needed
  if (options.noCustomHeader) {
    options.headers = {
      'Content-Type': 'application/json',
      ...customConfig.headers,
    };
  } else {
    options.headers = {
      ...headers,
      ...(token ? { Authorization: `Bearer ${token}` } : {}),
      ...customConfig.headers,
    };
  }

  // Add this option to endpoint if you want to use mocks for a specific endpoint when running a non-mock backend
  if (options.useLocalMockServer) {
    options.baseURL = `https://localhost:3000${url}`;
  }

  if (options.appEnvironment === ENVIRONMENT_SANDBOX) {
    options.headers['lean-access-level'] = ENVIRONMENT_SANDBOX;

    // change url to include sandbox in url if appEnvironment is sandbox.
    options.baseURL = options.baseURL.includes('localhost:3000')
      ? `${options.baseURL}/sandbox`
      : config.sandboxUrl;
  }

  const authUrl =
    options.appEnvironment === ENVIRONMENT_SANDBOX
      ? config.oauthSandboxUrl
      : config.oauthUrl;

  const resourceBaseUrl = options.authURL ? authUrl : options.baseURL;
  const resourceURL = options.noBaseURL ? url : `${resourceBaseUrl}${url}`;

  const response = await window.fetch(resourceURL, options);

  let data;
  const contentType = response.headers.get('content-type');
  if (ignoreResponseBody) {
    data = response.statusText;
  } else if (contentType && contentType.indexOf('application/json') !== -1) {
    data = await response.json();
  } else if (contentType && contentType.indexOf('application/pdf') !== -1) {
    data = await response.arrayBuffer();
  } else {
    data = await response.text();
  }

  // handle errors
  if (!response.ok) {
    const error = { response, data };

    return Promise.reject(error);
  }

  return ignoreResponseBody ? null : { data };
};

export default apiFetch;

import Roles from 'security/roles';
import { removeDuplicates } from 'utils/validators';
import { isKSACountry } from 'config/countriesEnvironments';
import { FeatureFlags } from 'modules/featureFlags';

export const errorMessages = {
  invalidEmail: (emailCount, invalidEmails) => {
    if (emailCount > 1) {
      return `Please enter valid email addresses (${invalidEmails.join(', ')})`;
    }

    return 'Please enter a valid email address';
  },
  existingMember: emails => {
    emails = removeDuplicates(emails);
    if (emails.length > 1) {
      return `${emails.join(', ')} are already members of your team`;
    }

    return `${emails[0]} is already a member of your team`;
  },
  duplicateEmails: emails => {
    emails = removeDuplicates(emails);

    if (emails.length > 1) {
      return `${emails.join(', ')} are entered multiple times`;
    }

    return `${emails[0]} is entered multiple times`;
  },
  role: 'Please select at least one role',
};

const { userRoles } = Roles;

export const memberRoles = [
  {
    label: 'Owner',
    name: userRoles.OWNER,
    value: userRoles.OWNER,
    description: `Owners have full access to view and modify all configurations and data. Only one Owner is allowed; assigning a new Owner removes the role from you. ${
      isKSACountry
        ? 'Identity verification is required for regulatory compliance.'
        : ''
    }`,
    featureFlag: FeatureFlags.rolloutOwnerRole,
  },
  {
    label: 'Admin',
    name: userRoles.ADMIN,
    value: userRoles.ADMIN,
    description:
      'Same access as the application owner with full view and edit privileges to all sections and data within the application.',
  },
  {
    label: 'Compliance',
    name: userRoles.COMPLIANCE,
    value: userRoles.COMPLIANCE,
    description:
      'View access to all the data and payments monitoring sections and edit privileges for the application settings and bank connections.',
  },
  {
    label: 'Financial controller',
    name: userRoles.PAYOUTS,
    value: userRoles.PAYOUTS,
    description:
      'Access to the Payouts functionality to view queued payments and authorize payouts from a connected corporate bank account.',
    featureFlag: FeatureFlags.payoutsPage,
  },
  {
    label: 'Finance',
    name: userRoles.FINANCE,
    value: userRoles.FINANCE,
    description:
      'Access to view all data logs and billing records required for financial reports, reconciliation, and spending tracking.',
  },
  {
    label: 'Lead Developer',
    name: userRoles.LEAD_DEVELOPER,
    value: userRoles.LEAD_DEVELOPER,
    description:
      'Access to relevant application settings and data monitoring required during the integration and debugging process.',
  },
  {
    label: 'Developer',
    name: userRoles.DEVELOPER,
    value: userRoles.DEVELOPER,
    description:
      'Access to limited application data and restricted data monitoring required during development and debugging process.',
  },
  {
    label: 'Support',
    name: userRoles.SUPPORT,
    value: userRoles.SUPPORT,
    description:
      'Access to limited data logs required to follow up a customer’s dispute, or assist during a troubleshooting request.',
  },
  {
    label: 'Operations',
    name: userRoles.OPERATIONS,
    value: userRoles.OPERATIONS,
    description: 'Access to customer data, including Reports.',
    featureFlag: FeatureFlags.reportsPage,
  },
];

export const noMatchingResult = [
  {
    user: { id: '', inviteId: '', name: '', email: '', isActivated: false, expiration_date: '' },
    roles: [],
    'last login': '',
  },
];

const getRoleLabelByValue = role =>
  memberRoles.find(m => m.value === role)?.label;

export const setRolesLabels = roles => roles.map(r => getRoleLabelByValue(r));

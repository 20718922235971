const en = {
  common: {
    or: 'or',
    cancel: 'Cancel',
    reset: 'Reset',
    save: 'Save',
    update: 'Update',
    return: 'Return',
    confirm: 'Confirm',
    create: 'Create',
    search: 'Search',
    edit: 'Edit',
    remove: 'Remove',
    back: 'Back',
    continue: 'Continue',
    copy: 'Copy',
    run: 'Run',
    start: 'Start',
    finish: 'Finish',
    try: 'Try',
    demo: 'Demo',
    new: 'New',
    export: 'Export to Excel',
    noDataToExport: 'No data to export',
    import: 'Import',
    discard: 'Discard',
    yes: 'Yes',
    no: 'No',
    pause: 'Pause',
    areYouSure: 'Are you sure?',
    view: 'View',
    destroy: 'Delete',
    activate: 'Activate',
    deactivate: 'Deactivate',
    active: 'Active',
    inactive: 'Inactive',
    revoke: 'Revoke',
    newCert: 'New Certificate',
    certChain: 'Cert Chain',
    contact: 'Contact us',
    help: 'Connect with us',
    documentation: 'Documentation',
    fulldocumentation: 'Full Documentation',
    mustSelectARow: 'Must select a row',
  },

  time: {
    hour: 'Hour',
    day: 'Day',
    week: 'Week',
    month: 'Month',
    year: 'Year',
    today: 'Today',
    months: {
      1: 'January',
      2: 'February',
      3: 'March',
      4: 'April',
      5: 'May',
      6: 'June',
      7: 'July',
      8: 'August',
      9: 'September',
      10: 'October',
      11: 'November',
      12: 'December',
    },
  },

  social: {
    twitter: 'Twitter',
    linkedin: 'Linkedin',
  },

  app: {
    title: 'Lean',
  },

  entities: {
    oauthSecret: {
      view: {
        confirmation: 'Are you sure you want to continue?',
        description:
          'Continuing will invalidate the existing secret and any associated tokens that have been issued.',
      },
    },
    application: {
      name: 'application',
      label: 'Applications',
      menu: 'Applications',
      exporterFileName: 'application_export',
      tabs: {
        overview: 'Overview',
        logs: 'Logs',
        settings: 'Settings',
        activity: 'Activity',
      },
      list: {
        menu: 'Applications',
        title: 'Applications',
      },
      create: {
        success: 'Application saved successfully',
      },
      update: {
        success: 'Application updated successfully',
      },

      destroy: {
        tooltip: 'Delete the application from your account.',
        success: 'Application deleted successfully',
      },
      toggle: {
        success: 'Application deactivated successfully',
      },
      destroyAll: {
        success: 'Application(s) deleted successfully',
      },
      edit: {
        tooltip: 'Update the application details.',
        title: 'Edit Application',
      },
      overview: {
        summary: 'Summary',
        logs: 'Logs',
        activeUsers: 'Active Users',
        totalRequests: 'Total Requests',
        successfulRequests: 'Successful requests',
        failedRequests: 'Failed requests',
        export: 'Export data',
        filters: {
          filters: 'Filters',
          noFilters: 'Filters',
          latest: 'Latest',
          dataType: 'Data Type',
          status: 'Status',
          errorType: 'Error Type',
          institution: 'Institution',
          timeframe: 'Timeframe',
          clear: 'Clear filters',
          update: 'Update filters',
        },
        log: {
          dateRange: 'Date range',
          start: 'Start',
          end: 'End',
          filter: 'Filter',
          timestamp: 'Timestamp',
          requestId: 'Request ID',
          type: 'Type',
          status: 'Status',
          errorMessage: 'Error Message',
        },
      },
      form: {
        alertTitle: 'Heads Up!',
        alert:
          'You will be prompted to download and keep safe your private key and certificate. For security reasons, we DO NOT keep them.  Losing them will prevent you or your team further access to this app',
        name: 'Fintech App',
        description:
          'Fintech App is a P2P lending and personal finance management platform.',
        link_redirect_url: 'https://fintech.app/auth/users/redirect',
        permissions: 'Identity, Account, Balance, Transactions',
        loading: 'Loading...',
      },
      fields: {
        id: 'Id',
        name: 'Application name',
        arabic_name: 'Arabic application name',
        legal_name: 'Legal name',
        arabic_legal_name: 'Arabic legal name',
        description: 'Description',
        logo_url: 'Logo',
        active: 'Active',
        cert_public_key: 'Public Key',
        link_redirect_url: 'Callback URL',
        token: 'App Token',
        permissions: 'Permissions',
        common_name: 'Common Name',
        created_at: 'Created at',
        updated_at: 'Updated at',
        created_at_range: 'Created at',
      },
      placeholders: {
        id: 'Id',
        name: 'Application name',
        arabic_name: 'Arabic application name',
        legal_name: 'Legal name',
        arabic_legal_name: 'Arabic legal name',
        description: 'Describe your app',
        logo_url: 'Logo',
        active: 'Active',
        cert_public_key: 'Public Key',
        link_redirect_url: 'Enter the callback URL',
        token: 'App Token',
        permissions: 'Select your App permissions',
        common_name: 'Common Name',
        created_at: 'Created at',
        updated_at: 'Updated at',
        created_at_range: 'Created at',
      },
      tooltip: {
        name: 'The name of the application. This will be displayed in the Link SDK for your end-users',
        description:
          'A summary of the application. This will be displayed in the Link SDK for your end-users',
        active: 'Toggle the state of the application to be active or unactive.',
        cert_public_key:
          'The Public Key used to validate the Certificate of the Application',
        token: 'The application token used by the API request headers.',
        logo_url:
          'The application logo or image to be displayed in the Link SDK widgets.',
        link_redirect_url:
          'The Callback URL that Lean will call after a end-user authenticates.',
        permissions: 'The permissions requested to Lean by the app',
        common_name: 'The application name used by the API request headers.',
        userId: 'The internal user Identifier. Used by the developer.',
        codeSnippet:
          'An example snippet of how the Lean Link SDK looks for the Web.',
        copyCode: 'Copy to clipboard',
        created_at: 'Created at',
        updated_at: 'Updated at',
        created_at_range: 'Created at',
      },
      enumerators: {},
      new: {
        title: 'New Application',
      },
      view: {
        title: 'View Application',
        testURL: 'Test URL',
      },
      importer: {
        title: 'Import Applications',
        fileName: 'application_import_template',
        hint: 'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    certificate: {
      name: 'certificate',
      label: 'Certificates',
      menu: 'Certificates',
      exporterFileName: 'certificate_export',
      list: {
        menu: 'Certificates',
        title: 'Certificates',
      },
      create: {
        success: 'Certificate saved successfully',
      },
      update: {
        success: 'Certificate saved successfully',
      },
      destroy: {
        success: 'Certificate deleted successfully',
      },
      regenerate: {
        tooltip: 'Create a new certificate.',
        success: 'Certificate created  successfully',
      },
      certChain: {
        tooltip:
          'Retrieve Lean Public Certificate Chain. For client authentication.',
        success: 'Public Cert Chain retrieved successfully',
      },
      revoke: {
        tooltip:
          'Revoke the application certificate. This will block all activity.',
        success: 'Certificate revoked successfully',
      },
      destroyAll: {
        success: 'Certificate(s) deleted successfully',
      },
      edit: {
        title: 'Edit Certificate',
      },

      download: {
        cta: 'Download Now',
        confirmation:
          'Confirm you are ready to download your certificate and private key.',
        description:
          'You can revoke the certificate or delete the application later.',
        certChain:
          "Confirm you are ready to download Lean's Public certificate chain.",
      },

      fields: {
        id: 'Id',
        tag: 'Tag',
        issued_range: 'Issued',
        issued: 'Issued',
        valid_until_range: 'Valid Until',
        valid_until: 'Valid Until',
        cert_public_key: 'Public Key',
        created_at: 'Created at',
        updated_at: 'Updated at',
        created_at_range: 'Created at',
      },
      enumerators: {},
      new: {
        title: 'New Certificate',
      },
      view: {
        title: 'View Certificate',
      },
      importer: {
        title: 'Import Certificates',
        fileName: 'certificate_import_template',
        hint: 'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    demo: {
      name: 'demo',
      label: 'Demo',
      menu: 'Demo',
      tabs: {
        overview: 'Overview',
        logs: 'Logs',
        settings: 'Settings',
        activity: 'Activity',
      },
      view: {
        title: 'Demo',
        text: {
          base: {
            subtitle: 'Getting Started',
            description:
              "Learn how to get started integrating with Lean. See how does connecting to your end users' bank accounts will look like for them.",
            alertTitle: 'About the data',
            alertDescription:
              'The information you see in the last steps, is used by Lean for testing purposes and does not belong to anyone. Contact us at contact@leantech.me if you have questions.',
            redirectApplications:
              'Ready to move on? Visit the application section to create a new application.',
            two: 'The objective of this demo is to create a easy-to-follow guide to get started.',
            three: 'Ideally your use cases will likely be:',
          },
          configure: {
            developer: 'Developer',
            headline:
              'The first step in Lean’s dev cycle is when you, the developer, ask your user to connect with their bank account.',
            subheadline:
              'Our Link module takes care of this. All you need to do is select the permissions you’re asking the user for, and tell the Link how you refer to the user in your internal user database.',
            createTitle: 'Create Application',
            fieldName: 'Name',
            fieldRedirectUrl: 'Callback URL',
            fieldAppToken: 'App Token',
            fieldAppName: 'App Name',
            configureTitle: 'Configure Link',
            fieldPermissions: 'Permissions',
            permissions: {
              identity: 'Identity',
              balance: 'Balance',
              accounts: 'Accounts',
              transactions: 'Transactions',
            },
            fieldAppUserID: 'Internal User ID',
            fieldUserID: 'Username',
            appUserIDDescription:
              'How do you refer to the user in your database',
            fieldCodeSnippet: 'Code Snippet',
            userBrowser: 'User Browser',
            developerPortal: 'Lean Developer Portal',
          },
          connect: {
            user: 'User',
            userBrowser: 'User Browser',
            developerPortal: 'Lean Developer Portal',
          },
          callback: {
            success: 'This happens when correct user credentials are provided',
            fail: 'This happens when incorrect user credentials are provided or there was an error',
            frontend: 'Frontend',
            backend: 'Backend',
            response: 'Link responds with:',
            reception: 'The callback url receives the following request:  ',
          },
          retrieve: {
            permissions: {
              identity: 'Identity',
              balance: 'Balance',
              accounts: 'Accounts',
              transactions: 'Transactions',
            },
            requestTitle: 'Request',
            requestHeaders: 'Headers',
            requestBody: 'Body',
            responseTitle: 'Response',
            responseBody: 'Body',
            message: 'Retrieve data',
            description:
              'We suggest you go through the endpoints in the order they’re presented. You’ll need to get account uuids to make /balance or /transactions calls for which you’re going to need to use the /accounts endpoint.',
          },
          finish: {
            title: 'Finish',
            description:
              'Congratulations on reaching this far. We sincerelly hope you have a better understanding of Lean and how to integrate your application with us. Once you do, you will be one step away from using real data.',
            docs: 'Please refer to our documentation to learn more.',
            migrateDescription:
              'We are currently reviewing applications on a manual basis.',
            contactDetails:
              'If your feel you are ready to migrate, please contact us at',
            redirectApplications:
              'Ready to move on? Visit the application section to create a new application.',
          },
          code: {
            copy: 'Code copied',
          },
        },
      },
    },

    docs: {
      name: 'docs',
      label: 'Docs',
      menu: 'Docs',
      view: {
        title: 'Documentation',
      },
    },
  },

  auth: {
    profile: {
      title: 'Edit Profile',
      success: 'Profile updated successfully',
    },
    account: {
      update: {
        success: 'Your settings were saved.',
      },
    },
    createAnAccount: 'Create an account',
    remember_me: 'Remember me',
    forgotPassword: 'Forgot password',
    signin: 'Sign in',
    signup: 'Sign up',
    signout: 'Sign out',

    alreadyHaveAnAccount: 'Already have an account? Sign in.',
    signinWithAnotherAccount: 'Sign in with another account',
    forbidden: {
      message: 'Incorrect user/email or password',
    },
    disabled: {
      message: 'You are currently on the waitlist. Please be patient.',
      error: 'User not validated or already on the waitlist',
      regulation: {
        message:
          '<strong>{0}</strong>, Thank you for taking the interest in Lean. We are currently in talks with several regional regulatory bodies in order be able to operate. We appreciate your patience.',
        submit: 'Let me know when this changes.',
      },
      validation: {
        message: 'Thank you for registering with Lean!',
        description:
          'We’re controlling access to our product so that we can spend more time working on the feedback from every one of our early users. That’s why we’re putting you on a waitlist for now. ',
        description2:
          'As soon as we have some more bandwidth, we’ll send you an email with everything you need to start working with our APIs. It shouldn’t take too long!',
        redirect: 'Till then, why don’t you start looking at',
        cta: 'our docs.',
        social: 'Follow us on our social media for the latest news.',
      },
    },
    emailUnverified: {
      message: 'Your verification email expired.',
      submit: 'Resend verification email',
    },
    resendEmail: {
      message: 'Click here to resend your verification email.',
      submessage: 'Make sure to check the spam folder',
      submit: 'Resend verification email',
    },
    emptyPermissions: {
      message:
        'You have no permissions yet. Wait for the admin to grant you privileges.',
    },
    passwordResetEmail: {
      message: 'Send password reset email',
      error: 'Email not recognized',
    },
    passwordSave: {
      message: 'Save password',
    },
    passwordChange: {
      message: 'Change password',
    },
    emailAddressVerificationEmail: {
      error: 'Email not recognized',
    },
    signupSuccess: 'Account created successfully',
    interestSuccess: 'User added to waitlist',
    verificationEmailSuccess: 'Verification email sent successfully',
    passwordResetEmailSuccess: 'Password reset email sent successfully',
    passwordResetSuccess: 'Password changed successfully',
    verifyEmail: {
      success: 'Email successfully verified',
      message: 'Just a moment, your email is being verified...',
      error: 'Your email was not verified. Click on resend to try again',
    },
    resendEmailVerification: {
      success: 'Verification email successfully sent. Check your inbox',
      error: 'Your verification email was not sent. Try again',
    },
  },

  roles: {
    owner: {
      label: 'Owner',
      description: 'Full access to all resources',
    },
    editor: {
      label: 'Editor',
      description: 'Edit access to all resources',
    },
    viewer: {
      label: 'Viewer',
      description: 'View access to all resources',
    },
    auditLogViewer: {
      label: 'Audit Log Viewer',
      description: 'Access to view audit logs',
    },
    iamSecurityReviewer: {
      label: 'Security Reviewer',
      description: 'Full access to manage users roles',
    },
    entityEditor: {
      label: 'Entity Editor',
      description: 'Edit access to all entities',
    },
    entityViewer: {
      label: 'Entity Viewer',
      description: 'View access to all entities',
    },
    applicationEditor: {
      label: 'Application Editor',
      description: 'Edit access to Applications',
    },
    applicationViewer: {
      label: 'Application Viewer',
      description: 'View access to Applications',
    },
    certificateEditor: {
      label: 'Certificate Editor',
      description: 'Edit access to Certificates',
    },
    certificateViewer: {
      label: 'Certificate Viewer',
      description: 'View access to Certificates',
    },
  },

  iam: {
    title: 'Identity and Access Management',
    menu: 'IAM',
    disable: 'Disable',
    disabled: 'Disabled',
    enabled: 'Enabled',
    enable: 'Enable',
    doEnableSuccess: 'User enabled successfully',
    doDisableSuccess: 'User disabled successfully',
    doDisableAllSuccess: 'User(s) disabled successfully',
    doEnableAllSuccess: 'User(s) enabled successfully',
    doAddSuccess: 'User(s) saved successfully',
    doUpdateSuccess: 'User saved successfully',
    viewBy: 'View By',
    users: {
      name: 'users',
      label: 'Users',
      exporterFileName: 'users_export',
      doRemoveAllSelectedSuccess: 'Permissions removed successfully',
    },
    roles: {
      label: 'Roles',
      doRemoveAllSelectedSuccess: 'Permissions removed successfully',
    },
    edit: {
      title: 'Edit User',
    },
    new: {
      title: 'New User(s)',
      titleModal: 'New User',
      emailsHint:
        'Separate multiple email addresses using the comma character.',
    },
    view: {
      title: 'View User',
      activity: 'Activity',
    },
    importer: {
      title: 'Import Users',
      fileName: 'users_import_template',
      hint: 'Files/Images columns must be the URLs of the files separated by space. Relationships must be the ID of the referenced records separated by space. Roles must be the role ids separated by space.',
    },
    errors: {
      userAlreadyExists: 'User with this email already exists',
      userNotFound: 'User not found',
      disablingHimself: 'You can not disable yourself',
      revokingOwnPermission: 'You can not revoke your own owner permission',
    },
  },

  user: {
    fields: {
      id: 'Id',
      authentication_uid: 'Authentication Uid',
      avatars: 'Avatar',
      email: 'Email',
      emails: 'Email(s)',
      fullName: 'Name',
      first_name: 'First name',
      last_name: 'Last name',
      organization_name: 'Company name',
      organization_address: 'Company address',
      status: 'Status',
      disabled: 'Disabled',
      phone_number: 'Phone number',
      role: 'Role',
      created_at: 'Created at',
      updated_at: 'Updated at',
      role_user: 'Role/User',
      roles: 'Roles',
      created_at_range: 'Created at',
      password: 'Password',
      confirm_password: 'Confirm password',
      tncs: 'I accept the ',
      account_type: 'What type of customers do you serve?',
      account_type_retail: 'Individuals',
      account_type_sme: 'Businesses',
      remember_me: 'Remember me',
      country: 'Where are you based?',
      country_default_value: 'Select your country',
      build_description: 'What are you building?',
      organization_size: 'Company size',
      organization_size_default_value: 'Select the size of your company',
      vocation: 'What is your role?',
      vocation_default_value: 'Choose the role you have in your company',
      acquisition: 'How did you hear about Lean?',
      interested_features: 'What features are you interested in?',
      new_password: 'Enter your new password',
      confirm_new_password: 'Confirm your new password',
    },
    enabled: 'Enabled',
    disabled: 'Disabled',
    validations: {
      // eslint-disable-next-line
      email: 'Email ${value} is invalid',
    },
  },

  demo: {
    menu: 'Demo',
    message:
      'The purpose of this section is to showcase the flow of Lean Data & Link API in action.',
  },

  links: {
    privacy: 'Privacy',
    termsOfUse: 'Terms of use',
    responsible: 'Responsible disclosure',
  },

  auditLog: {
    menu: 'Audit Logs',
    title: 'Audit Logs',
    exporterFileName: 'audit_log_export',
    entityNamesHint: 'Separate multiple entities using the comma character.',
    fields: {
      id: 'Id',
      timestampRange: 'Period',
      entityName: 'Entity',
      entityNames: 'Entities',
      entityId: 'Entity ID',
      action: 'Action',
      values: 'Values',
      timestamp: 'Date',
      createdByEmail: 'User Email',
    },
  },

  settings: {
    title: 'Settings',
    menu: 'Settings',
    save: {
      success:
        'Settings saved successfully. The page will reload in {0} seconds for changes to take effect.',
    },
    fields: {
      theme: 'Theme',
    },
    colors: {
      default: 'Default',
      cyan: 'Cyan',
      'geek-blue': 'Geek Blue',
      gold: 'Gold',
      lime: 'Lime',
      magenta: 'Magenta',
      orange: 'Orange',
      'polar-green': 'Polar Green',
      purple: 'Purple',
      red: 'Red',
      volcano: 'Volcano',
      yellow: 'Yellow',
    },
  },

  home: {
    menu: 'Home',
    message:
      'This page uses fake data for demonstration purposes only. You can edit it at frontend/view/home/HomePage.js.',
    charts: {
      day: 'Day',
      red: 'Red',
      green: 'Green',
      yellow: 'Yellow',
      grey: 'Grey',
      blue: 'Blue',
      orange: 'Orange',
      months: {
        1: 'January',
        2: 'February',
        3: 'March',
        4: 'April',
        5: 'May',
        6: 'June',
        7: 'July',
      },
      eating: 'Eating',
      drinking: 'Drinking',
      sleeping: 'Sleeping',
      designing: 'Designing',
      coding: 'Coding',
      cycling: 'Cycling',
      running: 'Running',
      customer: 'Customer',
    },
  },

  errors: {
    backToHome: 'Back to home',
    403: 'Sorry, you do not have access to this page',
    404: 'Sorry, the page you visited does not exist',
    500: 'Sorry, the server is reporting an error',
    forbidden: {
      message: 'Forbidden',
    },
    validation: {
      message: 'An error occurred',
    },
    defaultErrorMessage: 'An error occurred',
  },
  // See https://github.com/jquense/yup#using-a-custom-locale-dictionary
  /* eslint-disable */
  validation: {
    mixed: {
      default: '${path} is invalid',
      required: '${path} is required',
      requiredCheckbox: 'This field is required',
      oneOf: '${path} must be one of the following values: ${values}',
      notOneOf: '${path} must not be one of the following values: ${values}',
      // notType: ({ path, type, value, originalValue }) => {
      //   return '${path} must be a ${type}';
      // },
    },
    string: {
      length: '${path} must be exactly ${length} characters',
      min: '${path} must be at least ${min} characters',
      max: '${path} must be at most ${max} characters',
      // matches: '${path} must match the following: "${regex}"',
      matches: data => {
        const numbersOnlyRegex = /^\d+$/;

        if (data.path === 'Password') {
          return 'Ensure your password has a minimum of 10 characters, at least 1 digit, 1 uppercase, 1 lowercase and 1 special character.';
        } else if (
          data.regex &&
          data.regex.toString() === numbersOnlyRegex.toString()
        ) {
          return `Only numbers are allowed`;
        } else {
          // currently for text fields in signup forms
          return `Only letters, numbers and these special characters ! . , - _ " ' ? are allowed`;
        }
      },
      email: '${path} must be a valid email',
      url: '${path} must be a valid URL',
      trim: '${path} must be a trimmed string',
      lowercase: '${path} must be a lowercase string',
      uppercase: '${path} must be a upper case string',
      selected: '${path} must be selected',
    },
    number: {
      min: '${path} must be greater than or equal to ${min}',
      max: '${path} must be less than or equal to ${max}',
      lessThan: '${path} must be less than ${less}',
      moreThan: '${path} must be greater than ${more}',
      notEqual: '${path} must be not equal to ${notEqual}',
      positive: '${path} must be a positive number',
      negative: '${path} must be a negative number',
      integer: '${path} must be an integer',
    },
    date: {
      min: '${path} field must be later than ${min}',
      max: '${path} field must be at earlier than ${max}',
    },
    boolean: {},
    object: {
      noUnknown:
        '${path} field cannot have keys not specified in the object shape',
    },
    array: {
      min: '${path} field must have at least ${min} items',
      max: '${path} field must have less than or equal to ${max} items',
    },
  },
  /* eslint-disable */
  fileUploader: {
    upload: 'Upload',
    image: 'You must upload an image',
    size: 'File is too big. Max allowed size is {0}',
    formats: `Invalid format. Must be '{0}'.`,
  },
  importer: {
    line: 'Line',
    status: 'Status',
    pending: 'Pending',
    imported: 'Imported',
    error: 'Error',
    total: '{0} imported, {1} pending and {2} with error',
    importedMessage: 'Processed {0} of {1}.',
    noNavigateAwayMessage:
      'Do not navigate away from this page or import will be stopped.',
    completed: {
      success: 'Import completed. All rows were successfully imported.',
      someErrors:
        'Processing completed, but some rows were unable to be imported.',
      allErrors: 'Import failed. There are no valid rows.',
    },
    form: {
      downloadTemplate: 'Download the template',
      hint: 'Click or drag the file to this area to continue',
    },
    list: {
      discardConfirm: 'Are you sure? Non-imported data will be lost.',
    },
    errors: {
      invalidFileEmpty: 'The file is empty',
      invalidFileExcel: 'Only excel (.xlsx) files are allowed',
      invalidFileUpload:
        'Invalid file. Make sure you are using the last version of the template.',
      importHashRequired: 'Import hash is required',
      importHashExistent: 'Data has already been imported',
    },
  },

  autocomplete: {
    loading: 'Loading...',
  },

  imagesViewer: {
    noImage: 'No image',
  },
  webhooks: {
    resendWebhook: {
      success: 'Webhook sent',
      failure: 'Webhook was not sent, please try again later',
    },
  },
  team: {
    members: {
      invite_members: {
        add_members: 'Add members',
        add_new_members: 'Add new members',
        success: 'Your invite(s) have been sent.',
      },
      cancel_invite: {
        success: 'Invite canceled successfully',
      },
      update_member: {
        success: 'Member updated successfully.',
      },
      remove_member: {
        success: 'Member removed successfully.',
      },
    },
  },
  organisation: {
    activation: {
      success: 'Organisation Owner verified successfully',
    },
  },
  hyperlink: {
    title: 'Connect your Account to {0}',
    getting_started: {
      title: 'Start a bank check',
      description_personal:
        '{0} has partnered with Lean to securely access your bank data. Please connect the bank account(s) where you receive your salary.',
      description_business:
        '{0} has partnered with Lean to securely access your bank data. Please connect the bank account(s) used for your business activities.',
      get_started: 'Get started',
    },
    bank_selection: {
      title: 'Select your bank',
      subtitle: 'Choose which bank to connect with {0}',
      fail: 'Banks failed to load',
      request_btn: 'Request',
    },
    request_bank: {
      title: 'Request {0}',
      subtitle:
        "We can't connect to this bank right now. Please try another bank or notify {0} to provide an alternative verification method.",
      success:
        'We have notified {0} to provide an alternative verification method. Please contact them for further support.',
      request_bank_btn: 'Notify {0}',
      connect_other_bank_btn: 'Connect other bank',
    },
    link_account: {
      connect: {
        title: 'Connect your bank account',
        paragraph_1_personal:
          'To provide a lending service {0} needs to analyze your income, spending and savings patterns.',
        paragraph_1_business:
          'To provide a lending service {0} needs to analyse your business income, spending and savings patterns.',
        paragraph_2:
          'In return {0} will be able to assess your eligibility for your loan application.',
        consent_details: {
          title: 'Consent Details',
          paragraph:
            'You are consenting to Lean Technologies Saudi for Technology and Information Systems and {0} to access your data',
          access_from: 'Access from',
          access_to: 'Access to',
          expires: 'Expires',
        },
      },
      permission: {
        title: 'What data are you sharing?',
        account_details: {
          title: 'Your Account Details',
          content: 'Your account name and number<br />Your account balance',
        },
        account_transactions: {
          title: 'Your Account Transactions',
          content: 'Details of your transactions',
        },
        identity: {
          title: 'Contact and party details',
          content:
            'The name of the account, address, date of birth, national ID and residency number<br />The name of the account and the full legal name(s) of all parties. Optionally this can also include their address or addresses, telephone',
        },
        tc: {
          content: "By continuing you agree to {0}'s",
          link_text: 'Terms & Conditions',
        },
        link_account: 'Link account',
      },
    },
    terms_and_conditions: {
      title: 'Open Banking Access',
      continue: 'Continue',
      content: `
        <div>
          To provide you with a lending service, we need to analyze your
          income, spending and savings habits. By continuing, you agree to
          allow {0} to securely connect to your bank accounts through
          Lean Technologies Saudi for Information Technology Company (our Open
          Banking Sponsor Third Party Provider) (<strong>‘Lean’</strong>) to
          obtain your financial data. Lean is a Permitted Fintech in Saudi
          Arabia regulated by the Saudi Central Bank to operate within the
          Regulatory Sandbox, under license no. 1010622090.
          <br />
          <br />
          These terms will apply where you utilize Lean’s Open Banking related
          services. {0} will use your data solely for the purpose of
          providing you with a lending service. We will not share your
          financial data with any other party without your permission. Under
          the Saudi Open Banking framework, you retain full control to revoke
          consent to access your financial data. You may contact us at any
          time to revoke your consent to access your financial data.
          <br />
          <br />
          When you permit Lean to access information from your connected bank
          accounts, you will be asked to provide them with certain permissions
          related to your account and it’s usage. The Open Banking related
          services provided by Lean are governed by a separate End-user
          Agreement and Privacy Policy which are accessible prior to
          connecting any of your bank accounts with {0}.
          <br />
          <br />
          If you suspect unauthorized access or wish to raise a complaint
          regarding the Open Banking services, contact <a href="mailto:complaints@leantech.sa">complaints@leantech.sa</a> for assistance.
        </div>
      `,
    },
    link_successful: {
      title: 'Your bank was successfully connected',
      subtitle: 'You can connect another account or close this window.',
      connect_another_account: 'Connect another account',
    },
    link_failed: {
      title: "We couldn't connect your bank",
      subtitle:
        'Please try again. If the problems persists, get in touch with {0}.',
      try_again: 'Try again',
    },
    generic_error: {
      title: 'Something went wrong',
      subtitle: 'Please reload the page or try again later.',
    },
    bank_request_failed: {
      too_many_requests: 'Something went wrong',
      already_requested:
        'You have already submitted a request for this bank connection.',
    },
  },
};

export default en;

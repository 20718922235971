import { columns } from 'modules/reconciliation';

export const SORT_ASCENDING = 'ASC';
export const SORT_DESCENDING = 'DESC';

export const sortTableByColumnName = ({ data, column, direction }) =>
  [...data].sort((a, b) => {
    let valueA =
      typeof a[column] === 'string'
        ? a[column].toLowerCase().trim()
        : a[column];
    let valueB =
      typeof b[column] === 'string'
        ? b[column].toLowerCase().trim()
        : b[column];

    if (column === columns.AMOUNT) {
      const [, amountA] = a[column].split(' ');
      const [, amountB] = b[column].split(' ');

      valueA = parseFloat(amountA.replaceAll(',', ''));
      valueB = parseFloat(amountB.replaceAll(',', ''));
    }

    // equal items sort equally
    if (valueA === valueB) return 0;

    // nulls and empty values sorted at the end
    if (!valueA) return 1;
    if (!valueB) return -1;

    if (direction === SORT_DESCENDING) {
      // sort descending
      return valueA < valueB ? 1 : -1;
    }

    // sort ascending
    return valueA < valueB ? -1 : 1;
  });

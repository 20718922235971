import Errors from '../shared/error/errors';
import OrganisationService from './organisationService';
import Message from '../../view/shared/message';
import { i18n } from '../../i18n';

const prefix = 'dev-portal/organisation';

export const actions = {
  GET_ORGANISATION_STARTED: `${prefix}/GET_ORGANISATION_STARTED`,
  GET_ORGANISATION_SUCCESS: `${prefix}/GET_ORGANISATION_SUCCESS`,
  GET_ORGANISATION_ERROR: `${prefix}/GET_ORGANISATION_ERROR`,

  ACTIVATE_ORGANISATION_STARTED: `${prefix}/ACTIVATE_ORGANISATION_STARTED`,
  ACTIVATE_ORGANISATION_SUCCESS: `${prefix}/ACTIVATE_ORGANISATION_SUCCESS`,
  ACTIVATE_ORGANISATION_ERROR: `${prefix}/ACTIVATE_ORGANISATION_ERROR`,

  doGetOrganisation: () => async dispatch => {
    try {
      dispatch({
        type: actions.GET_ORGANISATION_STARTED,
      });

      const org = await OrganisationService.getOrganisation();

      dispatch({
        type: actions.GET_ORGANISATION_SUCCESS,
        payload: org,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: actions.GET_ORGANISATION_ERROR,
      });
    }
  },

  doActivateOrganisation: data => async dispatch => {
    try {
      dispatch({
        type: actions.ACTIVATE_ORGANISATION_STARTED,
      });

      await OrganisationService.activateOrganisation(data);

      dispatch({
        type: actions.ACTIVATE_ORGANISATION_SUCCESS,
      });

      Message.success(i18n('organisation.activation.success'));
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: actions.ACTIVATE_ORGANISATION_ERROR,
      });
    }
  },
};

/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
// import PermissionChecker from 'modules/auth/permissionChecker';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import featureFlags from 'modules/featureFlags';

function PublicRoute({
  component: Component,
  currentUser,
  featureFlag,
  ...rest
}) {
  return (
    <Route
      {...rest}
      render={props => {
        // const permissionChecker = new PermissionChecker(currentUser);

        // if (permissionChecker.isAuthenticated) {
        //   return <Redirect to="/" />;
        // }

        if (featureFlag && !featureFlags.isOn(featureFlag)) {
          return (
            <Redirect
              to={{
                pathname: '/',
              }}
            />
          );
        }

        return <Component {...props} />;
      }}
    />
  );
}

export default PublicRoute;

import apiFetch from '../shared/fetch/apiFetch';

export default class WebhooksService {
  static async getWebhookDestinations(appToken, { appEnvironment }) {
    const response = apiFetch(
      `/webhooks/applications/${appToken}/destinations`,
      { appEnvironment },
    );
    const { data } = await response;

    return data;
  }

  static async createWebhookDestination(appToken, body, { appEnvironment }) {
    const response = apiFetch(
      `/webhooks/applications/${appToken}/destinations`,
      { appEnvironment, method: 'POST', body },
    );
    const { data } = await response;

    return data;
  }

  static async updateDefaultDestination(appToken, url, { appEnvironment }) {
    const response = apiFetch(
      `/webhooks/applications/${appToken}/destinations/default`,
      { appEnvironment, method: 'PUT', body: { url } },
    );
    const { data } = await response;

    return data;
  }

  static async getDefaultDestination(appToken, { appEnvironment }) {
    const response = apiFetch(
      `/webhooks/applications/${appToken}/destinations/default`,
      { appEnvironment },
    );
    const { data } = await response;

    return data;
  }

  static async getWebhookLogs(
    appToken,
    destinationId,
    queryStringParams,
    { appEnvironment },
  ) {
    const response = apiFetch(
      `/logs/applications/${appToken}/webhooks/destinations/${destinationId}?${queryStringParams}`,
      { appEnvironment },
    );
    const { data } = await response;

    return data;
  }

  static async resendWebhook(
    appToken,
    destinationId,
    messageId,
    { appEnvironment },
  ) {
    const response = apiFetch(
      `/webhooks/applications/${appToken}/messages/${messageId}/resend/${destinationId}`,
      { appEnvironment, method: 'POST' },
    );
    const { data } = await response;

    return data;
  }

  static async setDestinationEnabled(
    appToken,
    destinationId,
    enable,
    { appEnvironment },
  ) {
    const response = apiFetch(
      `/webhooks/applications/${appToken}/destinations/${destinationId}/enable`,
      {
        appEnvironment,
        ignoreResponseBody: true,
        method: 'PUT',
        body: { enable },
      },
    );
    await response;
  }

  static async getEventTypes({ appEnvironment }) {
    const response = apiFetch(`/webhooks/events`, { appEnvironment });
    const { data } = await response;

    return data;
  }

  static async deleteWebhookDestination(
    appToken,
    destinationId,
    { appEnvironment },
  ) {
    const response = apiFetch(
      `/webhooks/applications/${appToken}/destinations/${destinationId}`,
      { appEnvironment, method: 'DELETE' },
    );
    const { data } = await response;

    return data;
  }
}

import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components/macro';

const $FilterBar = styled.div`
  display: flex;
  flex-direction: row;
  margin: 16px 0;
`;

const FilterBar = ({ children }) => <$FilterBar>{children}</$FilterBar>;

FilterBar.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FilterBar;

import styled from 'styled-components/macro';

import $Main from './Main';
import $Sidenav from './Sidenav';
import $Topnav from './Topnav';

const $Layout = styled.div`
  display: grid;
  grid-template-rows: ${({ theme }) =>
      theme.layout.topnav.height} 1fr 0;
  height: 100vh;
  grid-template-columns: ${({ theme }) =>
      theme.layout.sidenav.width} 1fr;
  grid-template-areas:
    'sidenav topnav'
    'sidenav main';

  ${$Sidenav} {
    grid-area: sidenav;
  }

  ${$Topnav} {
    grid-area: topnav;
  }

  ${$Main} {
    grid-area: main;
  }
`;

export default $Layout;

import React from 'react';
import { $Menu } from 'styles';
import PropTypes from 'prop-types';
import { generatePath } from 'react-router';
import { privateRoutes } from 'view/routes';
import PermissionChecker from 'modules/auth/permissionChecker';
import featureFlags from 'modules/featureFlags';
import { currentUserPropTypes, matchPropTypes } from 'utils/proptypes';

import Footer from '../Footer';
import MenuLink from '../MenuLink';
import AppProfile from '../AppProfile';
import EnvironmentSection from './EnvironmentSection';
import { $Divider, $MenuLinksContainer } from './styles';
import generateBreadcrumbs from '../BreadCrumbs/generateBreadcrumbs';

const Menu = ({
  appId,
  match,
  scopes,
  appName,
  appLogoUrl,
  currentUser,
  environment,
  toggleEnvironmentValue,
  isToggleEnvironmentPossible,
}) => {
  const matchPermissions = permission => {
    const permissionChecker = new PermissionChecker(
      currentUser,
      scopes,
      environment,
    );

    return permissionChecker.check(permission);
  };
  return (
    <$Menu>
      <AppProfile appLogoUrl={appLogoUrl} appName={appName} />
      <$Divider />

      <EnvironmentSection
        appId={appId}
        appName={appName}
        environment={environment}
        userEmail={currentUser && currentUser.email}
        toggleEnvironmentValue={toggleEnvironmentValue}
        isToggleEnvironmentPossible={isToggleEnvironmentPossible}
      />
      <$Divider />

      <$MenuLinksContainer>
        {privateRoutes
          .filter(privateRoute => privateRoute.menu)
          .filter(privateRoute => {
            if (!privateRoute.featureFlag) {
              return true;
            }

            return featureFlags.isOn(privateRoute.featureFlag);
          })
          .filter(privateRoute =>
            matchPermissions(privateRoute.permissionRequired),
          )
          .filter(
            privateRoute =>
              !(
                privateRoute.showOnlyOnEnv &&
                privateRoute.showOnlyOnEnv !== environment
              ),
          )
          .map(({ icon, label, path }) => {
            // Bit hacky while we get more purpose-built routing
            // If it's an :id based route, swap the id out for first app id
            const generatedPath =
              path.includes(':id') && appId
                ? generatePath(path, {
                    id: appId,
                  })
                : path;

            // Keep the menu active even when the user is viewing its children pages
            const crumbs = generateBreadcrumbs(match);
            const isActiveMenu = crumbs.some(
              crumb => crumb.path === generatedPath,
            );

            return (
              <MenuLink
                icon={icon}
                isActive={isActiveMenu}
                key={path}
                path={generatedPath}
              >
                {label}
              </MenuLink>
            );
          })}
      </$MenuLinksContainer>
      <Footer />
    </$Menu>
  );
};

Menu.propTypes = {
  appId: PropTypes.string,
  currentUser: currentUserPropTypes,
  scopes: PropTypes.arrayOf(PropTypes.string),
  match: matchPropTypes,
  ...AppProfile.propTypes,
};

Menu.defaultProps = {
  appId: '',
  scopes: [],
  ...AppProfile.defaultProps,
};

export default Menu;

/* eslint-disable no-console */
import Message from 'view/shared/message';
import { getHistory } from 'modules/history';
import AuthService from 'modules/auth/authService';

import { i18n } from 'i18n';

const DEFAULT_ERROR_MESSAGE = i18n('errors.defaultErrorMessage');

function selectErrorMessage(error) {
  if (error) {
    if (error.data) {
      // standard lean api error format => data.error
      // sanbox.leantech.me api error format => data.message
      return error.data.error || error.data.message;
    }
  }

  return error?.response?.statusText || DEFAULT_ERROR_MESSAGE;
}

function selectErrorCode(error) {
  if (error && error.response && error.response.status) {
    return error.response.status;
  }

  if (error && error.data && error.data.status) {
    return error.data.status;
  }

  return 500;
}

function selectErrorStatusOrStatusCode(error) {
  if (error && error.data) {
    return error.data.status || error.data.status_code;
  }

  return '';
}

export default class Errors {
  static handle(error) {
    if (process.env.NODE_ENV === 'development') {
      console.error(selectErrorMessage(error));
      console.error(error);
    }

    if (selectErrorCode(error) === 403) {
      getHistory().push('/403');
      return;
    }

    if (selectErrorCode(error) === 400) {
      Message.error(selectErrorMessage(error));
      return;
    }

    if (selectErrorCode(error) === 409) {
      Message.error(selectErrorMessage(error));
      return;
    }

    // If not authorized redirect to signin URL
    if (selectErrorCode(error) === 401) {
      AuthService.signout()
      getHistory().push('/auth/signin');
      Message.error(selectErrorMessage(error));
      return;
    }

    if (selectErrorCode(error) === 429) {
      getHistory().push('/429');
      return;
    }

    if (selectErrorCode(error) === 404) {
      getHistory().push('/404');
      return;
    }

    if (selectErrorCode(error) === 500 && selectErrorStatusOrStatusCode(error) === 'EXTERNAL_CLIENT_SERVICE_FAILURE') {
      Message.error(selectErrorMessage(error));
      return;
    }

    getHistory().push('/500');
  }

  static errorCode(error) {
    return selectErrorCode(error);
  }

  static errorStatusOrStatusCode(error) {
    return selectErrorStatusOrStatusCode(error);
  }

  static selectMessage(error) {
    return selectErrorMessage(error);
  }

  static showMessage(error) {
    Message.error(selectErrorMessage(error));
  }
}

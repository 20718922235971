import React, { useState } from 'react';
import { i18n } from 'i18n';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';
import authActions from 'modules/auth/authActions';
import authSelectors from 'modules/auth/authSelectors';
import { $DropdownButton } from './form/DropdownButton/styles';
import AccountSettingsDialog from './AccountSettings/AccountSettingsDialog';
import Tooltip from './Tooltip';
import Logo from './Logo';

const logoWidth = '32px';

const $DropdownButtonContainer = styled.div`
  margin-right: ${logoWidth};

  button {
    padding: 0;
    border-radius: ${logoWidth};
  }
`;

const $LogoWrapper = styled.div`
  width: ${logoWidth};

  div {
    border-radius: ${logoWidth};
  }
`;

const $DropdownContent = styled.div`
  width: 210px;
  padding: 16px;
`;

const $DropdownItem = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  padding: 12px;
  cursor: pointer;
  font-size: ${({ theme }) => theme.font.size.body.md};

  &.sign-out {
    color: ${({ theme }) => theme.colors.coral._50};
  }

  ion-icon {
    margin-right: 12px;
  }

  :hover {
    background-color: ${({ theme }) => theme.colors.neutral._20};
    border-radius: 4px;
  }
`;

function UserMenu({ dispatch, currentUser }) {
  const [menuIsVisible, setMenuIsVisible] = useState(false);
  const [accountSettingsIsOpen, setAccountSettingsIsOpen] = useState(false);

  const showMenu = () => setMenuIsVisible(true);
  const hideMenu = () => setMenuIsVisible(false);

  const handleSignOut = () => {
    hideMenu();
    dispatch(authActions.doSignout());
  };

  const toggleAccountSettings = () => {
    hideMenu();
    setAccountSettingsIsOpen(!accountSettingsIsOpen);
  };

  const DropdownContent = () => (
    <$DropdownContent>
      <$DropdownItem
        onClick={toggleAccountSettings}
        data-cy="account_settings_button"
      >
        <ion-icon name="person">Account settings</ion-icon>
        <span>Account settings</span>
      </$DropdownItem>
      <$DropdownItem
        className="sign-out"
        onClick={handleSignOut}
        data-cy="signout_button"
      >
        <ion-icon name="log-out">{i18n('auth.signout')}</ion-icon>
        <span>Sign Out</span>
      </$DropdownItem>
    </$DropdownContent>
  );

  return (
    <>
      <Tooltip
        content={<DropdownContent />}
        placement="bottom-start"
        visible={menuIsVisible}
        onClickOutside={hideMenu}
      >
        <$DropdownButtonContainer>
          <$DropdownButton
            id="user-menu"
            name="user-menu"
            onClick={() => showMenu()}
            data-cy="dropdown_user_menu"
            aria-labelledby="User Menu Label"
            aria-describedby="User Menu Description"
          >
            <$LogoWrapper>
              <Logo
                name={`${currentUser.first_name} ${currentUser.last_name}`}
              />
            </$LogoWrapper>
          </$DropdownButton>
        </$DropdownButtonContainer>
      </Tooltip>

      <AccountSettingsDialog
        isOpen={accountSettingsIsOpen}
        toggleDialog={toggleAccountSettings}
      />
    </>
  );
}

UserMenu.propTypes = {
  dispatch: PropTypes.func.isRequired,
  currentUser: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
  }),
};

UserMenu.defaultProps = {
  currentUser: null,
};

function select(state) {
  return {
    currentUser: authSelectors.selectCurrentUser(state),
  };
}

export default connect(select)(UserMenu);

import { createSlice } from '@reduxjs/toolkit';
import ApplicationListActions from 'modules/application/list/applicationListActions';
import ApplicationListSelectors from 'modules/application/list/applicationListSelectors';
import {
  ENVIRONMENT_SANDBOX,
  ENVIRONMENT_PRODUCTION,
  ENVIRONMENT_PRE_LIVE,
} from 'utils/constants';
import authSelectors from 'modules/auth/authSelectors';
import PermissionChecker from 'modules/auth/permissionChecker';
import { getHistory } from 'modules/history';
import featureFlags, { FeatureFlagsAttributes } from 'modules/featureFlags';
import { reloadApp } from './bootstrap';
import { privateRoutes } from '../view/routes';

const initialState = {
  environment: '',
  appToken: '',
};

export const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    updateEnvironment: (state, action) => {
      state.environment = action.payload;
    },
    updateAppToken: (state, action) => {
      state.appToken = action.payload;
    },
  },
});

// APIs
const ENVIRONMENT_LOCAL_STORAGE_KEY = '@GLOBAL/ENVIRONMENT';

const getEnvironmentFromLocalStorage = async () =>
  localStorage.getItem(ENVIRONMENT_LOCAL_STORAGE_KEY);

const setEnvironmentToLocalStorage = async value =>
  localStorage.setItem(ENVIRONMENT_LOCAL_STORAGE_KEY, value);

// Action creators
const { updateEnvironment, updateAppToken } = globalSlice.actions;

const getDefaultEnvironment = applications => {
  const { sandbox, production, preLive } = applications;

  // If user has only production apps
  if (sandbox.length === 0 && preLive.length === 0 && production.length > 0) {
    return ENVIRONMENT_PRODUCTION;
  }

  // If user has only pre live apps
  if (sandbox.length === 0 && production.length === 0 && preLive.length > 0) {
    return ENVIRONMENT_PRE_LIVE;
  }

  return ENVIRONMENT_SANDBOX;
};

const getAppIdForEnvironmentFromAppList = ({
  environment,
  applicationList,
}) => {
  const appId =
    applicationList &&
    applicationList[environment] &&
    applicationList[environment][0] &&
    applicationList[environment][0].id;

  return appId || '';
};

// Actions
export const setAppToken = value => async dispatch => {
  dispatch(updateAppToken(value));

  featureFlags.setAttributes({
    [FeatureFlagsAttributes.appToken]: value,
  });
};

export const setEnvironment = value => async dispatch => {
  dispatch(updateEnvironment(value));

  featureFlags.setAttributes({
    [FeatureFlagsAttributes.accessLevel]: value,
  });
};

export const setEnvironmentInitialValue =
  isToggleEnvironment => async (dispatch, getState) => {
    let environmentFromURL;

    const applications = ApplicationListSelectors.selectApplicationList(
      getState(),
    );
    // Since there is no longer a default application env, check which exist and set that in redux
    const defaultEnvironment = getDefaultEnvironment(applications);

    const { router } = getState();
    const { pathname } = router.location;
    const pathnameArray = pathname.split('/');
    const applicationList = ApplicationListSelectors.selectApplicationList(
      getState(),
    );

    if (pathnameArray[1] === 'id' && !isToggleEnvironment) {
      // current pathname has id
      const appIdFromURL = pathnameArray[2];
      environmentFromURL = dispatch(
        ApplicationListActions.getApplicationEnvironment(appIdFromURL),
      );
      if (environmentFromURL) {
        // if appId in url is for an app in redux (either sandbox or production), set its env to localStorage
        await setEnvironmentToLocalStorage(environmentFromURL);
      }
    }

    const environmentFromLocalStorage = await getEnvironmentFromLocalStorage();

    // if env value isn't stored in local storage: set env value from redux in local storage
    if (!environmentFromLocalStorage && !environmentFromURL) {
      const environmentFromRedux = selectEnvironment(getState());
      const availableEnvironment = environmentFromRedux || defaultEnvironment;

      const targetApplicationId = getAppIdForEnvironmentFromAppList({
        environment: availableEnvironment,
        applicationList,
      });

      // If there is nothing set in state, use the default depending on what's available.
      await setEnvironmentToLocalStorage(availableEnvironment);
      dispatch(setEnvironment(availableEnvironment));
      dispatch(setAppToken(targetApplicationId));
    } else {
      // if env value is stored in local storage: set env value from localStorage in redux
      let environment = environmentFromURL || environmentFromLocalStorage;
      // check if environment production/preLive that user has appropriate app. if not set to sandbox
      const userHasProductionApp =
        ApplicationListSelectors.selectHasProductionApplication(getState());
      const userHasPreLiveApp =
        ApplicationListSelectors.selectHasPreLiveApplication(getState());
      if (
        (environment === ENVIRONMENT_PRODUCTION && !userHasProductionApp) ||
        (environment === ENVIRONMENT_PRE_LIVE && !userHasPreLiveApp)
      ) {
        environment = ENVIRONMENT_SANDBOX;
      }

      const targetApplicationId = getAppIdForEnvironmentFromAppList({
        environment,
        applicationList,
      });

      dispatch(setEnvironment(environment));
      dispatch(setAppToken(targetApplicationId));

      if (environment !== environmentFromLocalStorage) {
        await setEnvironmentToLocalStorage(environment);
      }
    }
  };

export const toggleEnvironmentValue =
  ({ currentRoutePath = '', environment } = {}) =>
  async (dispatch, getState) => {
    const application = ApplicationListSelectors.selectApplication(getState());
    const applicationList = ApplicationListSelectors.selectApplicationList(
      getState(),
    );
    const { router, root } = getState();
    const { pathname } = router.location;
    const { auth } = root;
    const { currentUser } = auth;

    const targetApplicationId = getAppIdForEnvironmentFromAppList({
      environment,
      applicationList,
    });

    // check if user has access to the same current page on the other environment
    const permissionChecker = new PermissionChecker(
      currentUser,
      authSelectors.selectScopes(getState()),
      environment,
    );

    const isPageAccessibleOnNewEnvironment =
      permissionChecker.isPageAccessibleOnEnvironment(pathname);

    const currentRoute = privateRoutes.find(
      route => route.path === currentRoutePath,
    );

    if (isPageAccessibleOnNewEnvironment && currentRoute?.isDetailsPage) {
      // go back to the parent page or the first accessible page
      getHistory().push(
        permissionChecker.getParentPage(application.id, currentRoutePath),
      );
    }

    if (!isPageAccessibleOnNewEnvironment) {
      // if isPageAccessibleOnNewEnvironment is false, redirect to first accessible page from menu
      getHistory().push(
        permissionChecker.getFirstAccessiblePage(application.id),
      );
    }

    // update environment value in local storage and redux store
    await setEnvironmentToLocalStorage(environment);
    dispatch(setEnvironment(environment));
    dispatch(setAppToken(targetApplicationId));

    await dispatch(reloadApp());
  };

// Selectors
export const selectEnvironment = state => state.root.global.environment;
export const selectAppToken = state => state.root.global.appToken;

export default globalSlice.reducer;
